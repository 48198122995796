import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

import "./ReservationInfo.css";
import logo from '../../image/Aya&Reem.png';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DownloadIcon from '@mui/icons-material/Download';

import { saveAs } from "file-saver";
import { AlignmentType, HeadingLevel, TabStopPosition, TabStopType, TextRun, Document, ImageRun, Packer, Paragraph, TextDirection } from "docx";

export default function Contract(props) {

    const [startDate, setStartDate] = useState('');
    useEffect(()=>{
        let newStart;
        if (props.reservationInfo?.loggedIn_At) {
            newStart = props.reservationInfo?.loggedIn_At.split(" ")[2]
            let day = newStart.split('/')[0];
            let month = parseInt(newStart.split('/')[1]) - 1;
            let year = newStart.split('/')[2];

            newStart = new Date(year, month, day, 0, 0, 0).getTime();
        } else {
            newStart = new Date(props.reservationInfo?.checkIn).getTime();
        }
        let startDay = new Date(newStart).getDate();
        let startMonth = new Date(newStart).getMonth()+1;
        let startYear = new Date(newStart).getFullYear();
        setStartDate(`${startDay}/${startMonth}/${startYear}`);
    },[])

    const Export2Doc = async () => {

        const blob = await fetch(
            `${process.env.REACT_APP_BACK}/myImage/Aya&Reem.png`
        ).then(r => r.blob());
        let period = 'الشهرية';
        let partPeriod = 'شهرية';
        if (props.reservationInfo?.rent_type === 'اسبوعي') {
            period = 'الاسبوعية';
            partPeriod = 'اسبوعية';
        } else if (props.reservationInfo?.rent_type === 'يومي') {
            period = 'اليومية'
            partPeriod = 'سومية'
        }
        const doc = new Document({
            direction: TextDirection.TOP_TO_BOTTOM_RIGHT_TO_LEFT,
            sections: [
                {
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            textDirection: TextDirection.TOP_TO_BOTTOM_RIGHT_TO_LEFT,
                            direction: TextDirection.TOP_TO_BOTTOM_RIGHT_TO_LEFT,
                            children: [
                                new ImageRun({
                                    data: blob,
                                    transformation: {
                                        width: 100,
                                        height: 100
                                    }
                                })
                            ]
                        }),
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            bidirectional: true,
                            children: [
                                // new TextRun({text:"سكن آيه وريم للطالبات", bold:true, underline:true}),
                                new TextRun({
                                    text: " سكن آيه وريم للطالبات ",
                                    bold: true,
                                    underline: true,
                                }),
                            ],
                        }),
                        new Paragraph(""),
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: "عفد ايجار",
                                    bold: true,
                                    underline: true
                                })
                            ]
                        }),
                        new Paragraph(""),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: " الفريـــق الاول : ",
                                    bold: true,
                                    underline: true
                                }),
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "شركة خلف المدان وعيسى ابو غوش ) سكن اية و ريم( ",
                                }),
                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "                     ويمثلهم السيدة ريم محمود حمودة.",
                                }),
                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "                     هاتف 0790203133",
                                }),
                            ]
                        }),
                        new Paragraph(""),

                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "الفريق الثاني:  ",
                                    bold: true,
                                    underline: true
                                }),
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: `${props.guest.name} `,
                                }),
                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [

                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "                     الرقم الوطني /رقم جواز",
                                }),
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: `${props.guest.id_number} `,
                                }),
                            ]
                        }),

                        new Paragraph({
                            bidirectional: true,
                            children: [

                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "                     الجنسية ",
                                }),
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: `${props.guest.nationallity} `,
                                }),
                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [

                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "                     هاتف ",
                                }),
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: `${props.guest.phone_number} `,
                                }),
                            ]
                        }),
                        new Paragraph(""),

                        new Paragraph({
                            alignment: AlignmentType.RIGHT,
                            children: [
                                new TextRun({
                                    text: "المقدمة",
                                    bold: true,
                                    underline: true
                                }),
                            ]
                        }),

                        new Paragraph({
                            // textDirection: TextDirection.BOTTOM_TO_TOP_LEFT_TO_RIGHT,
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    text: "ان الفريق الاول شركة متخصصة بانشطة تاجيرالشقق السكنية , يمتلك العقار المقام على قطعة الارض رقم 190 حوض الطوال رقم 4 من اراضي ناعور في منطقة مرج الحمام ويملك حق التصرف فيه ,فقد تم الاتفاق والرضا بين الفريقين على ما يلي:",
                                    alignment: AlignmentType.RIGHT,
                                }),
                            ],
                        }),
                        new Paragraph(""),

                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "أولا : ",
                                    bold: true,
                                    underline: true
                                }),
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: " . تعتبر مقدمة هذا العقد جزءاً لا يتجزأ منه وتقرأ معه ",
                                }),

                            ]
                        }),
                        new Paragraph(""),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "ثانيا : ",
                                    bold: true,
                                    underline: true
                                }),
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: ` أ- وافق الفريق الاول على تأجير الفريق الثاني غرفة رقم (${props.reservationInfo?.room_id}) في البناء الموصوف أعلاه `,
                                }),

                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "       ب- يقر الفريق الثاني بانه استلم المأجور موضوع هذا العقد سليماً خالياً من العيوب او الخلل حسب الملحق رقم (1). ",
                                }),
                            ]
                        }),

                        new Paragraph(""),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "ثالثا : ",
                                    bold: true,
                                    underline: true
                                }),
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: ` أ- اتفق الفريقان على أن تكون مدة هذا العقد هي ${props.duration} تبدأ من تاريخ ${startDate} ولا يجدد هذا العقد إلا باتفاق الفريقين الخطي. `,
                                }),

                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: " ب- اتفق الفريقان أن مدة العقد المذكورة سابقا هي مدة إلزامية للفريق الثاني على ان تكون كامل الفترة التعاقدية وجوبية بحيث يكون الفريق الثاني ملزماً باداء بدل كامل الاجرة عن كامل الفترة حتى لو ترك المأجور قبل انقضاء الفترة المذكورة اعلاه "
                                }),
                            ]
                        }),

                        new Paragraph(""),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "رابعا : ",
                                    bold: true,
                                    underline: true
                                }),
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: ` ا- تكون الأجرة ${period} للمأجور الموصوف في مقدمة هذا العقد (${(props.reservationInfo?.price / props.reservationInfo?.period.length)?.toFixed(2)}) دينار يلتزم الفريق الثاني بدفعها للفريق الأول دفعة مقدما بداية تاريخ العقد, وتشمل بدل الاجرة وبدل الخدمات واشتراك الانترنت الشهري ولا يسمح بتقسيط و/او تاجيل اي دفعة ${partPeriod} باي حال من الاحوال. `,

                                }),

                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "     ب- يلتزم المستأجر بدفع بدل استهلاك المياه والكهرباء  حسب قراءة العدادات ولا يحق له تاجيل الدفع. "
                                }),
                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "     ج- في حالة تخلف الفريق الثاني عن دفع أي قسط من الأقساط في ميعاد استحقاقها فتصبح جميع الأقساط الأخرى مستحقة الأداء حالا وفورا وللفريق الاول الحق بفسخ العقد واستلام المأجور فوراً . وله الحق في وضع يده عليه وإجارته للغير بالبدل الذي يراه مناسباً على أن يعود بالفرق بين البدلين على الفريق الثاني. "
                                }),
                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "     د - يلتزم الفريق الثاني بدفع أية رسوم أو ضرائب تفرضها أية جهة رسمية أو حكومية أو   شبه حكومية بما فيها أمانة عمان الكبرى او البلديات المختصة. "
                                }),
                            ]
                        }),

                        new Paragraph(""),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "خامسا : ",
                                    bold: true,
                                    underline: true
                                }),
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: " أ- يقوم الفريق الثاني بدفع مبلغ (150 دينار) كمبلغ تامين في حال حصول اي نوع من انوع الضرر على الماجور و محتوياته بمختلف اشكالها فيعود تصليحه على الفريق الثاني الذي لا يحق له مطالبة الفريق الأول بأي شيء من التعويضات أو النفقات, وللفريق الاول دون الحاجة إلى إنذار أن يجري هذه الاصلاحات على حساب الفريق الثاني وتعتمد دفاتر وحسابات الفريق الاول لتحديد قيمة ما أنفقه في سبيل ذلك. ",
                                }),

                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "     ب- لا يحق للفريق الثاني أن يطالب بأية تعويضات مقابل أي عطل أو ضرر مهما كان نوعه ناجم أو قد ينجم عن أي عطل أو خلل طارئ يحصل في المرافق العامة الملحقة بالمبنى أو أي عطل للخدمات مثل المصاعد والتبريد والتكييف أثناء فترة الصيانة أو الإصلاح وإعادة التشغيل. "
                                }),
                            ]
                        }),

                        new Paragraph(""),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "سادسا : ",
                                    bold: true,
                                    underline: true
                                }),
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: " أ- لا يحق للفريق الثاني تغيير أو نقل مكان او اضافة أي من محتويات الماجور بمختلف اشكالها دون موافقة الفريق الأول الخطية المسبقة ويتحمل الفريق الثاني تبعة إخلاله بهذا البند بما في ذلك أي عطل أو ضرر أو خراب قد يحدث للمأجور. ",
                                }),

                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "        ب- لا يحق للفريق الثاني التنازل عن هذا العقد بحقوقه والتزاماته ولا يحق له أن يؤجر المأجور أو أي جزء منه إلى الغير أو يسمح بإشغاله من قبل شريك جديد أو أي شخص آخرمجاناً أو بمقابل دون موافقة المالك الخطية والمسبقة. "
                                }),
                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "    ج- في حالة حدوث أن الفريق الثاني في هذا العقد هو أكثر من شخص واحد ما فيعتبرون جميعاً متكافلين متضامنين فيه وفي جميع أحكامه والتزاماته وإن أي تبليغ لأي واحد منهم يعتبر بمثابة تبليغ لهم جميعاً وكل واحد منهم ملزم ومسؤول بالقيام بكافة وكامل الالتزامات المالية والقانونية المترتبة عليه وعلى الآخرين معه بموجب هذا العقد باعتبارهم الفريق الثاني ."
                                }),
                            ]
                        }),

                        new Paragraph(""),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "سابعا : ",
                                    bold: true,
                                    underline: true
                                }),
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "يتعهد الفريق الثاني بالسماح لموظفي الفريق الاول أو من ينتدبهم لدخول المأجور لإجراء أية تصليحات أو صيانة حتى ولو كانت متعلقة بمصلحة مأجور أخر دون أي اعتراض أو تأخير من قبل الفريق الثاني ومهما بلغت مدة الصيانة والإصلاح.",
                                }),

                            ]
                        }),

                        new Paragraph(""),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "ثامنا : ",
                                    bold: true,
                                    underline: true
                                }),
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "يلتزم الفريق الثاني بكل التزام لم يذكر في هذا العقد و من ضمنها انظمة و تعليمات السكن الداخلية حسب ملحق رقم (2(",
                                }),

                            ]
                        }),

                        new Paragraph(""),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "تاسعا : ",
                                    bold: true,
                                    underline: true
                                }),
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "في حالة نشوء أي خلاف أو نزاع حول تفسير و/ أو تطبيق و/أو تنفيذ أي بند من بنود هذه الاتفاقية فقد اتفق الفريقان على إحالة هذا الخلاف لمحكمة قصر العدل في عمان باعتبارها المحكمة المختصة مكانياً وعلى أن يتم الخضوع لأحكام القانون الأردني.",
                                }),
                            ]
                        }),

                        new Paragraph(""),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "عاشرا : ",
                                    bold: true,
                                    underline: true
                                }),
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "في حال عدم الالتزام ببنود العقد او الملحق رقم (2) يحق للفريق الاول فسخ العقد فورا . ",
                                }),
                            ]
                        }),

                        new Paragraph(""),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    text: "أحد عشر : ",
                                    alignment: AlignmentType.RIGHT,
                                    bold: true,
                                    underline: true
                                }),
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: " حرر هذا العقد من احد عشرة بندا بما فيها هذه البند وعلى نسختين أصليتين بيد كل فريق نسخة للعمل بموجبها. ",
                                }),
                            ]
                        }),

                        new Paragraph(""),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: " عمان تحريراً في    /    / ",
                                }),
                            ]
                        }),

                        new Paragraph(""),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    bold: true,
                                    alignment: AlignmentType.RIGHT,
                                    text: " الفريق الأول ",
                                    underline: true
                                }),
                                new TextRun({
                                    bold: true,
                                    alignment: AlignmentType.RIGHT,
                                    text: "								        ",
                                }),
                                new TextRun({
                                    bold: true,
                                    alignment: AlignmentType.RIGHT,
                                    text: "الفريق الثاني ",
                                    underline: true
                                }),
                            ]
                        }),

                        new Paragraph(""),
                        new Paragraph(""),
                        new Paragraph(""),
                        new Paragraph(""),
                        new Paragraph(""),
                        new Paragraph(""),

                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    bold: true,
                                    alignment: AlignmentType.RIGHT,
                                    text: " ملحق  رقم (1) ",
                                    underline: true
                                }),
                            ]
                        }),

                        new Paragraph(""),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: " تم استلام المأجور بدون اي اضرار او نواقص حسب المذكورات التالية : ",
                                }),
                            ]
                        }),

                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: " ثلاجة, تلفزيون 32 بوصة , ريموت تلفزيون , وصلة التلفزيون , رسيفر , ريموت رسيفر , قواعد خشب اسفل التلفزيون (عدد 2) , مكيف ,ريموت المكيف, طباخ كهرباء,خزانة مطبخ سفلية و علوية, خزانة ملابس خشبية,سرير خشب,راسية سرير ثابتة,فرشة إسفنجية,غطاء فرشة سرير , شرشف سرير , وسادة, غطاء وسادة ,طاولة مكتب ,كرسي , ستارة , منشر غسيل, وجميع محتويات الحمام  ",
                                }),
                            ]
                        }),

                        new Paragraph(""),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    bold: true,
                                    alignment: AlignmentType.RIGHT,
                                    text: " ملحق  رقم (2) ",
                                    underline: true
                                }),
                            ]
                        }),

                        new Paragraph(""),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: `  •الإلتزام بمواعيد فتح وإغلاق باب السكن وهي كالآتي : `,
                                }),
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: ` موعد فتح الباب السادسة صباحاً وموعد إغلاقه العاشرة ليلاً ولا يسمح بالتاخير في اي حال من الاحوال . `,
                                }),
                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "   •في حالات المبيت خارج السكن على الاهل اعلام الادارة خطيا ومسبقا . ",
                                })
                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: " •موعد فتح باب المطبخ السادسة صباحاً وموعد إغلاقه الحادية عشر ليلاً ولا يسمح باستخدام المطبخ بعد ذلك.",
                                })
                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: " •الإلتزام بالآداب العامة داخل السكن , والمحافظة على الهدوء ليلا, وإحترام خصوصيات الطالبات الأخريات.",
                                })
                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: " •المحافظه على مرافق السكن وعدم اساءة استخدامها . "
                                })
                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: " •يقدم السكن خدمات صيانة عالية الجودة وعلى مستوى عالي من الدقة على يد خبراء وفنين معتمدين فيرجى اعلام الادارة عند الحاجة لذلك ."
                                })
                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: " •تعتذر الإدارة عن إستقبال الزوار بعد الساعة العاشرة ليلاً, وفي حالات إستضافة زائرة (مبيت) (وذلك ليوم أو يومين فقط ) يتم دفع 10 دينارعن كل ليلة . "
                                })
                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: " •يرجى المحافظة على نظافة الغرف والممرات والمطبخ المشترك , وعدم إلقاء أي نفايات داخل السكن وعدم وضع مناشر الغسيل بالممرات ."
                                })
                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: " •حفاظاَ على السلامة العامة يتم  إستخدام المصعد بطريقة صحيحه وعدم العبث فيه ."
                                })
                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: " •يرجى عدم إلصاق أي ملصقات داخل السكن او أي من محتويات الغرفة ."
                                })
                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: " •يتم حجزالغرفه اثناء الاجازات وبعد انتهاء العقد مقابل ثلاثة دنانير يوميا ."
                                })
                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: " •في حال ضياع أو طلب نسخة جديدة للمفتاح يتم دفع خمس دنانير."
                                })
                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: " •الإدارة غير مسؤولة عن الممتلكات والمقتنيات الشخصية للطالبة ,فيجب الحرص على عدم ترك الغرفة مفتوحه ."
                                })
                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "  •تتعهد المستاجرة المحافظة على سلامتها الشخصية و سلامة الغير في داخل الماجور و لا يتحمل الفريق الاول اي مسؤولية تجاه اي اصابات او اضرار من الممكن ان تحدث لها لا قدر الله .  "
                                })
                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "  •إعلام الإدارة بمواعيد وايام السفر قبل 48 ساعة من موعد السفر. "
                                })
                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: " •يمنع التواصل مع اي جهة تابعة لادارة السكن و تكون السيدة ريم حمودة هي جهة الوحيدة المسموح التواصل معها فقط ولا يسمح لي اي ولي امر طالبة بالتدخل بشؤون السكن الداخلية"
                                })
                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "  •في حالة مخالفة انظمة السكن يتم توجيه تنبيه خطي , وفي حالة تكرار المخالفه يتم توجيه انذار خطي ملزم ,ويكون للادارة بعدها الحق باتخاذ الاجراء المناسب ومنها اخلاء الماجور فورا . "
                                })
                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "  •يعتبر هذا العقد لاغيا و يتم اخلاء الماجورفورا وحالا في حال مخالفة الطرف الثاني للقوانين و الانظمة المعمول بها في الاردن والاخلاق العامة او ادين بحكم قضائي من قبل الجهات المختصة "
                                })
                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "  •في حال اخلاء الغرف بعد انتهاء مدة العقد يتوجب دفع مبلغ 10 دناير اجور نتظيف بالاضافة الى 5 دناير لتظيف الكفرات في الغرف، و في حال عدم اللاتزام بالتعليمات اعلاه فيتم اقتطاع هذه المصاريف من التامينات، دون الرجوع الى الطرف الثاني  "
                                })
                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "  •يمنع دخول اي زائر الى السكن دون اعلام الطرف الاول على الاقل قبل 24 ساعة من حضوره "
                                })
                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "  •في حال حضور زائر الى غرفة الطالبة يتوجب دفع مبلغ 10 دنانير في حال المبيت بنفس الغرفة "
                                })
                            ]
                        }),
                        new Paragraph({
                            bidirectional: true,
                            children: [
                                new TextRun({
                                    alignment: AlignmentType.RIGHT,
                                    text: "  •الاجرة اليومية للشخص الواحد باليوم الواحد 20 دينار شاملة الخدمات. "
                                })
                            ]
                        }),

                        new Paragraph(""),

                    ]
                }
            ]
        });

        Packer.toBlob(doc).then(blob => {
            saveAs(blob, "عقد الايجار.docx");
        });
    }


    return (
        <Modal show={props.showContract} onHide={props.handleClose} className='contarct_modal'>
            <Modal.Header className='contarct_modal_header'>
                <CloseRoundedIcon onClick={props.handleClose} />
                <Modal.Title>
                    عقد رقم #{props.reservationInfo?.Contract?.id}
                </Modal.Title>
            </Modal.Header>

            <Modal.Header className='contarct_modal_print'>
                <i onClick={() => Export2Doc('myWordEle')}>
                <DownloadIcon/>
                </i>
                <span onClick={() => Export2Doc('myWordEle')} id="handlePrint">تحميل</span>
            </Modal.Header>

            <Modal.Body className='contarct_modal_body'>
                <div ref={props.componentRef} id="myWordEle" className='contarct_modal_body_container'>

                    <div className='contract_Head'>
                        <img className='printCashContainer_Logo' src={logo} alt="logo" />
                        <p className='hotelName_Contract'> {props.hotel?.name} </p>
                    </div>

                    <div className='contarct_modal_body_top'>
                        <span>رقم العقد #{props.reservationInfo?.Contract?.id}</span>
                        <span>التاريخ : {props.date}</span>
                    </div>

                    <div className='contarct_modal_body_middle'>
                        <h3> عقد الإيجار</h3>
                    </div>

                    <div className='contarct_modal_body_table' >

                        <p>
                            <span className='bold_title'>    الفريـــق الاول :   </span>
                            شركة خلف المدان وعيسى ابو غوش ( سكن اية و ريم)
                            <br></br>
                            ويمثلهم السيدة ريم محمود حمودة
                            <br></br>
                            هاتف 0790203133
                            <br></br>

                            <span className='row_info'>
                                <span>{props.guest.name}</span>
                                <span className='bold_title'> :  الفريق الثاني    </span>
                            </span>

                            <br></br>

                            <span className='row_info'>
                                <span>{props.guest.id_number}</span>
                                <span className='bold_title'> : الرقم الوطني /رقم جواز     </span>
                            </span>

                            <br></br>

                            <span className='row_info'>
                                <span>{props.guest.nationallity}</span>
                                <span className='bold_title'> :   الجنسية   </span>
                            </span>

                            <br></br>

                            <span className='row_info'>
                                <span>{props.guest.phone_number}</span>
                                <span className='bold_title'>  :  هاتف    </span>
                            </span>
                            <br></br>
                            <br></br>
                            <span className='bold_title'>: المقدمة      </span>

                            <br></br>
                            ان الفريق الاول شركة متخصصة بانشطة تاجيرالشقق السكنية , يمتلك العقار المقام على قطعة الارض رقم 190
                            حوض الطوال رقم 4 من اراضي ناعور في منطقة مرج الحمام ويملك حق التصرف فيه
                            <br></br>
                            :   فقد تم الاتفاق والرضا بين الفريقين على ما يلي
                            <br></br>
                            <br></br>

                            <span className='bold_title'>   اولاً  :   </span>

                            تعتبر مقدمة هذا العقد جزءاً لا يتجزأ منه وتقرأ معه
                            <br></br>
                            <br></br>
                            <span className='bold_title'> :  ثانياً    </span>
                            <br></br>

                            أ- وافق الفريق الاول على تأجير الفريق الثاني غرفة رقم ( <span className='bold_title'>{props.reservationInfo?.room_id}</span> ) في البناء الموصوف أعلاه
                            <br></br>

                            ب- يقر الفريق الثاني بانه استلم المأجور موضوع هذا العقد سليماً خالياً من العيوب او
                            الخلل حسب الملحق رقم (1)
                            <br></br>
                            <br></br>
                            <span className='bold_title'> :   ثالثاً   </span>

                            <br></br>

                            أ- اتفق الفريقان على أن تكون مدة هذا العقد هي ( <span className='bold_title'>{props.duration} </span>) تبدأ من تاريخ 
                            ( <span className='bold_title'>{props.reservationInfo?.loggedIn_At?props.reservationInfo?.loggedIn_At?.split('  ')[1]:new Date(props.reservationInfo?.checkIn).toLocaleDateString()}</span> ) 
                            ولا يجدد هذا العقد إلا باتفاق الفريقين الخطي
                            <br></br>
                            <br></br>
                            ب- اتفق الفريقان أن مدة العقد المذكورة سابقا هي مدة إلزامية للفريق الثاني الذي إذا أراد إخلاء المأجور قبل إنقضاء مدة العقد، فإنه يترتب عليه دفع بدل الإيجار المحدد في هذا العقد عن كامل المدة، اوفي حال رغبة الفريق الثاني اخلاء الماجورقبل نهاية إنقضاء الفترة العقدية بشرط إعلام الفريق الأول خطياً بذلك قبل شهرعلى الأقل من التاريخ الذي يرغب بترك المأجور بدءاً منه.
                            <br></br>
                            <br></br>
                            <span className='bold_title'> :  رابعاً  </span>

                            <br></br>
                            ا- تكون الأجرة الشهرية للمأجور الموصوف في مقدمة هذا العقد (<span className='bold_title'>  {(props.reservationInfo?.price / props.reservationInfo?.period?.length)?.toFixed(2)} دينار</span>) دينار              يلتزم الفريق الثاني بدفعها للفريق الأول دفعة مقدما بداية تاريخ العقد, وتشمل بدل الاجرة وبدل الخدمات واشتراك الانترنت الشهري
                            <br></br>
                            .   ب- يلتزم المستأجر بدفع بدل استهلاك المياه والكهرباء  حسب قراءة العدادات ولا يحق له تاجيل الدفع
                            <br></br>

                            ج- في حالة تخلف الفريق الثاني عن دفع أي قسط من الأقساط في ميعاد استحقاقها فتصبح جميع الأقساط الأخرى مستحقة الأداء حالا وفورا وللفريق الاول الحق بفسخ العقد واستلام المأجور فوراً . وله الحق في وضع يده عليه وإجارته للغير بالبدل الذي يراه مناسباً على أن يعود بالفرق بين البدلين على الفريق الثاني
                            <br></br>
                            د - يلتزم الفريق الثاني بدفع أية رسوم أو ضرائب تفرضها أية جهة رسمية أو حكومية أو   شبه حكومية بما فيها أمانة عمان الكبرى او البلديات المختصة.

                            <br></br>
                            <br></br>
                            <span className='bold_title'> :  خامساً  </span>

                            <br></br>
                            أ- يقوم الفريق الثاني بدفع مبلغ (<span className='bold_title'>150 دينار</span>) كمبلغ تامين في حال حصول اي نوع من انوع الضرر على الماجور و محتوياته بمختلف اشكالها فيعود تصليحه على الفريق الثاني الذي لا يحق له مطالبة الفريق الأول بأي شيء من التعويضات أو النفقات, وللفريق الاول دون الحاجة إلى إنذار أن يجري هذه الاصلاحات على حساب الفريق الثاني وتعتمد دفاتر وحسابات الفريق الاول لتحديد قيمة ما أنفقه في سبيل ذلك
                            <br></br>
                            ب- لا يحق للفريق الثاني أن يطالب بأية تعويضات مقابل أي عطل أو ضرر مهما كان نوعه ناجم أو قد ينجم عن أي عطل أو خلل طارئ يحصل في المرافق العامة الملحقة بالمبنى أو أي عطل للخدمات مثل المصاعد والتبريد والتكييف أثناء فترة الصيانة أو الإصلاح وإعادة التشغيل.
                            <br></br>
                            <br></br>
                            <span className='bold_title'>  :   سادساً  </span>

                            <br></br>
                            أ- لا يحق للفريق الثاني تغيير أو نقل مكان او اضافة أي من محتويات الماجور بمختلف اشكالها دون موافقة الفريق الأول الخطية المسبقة ويتحمل الفريق الثاني تبعة إخلاله بهذا البند بما في ذلك أي عطل أو ضرر أو خراب قد يحدث للمأجور
                            <br></br>

                            ب- لا يحق للفريق الثاني التنازل عن هذا العقد بحقوقه والتزاماته ولا يحق له أن يؤجر المأجور أو أي جزء منه إلى الغير أو يسمح بإشغاله من قبل شريك جديد أو أي شخص آخرمجاناً أو بمقابل دون موافقة المالك الخطية والمسبقة
                            <br></br>
                            ج- في حالة حدوث أن الفريق الثاني في هذا العقد هو أكثر من شخص واحد ما فيعتبرون جميعاً متكافلين متضامنين فيه وفي جميع أحكامه والتزاماته وإن أي تبليغ لأي واحد منهم يعتبر بمثابة تبليغ لهم جميعاً وكل واحد منهم ملزم ومسؤول بالقيام بكافة وكامل الالتزامات المالية والقانونية المترتبة عليه وعلى الآخرين معه بموجب هذا العقد باعتبارهم الفريق الثاني
                            <br></br>
                            <br></br>
                            <span className='bold_title'> :  سابعا </span>

                            <br></br>

                            يتعهد الفريق الثاني بالسماح لموظفي الفريق الاول أو من ينتدبهم لدخول المأجور لإجراء أية تصليحات أو صيانة حتى ولو كانت متعلقة بمصلحة مأجور أخر دون أي اعتراض أو تأخير من قبل الفريق الثاني ومهما بلغت مدة الصيانة والإصلاح
                            <br></br>
                            <br></br>
                            <span className='bold_title'>  :  ثامنا </span>

                            <br></br>
                            (2) يلتزم الفريق الثاني بكل التزام لم يذكر في هذا العقد و من ضمنها انظمة و تعليمات السكن الداخلية حسب ملحق رقم
                            <br></br>
                            <br></br>
                            <span className='bold_title'>   :  تاسعا </span>

                            <br></br>
                            في حالة نشوء أي خلاف أو نزاع حول تفسير و/ أو تطبيق و/أو تنفيذ أي بند من بنود هذه الاتفاقية فقد اتفق الفريقان على إحالة هذا الخلاف لمحكمة قصر العدل في عمان باعتبارها المحكمة المختصة مكانياً وعلى أن يتم الخضوع لأحكام القانون الأردني
                            <br></br>
                            <br></br>
                            <span className='bold_title'>    :  عاشرا</span>

                            <br></br>
                            في حال عدم الالتزام ببنود العقد او الملحق رقم (2) يحق للفريق الاول فسخ العقد فورا
                            <br></br>
                            <br></br>
                            <span className='bold_title'>    :  احد عشر</span>

                            <br></br>
                            حرر هذا العقد من احد عشرة بندا بما فيها هذه البند وعلى نسختين أصليتين بيد كل فريق نسخة للعمل بموجبها
                            <br></br>
                            <br></br>


                            <span className='date_space'>
                                <span>/</span>
                                <span>/</span>
                                <span>  عمان تحريراً في</span>
                            </span>


                            <br></br>
                            <br></br>


                        </p>

                        <div className='signature'>
                            <div>
                                <span> الطرف الثاني</span>

                                <span>..................................</span>
                            </div>

                            <div>
                                <span>الطرف الأول </span>
                                <span>..................................</span>
                            </div>

                        </div>



                        <p>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <span className='bold_title'>   ملحق رقم  (1)</span>
                            <br></br>
                            : تم استلام المأجور بدون اي اضرار او نواقص حسب المذكورات التالية
                            <br></br>
                            ثلاجة  o
                            <br></br>
                            تلفزيون 32 بوصة    o
                            <br></br>
                            ريموت تلفزيون    o
                            <br></br>
                            وصلة التلفزيون    o
                            <br></br>
                            رسيفر   o
                            <br></br>
                            ريموت رسيفر   o
                            <br></br>
                            قواعد خشب اسفل التلفزيون (عدد 2)    o
                            <br></br>
                            مكيف    o
                            <br></br>
                            ريموت المكيف   o
                            <br></br>
                            طباخ كهرباء   o
                            <br></br>
                            خزانة مطبخ سفلية وعلوية   o
                            <br></br>
                            خزانة ملابس خشبية   o
                            <br></br>
                            سرير خشب    o
                            <br></br>
                            راسية سرير ثابتة   o
                            <br></br>
                            فرشة إسفنجية    o
                            <br></br>
                            غطاء فرشة سرير    o
                            <br></br>
                            شرشف سرير وسادة وغطاء وسادة    o
                            <br></br>
                            طاولة   o
                            <br></br>
                            مكتب    o
                            <br></br>
                            كرسي    o
                            <br></br>
                            ستارة   o
                            <br></br>
                            منشر غسيل   o
                            <br></br>
                            جميع محتويات الحمام   o

                        </p>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <p>
                            <span className='bold_title'> ملحق رقم (2)</span>
                            <br></br>

                            : الإلتزام بمواعيد فتح وإغلاق باب السكن وهي كالآتي
                            <br></br>
                            موعد فتح الباب السادسة صباحاً وموعد إغلاقه العاشرة ليلاً ولا يسمح بالتاخير في احال من الاحوال •
                            <br></br>
                            في حالات المبيت خارج السكن على الاهل اعلام الادارة مسبقا  •
                            <br></br>
                            موعد فتح باب المطبخ السادسة صباحاً وموعد إغلاقه العاشرة ليلاً ولا يسمح باستخدام المطبخ بعد ذلك •
                            <br></br>
                            الإلتزام بالآداب العامة داخل السكن , والمحافظة على الهدوء ليلا, وإحترام خصوصيات الطالبات الأخريات •
                            <br></br>
                            المحافظه على مرافق السكن وعدم اساءة استخدامها •
                            <br></br>
                            يقدم السكن خدمات صيانة عالية الجودة وعلى مستوى عالي من الدقة على يد خبراء وفنين معتمدين فيرجى اعلام الادارة عند الحاجة
                            •
                            <br></br>
                            لذلك
                            <br></br>
                            تعتذر الإدارة عن إستقبال الزوار بعد الساعة العاشرة ليلاً, وفي حالات إستضافة زائرة (مبيت) (وذلك ليوم أو يومين فقط ) يتم دفع 15
                            •
                            <br></br>
                            دينارعن كل ليلة
                            <br></br>
                            يرجى المحافظة على نظافة الغرف والممرات والمطبخ المشترك , وعدم إلقاء أي نفايات داخل السكن وعدم وضع مناشر الغسيل
                            •
                            <br></br>
                            بالممرات
                            <br></br>
                            حفاظاَ على السلامة العامة يتم  إستخدام المصعد بطريقة صحيحه وعدم العبث فيه •
                            <br></br>
                            يرجى عدم إلصاق أي ملصقات داخل السكن او أي من محتويات الغرفة  •
                            <br></br>
                            يتم حجزالغرفه اثناء الاجازات وبعد انتهاء العقد مقابل ثلاثة دنانير يوميا  •
                            <br></br>
                            في حال ضياع أو طلب نسخة جديدة للمفتاح يتم دفع خمس دنانير  •
                            <br></br>
                            الإدارة غير مسؤولة عن الممتلكات والمقتنيات الشخصية للطالبة ,فيجب الحرص على عدم ترك الغرفة مفتوحه  •
                            <br></br>
                            تتعهد المستاجرة المحافظة على سلامتها الشخصية و سلامة الغير في داخل الماجور و لا يتحمل الفريق الاول اي مسؤولية تجاه اي
                            •
                            <br></br>
                            اصابات او اضرار من الممكن ان تحدث لها لا قدر الله
                            <br></br>
                            إعلام الإدارة بمواعيد وايام السفر  •
                            <br></br>
                            يمنع التواصل مع اي جهة تابعة لادارة السكن  •
                            <br></br>
                            في حالة مخالفة انظمة السكن يتم توجيه تنبيه شفهي , وفي حالة تكرار المخالفه يتم توجيه انذار خطي ملزم ,ويكون للادارة بعدها الحق
                            •
                            <br></br>
                            باتخاذ الاجراء المناسب ومنها الخلاء الماجور فورا
                            <br></br>

                        </p>





                        <div className='contract_hotel_info'>
                            <span>سكن اية و ريم</span>
                            <span>0790203133</span>
                        </div>

                    </div>

                </div>
            </Modal.Body>


        </Modal>
    )
}
