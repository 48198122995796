
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LoginContext } from '../../context/login';
import cookies from 'react-cookies';
import Swal from 'sweetalert2';
// import { Link } from 'react-router-dom';
import "./management.css";
import { propTypes } from 'react-bootstrap/esm/Image';

class MyTable extends React.Component {

    constructor(props) {
        super(props)


    }

    setreservationInfo = (room_id, date, room_name, reservation_id) => {
        let data = {
            room_id: room_id,
            date: date,
            room_name: room_name,
            reservation_id: reservation_id
        }
        cookies.remove('reservationInfo');
        cookies.save('reservationInfo', JSON.stringify(data));

        this.props.setreservationInfo({
            room_id: room_id,
            date: date,
            room_name: room_name,
            reservation_id: reservation_id
        })
    }

    sortData = (sortBy) => {


        this.props.sortReservation(sortBy);
    }

    calculateNights = reserve => {
        let totalNight = 0, newStart, newEnd;
        if (reserve.loggedOut_At) {
            newStart = reserve.loggedIn_At.split(" ")[2]
            let starDay = newStart.split('/')[0];
            let startMonth = parseInt(newStart.split('/')[1]) - 1;
            let startYear = newStart.split('/')[2];

            let startTime = new Date(startYear, startMonth, starDay, 0, 0, 0).getTime();

            newEnd = reserve.loggedOut_At.split(" ")[2]
            let endDay = newEnd.split('/')[0];
            let endMonth = parseInt(newEnd.split('/')[1]) - 1;
            let endYear = newEnd.split('/')[2];

            let endTime = new Date(endYear, endMonth, endDay, 0, 0, 0).getTime();

            totalNight = parseInt((endTime - startTime) * (1 / (1000 * 60 * 60 * 24)));
        } else if (reserve.loggedIn_At) {
            newStart = reserve.loggedIn_At.split(" ")[2]
            let day = newStart.split('/')[0];
            let month = parseInt(newStart.split('/')[1]) - 1;
            let year = newStart.split('/')[2];

            let time = new Date(year, month, day, 0, 0, 0).getTime();

            totalNight = parseInt((new Date(reserve.checkOut).getTime() - time) * (1 / (1000 * 60 * 60 * 24)));
        } else {
            totalNight = parseInt((new Date(reserve.checkOut).getTime() - new Date(reserve.checkIn).getTime()) * (1 / (1000 * 60 * 60 * 24)));
        }
        return (totalNight)
    }

    render() {
        return (
            <div className='bodyOfTable'>


                <div className='headOfPrint'>
                    <p className='firstP'> تقرير الحجوزات </p>

                    <p className='secondP'> <span>اجمالي المقبوضات:{this.props.maqbodat}</span> <span>اجمالي المصروفات:{this.props.masaref}</span> <span>اجمالي التكلفة:{this.props.totalOfAll}</span> </p>
                    <p className='secondP'> <span>اجمالي الدائن:{this.props.totalDaen}</span> <span>اجمالي المدين:{this.props.totalMaden}</span> <span>اجمالي الرصيد:{(this.props.maqbodat - this.props.masaref)?.toFixed(2)}</span> </p>
                    {/* === */}
                </div>
                <div className='filterInputsContainer'>
                    <table id="myReport">
                        <thead>
                            {/* رقم الوحدة	الاسم	قسم الوحدة	مفعل	حالة الوحدة	الصورة الرئيسية	الاجرائات */}
                            <tr className='trMainTable'>
                                {/* <th ></th> */}
                                <th className='partOfHeadTable' colSpan="3"> المالية</th>
                                <th className='partOfHeadTable' colSpan="3">المستحق دفعه</th>
                                <th className='partOfHeadTable' colSpan="6" > معلومات الوقت </th>
                                <th className='partOfHeadTable' colSpan="5"> معلومات الحجز </th>
                            </tr>
                            <tr className='trMainTable'>
                                {/* <th>الاجراءات </th> */}
                                {/* sortReservation}money'end'start'unit'geust'id */}
                                <th className='partOfHeadTable'>المدين</th>
                                <th className='partOfHeadTable'>الدائن</th>
                                <th className='partOfHeadTable'>المدفوع</th>

                                <th className=' tableSortButton' id="money" onClick={() => this.sortData('money')}>الاجمالي</th>
                                <th className='partOfHeadTable'>الضرائب </th>
                                <th className='partOfHeadTable'>المبلغ</th>

                                <th className='partOfHeadTable'>الخدمات</th>
                                <th className='partOfHeadTable'>التأجير</th>
                                <th className='partOfHeadTable'>عدد الليالي</th>
                                <th className='tableSortButton' id="end" onClick={() => this.sortData('end')}>نهاية الحجز </th>
                                <th className='tableSortButton' id="start" onClick={() => this.sortData('start')}>بداية الحجز</th>
                                <th className='partOfHeadTable'>نوع الايجار</th>

                                <th className='partOfHeadTable'>حالة الحجز</th>
                                <th className='partOfHeadTable'>الحالة </th>
                                <th className='tableSortButton' id="unit" onClick={() => this.sortData('unit')}>الوحدة</th>
                                <th className='tableSortButton' id="geust" onClick={() => this.sortData('geust')}>العميل</th>
                                <th className='tableSortButton' id="id" onClick={() => this.sortData('id')}>رقم الحجز</th>

                                {/* رقم الحجز	العميل	الوحدة	الحالة	حالة الحجز	نوع الإيجار	بدايه الحجز	نهاية الحجز	عدد الليالي	التأجير	الخدمات	المبلغ	الضرائب	الاجمالى	المدفوع	دائن	مدين */}
                            </tr>
                        </thead>
                        <tbody>
                            {/* run from database */}


                            {
                                this.props.reservation.map((row, idx) => {
                                    return (

                                        <tr onClick={() => this.setreservationInfo(row.room_id, row.checkIn, row.room_name, row.id)} className='veiwReservationTable' key={idx}>

                                            {/* <td title=""> +++ </td> */}

                                            <td title="المدين"> <Link className='tableButton' to="/reservationInfo">  {row.maden?.toFixed(2) || "--"} </Link> </td>
                                            <td title="الدائن"> <Link className='tableButton' to="/reservationInfo">  {row.daen?.toFixed(2) || "--"} </Link> </td>
                                            <td title="المدفوع"> <Link className='tableButton' to="/reservationInfo">  {row.reservationMadfo3?.toFixed(2)} </Link> </td>

                                            <td title="الاجمالي"> <Link className='tableButton' to="/reservationInfo">  {(row.priceWithTax + row.services_price)?.toFixed(2) || 0} </Link> </td>
                                            <td title="الضرائب"> <Link className='tableButton' to="/reservationInfo">  0 </Link> </td>
                                            <td title="المبلغ"> <Link className='tableButton' to="/reservationInfo">  {row.total_price?.toFixed(2) || 0} </Link> </td>

                                            <td title="الخدمات"> <Link className='tableButton' to="/reservationInfo">  {row.services_price?.toFixed(2)} </Link> </td>
                                            <td title="التأجير"> <Link className='tableButton' to="/reservationInfo">  {row.price?.toFixed(2)} </Link> </td>
                                            <td title="عدد الليالي">
                                                <Link className='tableButton' to="/reservationInfo">{this.calculateNights(row)}</Link>
                                            </td>
                                            <td title="نهاية الحجز"> <Link className='tableButton' to="/reservationInfo">  {row.loggedOut_At ? `${row.loggedOut_At.split(' ')[2]}` : `${new Date(row.checkOut).getDate()}/${new Date(row.checkOut).getMonth() + 1}/${new Date(row.checkOut).getFullYear()}`}  </Link></td>
                                            <td title="بداية الحجز">  <Link className='tableButton' to="/reservationInfo">{row.loggedIn_At ? `${row.loggedIn_At.split(' ')[2]}` : `${new Date(row.checkIn).getDate()}/${new Date(row.checkIn).getMonth() + 1}/${new Date(row.checkIn).getFullYear()}`} </Link> </td>
                                            <td title="نوع الايجار"> <Link className='tableButton' to="/reservationInfo">  {row.rent_type} </Link> </td>

                                            <td title="حالة الحجز"> <Link className='tableButton' to="/reservationInfo">  {row.status} </Link> </td>
                                            {
                                                (row.percenteOfGet == "مؤكد") &&
                                                <td title="الحالة"><Link className='tableButton greenReservation' to="/reservationInfo">  {row.percenteOfGet} </Link> </td>
                                            }
                                            {
                                                (row.percenteOfGet == "حجز مستقبلي") &&
                                                <td title="الحالة"><Link className='tableButton blueReservation' to="/reservationInfo">  {row.percenteOfGet} </Link> </td>
                                            }
                                            {
                                                (row.percenteOfGet == "حجز منقضي") &&
                                                <td title="الحالة"><Link className='tableButton yellowReservation' to="/reservationInfo">  {row.percenteOfGet} </Link> </td>
                                            }
                                            {
                                                (row.percenteOfGet == "ملغي") &&
                                                <td title="الحالة"><Link className='tableButton redReservation' to="/reservationInfo">  {row.percenteOfGet} </Link> </td>
                                            }
                                            <td title="الوحدة"> <Link className='tableButton' to="/reservationInfo">  {row.room_id} </Link> </td>
                                            <td title="العميل"> <Link className='tableButton' to="/reservationInfo"> {row.name} </Link> </td>
                                            <td title="رقم الحجز"> <Link className='tableButton' to="/reservationInfo"> {row.id}# </Link> </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default MyTable;
