import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import superagent from "superagent";
import Swal from 'sweetalert2';
import { LoginContext } from '../../context/login';
import useForm from '../../hooks/form';
import cookies from 'react-cookies';
import axios from 'axios';
import './AddNewEmployee.css';
import EditIcon from '@mui/icons-material/Edit';


export default function EditEmplyee(props) {
    // To load the token from cookies
    const qs = new URLSearchParams(window.location.search);
    const cookieToken = cookies.load('token');
    const token = qs.get('token') || cookieToken || null;

    const login = useContext(LoginContext);

    // btn to enable change password
    const changePass = () => {
        // changePassInput'changePassBtn
        const changePassInput = document.getElementById("changePassInput");

        if (changePassInput.disabled) {
            changePassInput.disabled = false;
            changePassInput.style.background = "var(--50)";
        } else {
            changePassInput.disabled = true;
            changePassInput.style.background = "var(--60)";
        }
    }

    // employee state
    const [employee, setEmployee] = useState({});

    useEffect(() => {
        // get employee data from database
        props.setLoadingFunc(true).then(async()=>{
            await axios.post(`${login.API}/getEmployeeByID`, { id: props.employeeId },
                {
                    headers: {
                        "Authorization": `Basic ${token}`
                    }
                }).then(res => {
                    setEmployee(res.data);
                }).catch(err => {
                    console.log("error");
                })
                props.setLoadingFunc(false)
        })

    }, [])

    const handelEditEmployee = (data) => {
        // submit to add new employee to our database




        // Send data to the database
        axios.post(`${login.API}/editEemployee`, { id: props.employeeId, data: data },
            {
                headers: {
                    "Authorization": `Basic ${token}`
                }
            }).then(res => {
               
                let name = data.name;
                if (!name) {
                    name = employee.name
                }
                axios.post(`${login.API}/newActivity`, {
                    employee_id: login.user.id,
                    description: `تعديل بيانات الموظف - ${name}`,
                    type: "الموظفين",
                },
                {headers: {'Authorization': `Bearer ${token}`}});

                Swal.fire(
                    'تم إضافة الموظف بنجاح',
                    '',
                    'success'
                )
            }).catch(err => {
                console.log("error");
            })

    }
    // Form of the new employee
    const { handleChange, handleSubmit } = useForm(handelEditEmployee);
    return (
        <div className='profileContainer'>
            <div className='navigator'>
                <p>

                    <Link className='linkContainer' to='/'>
                        الرئيسية
                    </Link>
                    /
                    <Link className='linkContainer' to='/profile'>
                        حسابي
                    </Link>
                    /
                    تعديل بيانات الموظف

                </p>
            </div>

            <div>
                <h4 className='newEmployeeTag'> تعديل بيانات الموظف </h4>
            </div>
            <Form onSubmit={handleSubmit}>
                <div className='infoContainer'>

                    <div className='row'>
                        <input required className='colLeft' onChange={handleChange} name="id" defaultValue={employee.id} placeholder='الرقم التسلسلي للموظف #' required type='number' />
                        <p className='colRight'> الرقم التسلسلي للموظف # </p>
                    </div>
                    <div className='row'>
                        <input required className='colLeft' onChange={handleChange} name="name" defaultValue={employee.name} placeholder='اسم الموظف' required type='text' />
                        <p className='colRight'> اسم الموظف </p>
                    </div>
                    <div className='row'>
                        <input required className='colLeft' onChange={handleChange} name="email" defaultValue={employee.email} placeholder='البريد الالكتروني' required type='email' />
                        <p className='colRight'> البريد الالكتروني </p>
                    </div>
                    <div className='row'>
                        <input required className='colLeft' onChange={handleChange} name="phone_number" defaultValue={employee.phone_number} placeholder='رقم الهاتف' required type='number' />
                        <p className='colRight'> رقم الهاتف </p>
                    </div>
                    <div className='row'>
                        <input required id='changePassInput' className='colLeft' disabled onChange={handleChange} name="password" placeholder='كلمة المرور' required type='password' />

                        <p className='colRightPass'> <i onClick={changePass} id='changePassBtn' className="changePass">
                        <EditIcon/>
                            </i> كلمة المرور </p>
                    </div>
                    <div className='row'>
                        <select className='colLeft' onChange={handleChange} name="gender" defaultValue={employee.gender} id="gender">
                            <option value="male"> ذكر </option>
                            <option value="female"> انثى </option>
                        </select>
                        <p className='colRight'> جنس الموظف </p>
                    </div>
                    <div className='row'>
                        <select className='colLeft' onChange={handleChange} defaultValue={employee.role} name="role" id="role">
                            <option value="manager"> مدير </option>
                            <option value="reciptionist"> موظف استقبال </option>
                        </select>
                        <p className='colRight'> الصلاحيات </p>
                    </div>
                </div>

                <div className='submitNewEmployee'>
                    <button type='submit'>
                        حفظ بيانات الموظف
                    </button>
                </div>
            </Form>
        </div>
    )
}
