import React, { useEffect, useState, useContext, useRef } from 'react';
import { LoginContext } from '../../context/login';
import useForm from '../../hooks/form';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Button, Modal, Form, Table } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import QRCode from 'qrcode.react';
import cookies from 'react-cookies';
import swal from 'sweetalert2';
import './ReservationInfo.css';
import FinishCurrentReservationModal from './FinishCurrentReservationModal';
import Contract from './Contract';
import logo from '../../image/Aya&Reem.png';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import Swal from 'sweetalert2';
import { saveAs } from "file-saver";
import { AlignmentType, HeadingLevel, TabStopPosition, TabStopType, TextRun, Document, ImageRun, Packer, Paragraph, TextDirection } from "docx";
import PrintIcon from '@mui/icons-material/Print';
import ReceiptIcon from '@mui/icons-material/Receipt';
import UpdateReservationInfo from './updateReservationInfo';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DatePicker from "react-datepicker";
import GetSanad from './getSanadFromOldData'

export default function ReservationInfo(props) {
    // To load the token from cookies
    const qs = new URLSearchParams(window.location.search);
    const cookieToken = cookies.load('token');
    const token = qs.get('token') || cookieToken || null;
    const login = useContext(LoginContext);

    const [specificCheckInDate, setSpecificCheckInDate] = useState(new Date());
    const [sanadDate, setSanadDate] = useState(new Date());

    const [showRecipts, setShowRecipts] = useState(false);
    const [showExpenses, setShowExpenses] = useState(false);
    const [showReciptsAndExpence, setShowReciptsAndExpence] = useState(false);
    const [indexOf, setIndexOf] = useState();
    const [showContract, setShowContact] = useState(false);
    const [showInvoice, setShowInvoice] = useState(false);
    const [showLoginModalStatus, setShowLoginModalStatus] = useState(false);
    const [reservationInfo, setreservationInfo] = useState({});
    const [guest, setGuest] = useState({});
    const [recieptsAndExpence, setRecieptsAndExpence] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [reciepts, setReciepts] = useState([]);
    const [logInStatus, setLogInStatus] = useState();
    const [loggedTime, setLoggedTime] = useState('');
    const [loggedOutTime, setLoggedOutTime] = useState('');
    const [date, setDate] = useState('');
    const [invoice, setInvoice] = useState();
    const [For, setFor] = useState();
    const [duration, setDuration] = useState("");
    const [services, setServices] = useState([]);
    const [activeServices, setActiveServices] = useState({ price: 0 });
    const [hasInsurance, setHasInsurance] = useState(false);
    const [insuranceValue, setInsuranceValue] = useState(0);
    const [updateReservation, setUpdateReservation] = useState(false);
    const [period, setPeriod] = useState(1);

    const [oldSanadsListData, setOldSanadsListData] = useState([]);
    const [oldSanadsListTitle, setOldSanadsListTitle] = useState('');
    const [oldSanadData, setOldSanadData] = useState({});

    const [moneyBillsToUpdate, setMoneyBillsToUpdate] = useState(null);
    const [moneyBillsToDelete, setMoneyBillsToDelete] = useState(null);

    const [amountPaid, setAmountPaid] = useState();

    const [hotel, setHotel] = useState();

    const [finishReservation, setFinishReservation] = useState(false);
    // for print contract
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {

        let dataFromToken = cookies.load('reservationInfo');
        if (dataFromToken) {
            props.resrvationInfo.reservation_id = parseInt(dataFromToken.reservation_id);
            props.resrvationInfo.room_id = dataFromToken.room_id;
            props.resrvationInfo.date = dataFromToken.date;
            props.resrvationInfo.room_name = dataFromToken.room_name;
        }

        axios.post(`${login.API}/getMyHotel`, {}, { headers: { 'Authorization': `Basic ${token}` } })
            .then(res => {
                setHotel(res.data[0]);

            })

        const fetchData = async () => {
            props.setLoadingFunc(true).then(async () => {
                let currentdate = new Date();
                let datetime = currentdate.toLocaleDateString();
                setDate(datetime);
                await axios.post(`${login.API}/getReservationId`, props.resrvationInfo, { headers: { 'Authorization': `Basic ${token}` } })
                    .then(async res => {
                        let newReservationRendered = res.data.record;
                        let newExpensesRendered = res.data.expense;
                        let newRecieptsRendered = res.data.reciepts;
                        filterServices(newReservationRendered);
                        newReservationRendered.priceWithTax = parseFloat(newReservationRendered.price) * parseFloat(newReservationRendered.taxPercentege) + parseFloat(newReservationRendered.price);

                        setreservationInfo(newReservationRendered);
                        setExpenses(newExpensesRendered);
                        setReciepts(newRecieptsRendered);

                        if (newReservationRendered.loggedIn_At) {
                            setLoggedTime(newReservationRendered.loggedIn_At);

                            if (newReservationRendered.loggedOut_At) {
                                setLoggedOutTime(newReservationRendered.loggedOut_At);
                            }
                        }

                        setLogInStatus(newReservationRendered.status);

                        setGuest(newReservationRendered.Guest);

                        setInvoice(newReservationRendered.Invoice);
                        props.setLoadingFunc(false);
                    });
            })

        }
        fetchData();
    }, []);

    useEffect(() => {
        calculatePrice(reservationInfo);
    }, [reservationInfo])

    const calculatePrice = reservationData => {
        let startDate, endDate, newPeriod;
        if (reservationData.loggedOut_At) {
            let startDay = reservationData.loggedIn_At.split(' ')[2].split('/')[0];
            let startMonth = reservationData.loggedIn_At.split(' ')[2].split('/')[1];
            let startYear = reservationData.loggedIn_At.split(' ')[2].split('/')[2];

            let endDay = reservationData.loggedOut_At.split(' ')[2].split('/')[0];
            let endMonth = reservationData.loggedOut_At.split(' ')[2].split('/')[1];
            let endYear = reservationData.loggedOut_At.split(' ')[2].split('/')[2];

            startDate = new Date(startYear, startMonth - 1, startDay, 0, 0, 0);
            endDate = new Date(endYear, endMonth - 1, endDay, 0, 0, 0);
        } else if (reservationData.loggedIn_At) {
            let startDay = reservationData.loggedIn_At.split(' ')[2].split('/')[0];
            let startMonth = reservationData.loggedIn_At.split(' ')[2].split('/')[1];
            let startYear = reservationData.loggedIn_At.split(' ')[2].split('/')[2];

            startDate = new Date(startYear, startMonth - 1, startDay, 0, 0, 0);
            endDate = new Date(reservationData.checkOut);
        } else {
            startDate = new Date(reservationData.checkIn);
            endDate = new Date(reservationData.checkOut);
        }
        if (reservationData.rent_type == "يومي") {
            let d = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
            setDuration(`${d} أيام`);
            setPeriod(d);
            newPeriod = d;
        } else if (reservationData.rent_type == "شهري") {
            let year = endDate.getFullYear() - startDate.getFullYear();
            let day = endDate.getDate() - startDate.getDate();

            let month = year * 12 + (endDate.getMonth() - startDate.getMonth());
            if (day > 0) {
                month = month + 1;
            }
            setDuration(`${month} أشهر`);
            setPeriod(month);
            newPeriod = month;
        } else if (reservationData.rent_type == "اسبوعي") {
            let d = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);

            let newWeek = (d / 7);
            let week = parseInt(d / 7);
            if (newWeek > week) {
                week = week + 1;
            }
            setDuration(`${week} اسابيع`);
            setPeriod(week);
            newPeriod = week;
        }
        return newPeriod;
    }

    const getInsuranceData = () => {
        if (guest.GuestInsurances?.length) {
            setHasInsurance(true);
            let totalInsurance = 0;
            guest.GuestInsurances?.map(ins => {
                totalInsurance += ins.value || 0
            })
            setInsuranceValue(totalInsurance);
        } else {
            setHasInsurance(false);
        }
    }

    useEffect(() => {
        getInsuranceData();
    }, [guest])

    useEffect(() => {
        props.setLoadingFunc(true).then(() => {
            let currentdate = new Date();
            let datetime = currentdate.toLocaleDateString();
            setDate(datetime);
            async function fetchData() {
                await axios.post(`${login.API}/getReservationId`, props.resrvationInfo, { headers: { 'Authorization': `Basic ${token}` } })
                    .then(async res => {
                        let newReservationRendered = res.data.record;
                        newReservationRendered.priceWithTax = newReservationRendered.price * parseFloat(newReservationRendered.taxPercentege) + newReservationRendered.price;
                        setreservationInfo(newReservationRendered);
                        if (newReservationRendered.loggedIn_At) {
                            setLoggedTime(newReservationRendered.loggedIn_At);
                        }
                        if (newReservationRendered.loggedOut_At) {
                            setLoggedOutTime(newReservationRendered.loggedOut_At);
                        }
                    });
                props.setLoadingFunc(false);
            }
            fetchData();
        })
    }, [logInStatus]);

    useEffect(() => {
        let currentdate = new Date();
        let datetime = currentdate.toLocaleDateString();
        setDate(datetime);
        let testPayMent;
        let expenseAndReciepts = [];
        async function fetchData() {
            expenseAndReciepts = [...reciepts, ...expenses];
            // remove repeated data -=>
            let removed = {};
            expenseAndReciepts = expenseAndReciepts.filter(sanad => {
                if (sanad)
                    if (removed[`${sanad.id}-${sanad.type}`] !== sanad.type) {
                        removed[`${sanad.id}-${sanad.type}`] = sanad.type;
                        return sanad;
                    }
            })

            expenseAndReciepts.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1);
            setRecieptsAndExpence(expenseAndReciepts);
            let value = 0;
            expenseAndReciepts.map(item => {
                if (item.type === 'قبض') {
                    if (item.for === 'تأمين') {
                        value += item.amount;
                    }
                }

            })
        }
        fetchData();
    }, [guest, reciepts, expenses]);

    useEffect(() => {
        let totalPaidValue = 0;
        let totalExpenseValue = 0;
        recieptsAndExpence.map(async (item) => {
            if (item.for !== 'تأمين' && item.type == 'قبض') {
                totalPaidValue += item.amount;
            } else if (item.type == 'صرف') {
                totalExpenseValue += item.amount;
            }
        })
        setAmountPaid(totalPaidValue)
    }, [recieptsAndExpence]);

    const filterServices = (reservation = reservationInfo, sarf_id) => {
        let activeServices = reservation.Services.filter(service => {
            if (!service.paidStatus || service.sarf_id == sarf_id) return service;
        })
        setActiveServices(activeServices[0])
        setServices(activeServices);
    }

    const { handleChange, handleSubmit } = useForm(newRecipts);
    const { handleChange_2, handleSubmit_2 } = useForm(newExpenses);

    const getActiveService = (id) => {
        let service = { price: 0 }
        services?.forEach(serve => {
            if (serve.id == id)
                service = serve
        })
        return service;
    }

    const handleChange_3 = (e) => {
        setOldSanadData({});
        setFor(e.target.value);
        if (e.target.value == 'خدمات') {
            let service = getActiveService(services[0]?.id || null);
            setActiveServices(service)
        }
    }

    const handleChange_service = e => {
        let service = getActiveService(e.target.value);
        setActiveServices(service)
    }

    const handleChange_4 = (e) => {
        setOldSanadData({});
        setFor(e.target.value);
    }

    useEffect(() => {
        if (For === "صيانة") {
            let reciver_name = document.getElementById('reciver_name');
            reciver_name.value = "";
        }

    }, [For])

    async function newRecipts(params) {
        params = { ...moneyBillsToUpdate, ...params };
        params.date = sanadDate;
        props.setLoadingFunc(true).then(async () => {
            let dataToPrint;
            let myReciptForm = document.getElementById('recipt-form');
            params.for = For || myReciptForm['for'].value;
            params.amount = params.amount || myReciptForm['amount'].value;
            params.description = params.description || myReciptForm['description'].value;
            params.payment_method = params.payment_method || myReciptForm['payment_method'].value;
            params.paied_for = params.paied_for || myReciptForm['paied_for'].value;

            params.type = "قبض"
            if (params.for === 'خدمات' && activeServices.price) {
                params.amount = activeServices.price;
                params.serviceId = activeServices.id;
            }
            params.employee_id = login.user.id;
            params.employee_name = login.user.name;
            params.guest_id = guest.id;
            params.recievedFrom = guest.name;
            params.reservation_id = reservationInfo.id;
            if (!params.amount) {
                params.amount = parseFloat(((reservationInfo.priceWithTax + reservationInfo.services_price) / reservationInfo.period.length)?.toFixed(2));
            } else {
                params.amount = Number(params.amount);
            }
            if (params.for != "قيمة إيجار" && params.for != "خددمات") {
                params.paied_for = props.resrvationInfo.room_id + ' - ' + props.resrvationInfo.room_name
            } else if (params.for === "خددمات") {
                params.paied_for = activeServices.id;
            } else if (params.for === "قيمة إيجار") {
                params.periodIdx = parseInt(params.paied_for.split('-')[0]);
                let newPeriods = reservationInfo.period;
                newPeriods[params.periodIdx] = true;
                setreservationInfo({ ...reservationInfo, period: newPeriods })
            }

            if (params.for && params.amount && params.description && params.type && params.employee_id && params.employee_name && params.guest_id && params.recievedFrom && params.reservation_id && params.date && params.paied_for && params.payment_method) {
                let service;
                if (moneyBillsToUpdate) {
                    if (moneyBillsToUpdate?.for === 'خدمات') service = reservationInfo.Services.find(serve => (serve.sarf_id == moneyBillsToUpdate.id))
                    await axios.post(`${login.API}/deleteRecipte`, { oldData: moneyBillsToUpdate, serviceID: service?.id || null, period: reservationInfo.period }, { headers: { 'Authorization': `Basic ${token}` } })
                        .then(async res => {
                            let newReciepts = reciepts;
                            setReciepts([]);
                            newReciepts = newReciepts.map(resep => {
                                if (resep.id !== moneyBillsToUpdate.id) return resep;
                            })
                            setReciepts(newReciepts);

                            if (params.for === 'تأمين') {
                                let newGuestInfo = guest;
                                setGuest({});
                                newGuestInfo.GuestInsurances = newGuestInfo.GuestInsurances.filter(ins => (ins.id !== moneyBillsToUpdate.id))
                                setGuest(newGuestInfo);
                            };

                            if (params.for === 'خدمات') {
                                let newReservation = reservationInfo;
                                newReservation.Services = newReservation.Services.map(reserve => {
                                    if (reserve !== service) return reserve;
                                    return { ...service, paidStatus: false }
                                })
                                filterServices(newReservation);
                            }

                        }).catch(err => {
                            handleClose(true)
                            props.setLoadingFunc(false);
                            setMoneyBillsToUpdate(null);
                            return swal.fire({
                                title: 'حدث خطأ أثناء تعديل البيانات',
                                icon: 'warning',
                                confirmButtonColor: '#d33',
                                cancelButtonText: 'حسناً'
                            })
                        });
                }
                await axios.post(`${login.API}/newRecipte`, params, { headers: { 'Authorization': `Basic ${token}` } })
                    .then(res => {
                        handleClose(true)
                        props.setLoadingFunc(false);
                        setMoneyBillsToUpdate(null);

                        let newReceipt = res.data.reciepts;
                        setReciepts([newReceipt, ...reciepts]);
                        if (params.for === 'تأمين') {
                            let newGuestInfo = guest;
                            setGuest({});
                            newGuestInfo.GuestInsurances.push({ id: newReceipt.id, value: params.amount });
                            setGuest(newGuestInfo);
                        };

                        if (params.for === "خدمات") {
                            let newReservationData = reservationInfo;
                            // let 
                            newReservationData.Services = newReservationData.Services.map(serve => {
                                if (serve.id !== activeServices.id) return serve;
                                return { ...serve, paidStatus: true, sarf_id: newReceipt.id }
                            });
                            filterServices(newReservationData)
                        }

                        // new activity
                        axios.post(`${login.API}/newActivity`, {
                            employee_id: login.user.id,
                            description: `اضافة مقبوضات للغرفة ${props.resrvationInfo.room_id} - ${props.resrvationInfo.room_name}`,
                            type: "اضافة سند مالي"
                        },
                            { headers: { 'Authorization': `Basic ${token}` } })

                    });

            }
            else {
                handleClose(true)
                props.setLoadingFunc(false);
                setMoneyBillsToUpdate(null);
                swal.fire({
                    title: 'يرجى التأكد من صحة المعلومات!!',
                    icon: 'warning',
                    confirmButtonColor: '#d33',
                    cancelButtonText: 'حسناً'
                })
            }
        })
    }

    async function newExpenses(params) {
        params = { ...moneyBillsToUpdate, ...params };
        params.date = sanadDate;
        props.setLoadingFunc(true).then(async () => {
            let myExpenseForm = document.getElementById('expense-form');
            params.amount = params.amount || myExpenseForm['amount'].value;
            params.for = For || myExpenseForm['for'].value;
            params.description = params.description || myExpenseForm['description'].value;
            params.payment_method = params.payment_method || myExpenseForm['payment_method'].value;
            params.paied_for = params.paied_for || myExpenseForm['paied_for'].value;

            params.type = "صرف"
            params.employee_id = login.user.id;
            params.employee_name = login.user.name;
            params.reciver_id = guest.id;
            if (!params.reciver_name) {
                params.reciver_name = guest.name;
            }
            params.reservation_id = reservationInfo.id;
            params.guest_id = guest.id;

            params.paied_for = props.resrvationInfo.room_id + ' - ' + props.resrvationInfo.room_name;

            params.amount = parseFloat(parseFloat(params.amount)?.toFixed(2));
            let newReservationInfo = reservationInfo;

            if (params.for && params.amount && params.description && params.type) {
                if (moneyBillsToUpdate) {
                    let oldData = moneyBillsToUpdate;

                    if (oldData.for === "مرتجع تأمين") {
                        oldData.insurance = { guest_id: guest.id, value: 0 }
                        expenses.forEach(ele => {
                            if (ele.for === 'تأمين') {
                                oldData.insurance.value += ele.amount;
                                oldData.insurance.id = ele.id;
                            }
                        })
                        if (oldData.insurance.value == 0) oldData.insurance.value = moneyBillsToUpdate.amount;
                        if (!oldData.insurance.id) oldData.insurance.id = parseInt(reservationInfo.insuranceTakenValue) || Math.floor(Math.random() * 15200456210330);
                        setInsuranceValue(oldData.insurance.value);
                        setHasInsurance(true);
                    } else if (oldData.for === "خدمات") {
                        oldData.total_price = parseInt(newReservationInfo.total_price) - parseInt(moneyBillsToUpdate.amount);
                        oldData.services_price = parseInt(newReservationInfo.services_price) - parseInt(moneyBillsToUpdate.amount);
                    }
                    let oldService = reservationInfo.Services?.find(serve => (serve?.id == moneyBillsToUpdate?.id))
                    await axios.post(`${login.API}/deleteExpense`, { oldData, serviceID: oldService?.id || null }, { headers: { 'Authorization': `Basic ${token}` } })
                        .then(res => {
                            if (moneyBillsToUpdate.for == "خدمات") {
                                newReservationInfo = { ...newReservationInfo, total_price: oldData.total_price, services_price: oldData.services_price }
                                newReservationInfo.Services = newReservationInfo.Services.filter(serve => {
                                    if (serve.id !== oldService.id) return serve;
                                    return { ...serve, paidStatus: false, sarf_id: null }
                                })

                                filterServices(newReservationInfo);
                                setreservationInfo(newReservationInfo);
                            }

                            handleClose(true)
                            props.setLoadingFunc(false);
                            setMoneyBillsToUpdate(null);

                        }).catch(err => {
                            props.setLoadingFunc(false);
                            return swal.fire({
                                title: 'حدث خطأ أثناء تعديل البيانات',
                                icon: 'warning',
                                confirmButtonColor: '#d33',
                                cancelButtonText: 'حسناً'
                            })
                        });
                }
                await axios.post(`${login.API}/newExpense`, { ...params, price: parseInt(newReservationInfo.price), services_price: parseInt(newReservationInfo.services_price) + params.amount }, { headers: { 'Authorization': `Basic ${token}` } })
                    .then(res => {
                        let newExpenses = expenses;
                        setExpenses([]);
                        newExpenses = newExpenses.filter(expen => (expen?.id !== moneyBillsToUpdate?.id))
                        setExpenses([...newExpenses, res.data.record]);
                        if (For === "مرتجع تأمين") {
                            let newGuestInfo = guest;
                            setGuest({});
                            newGuestInfo.GuestInsurances = [];
                            setGuest(newGuestInfo);
                            // newReservationInfo.insuranceTakenValue
                        }

                        if (For === "خدمات") {
                            let newReservationData = reservationInfo;

                            setreservationInfo({});
                            setServices([])

                            newReservationData.Services = [...newReservationData.Services, res.data.service];
                            newReservationData.total_price = parseInt(newReservationData.price) + parseInt(newReservationData.services_price) - (parseInt(newReservationData.services_price) - newReservationInfo.services_price) + params.amount;
                            newReservationData.services_price = parseInt(newReservationData.services_price) + params.amount - (parseInt(newReservationData.services_price) - newReservationInfo.services_price);

                            setreservationInfo(newReservationData);
                            filterServices(newReservationData)
                        }
                        // new activity
                        axios.post(`${login.API}/newActivity`, {
                            employee_id: login.user.id,
                            description: `اضافة مصروفات للغرفة ${props.resrvationInfo.room_id} - ${props.resrvationInfo.room_name}`,
                            type: "اضافة سند مالي"
                        }, { headers: { 'Authorization': `Basic ${token}` } })
                    });

                handleClose(true)
                setMoneyBillsToUpdate(null);
                props.setLoadingFunc(false);
            }
            else {
                props.setLoadingFunc(false);
                swal.fire({
                    title: 'يرجى التأكد من صحة المعلومات!!',
                    icon: 'warning',
                    confirmButtonColor: '#d33',
                    cancelButtonText: 'حسناً'
                }).then((result) => {

                }).catch(() => {

                })
            }
        })
    }

    const handleShowRecipts = () => {
        setShowRecipts(true);
    }

    const handleShowExpenses = () => {
        setShowExpenses(true);
    }

    const handleShowContract = () => {
        setShowContact(true);
    }

    const handleShowInvoice = () => {
        setShowInvoice(true);
    }

    const showLoginModal = status => {
        setShowLoginModalStatus(status);
    }

    function handleShowReciptsAndExpence(idx) {
        setIndexOf(idx);
        setShowReciptsAndExpence(true);
    }

    const handleClose = (fromRequest) => {
        setShowRecipts(false);
        setShowExpenses(false);
        setShowContact(false);
        setShowInvoice(false);
        setShowReciptsAndExpence(false);
        setSanadDate(new Date());
        if (moneyBillsToUpdate && !fromRequest) window.location.reload();
    }

    const readFromOldData = (type) => {
        let list = [];
        switch (type) {
            case 'صرف':
                list = recieptsAndExpence.filter(sanad => (sanad.type === 'قبض'));
                list = list.map(sanad => {
                    if (sanad.type === 'قبض') {
                        let isExist = services.find(s => s.id == sanad.id);
                        if (isExist) {
                            return ({ ...sanad, isPaied: false });
                        }else{
                            return ({ ...sanad, isPaied: true });
                        }
                    }
                })
                break;
            default:
                list = [];
                recieptsAndExpence.forEach(sanad => {
                    if (sanad.type === 'صرف') {
                        let isExist = services.find(s => s.id == sanad.id);
                        if (isExist) {
                            list.push({ ...sanad, isPaied: false })
                        }
                    }
                })
                break;
        }
        if (list.length) {
            setOldSanadsListData(list);
            setOldSanadsListTitle(type);
        } else if (type === 'صرف') {
            handleShowExpenses()
        } else {
            handleShowRecipts()
        }
    }

    const handleReadOldDataModal = sanad => {
        setOldSanadsListData([]);
        setOldSanadData(sanad);
        setTimeout(() => {
            if (oldSanadsListTitle === 'صرف') {
                handleShowExpenses()
            } else {
                if(sanad.for == "خدمات"){
                    sanad.price = sanad.amount;
                    setActiveServices(sanad);
                }
                handleShowRecipts()
            }
        }, 300);
    }

    const logInStatusHandler = async () => {
        showLoginModal(false);
        props.setLoadingFunc(true).then(async () => {
            if (logInStatus === 'لم يسجل دخول') {
                let currentdate = new Date(specificCheckInDate);
                let datetime = (currentdate.toDateString()).split(' ')[0] + "  " + currentdate.getDate() + "/" + (currentdate.toLocaleDateString()).split('/')[0] + "/" + currentdate.getFullYear() + "  "
                    + currentdate.toLocaleTimeString();
                setLogInStatus('سجل دخول');
                setLoggedTime(datetime);

                let newPeriod = calculatePrice({ ...reservationInfo, checkIn: currentdate });
                let oldBarePrice = (reservationInfo.total_price - reservationInfo.services_price) / reservationInfo.period.length;
                let price = newPeriod * oldBarePrice;
                let total_price = price + reservationInfo.services_price;

                await axios.put(`${login.API}/updateReservation`, { id: reservationInfo.id, status: 'سجل دخول', loggedIn_At: datetime, price, total_price }, { headers: { 'Authorization': `Basic ${token}` } });
                setreservationInfo({ ...reservationInfo, price, total_price })
                // new activity
                axios.post(`${login.API}/newActivity`, {
                    employee_id: login.user.id,
                    description: `سجل دخول للغرفة ${reservationInfo.room_id} - ${props.resrvationInfo.room_name}`,
                    type: "سجل دخول"
                }, { headers: { 'Authorization': `Basic ${token}` } })

                setLogInStatus('سجل دخول');
                if (login.setting) {
                    if (login.setting.loggedIn === "on") {
                        let sendedData = {};
                        sendedData.loggedInMessage = login.setting.loggedInMessage;
                        if (login.setting.loggedInCotract === "on") {
                            sendedData.loggedInCotract = reservationInfo.Contract?.id
                        }
                        if (login.setting.loggedInDate === "on") {
                            sendedData.loggedInDate = datetime;
                        }
                        if (login.setting.loggedInUnit === "on") {
                            sendedData.loggedInUnit = props.resrvationInfo.room_name
                            sendedData.loggedInUnitId = props.resrvationInfo.room_id
                        }
                        if (login.setting.loggedInAmount === "on") {
                            sendedData.loggedInAmount = parseFloat((reservationInfo.priceWithTax + reservationInfo.services_price)?.toFixed(2))
                        }

                        sendedData.name = guest.name;
                        sendedData.email = guest.email;
                        login.socket.emit("guestLoggedIn", sendedData)
                    }
                }


            } else if (logInStatus === 'سجل دخول') {

                if (recieptsAndExpence) {
                    let paiedAmount = 0;
                    let neededAmount = parseInt((reservationInfo.price)?.toFixed(0));

                    recieptsAndExpence.map(async (item) => {
                        if (item.type === 'قبض' && item.for !== 'تأمين') {
                            neededAmount -= item.amount;

                        } else if (item.type === 'صرف' && item.for !== 'مرتجع تأمين') {
                            neededAmount += item.amount;

                        }
                    })

                    if ((neededAmount)?.toFixed(2) === '0.00') {
                        // if (neededAmount < 0.1 || neededAmount > 0.1) {
                        setLogInStatus('سجل مغادرة');
                        let currentdate = new Date();
                        let datetime = (currentdate.toDateString()).split(' ')[0] + "  " + currentdate.getDate() + "/" + (currentdate.toLocaleDateString()).split('/')[0] + "/" + currentdate.getFullYear() + "  "
                            + currentdate.toLocaleTimeString();

                        setLoggedOutTime(datetime);
                        let nights = (new Date().getTime() - new Date(reservationInfo.checkIn).getTime()) / (1000 * 60 * 60 * 24);

                        let invoiceData = {
                            id: reservationInfo.id,
                            guest_id: guest.id,
                            guest_name: guest.name,
                            guest_phone: guest.phone_number,
                            reservation_id: reservationInfo.id,
                            room_id: reservationInfo.room_id,
                            room_name: props.resrvationInfo.room_name,
                            date: datetime,
                            checkOut: reservationInfo.loggedIn_At,
                            checkIn: datetime,
                            reservation_nights: nights,
                            total_price: reservationInfo.total_price,
                            price: reservationInfo.price,
                            services_price: reservationInfo.services_price,
                            employee_id: login.user.id,
                        }
                        await axios.post(`${login.API}/newInvoice`, invoiceData, { headers: { 'Authorization': `Basic ${token}` } })
                            .then(res => {
                                // new activity
                                axios.post(`${login.API}/newActivity`, {
                                    employee_id: login.user.id,
                                    description: `إنشاء فاتورة رقم ${res.data.id} للغرفة ${props.resrvationInfo.room_id} - ${props.resrvationInfo.room_name}`,
                                    type: "إنشاء فاتورة"
                                }, { headers: { 'Authorization': `Basic ${token}` } })
                                setInvoice(res.data);

                                logedMolakhasAlert();

                                if (login.setting) {
                                    if (login.setting.loggedOut === "on") {
                                        let sendedData = {};
                                        sendedData.loggedOutMessage = login.setting.loggedOutMessage;
                                        if (login.setting.loggedOutCotract === "on") {
                                            sendedData.loggedOutCotract = reservationInfo.Contract?.id
                                        }
                                        if (login.setting.loggedOutDate === "on") {
                                            let currentdate = new Date();
                                            let datetime = (currentdate.toDateString()).split(' ')[0] + "  " + currentdate.getDate() + "/" + (currentdate.toLocaleDateString()).split('/')[0] + "/" + currentdate.getFullYear() + "  "
                                                + currentdate.toLocaleTimeString();

                                            sendedData.loggedOutDate = datetime;
                                        }
                                        if (login.setting.loggedOutUnit === "on") {
                                            sendedData.loggedOutUnit = props.resrvationInfo.room_name
                                            sendedData.loggedOutUnitId = props.resrvationInfo.room_id
                                        }
                                        if (login.setting.loggedOutAmount === "on") {
                                            sendedData.loggedOutAmount = parseFloat((reservationInfo.priceWithTax + reservationInfo.services_price)?.toFixed(2))
                                        }

                                        sendedData.name = guest.name;
                                        sendedData.email = guest.email;
                                        sendedData.invoice = `${window.location.hostname}/invoice?id=${res.data.id}`;

                                        login.socket.emit("guestLoggedOut", sendedData)
                                    }
                                }
                            });

                        await axios.put(`${login.API}/updateReservation`,
                            { id: reservationInfo.id, room_id: reservationInfo.room_id, bed_numbers: reservationInfo.bed_numbers, status: 'سجل مغادرة', loggedOut_At: datetime },
                            { headers: { 'Authorization': `Basic ${token}` } }
                        );
                        // new activity
                        axios.post(`${login.API}/newActivity`, {
                            employee_id: login.user.id,
                            description: `تسجيل مغادرة الغرفة ${props.resrvationInfo.room_id} - ${props.resrvationInfo.room_name}`,
                            type: "تسجيل مغادرة"
                        }, { headers: { 'Authorization': `Basic ${token}` } })

                        setLogInStatus('سجل مغادرة');
                    } else if (neededAmount < 0) {
                        swal.fire({
                            title: 'لم يتم ارجاع كامل المبلغ للعميل',
                            text: 'المبلغ المتبقي  للعميل ' + (Math.abs(neededAmount))?.toFixed(2),
                            icon: 'warning',
                        })
                    } else {
                        swal.fire({
                            title: 'العميل غير مسدد',
                            text: 'المبلغ المتبقي على العميل ' + (neededAmount)?.toFixed(2),
                            icon: 'warning',
                        })
                    }
                }
            }
            props.setLoadingFunc(false);
        })
    }

    const cancleReservationHandler = async () => {
        swal.fire({
            title: 'هل أنت متأكد من إلغاء الحجز؟',
            text: "سيتم تنبيه الإدارة بهذا الإجراء",
            icon: 'warning',
            confirmButtonText: 'إلغاء الحجز ',
            cancelButtonText: 'تراجع',
            showCancelButton: true,
        })
            .then(async (result) => {
                if (result.isConfirmed) {
                    props.setLoadingFunc(true).then(async () => {
                        await axios.put(`${login.API}/cancleReservation`,
                            { id: reservationInfo.id, confirmed: false, room_id: reservationInfo.room_id, bed_numbers: reservationInfo.bed_numbers }
                            , { headers: { 'Authorization': `Basic ${token}` } })
                            .then(res => {
                                // new activity
                                axios.post(`${login.API}/newActivity`, {
                                    employee_id: login.user.id,
                                    description: `الغاء حجز الغرفة ${props.resrvationInfo.room_id} - ${props.resrvationInfo.room_name}`,
                                    type: "الغاء حجز الغرفة"
                                }, { headers: { 'Authorization': `Basic ${token}` } })

                                let newReservationRendered = res.data;
                                newReservationRendered.priceWithTax = newReservationRendered.price * parseFloat(newReservationRendered.taxPercentege) + newReservationRendered.price;
                                setreservationInfo(newReservationRendered);
                                if (login.setting) {
                                    if (login.setting.cancleReserve === "on") {
                                        let data = {
                                            id: reservationInfo.id,
                                            room_id: reservationInfo.room_id,
                                            room_name: props.resrvationInfo.room_name,
                                            employee_id: login.user.id,
                                            employee_name: login.user.name
                                        }
                                        login.socket.emit("cancleReserve", data)
                                    }
                                }
                            });
                        props.setLoadingFunc(false);
                    })
                }
            })
    }

    const handleFicishReservation = () => {
        setFinishReservation(!finishReservation);
    }

    const sendGuest = (guest) => {
        cookies.remove('guest');
        cookies.save('guest', JSON.stringify(guest));
    }

    const printInsuranceWord = async (amount = reservationInfo.insuranceTakenValue || 0) => {

        const blob = await fetch(
            `${process.env.REACT_APP_BACK}/myImage/Aya&Reem.png`
        ).then(r => r.blob());

        const doc = new Document({
            direction: TextDirection.TOP_TO_BOTTOM_RIGHT_TO_LEFT,
            sections: [{
                children: [
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        textDirection: TextDirection.TOP_TO_BOTTOM_RIGHT_TO_LEFT,
                        direction: TextDirection.TOP_TO_BOTTOM_RIGHT_TO_LEFT,
                        children: [
                            new ImageRun({
                                data: blob,
                                transformation: {
                                    width: 100,
                                    height: 100
                                }
                            })
                        ]
                    }),
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        bidirectional: true,
                        children: [
                            new TextRun({
                                text: " سكن آيه وريم للطالبات ",
                                size: 32,
                                bold: true,
                            }),
                        ],
                    }),
                    new Paragraph(""),
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        bidirectional: true,
                        children: [
                            new TextRun({
                                text: `غرفة رقم (${reservationInfo.room_id})`,
                                size: 32,
                                bold: true,
                            }),
                        ],
                    }),
                    new Paragraph(""),
                    new Paragraph(""),
                    new Paragraph(""),
                    new Paragraph(""),
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        bidirectional: true,
                        children: [
                            new TextRun({
                                text: "نموذج إبراء ومخالصة",
                                size: 32,
                                bold: true,
                            }),
                        ],
                    }),
                    new Paragraph(""),
                    new Paragraph(""),
                    new Paragraph(""),
                    new Paragraph({
                        bidirectional: true,
                        children: [
                            new TextRun({
                                alignment: AlignmentType.RIGHT,
                                text: "أنا الموقع أدناه : ",
                                size: 32,
                                bold: true,
                            }),
                            new TextRun({
                                alignment: AlignmentType.RIGHT,
                                text: `${guest.name} `,
                                size: 32,
                            }),
                        ]
                    }),
                    new Paragraph({
                        bidirectional: true,
                        children: [
                            new TextRun({
                                alignment: AlignmentType.RIGHT,
                                text: "الرقم الوطني / جواز السفر",
                                size: 32,
                                bold: true,
                            }),
                            new TextRun({
                                alignment: AlignmentType.RIGHT,
                                text: `${guest.id_number} : `,
                                size: 32,
                            }),
                        ]
                    }),
                    new Paragraph({
                        bidirectional: true,
                        children: [
                            new TextRun({
                                alignment: AlignmentType.RIGHT,
                                text: "أقر بأني إستلمت مبلغ",
                                size: 32,
                                bold: true,
                            }),
                            new TextRun({
                                alignment: AlignmentType.RIGHT,
                                text: `(${amount}) : `,
                                size: 32,
                            }),
                        ]
                    }),
                    new Paragraph({
                        bidirectional: true,
                        children: [
                            new TextRun({
                                alignment: AlignmentType.RIGHT,
                                text: "وذلك عن ",
                                size: 32,
                                bold: true,
                            }),
                        ]
                    }),
                    new Paragraph({
                        bidirectional: true,
                        children: [
                            new TextRun({
                                alignment: AlignmentType.RIGHT,
                                text: "...................................",
                                size: 32,
                                bold: true,
                            }),
                        ]
                    }),
                    new Paragraph({
                        bidirectional: true,
                        children: [
                            new TextRun({
                                alignment: AlignmentType.RIGHT,
                                text: "...................................",
                                size: 32,
                                bold: true,
                            }),
                        ]
                    }),
                    new Paragraph(""),
                    new Paragraph(""),
                    new Paragraph({
                        bidirectional: true,
                        children: [
                            new TextRun({
                                alignment: AlignmentType.RIGHT,
                                text: "وبناءاً عليه أبرء ذمة سكن آية وريم المالية والتعاقدية والقانونية إبراءاً عاماً شاملاً من أي حق أو مطالبة حالية أو مستقبلية ولا يحق لي الرجوع عنها .",
                                size: 32,
                                bold: true,
                            }),
                        ]
                    }),
                    new Paragraph(""),
                    new Paragraph(""),
                    new Paragraph({
                        bidirectional: true,
                        children: [
                            new TextRun({
                                alignment: AlignmentType.RIGHT,
                                text: "وعليه أوقع على هذا السند ",
                                size: 32,
                                bold: true,
                            }),
                        ]
                    }),
                    new Paragraph({
                        bidirectional: true,
                        children: [
                            new TextRun({
                                alignment: AlignmentType.RIGHT,
                                text: " التوقيع ",
                                size: 32,
                                bold: true,
                            }),
                            new TextRun({
                                alignment: AlignmentType.RIGHT,
                                text: "...................",
                                size: 32,
                                bold: true,
                            }),
                        ]
                    }),
                    new Paragraph({
                        bidirectional: true,
                        children: [
                            new TextRun({
                                alignment: AlignmentType.RIGHT,
                                text: " التاريخ ",
                                size: 32,
                                bold: true,
                            }),
                            new TextRun({
                                alignment: AlignmentType.RIGHT,
                                text: `${new Date().toLocaleDateString()}`,
                                size: 32,
                                bold: true,
                            }),
                        ]
                    }),
                ]
            }]
        });

        Packer.toBlob(doc).then(blob => {
            saveAs(blob, "نموذج المخالصة.docx");
        });
    }

    const logedMolakhasAlert = () => {
        if (hasInsurance) {
            return Swal.fire({
                title: 'يمتلك هذا العميل رصيد تأمين فعال',
                text: 'هل ترغب بارجاعه اليه في الحال ؟',
                showCancelButton: true,
                confirmButtonText: 'نعم أعد رصيد التأمين',
                cancelButtonText: 'ليس الآن',
            }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    // new activity
                    props.setLoadingFunc(true);
                    await axios.post(`${login.API}/finishTheInsurance`, {
                        id: guest.insuranceId,
                        employee_id: login.user.id,
                        employee_name: login.user.name,
                        reciver_id: guest.id,
                        reciver_name: guest.name,
                        reservation_id: reservationInfo.id,
                        insuranceValue,
                        guest_id: guest.id
                    }, { headers: { 'Authorization': `Basic ${token}` } }).then(res => {
                        // lastInsuranceValue
                        props.setLoadingFunc(false);
                        setInsuranceValue(0);
                        return Swal.fire({
                            title: 'تم ارجاع مبلغ التأمين وسيتم طباعة نموذج المخالصة',
                            confirmButtonText: 'طباعة',
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            // res.data.lastInsuranceValue
                            printInsuranceWord(res.data.lastInsuranceValue);
                        })
                    }).catch(err => {
                        return Swal.fire({
                            title: 'حصل خطأ أثناء عملية تثبيت الدفعة',
                            confirmButtonText: 'حسناً',
                        }).then(() => {
                            printInsuranceWord(0);
                        })
                    })
                } else {
                    printInsuranceWord(0);
                }
            })
        } else {
            return Swal.fire({
                title: 'لا يمتلك هذا العميل رصيد تأمين فعال',
                text: 'لن يتم إرجاع أي مبلغ وسيتم طباعة نموذج المخالصة',
                showCancelButton: false,
                confirmButtonText: 'طباعة',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                printInsuranceWord(0);
            })
        }
    }

    useEffect(() => {
        if (moneyBillsToUpdate && moneyBillsToUpdate?.type == "قبض") {
            if (!hasInsurance && moneyBillsToUpdate?.for == 'تأمين') {
                return swal.fire({
                    title: 'لا يمكنك تعديل سندات التأمين بعد إرجاع مبلغ التأمين الى العميل',
                    icon: 'warning',
                    confirmButtonColor: '#d33',
                    cancelButtonText: 'حسناً'
                })
            } else {
                if (moneyBillsToUpdate?.for == 'خدمات') {
                    filterServices(reservationInfo, moneyBillsToUpdate.id);
                } else if (moneyBillsToUpdate?.for == 'قيمة إيجار') {
                    let newReservationData = reservationInfo;
                    setreservationInfo({});
                    newReservationData.period[parseInt(moneyBillsToUpdate.paied_for.split('-')[0])] = false;
                    setreservationInfo(newReservationData);
                }
                setShowReciptsAndExpence(false);
                setSanadDate(new Date(moneyBillsToUpdate.date) != 'Invalid Date' ? new Date(moneyBillsToUpdate.date) : new Date())
                handleShowRecipts();
            }
        } else if (moneyBillsToUpdate && moneyBillsToUpdate?.type == "صرف") {
            let myService = reservationInfo.Services?.find(serve => (serve.id == moneyBillsToUpdate?.id));
            let oldSarf = recieptsAndExpence.find(exp => exp.id == myService?.sarf_id)
            if (moneyBillsToUpdate.for === 'خدمات')
                if (myService?.paidStatus == true && oldSarf?.for === 'خدمات') {
                    setMoneyBillsToUpdate(null);
                    return swal.fire({
                        title: 'هذه الخدمة تم الدفع عنها مسبقا',
                        icon: 'warning',
                        confirmButtonColor: '#d33',
                        cancelButtonText: 'حسناً'
                    })
                }
            if (moneyBillsToUpdate?.for == 'مرتجع تأمين') {
                setHasInsurance(true);
                let newInsuranceValue = 0;
                expenses.forEach(ele => {
                    if (ele.for === 'تأمين') {
                        newInsuranceValue += ele.amount;
                    }
                })
                if (newInsuranceValue == 0) newInsuranceValue = moneyBillsToUpdate.amount;
                setInsuranceValue(newInsuranceValue)
            }
            setShowReciptsAndExpence(false);
            setSanadDate(new Date(moneyBillsToUpdate.date) ? new Date(moneyBillsToUpdate.date) : new Date())
            handleShowExpenses();
        }
    }, [moneyBillsToUpdate])

    const deleteRecord = async (from, id) => {
        return await axios.post(`${login.API}/deleteRecord`, { from, id }, { headers: { 'Authorization': `Basic ${token}` } });
    }
    const updateRecord = async (data, from, id) => {
        return await axios.post(`${login.API}/updateRecord`, { data, from, id }, { headers: { 'Authorization': `Basic ${token}` } });
    }
    const createRecord = async (data, from) => {
        return await axios.post(`${login.API}/createRecord`, { data, from }, { headers: { 'Authorization': `Basic ${token}` } });
    }
    useEffect(() => {
        if (moneyBillsToDelete && moneyBillsToDelete.for) {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'هل انت متأكد؟',
                text: "لن تستطيع استرجاع البيانات بعد حذفها!!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'حذف',
                cancelButtonText: 'الغاء',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    props.setLoadingFunc(true).then(async () => {
                        if (moneyBillsToDelete && moneyBillsToDelete?.type == "قبض") {
                            if (moneyBillsToDelete.for == 'قيمة إيجار') {
                                await deleteRecord('Receipts', moneyBillsToDelete.id).then(async res => {
                                    let newReciepts = JSON.parse(JSON.stringify(reciepts));
                                    setReciepts([]);
                                    newReciepts = newReciepts.filter(resep => (resep.id !== moneyBillsToDelete.id))
                                    setReciepts(newReciepts);

                                    let period = reservationInfo.period;
                                    for (let i = 0; i < period.length; i++) {
                                        if (period[i] == true) {
                                            period[i] = false;
                                            break;
                                        }
                                    }
                                    await updateRecord({ period }, 'Reservation', reservationInfo.id).then(res => {
                                        props.setLoadingFunc(false);
                                    }).catch(err => {
                                        props.setLoadingFunc(false);
                                    })
                                }).catch(err => {
                                    props.setLoadingFunc(false);
                                })
                            } else if (moneyBillsToDelete.for == 'تأمين') {
                                await deleteRecord('Receipts', moneyBillsToDelete.id).then(async res => {
                                    let newReciepts = JSON.parse(JSON.stringify(reciepts));
                                    setReciepts([]);
                                    newReciepts = newReciepts.filter(resep => (resep.id !== moneyBillsToDelete.id))
                                    setReciepts(newReciepts);
                                    if (guest.GuestInsurances.length) {
                                        let insuranceData = guest.GuestInsurances.find(insurance => insurance.value >= moneyBillsToDelete.amount);
                                        if (insuranceData && insuranceData.value == moneyBillsToDelete.amount) {
                                            await deleteRecord('GuestInsurance', insuranceData.id).then(res => {
                                                let insuranceAmount = insuranceValue;
                                                setInsuranceValue(0);
                                                setInsuranceValue(insuranceAmount - insuranceData.value);
                                                props.setLoadingFunc(false);
                                            }).catch(err => {
                                                props.setLoadingFunc(false);
                                            })
                                        } else if (insuranceData && insuranceData.value > moneyBillsToDelete.amount) {
                                            insuranceData.value = insuranceData.value - moneyBillsToDelete.amount;
                                            await updateRecord(insuranceData, 'GuestInsurance', insuranceData.id).then(res => {
                                                let insuranceAmount = insuranceValue;
                                                setInsuranceValue(0);
                                                setInsuranceValue(insuranceAmount - moneyBillsToDelete.amount);
                                                props.setLoadingFunc(false);
                                            }).catch(err => {
                                                props.setLoadingFunc(false);
                                            })
                                        }
                                    }

                                }).catch(err => {
                                    props.setLoadingFunc(false);
                                })
                            } else {
                                await deleteRecord('Receipts', moneyBillsToDelete.id).then(res => {
                                    let newReciepts = JSON.parse(JSON.stringify(reciepts));
                                    setReciepts([]);
                                    newReciepts = newReciepts.filter(resep => (resep.id !== moneyBillsToDelete.id))
                                    setReciepts(newReciepts);

                                    props.setLoadingFunc(false);
                                }).catch(err => {
                                    props.setLoadingFunc(false);
                                })
                            }
                        } else if (moneyBillsToDelete && moneyBillsToDelete?.type == "صرف") {
                            if (moneyBillsToDelete.for == 'خدمات') {
                                // delete sanad
                                await deleteRecord('Expense', moneyBillsToDelete.id).then(async res => {
                                    let newExpenses = JSON.parse(JSON.stringify(expenses));
                                    setExpenses([]);
                                    newExpenses = newExpenses.filter(expen => (expen?.id !== moneyBillsToDelete?.id))
                                    setExpenses([...newExpenses, res.data.record]);

                                    let serviceData = reservationInfo.Services.find(service => service.id == moneyBillsToDelete.id);
                                    await deleteRecord('Service', serviceData.id).then(async res => {
                                        let newReservationInfo = JSON.parse(JSON.stringify(reservationInfo));
                                        setreservationInfo({});
                                        let total_price = parseFloat(newReservationInfo.total_price) - serviceData.price;
                                        let services_price = parseFloat(newReservationInfo.services_price) - serviceData.price;
                                        setreservationInfo({ ...newReservationInfo, total_price, services_price });

                                        await updateRecord({ total_price, services_price }, 'Reservation', reservationInfo.id);
                                        setServices(reservationInfo.Services.filter(service => service.id !== serviceData.id));
                                        if (serviceData.sarf_id) {
                                            await deleteRecord('Receipts', serviceData.sarf_id).then(async res => {
                                                let newReciepts = JSON.parse(JSON.stringify(reciepts));
                                                setReciepts([]);
                                                newReciepts = newReciepts.filter(resep => (resep.id !== serviceData.sarf_id))
                                                setReciepts(newReciepts);
                                                props.setLoadingFunc(false);
                                            }).catch(err => {
                                                props.setLoadingFunc(false);
                                            })
                                        } else {
                                            props.setLoadingFunc(false);
                                        }
                                    }).catch(err => {
                                        props.setLoadingFunc(false);
                                    })
                                    props.setLoadingFunc(false);
                                }).catch(err => {
                                    props.setLoadingFunc(false);
                                })
                            } else if (moneyBillsToDelete.for == 'مرتجع تأمين') {
                                // delete sanad
                                await deleteRecord('Expense', moneyBillsToDelete.id).then(async res => {
                                    let newExpenses = JSON.parse(JSON.stringify(expenses));
                                    setExpenses([]);
                                    newExpenses = newExpenses.filter(expen => (expen?.id !== moneyBillsToDelete?.id))
                                    setExpenses([...newExpenses, res.data.record]);

                                    let newInsurance = {
                                        id: moneyBillsToDelete.id,
                                        guest_id: guest.id,
                                        value: 0,
                                    }
                                    reciepts.forEach(re => {
                                        if (re.for === 'تأمين') newInsurance.value += re.amount;
                                    })
                                    if (!newInsurance.value) newInsurance.value = moneyBillsToDelete.amount;

                                    await createRecord(newInsurance, 'GuestInsurance').then(r => {
                                        let insuranceAmount = insuranceValue;
                                        setInsuranceValue(0);
                                        setInsuranceValue(insuranceAmount + newInsurance.value);
                                    })
                                    props.setLoadingFunc(false);
                                }).catch(err => {
                                    props.setLoadingFunc(false);
                                })
                            } else {
                                await deleteRecord('Expense', moneyBillsToDelete.id).then(res => {
                                    let newExpenses = JSON.parse(JSON.stringify(expenses));
                                    setExpenses([]);
                                    newExpenses = newExpenses.filter(expen => (expen?.id !== moneyBillsToDelete?.id))
                                    setExpenses([...newExpenses, res.data.record]);

                                    props.setLoadingFunc(false);
                                }).catch(err => {
                                    props.setLoadingFunc(false);
                                })
                            }
                        } else {
                            props.setLoadingFunc(false);
                        }
                    })
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    setMoneyBillsToDelete({});
                    swalWithBootstrapButtons.fire(
                        'تم الالغاء',
                        'بياناتك في امان',
                        'error'
                    )
                }
            })
        }

    }, [moneyBillsToDelete]);

    const updateMoneyTransfer = item => {
        setMoneyBillsToUpdate(item);
        setFor(item.for);
    }

    const deleteMoneyTransfer = item => {
        setMoneyBillsToDelete(item);
        setFor(item.for);
    }

    return (

        <div>
            <div className='navigator'>
                <p>
                    <Link to='/'>
                        الرئيسية
                    </Link>
                    /
                    <Link to='/ReservationManagement'>
                        الحجوزات
                    </Link>
                    /
                    #{reservationInfo.id}
                </p>
            </div>

            <div className='reservationInfo'>
                {reservationInfo.confirmed ?
                    <div className='reservationInfo-header'>
                        {
                            (logInStatus === 'لم يسجل دخول' && login.user?.role === "manager") ? <Button variant='danger' onClick={cancleReservationHandler} >الغاء الحجز</Button> : null
                        }
                        {
                            ((logInStatus === 'لم يسجل دخول' || logInStatus === 'سجل دخول') && login.user?.role === "manager") ? <Button variant='warning' onClick={() => setUpdateReservation(true)} >تعديل معلومات الحجز</Button> : null
                        }
                        {
                            logInStatus === 'لم يسجل دخول' ? (
                                !showLoginModalStatus ? <Button onClick={() => showLoginModal(true)} variant='success' >تسجيل دخول</Button>
                                    : <div className='logInModal_Cleint'>
                                        <p>يرجى إدخال تاريخ تسجيل الدخول</p>
                                        <DatePicker
                                            selectsStart
                                            className="filterInput"
                                            dateFormat="dd-MM-yyyy"
                                            selected={specificCheckInDate}
                                            onChange={(date) => setSpecificCheckInDate(date)}
                                            name="checkIn"
                                            type="date"
                                            placeholder="checkIn-Date"
                                            id='checkIn_Spacific_Data'
                                            required />
                                        {/* <input required  className='' type='date' /> */}
                                        <Button onClick={logInStatusHandler}> تسجيل الدخول </Button>
                                        <p id='error_data_message' className='hidden emptyServices'> يرجى إدخال تاريخ الدخول أولا </p>
                                    </div>
                            ) :
                                logInStatus === 'سجل دخول' ?
                                    <span>
                                        <Button variant='success' onClick={logInStatusHandler} > تسجيل خروج </Button>
                                        <Button onClick={handleFicishReservation} className='finishCurrentReservation' variant='success' > تسجيل خروج مخصص </Button>
                                    </span> : null
                        }
                        {
                            logInStatus === 'سجل مغادرة' &&
                            <>
                                <Button onClick={handleShowInvoice} >ملخص الفاتورة</Button>
                                <Button onClick={() => printInsuranceWord()} > عقد المخالصة </Button>
                            </>
                        }
                        <Button onClick={handleShowContract} >عقد الإيجار</Button>
                    </div>
                    : null}
            </div>

            {
                hasInsurance &&
                <div className='loggedIn_loogedOut'>
                    <div className='insuranceAlert'>
                        <div>
                            <AttachMoneyIcon />
                            <span> يمتلك هذا العميل رصيد تأمين فعال بقيمة {insuranceValue}</span>
                        </div>
                    </div>
                </div>
            }

            {logInStatus === 'سجل دخول' ?
                <div className='loggedIn_loogedOut'>
                    <div className='loggedIn'>
                        <div>
                            <LoginRoundedIcon />
                            <span>تم تسجيل دخول العميل</span>
                        </div>
                        {loggedTime ? <span>{loggedTime}</span> : null}
                    </div>

                </div>
                : logInStatus === 'سجل مغادرة' ?
                    <div className='loggedIn_loogedOut'>
                        <div className='loggedIn'>
                            <div>
                                <LoginRoundedIcon />
                                <span>تم تسجيل دخول العميل</span>
                            </div>

                            {loggedTime ? <span>{loggedTime}</span> : null}
                        </div>
                        <div className='loggedOut'>
                            <div>
                                <span>تم تسجيل خروج العميل</span>
                                <LogoutRoundedIcon />
                            </div>
                            {loggedOutTime ? <span>{loggedOutTime}</span> : null}
                        </div>
                    </div>
                    : null
            }

            <div className='reservationInfo-body'>
                <div className='reservationInfo-body-top'>
                    <div className='reservationInfo-body-top-left'>
                        <p>معلومات العميل</p>
                        <div className='guest_info_container'>
                            <ul className='guest_info_list'>
                                <li> <span> {guest?.name} </span>  <span>: إسم العميل </span> </li>
                                <li> <span>{guest?.phone_number}</span> <span>: الهاتف</span> </li>
                                <li> <span>{guest?.phone_number_2}</span> <span>: رقم ولي الأمر</span> </li>
                                <li> <span> {guest?.nationallity} </span> <span>: الجنسية</span> </li>
                                <li> <span> {guest?.id_number}</span> <span>: رقم الهوية</span> </li>
                                <li> <span> {guest?.email}</span> <span>: البريد الإلكتروني</span> </li>
                            </ul>
                            <div className='button_more'>
                                <Link to="/guest">
                                    <Button onClick={() => sendGuest(guest)}> ... المزيد</Button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className='reservationInfo-body-top-right'>
                        <p>معلومات الحجز</p>
                        <ul className='reservation_info_list'>
                            <li> <span className='reservation_confirmed'> {reservationInfo.confirmed ? <span className='confirmed_true'> مؤكد </span> : <span className='confirmed_false'>ملغي</span>} <span>{reservationInfo.id}# </span>  </span> <span>: رقم الحجز </span> </li>
                            <li> <span>{logInStatus} </span> <span>: حالة الحجز </span> </li>
                            <li> <span> {props.resrvationInfo.room_name} - {props.resrvationInfo.room_id}</span> <span>: الوحدة</span> </li>
                            <li> <span id='nights'> {duration.split(" ")[1]} </span> <span>{
                                parseInt(duration.split(" ")[0])?.toFixed(0)
                            }</span> <span>: مدة الحجز</span> </li>
                            <li>  <span id='dates'>
                                <span>
                                    {
                                        reservationInfo.loggedOut_At ?
                                            reservationInfo.loggedOut_At?.split(' ')[2]
                                            : (reservationInfo.checkOut ?
                                                `${new Date(reservationInfo.checkOut).getDate()}/${new Date(reservationInfo.checkOut).getMonth() + 1}/${new Date(reservationInfo.checkOut).getFullYear()}`
                                                : 'Day/Month/Year'
                                            )
                                    }
                                </span>
                                <span>إلى</span>
                                <span>
                                    {
                                        reservationInfo.loggedIn_At ?
                                            reservationInfo.loggedIn_At?.split(' ')[2]
                                            : (reservationInfo.checkIn ?
                                                `${new Date(reservationInfo.checkIn).getDate()}/${new Date(reservationInfo.checkIn).getMonth() + 1}/${new Date(reservationInfo.checkIn).getFullYear()}`
                                                : 'Day/Month/Year'
                                            )
                                    }
                                </span> <span>من</span> </span> <span>: تاريخ الحجز</span> </li>
                            <li> <span> دينار {reservationInfo?.price?.toFixed(0)}</span> <span>: سعر التاجير</span> </li>
                            <li> <span> دينار {reservationInfo?.services_price?.toFixed(0)}</span> <span>: سعر الخدمات</span> </li>
                            <li> <span> دينار {reservationInfo?.total_price?.toFixed(0)}</span> <span>:  السعر الكلي</span> </li>
                            {/* priceWithTax */}
                        </ul>
                    </div>
                </div>
                <div className='reservationInfo-body-buttom'>
                    <div className='reservationInfo-body-buttom-left'>
                        <p>الفواتير</p>
                        <div className='invoice_container'>
                            {(invoice && logInStatus === 'سجل مغادرة') &&
                                <ul className='fawatercom'>

                                    <li onClick={handleShowInvoice} className='fatorah'>
                                        <div className='fatorah_Left'>
                                            <span>{invoice.date}</span>
                                            <span className='rent_type_span'> فاتورة </span>
                                        </div>

                                        <div className='fatorah_Right'>
                                            <span className='fatorahCardElements'>
                                                <span className='fatorahCardElements_Span'> {invoice.id} </span>
                                                <span> : رقم الفاتورة </span>
                                            </span>
                                            {/* checkIn}checkOut */}
                                            <span className='fatorahCardElements'>
                                                <span className='fatorahCardElements_Span'> من {reservationInfo.loggedIn_At?.split("  ")[1]} - {reservationInfo.loggedOut_At?.split("  ")[1]} </span>
                                                <span> : الفترة  </span>
                                            </span>
                                            <span className='fatorahCardElements'>
                                                <span className='fatorahCardElements_Span'> {reservationInfo?.total_price} دينار </span>
                                                <span> : المبلغ </span>
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            }
                            {
                                !invoice &&
                                <div className='Empty'>
                                    <AttachMoneyIcon />
                                    <p>لا يوجد فواتير</p>
                                </div>
                            }
                        </div>
                    </div>

                    <div className='reservationInfo-body-buttom-right'>
                        <p>السجل المالي</p>
                        <div className='recipts_expense_container'>
                            <ul className='recipts_expense_list' >
                                {recieptsAndExpence.length > 0 ?
                                    recieptsAndExpence.map((item, idx) => {
                                        if (item.type === 'قبض') {
                                            if (item.for === 'قيمة إيجار') {
                                                return (
                                                    <li key={idx}>
                                                        <snap className="moneySpan" onClick={() => handleShowReciptsAndExpence(idx)}>
                                                            <div className='time_span'> <span>{new Date(item.date) != 'Invalid Date' ? new Date(item.date).toJSON().split("T")[0] : item.date}</span>  <span> <span> {item.id} </span><span> : رقم السند</span> </span> </div>
                                                            <div>  <span className='payment_colors'> <span className='recipts'>قبض</span> <span className='rent_type_span'>قيمة إيجار</span> </span> <span className='amount_span'> <span>دينار</span> <span>{`  ` + ` ${item.amount} ` + `  `}</span> <span> : القيمة </span> </span> </div>
                                                        </snap>
                                                        {
                                                            (login.user?.role === "manager" && (logInStatus == "لم يسجل دخول" || logInStatus == "سجل دخول")) &&
                                                            <span>
                                                                <DriveFileRenameOutlineOutlinedIcon onClick={() => updateMoneyTransfer(item)} className="editPen" />
                                                                <DeleteForeverIcon onClick={() => deleteMoneyTransfer(item)} className="deletePen" />
                                                            </span>
                                                        }
                                                    </li>
                                                )
                                            } else if (item.for === 'تأمين') {
                                                return (
                                                    <li key={idx}>
                                                        <span onClick={() => handleShowReciptsAndExpence(idx)} className="moneySpan">
                                                            <div className='time_span'> <span>{new Date(item.date) != 'Invalid Date' ? new Date(item.date).toJSON().split("T")[0] : item.date}</span>  <span> <span> {item.id} </span><span> : رقم السند</span> </span> </div>
                                                            <div>  <span className='payment_colors' > <span className='recipts'>قبض</span> <span className='assurance' >تأمين</span> </span> <span className='amount_span'><span> دينار </span> <span>{item.amount}</span> <span>: القيمة </span> </span> </div>
                                                        </span>
                                                        {
                                                            (login.user?.role === "manager" && (logInStatus == "لم يسجل دخول" || logInStatus == "سجل دخول")) &&
                                                            <span>
                                                                <DriveFileRenameOutlineOutlinedIcon onClick={() => updateMoneyTransfer(item)} className="editPen" />
                                                                <DeleteForeverIcon onClick={() => deleteMoneyTransfer(item)} className="deletePen" />
                                                            </span>
                                                        }

                                                    </li>
                                                )
                                            }
                                            else if (item.for === "عربون") {
                                                return (
                                                    <li key={idx}>
                                                        <span onClick={() => handleShowReciptsAndExpence(idx)} className="moneySpan">
                                                            <div className='time_span'> <span>{new Date(item.date) != 'Invalid Date' ? new Date(item.date).toJSON().split("T")[0] : item.date}</span>  <span> <span> {item.id} </span><span> : رقم السند</span> </span> </div>
                                                            <div>  <span className='payment_colors' > <span className='recipts'>قبض</span> <span className='pre_paid' >عربون</span> </span> <span className='amount_span'><span> دينار </span> <span>{item.amount}</span> <span>: القيمة </span> </span> </div>
                                                        </span>
                                                        {
                                                            (login.user?.role === "manager" && (logInStatus == "لم يسجل دخول" || logInStatus == "سجل دخول")) &&
                                                            <span>
                                                                <DriveFileRenameOutlineOutlinedIcon onClick={() => updateMoneyTransfer(item)} className="editPen" />
                                                                <DeleteForeverIcon onClick={() => deleteMoneyTransfer(item)} className="deletePen" />
                                                            </span>
                                                        }

                                                    </li>
                                                )
                                            }
                                            else if (item.for === "خدمات") {
                                                return (
                                                    <li key={idx}>
                                                        <span onClick={() => handleShowReciptsAndExpence(idx)} className="moneySpan">
                                                            <div className='time_span'> <span>{new Date(item.date) != 'Invalid Date' ? new Date(item.date).toJSON().split("T")[0] : item.date}</span>  <span> <span> {item.id} </span><span> : رقم السند</span> </span> </div>
                                                            <div>  <span className='payment_colors' > <span className='recipts'>قبض</span> <span className='services_paid' >خدمات</span> </span> <span className='amount_span'><span> دينار </span> <span>{item.amount}</span> <span>: القيمة </span> </span> </div>
                                                        </span>
                                                        {
                                                            (login.user?.role === "manager" && (logInStatus == "لم يسجل دخول" || logInStatus == "سجل دخول")) &&
                                                            <span>
                                                                <DriveFileRenameOutlineOutlinedIcon onClick={() => updateMoneyTransfer(item)} className="editPen" />
                                                                <DeleteForeverIcon onClick={() => deleteMoneyTransfer(item)} className="deletePen" />
                                                            </span>
                                                        }
                                                    </li>
                                                )
                                            } else {
                                                return (
                                                    <li key={idx}>
                                                        <span className="moneySpan" onClick={() => handleShowReciptsAndExpence(idx)} >
                                                            <div className='time_span'> <span>{new Date(item.date) != 'Invalid Date' ? new Date(item.date).toJSON().split("T")[0] : item.date}</span>  <span> <span> {item.id} </span><span> : رقم السند</span> </span> </div>
                                                            <div>  <span className='payment_colors' > <span className='recipts'>قبض</span> <span className='discount' >خصم إيحار</span> </span> <span className='amount_span'><span> دينار </span> <span>{item.amount}</span> <span>: القيمة </span> </span> </div>
                                                        </span>
                                                        {
                                                            (login.user?.role === "manager" && (logInStatus == "لم يسجل دخول" || logInStatus == "سجل دخول")) &&
                                                            <span>
                                                                <DriveFileRenameOutlineOutlinedIcon onClick={() => updateMoneyTransfer(item)} className="editPen" />
                                                                <DeleteForeverIcon onClick={() => deleteMoneyTransfer(item)} className="deletePen" />
                                                            </span>
                                                        }
                                                    </li>
                                                )
                                            }

                                        } else {
                                            if (item.for === 'مرتجع إيجار') {
                                                return (
                                                    <li onClick={() => handleShowReciptsAndExpence(idx)} key={idx}>
                                                        <span onClick={() => handleShowReciptsAndExpence(idx)} className="moneySpan">
                                                            <div className='time_span'> <span>{new Date(item.date) != 'Invalid Date' ? new Date(item.date).toJSON().split("T")[0] : item.date}</span>  <span> <span> {item.id} </span><span> : رقم السند</span> </span> </div>
                                                            <div>  <span className='payment_colors'> <span className='expense'>صرف</span> <span className='rent_type_span'>مرتجع إيجار</span> </span> <span className='amount_span'> <span>دينار</span> <span>{`  ` + ` ${item.amount} ` + `  `}</span> <span> : القيمة </span> </span> </div>
                                                        </span>
                                                        {
                                                            (login.user?.role === "manager" && (logInStatus == "لم يسجل دخول" || logInStatus == "سجل دخول")) &&
                                                            <span>
                                                                <DriveFileRenameOutlineOutlinedIcon onClick={() => updateMoneyTransfer(item)} className="editPen" />
                                                                <DeleteForeverIcon onClick={() => deleteMoneyTransfer(item)} className="deletePen" />
                                                            </span>
                                                        }
                                                    </li>
                                                )
                                            } else if (item.for === 'مرتجع تأمين') {
                                                return (
                                                    <li onClick={() => handleShowReciptsAndExpence(idx)} key={idx}>
                                                        <span onClick={() => handleShowReciptsAndExpence(idx)} className="moneySpan">
                                                            <div className='time_span'> <span>{new Date(item.date) != 'Invalid Date' ? new Date(item.date).toJSON().split("T")[0] : item.date}</span>  <span> <span> {item.id} </span><span> : رقم السند</span> </span> </div>
                                                            <div>  <span className='payment_colors' > <span className='expense'>صرف</span> <span className='assurance' >مرتجع تأمين</span> </span> <span className='amount_span'><span> دينار </span> <span>{item.amount}</span> <span>: القيمة </span> </span> </div>
                                                        </span>
                                                        {
                                                            (login.user?.role === "manager" && (logInStatus == "لم يسجل دخول" || logInStatus == "سجل دخول")) &&
                                                            <span>
                                                                <DriveFileRenameOutlineOutlinedIcon onClick={() => updateMoneyTransfer(item)} className="editPen" />
                                                                <DeleteForeverIcon onClick={() => deleteMoneyTransfer(item)} className="deletePen" />
                                                            </span>
                                                        }
                                                    </li>
                                                )
                                            } else if (item.for === "مرتجع عربون") {
                                                return (
                                                    <li key={idx}>
                                                        <span className="moneySpan" onClick={() => handleShowReciptsAndExpence(idx)} >
                                                            <div className='time_span'> <span>{new Date(item.date) != 'Invalid Date' ? new Date(item.date).toJSON().split("T")[0] : item.date}</span>  <span> <span> {item.id} </span><span> : رقم السند</span> </span> </div>
                                                            <div>  <span className='payment_colors' > <span className='expense'>صرف</span> <span className='pre_paid' >مرتجع عربون</span> </span> <span className='amount_span'><span> دينار </span> <span>{item.amount}</span> <span>: القيمة </span> </span> </div>
                                                        </span>
                                                        {
                                                            (login.user?.role === "manager" && (logInStatus == "لم يسجل دخول" || logInStatus == "سجل دخول")) &&
                                                            <span>
                                                                <DriveFileRenameOutlineOutlinedIcon onClick={() => updateMoneyTransfer(item)} className="editPen" />
                                                                <DeleteForeverIcon onClick={() => deleteMoneyTransfer(item)} className="deletePen" />
                                                            </span>
                                                        }
                                                    </li>
                                                )
                                            } else if (item.for === "خدمات") {
                                                return (
                                                    <li key={idx}>
                                                        <span className="moneySpan" onClick={() => handleShowReciptsAndExpence(idx)}>
                                                            <div className='time_span'> <span>{new Date(item.date) != 'Invalid Date' ? new Date(item.date).toJSON().split("T")[0] : item.date}</span>  <span> <span> {item.id} </span><span> : رقم السند</span> </span> </div>
                                                            <div>  <span className='payment_colors' > <span className='expense'>صرف</span> <span className='maintinance_spent' >خدمات</span> </span> <span className='amount_span'><span> دينار </span> <span>{item.amount}</span> <span>: القيمة </span> </span> </div>
                                                        </span>
                                                        {
                                                            (login.user?.role === "manager" && (logInStatus == "لم يسجل دخول" || logInStatus == "سجل دخول")) &&
                                                            <span>
                                                                <DriveFileRenameOutlineOutlinedIcon onClick={() => updateMoneyTransfer(item)} className="editPen" />
                                                                <DeleteForeverIcon onClick={() => deleteMoneyTransfer(item)} className="deletePen" />
                                                            </span>
                                                        }
                                                    </li>
                                                )
                                            }
                                        }
                                    })
                                    :
                                    <div className='Empty'>
                                        <ReceiptIcon />
                                        <p>لا يوجد سندات</p>
                                    </div>
                                }
                            </ul>
                            {reservationInfo.confirmed ?
                                <div className='recipts_expense_buttons'>
                                    {
                                        (hasInsurance || logInStatus != 'سجل مغادرة') &&
                                        <Button onClick={() => readFromOldData('صرف')} > مصروفات</Button>
                                    }
                                    {
                                        logInStatus != 'سجل مغادرة' &&
                                        <Button onClick={() => readFromOldData('قبض')}> مقبوضات</Button>
                                    }
                                </div>
                                : null
                            }
                        </div>


                        {/* New Recipts Modal */}
                        <Modal show={showRecipts} onHide={() => handleClose(false)}>
                            <Modal.Header className='recipts_modal_header'>
                                <CloseRoundedIcon onClick={() => handleClose(false)} />
                                <Modal.Title>مقبوضات</Modal.Title>
                            </Modal.Header>

                            <Form id="recipt-form" onSubmit={handleSubmit}>
                                <Modal.Body className='recipts_modal_body'>
                                    <div className='recipts_modal_body_top'>
                                        <div>
                                            <span>التاريخ</span> {/* toDateString()).split(' ')[0] */}
                                            <DatePicker
                                                selectsStart
                                                className="inputDataForNewGuest"
                                                dateFormat="dd-MM-yyyy"
                                                selected={sanadDate}
                                                onChange={(date) => setSanadDate(date)}
                                                name="checkIn"
                                                type="date"
                                                placeholder="checkIn-Date"
                                                required />
                                            {/* <input onChange={handleChange} required name='date' type="date" /> */}
                                        </div>
                                        <div>
                                            <span> النوع</span>

                                            {reservationInfo.period &&
                                                reservationInfo.period.indexOf(false) != -1 ?
                                                <select name="for" className='recipts_type' onChange={handleChange_3}>
                                                    <option value=""> </option>
                                                    <option selected={moneyBillsToUpdate?.for == "عربون" ? 'selected' : ''} value="عربون"> عربون</option>
                                                    {logInStatus != "لم يسجل دخول" &&
                                                        <>
                                                            <option selected={(moneyBillsToUpdate?.for == "قيمة إيجار" || oldSanadData.for == 'مرتجع إيجار') ? 'selected' : ''} value="قيمة إيجار">قيمة إيجار </option>
                                                            {
                                                                ((insuranceValue <= 150) || (moneyBillsToUpdate?.for == "تأمين" || oldSanadData.for == 'مرتجع تأمين')) &&
                                                                <option selected={(moneyBillsToUpdate?.for == "تأمين" || oldSanadData.for == 'مرتجع تأمين') ? 'selected' : ''} value="تأمين"> تأمين</option>
                                                            }
                                                            {login.user.role === "manager" ?
                                                                <option selected={moneyBillsToUpdate?.for == "قيمة خصم إيجار" ? 'selected' : ''} value="قيمة خصم إيجار">قيمة خصم إيجار</option>
                                                                :
                                                                null
                                                            }
                                                            <option selected={(moneyBillsToUpdate?.for == "خدمات" || oldSanadData.for === 'خدمات') ? 'selected' : ''} value="خدمات">خدمات</option>
                                                        </>
                                                    }
                                                </select>
                                                :
                                                <select name="for" className='recipts_type' onChange={handleChange_3}>
                                                    <option value="">   </option>

                                                    <option selected={(moneyBillsToUpdate?.for == "تأمين" || oldSanadData.for == 'مرتجع تأمين') ? 'selected' : ''} value="تأمين"> تأمين</option>
                                                    <option selected={moneyBillsToUpdate?.for == "عربون" ? 'selected' : ''} value="عربون"> عربون</option>
                                                    {login.user.role === "manager" ?
                                                        <option selected={moneyBillsToUpdate?.for == "قيمة خصم إيجار" ? 'selected' : ''} value="قيمة خصم إيجار">قيمة خصم إيجار</option>
                                                        :
                                                        null
                                                    }
                                                    <option selected={(moneyBillsToUpdate?.for == "خدمات" || oldSanadData.for == 'خدمات') ? 'selected' : ''} value="خدمات">خدمات</option>
                                                </select>
                                            }
                                        </div>
                                    </div>

                                    <div className='recipts_modal_body_middle'>
                                        <div>
                                            <span>استلمت من</span>
                                            <input required readOnly defaultValue={guest?.name} type='text' />
                                        </div>
                                        <div>
                                            <span> المبلغ</span>
                                            {(For === "قيمة إيجار" || moneyBillsToUpdate?.for == "قيمة إيجار" || oldSanadData.for == 'مرتجع إيجار') ?
                                                <input required name='amount' step="0.01" type='number' defaultValue={moneyBillsToUpdate?.amount || oldSanadData.amount || parseFloat(((reservationInfo.priceWithTax + reservationInfo.services_price) / reservationInfo.period.length)?.toFixed(2))} onChange={handleChange} />
                                                :
                                                (For === "خدمات" || moneyBillsToUpdate?.for == "خدمات" || oldSanadData.for == "خدمات") ?
                                                    <input required name='amount' step="0.01" type='number' disabled value={oldSanadData.amount || activeServices?.price || moneyBillsToUpdate?.amount} onChange={handleChange} /> :
                                                    <input required max={(For == "تأمين" || oldSanadData.for == 'مرتجع تأمين') ? 150 - insuranceValue : 1000000} name='amount' step="0.01" type='number' defaultValue={moneyBillsToUpdate?.amount || oldSanadData.amount} onChange={handleChange} />
                                            }
                                        </div>
                                    </div>

                                    <div className='recipts_modal_body_bottom'>
                                        <div>
                                            <span>استلمت بواسطة</span>
                                            <input required readOnly defaultValue={login.user.name} type='text' />
                                        </div>
                                        <div>
                                            <span> لاجل </span>
                                            {For === 'قيمة إيجار' ?
                                                <select name="paied_for" className='recipts_type' onChange={handleChange}>
                                                    <option value="">   </option>
                                                    {reservationInfo?.period?.map((item, idx) => {
                                                        if (reservationInfo.rent_type == "شهري") {
                                                            let month = reservationInfo.checkIn.split("-")[1]
                                                            month = reservationInfo.loggedIn_At ? reservationInfo.loggedIn_At.split(" ")[2].split("/")[1] : month
                                                            let currentMonth = parseInt(month) + idx;
                                                            while (currentMonth > 12) {
                                                                currentMonth -= 12
                                                            }
                                                            let nextMonth = currentMonth + 1;
                                                            if (nextMonth > 12) nextMonth -= 12;
                                                            if (item === false) {
                                                                return (
                                                                    <option selected={moneyBillsToUpdate?.paied_for == `${idx}-من شهر ${currentMonth} إلى شهر ${nextMonth}` ? 'selected' : ''} className="period_options" value={`${idx}-من شهر ${currentMonth} إلى شهر ${nextMonth}`} key={idx}>
                                                                        {`من شهر ${currentMonth} إلى شهر ${nextMonth}`}
                                                                    </option>
                                                                )
                                                            }
                                                        } else if (reservationInfo.rent_type == "اسبوعي") {

                                                            if (item === false) {
                                                                return (
                                                                    <option selected={moneyBillsToUpdate?.paied_for == `${idx}-الاسبوع` ? 'selected' : ''} className="period_options" value={`${idx}-الاسبوع`} key={idx}>
                                                                        {`الاسبوع - ${idx + 1}`}
                                                                    </option>
                                                                )
                                                            }
                                                        } else if (reservationInfo.rent_type == "يومي") {
                                                            if (item === false) {
                                                                return (
                                                                    <option selected={moneyBillsToUpdate?.paied_for == `${idx}-اليوم` ? 'selected' : ''} className="period_options" value={`${idx}-اليوم`} key={idx}>
                                                                        {`اليوم - ${idx + 1}`}
                                                                    </option>
                                                                )
                                                            }
                                                        }

                                                    })}
                                                </select>
                                                :
                                                <input required readOnly defaultValue={props.resrvationInfo.room_id + ' - ' + props.resrvationInfo.room_name} name='paied_for' type='text' onChange={handleChange} />
                                            }
                                        </div>
                                    </div>
                                    {(For != "خدمات" && ((!moneyBillsToUpdate?.for || moneyBillsToUpdate?.for != "خدمات") && (!oldSanadData.for || oldSanadData.for != 'خدمات'))) ?
                                        <div className='recipts_modal_body_bottom'>
                                            <div>
                                                <span>ملاحظات </span>
                                                <input required defaultValue={moneyBillsToUpdate?.description || oldSanadData.description} onChange={handleChange} name='description' type='text' />
                                            </div>
                                            <div>
                                                <span> طريقة الدفع </span>
                                                <select name="payment_method" className='recipts_type' onChange={handleChange}>
                                                    <option selected='selected' className="payment_method" value='دفع نقدي'>دفع نقدي</option>
                                                    <option className="payment_method" value='بطاقة VISA'>بطاقة VISA</option>
                                                    <option className="payment_method" value='حوالة بنكية'>حوالة بنكية</option>
                                                </select>
                                            </div>
                                        </div>
                                        :
                                        <div className='recipts_modal_body_bottom'>
                                            <div>
                                                <span> إختر الخدمة </span>
                                                <select onChange={handleChange_service} name="service" className='recipts_type'>
                                                    {
                                                        services.map((service) => <option selected={((moneyBillsToUpdate?.id && moneyBillsToUpdate?.id == service.sarf_id) || oldSanadData.id == service.id) ? 'selected' : ''} value={`${service.id}`}>{service.name}</option>)
                                                    }
                                                </select>
                                                {
                                                    ((For === "خدمات" || moneyBillsToUpdate?.for === "خدمات" || oldSanadData.for === "خدمات") && services?.length == 0) &&
                                                    <span className='emptyServices'> لا يوجد خدمات، يرجى اضافة خدمة في سندات الصرف أولاَ </span>
                                                }
                                            </div>
                                            <div>
                                                <span> طريقة الدفع </span>
                                                <select name="payment_method" className='recipts_type' onChange={handleChange}>
                                                    <option selected='selected' className="payment_method" value='دفع نقدي'>دفع نقدي</option>
                                                    <option className="payment_method" value='بطاقة VISA'>بطاقة VISA</option>
                                                    <option className="payment_method" value='حوالة بنكية'>حوالة بنكية</option>
                                                </select>
                                            </div>
                                        </div>
                                    }


                                    {(For === "خدمات" || ((moneyBillsToUpdate?.for && moneyBillsToUpdate?.for === "خدمات") || (oldSanadData.for && oldSanadData.for === 'خدمات'))) &&
                                        <div className='recipts_modal_body_bottom'>
                                            <div>
                                                <span>ملاحظات </span>
                                                <input defaultValue={moneyBillsToUpdate?.description || oldSanadData.description} required onChange={handleChange} name='description' type='text' />
                                            </div>

                                        </div>
                                    }
                                </Modal.Body>
                                <Modal.Footer className='recipts_modal_footer'>
                                    <Button type='submit' variant="primary" >
                                        اضافة سند
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>

                        {/* New Expense Modal */}
                        <Modal show={showExpenses} onHide={() => handleClose(false)}>
                            <Modal.Header className='recipts_modal_header'>
                                <CloseRoundedIcon onClick={() => handleClose(false)} />
                                <Modal.Title>مصروفات</Modal.Title>
                            </Modal.Header>

                            <Form id="expense-form" onSubmit={handleSubmit_2}>
                                <Modal.Body className='recipts_modal_body'>
                                    <div className='recipts_modal_body_top'>
                                        <div>
                                            <span>التاريخ</span>
                                            <DatePicker
                                                selectsStart
                                                className="inputDataForNewGuest"
                                                dateFormat="dd-MM-yyyy"
                                                selected={sanadDate}
                                                onChange={(date) => setSanadDate(date)}
                                                name="checkIn"
                                                type="date"
                                                placeholder="checkIn-Date"
                                                required />
                                            {/* <input required onChange={handleChange_2} name='date' type="date" /> */}
                                        </div>
                                        <div>
                                            <span> النوع</span>
                                            <select name="for" className='recipts_type' onChange={handleChange_4}>
                                                <option value="">   </option>
                                                {
                                                    logInStatus != 'سجل مغادرة' &&
                                                    <>
                                                        <option selected={(moneyBillsToUpdate?.for == "مرتجع إيجار" || (oldSanadData.for && oldSanadData.for !== "خدمات")) ? 'selected' : ''} value="مرتجع إيجار">مرتجع إيجار </option>
                                                        <option selected={(moneyBillsToUpdate?.for == "خدمات" || oldSanadData.for == "خدمات") ? 'selected' : ''} value="خدمات">خدمات</option>
                                                    </>
                                                }
                                                {
                                                    hasInsurance &&
                                                    <option selected={(moneyBillsToUpdate?.for == "مرتجع تأمين" || oldSanadData.for == "تأمين") ? 'selected' : ''} value="مرتجع تأمين">مرتجع تأمين</option>
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className='recipts_modal_body_middle'>
                                        <div>
                                            <span>استلمت بواسطة</span>
                                            {For === "صيانة" ?
                                                <input required defaultValue={moneyBillsToUpdate?.reciver_name} onChange={handleChange_2} name="reciver_name" type='text' id="reciver_name" />
                                                :
                                                <input required readOnly value={guest?.name} type='text' />
                                            }
                                        </div>

                                        <div>
                                            <span> المبلغ</span>
                                            <input required max={For == "مرتجع تأمين" ? insuranceValue : 1000000} defaultValue={moneyBillsToUpdate?.amount || oldSanadData.amount} name='amount' step="0.01" type='number' onChange={handleChange_2} />
                                        </div>
                                    </div>

                                    <div className='recipts_modal_body_bottom'>
                                        <div>
                                            <span> قام بتسليمها</span>
                                            <input required readOnly defaultValue={login?.user?.name} type='text' />
                                        </div>
                                        <div>
                                            <span> لاجل </span>
                                            <input required readOnly defaultValue={props.resrvationInfo?.room_id + ' - ' + props.resrvationInfo?.room_name} name='paied_for' type='text' onChange={handleChange_2} />
                                        </div>
                                    </div>

                                    <div className='recipts_modal_body_bottom'>
                                        <div>
                                            {
                                                (For == 'خدمات' || moneyBillsToUpdate?.for == "خدمات" || oldSanadData.for === "خدمات") ?
                                                    <span>نوع الخدمة </span> :
                                                    <span>ملاحظات </span>
                                            }
                                            <input required defaultValue={moneyBillsToUpdate?.description || oldSanadData.description} onChange={handleChange_2} name='description' type='text' />
                                        </div>
                                        <div>
                                            <span> طريقة الدفع </span>
                                            <select name="payment_method" className='recipts_type' onChange={handleChange}>
                                                <option selected='selected' className="payment_method" value='دفع نقدي'>دفع نقدي</option>
                                                <option className="payment_method" value='بطاقة VISA'>بطاقة VISA</option>
                                                <option className="payment_method" value='حوالة بنكية'>حوالة بنكية</option>
                                            </select>
                                        </div>
                                    </div>

                                </Modal.Body>
                                <Modal.Footer className='recipts_modal_footer'>
                                    <Button type='submit' variant="primary" >
                                        {
                                            moneyBillsToUpdate ? 'تعديل السند' : 'اضافة سند'
                                        }
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>
                    </div>
                </div>
            </div>



            {/* Contract Modal */}
            {reservationInfo?.Contract &&
                <Contract hotel={hotel} handleClose={handleClose} handlePrint={handlePrint} componentRef={componentRef} date={date} showContract={showContract} guest={guest} reservationInfo={reservationInfo} duration={duration} />
            }

            {/* Invoice Modal */}
            {invoice &&
                <Modal show={showInvoice} onHide={handleClose} className='invoice_modal'>
                    <Modal.Header className='contarct_modal_header'>
                        <CloseRoundedIcon onClick={handleClose} />

                        <Modal.Title>
                            فاتورة رقم #{invoice.id}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Header className='contarct_modal_print'>
                        <i onClick={handlePrint}>
                            <PrintIcon />
                        </i>
                        <span onClick={handlePrint} id="handlePrint">طباعة </span>
                    </Modal.Header>

                    <Modal.Body className='contarct_modal_body'>
                        <div ref={componentRef} className='invoice_modal_body_container'>

                            <div className='contract_Head'>
                                <img className='printCashContainer_Logo' src={logo} alt="logo" />
                                <p className='hotelName_Contract'> {hotel?.name} </p>
                            </div>

                            <div className='invoice_modal_body_top'>
                                <span>رقم الحجز #{invoice?.id}</span>

                                <span>التاريخ : {invoice?.date}</span>
                            </div>

                            <div className='contarct_modal_body_middle'>
                                <h3> نموذج الفاتورة </h3>
                            </div>

                            <div className='invoice_modal_body_info'>
                                <div>
                                    <span className='invoice_modal_body_info_span'>
                                        <span>{reservationInfo?.loggedIn_At?.split("  ")[1]}</span> <span> : بداية الحجز</span>
                                    </span>

                                    <span className='invoice_modal_body_info_span'>
                                        <span>{guest?.guest_name}</span> <span> : الإسم</span>
                                    </span>

                                    <span className='invoice_modal_body_info_span'>
                                        <span>{invoice?.date}</span> <span> : التاريخ</span>
                                    </span>
                                </div>

                                <div>
                                    <span className='invoice_modal_body_info_span'>
                                        <span>{reservationInfo?.loggedOut_At?.split("  ")[1]}</span> <span> : نهاية الحجز</span>
                                    </span>

                                    <span className='invoice_modal_body_info_span'>
                                        <span>{invoice?.guest_phone}</span> <span> : رقم الهاتف</span>
                                    </span>

                                    <span className='invoice_modal_body_info_span'>
                                        <span>{invoice?.id}</span> <span> : رقم الحجز</span>
                                    </span>
                                </div>

                                <div>
                                    <span className='invoice_modal_body_info_span'>
                                        <span>
                                            {
                                                parseInt((new Date(reservationInfo?.loggedOut_At?.split("  ")[1].split('/')[2], reservationInfo?.loggedOut_At?.split("  ")[1].split('/')[1], reservationInfo?.loggedOut_At?.split("  ")[1].split('/')[0]).getTime()
                                                    - new Date(reservationInfo?.loggedIn_At?.split("  ")[1].split('/')[2], reservationInfo?.loggedIn_At?.split("  ")[1].split('/')[1], reservationInfo?.loggedIn_At?.split("  ")[1].split('/')[0]).getTime()) / (1000 * 60 * 60 * 24) + 1)
                                            }
                                        </span> <span>: ليالي الحجز</span>
                                    </span>

                                    <span className='invoice_modal_body_info_span'>
                                        <span>{invoice?.id}</span> <span> : رقم العقد</span>
                                    </span>

                                    <span className='invoice_modal_body_info_span'>
                                        <span>{reservationInfo?.room_id}</span> <span> : رقم الوحدة</span>
                                    </span>
                                </div>

                            </div>

                            <Table className='invoice_table'>
                                <tbody>
                                    <tr>
                                        <td>المجموع</td>
                                        <td>سعر الخدمات</td>
                                        <td>سعر الحجز</td>
                                        <td>عدد الليالي</td>
                                        <td>البيان</td>
                                        <td>#</td>
                                    </tr>
                                    <tr>
                                        <td>{reservationInfo?.total_price}</td>
                                        <td>{reservationInfo?.services_price}</td>
                                        <td>{reservationInfo?.price}</td>
                                        <td>{
                                            parseInt((new Date(reservationInfo?.loggedOut_At?.split("  ")[1].split('/')[2], reservationInfo?.loggedOut_At?.split("  ")[1].split('/')[1], reservationInfo?.loggedOut_At?.split("  ")[1].split('/')[0]).getTime()
                                                - new Date(reservationInfo?.loggedIn_At?.split("  ")[1].split('/')[2], reservationInfo?.loggedIn_At?.split("  ")[1].split('/')[1], reservationInfo?.loggedIn_At?.split("  ")[1].split('/')[0]).getTime()) / (1000 * 60 * 60 * 24) + 1)
                                        }</td>
                                        <td id='title_1'>
                                            <span>
                                                {reservationInfo?.loggedOut_At?.split("  ")[1]}
                                            </span>
                                            <span>
                                                :  إلى
                                            </span>
                                            <span>
                                                {reservationInfo?.loggedIn_At?.split("  ")[1]}
                                            </span>
                                            <span>
                                                : من
                                            </span>
                                            <span>
                                                - {reservationInfo?.room_id}
                                            </span>


                                            <span>
                                                {props.resrvationInfo?.room_name}
                                            </span>
                                            <span>
                                                -
                                            </span>

                                            <span>
                                                قيمة حجز
                                            </span>

                                        </td>
                                        <td>1</td>
                                    </tr>
                                </tbody>
                            </Table>

                            <div className='invoice_modal_body_bottom'>
                                <div>
                                    <ul>
                                        <li>
                                            <span>{reservationInfo?.price}</span>
                                            <span>سعر الحجز</span>
                                        </li>
                                        <li>
                                            <span>{reservationInfo?.services_price}</span>
                                            <span>سعر الخدمات</span>
                                        </li>
                                        <li>
                                            <span>{reservationInfo?.total_price}</span>
                                            <span>اجمالي القيمة</span>
                                        </li>
                                        <li>
                                            <span>{reservationInfo?.total_price}</span>
                                            <span>المدفوع</span>
                                        </li>
                                    </ul>
                                </div>

                                <div>
                                    <QRCode value={`${window.location.hostname}/invoice?id=${invoice?.id}`} />
                                    {/* <QRCode value={`http://localhost:3000/invoice?id=${invoice.id}`} /> */}
                                </div>
                            </div>

                            <div className='contract_hotel_info'>
                                <span>{hotel?.name}</span>
                                <span>{hotel?.email}</span>
                                <span>{hotel?.phone}</span>
                            </div>

                        </div>
                    </Modal.Body>
                </Modal>
            }
            {recieptsAndExpence[indexOf] &&
                <Modal show={showReciptsAndExpence} onHide={handleClose} className='invoice_modal'>


                    <Modal.Header className='contarct_modal_header'>
                        <CloseRoundedIcon onClick={handleClose} />

                        <Modal.Title>
                            سند رقم #{recieptsAndExpence[indexOf].id}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Header className='contarct_modal_print'>
                        <i onClick={handlePrint} >
                            <PrintIcon />
                        </i>
                        <span onClick={handlePrint} id="handlePrint">طباعة </span>
                    </Modal.Header>
                    <div ref={componentRef}>
                        {
                            ['for hotel', 'for clint'].map(() => {
                                return (
                                    <Modal.Body className='contarct_modal_body'>
                                        {recieptsAndExpence[indexOf].type === 'قبض' ?
                                            <div className='reciptsAndExpense_modal_body_container'>
                                                <div className='contract_Head'>
                                                    <img className='printCashContainer_Logo' src={logo} alt="logo" />
                                                    <p className='hotelName_Contract'> {hotel?.name} </p>
                                                </div>
                                                <div className='invoice_modal_body_top'>
                                                    <span>رقم السند #{recieptsAndExpence[indexOf].id}</span>

                                                    <span>التاريخ : {new Date(recieptsAndExpence[indexOf].date) != "Invalid Date" ? new Date(recieptsAndExpence[indexOf].date).toJSON().split("T")[0] : new Date().toJSON().split("T")[0]}</span>
                                                </div>
                                                <div className='contarct_modal_body_middle'>
                                                    <h4>  {` سند ${recieptsAndExpence[indexOf].type} `} </h4>
                                                </div>
                                                <div className='reciptsAndExpense_modal_body_info'>
                                                    <ul>
                                                        <li>
                                                            <span>Recived From Mr./Miss : </span>
                                                            <span>{guest.name}</span>
                                                            <span>: استلمت من السيد / السيدة </span>
                                                        </li>
                                                        <li>
                                                            <span>Amount : </span>
                                                            <span>{recieptsAndExpence[indexOf].amount}</span>
                                                            <span> : مبلغ وقدرة </span>
                                                        </li>
                                                        <li>
                                                            <span>Payment Method : </span>
                                                            <span>{recieptsAndExpence[indexOf].payment_method}</span>
                                                            <span> : طريقة الدفع </span>
                                                        </li>
                                                        <li>
                                                            <span>Being : </span>
                                                            <span>{`${recieptsAndExpence[indexOf].for} - ${recieptsAndExpence[indexOf].paied_for}  `}</span>

                                                            <span> : وذلك مقابل </span>
                                                        </li>
                                                        {recieptsAndExpence[indexOf].period != null ?
                                                            <li>
                                                                <span>Period : </span>
                                                                <span>{recieptsAndExpence[indexOf].period}</span>
                                                                <span> : الفترة  </span>
                                                            </li>
                                                            : recieptsAndExpence[indexOf].service != null ?
                                                                <li>
                                                                    <span>Service : </span>
                                                                    <span>{recieptsAndExpence[indexOf].service}</span>
                                                                    <span> : الخدمة  </span>
                                                                </li>
                                                                :
                                                                null
                                                        }
                                                        <li>
                                                            <span>Notes : </span>
                                                            <span>{recieptsAndExpence[indexOf].description}</span>
                                                            <span> : ملاحظات </span>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div className='signature'>


                                                    <div>
                                                        <span>المستلم</span>
                                                        <span>{login.user.name}</span>
                                                    </div>
                                                </div>

                                                <div className='contract_hotel_info'>
                                                    <span>{hotel?.name}</span>
                                                    <span>{hotel?.email}</span>
                                                    <span>{hotel?.phone}</span>
                                                </div>
                                            </div>
                                            :
                                            <div ref={componentRef} className='reciptsAndExpense_modal_body_container'>

                                                <div className='contract_Head'>
                                                    <img className='printCashContainer_Logo' src={logo} alt="logo" />
                                                    <p className='hotelName_Contract'> {hotel?.name} </p>
                                                </div>

                                                <div className='invoice_modal_body_top'>
                                                    <span>رقم السند #{recieptsAndExpence[indexOf].id}</span>

                                                    <span>التاريخ : {new Date(recieptsAndExpence[indexOf].date) != "Invalid Date" ? new Date(recieptsAndExpence[indexOf].date).toJSON().split("T")[0] : new Date().toJSON().split("T")[0]}</span>
                                                </div>

                                                <div className='contarct_modal_body_middle'>
                                                    <h3>  {` سند ${recieptsAndExpence[indexOf].type} `} </h3>
                                                </div>

                                                <div className='reciptsAndExpense_modal_body_info'>
                                                    <ul>
                                                        <li>
                                                            <span>Paid To Master : </span>
                                                            <span>{recieptsAndExpence[indexOf].reciver_name}</span>
                                                            <span>: تم تسليمها إلى السيد / السيدة </span>
                                                        </li>
                                                        <li>
                                                            <span>Amount : </span>
                                                            <span>{recieptsAndExpence[indexOf].amount}</span>
                                                            <span> : مبلغ وقدرة </span>
                                                        </li>
                                                        <li>
                                                            <span>Payment Method : </span>
                                                            <span>{recieptsAndExpence[indexOf].payment_method}</span>
                                                            <span> : طريقة الدفع </span>
                                                        </li>
                                                        <li>
                                                            <span>Being : </span>
                                                            <span>{`${recieptsAndExpence[indexOf].for} - ${recieptsAndExpence[indexOf].paied_for}  `}</span>

                                                            <span> : وذلك مقابل </span>
                                                        </li>
                                                        <li>
                                                            <span>Notes : </span>
                                                            <span>{recieptsAndExpence[indexOf].description}</span>
                                                            <span> : ملاحظات </span>
                                                        </li>
                                                    </ul>

                                                </div>

                                                <div className='signature'>

                                                    <div>
                                                        <span>الموظف</span>
                                                        <span>{login.user.name}</span>
                                                    </div>
                                                    <div>
                                                        <span>المستلم</span>
                                                        <span>{guest.name}</span>
                                                    </div>
                                                </div>

                                                <div className='contract_hotel_info'>
                                                    <span>{hotel?.name}</span>
                                                    <span>{hotel?.email}</span>
                                                    <span>{hotel?.phone}</span>
                                                </div>
                                            </div>
                                        }
                                    </Modal.Body>
                                )
                            })
                        }
                    </div>

                </Modal>
            }
            <FinishCurrentReservationModal logedMolakhasAlert={logedMolakhasAlert} recieptsAndExpence={recieptsAndExpence} setreservationInfo={setreservationInfo} setExpenses={setExpenses} setReciepts={setReciepts} setLoadingFunc={props.setLoadingFunc} setInvoice={setInvoice} setLoggedOutTime={setLoggedOutTime} setLogInStatus={setLogInStatus} amountPaid={amountPaid} reservationInfo={reservationInfo} show={finishReservation} handleClose={handleFicishReservation} />
            <UpdateReservationInfo setLoadingFunc={props.setLoadingFunc} setreservationInfo={setreservationInfo} logInStatus={logInStatus} duration={duration} reservationInfo={reservationInfo} resrvationInfo={props.resrvationInfo} updateReservation={updateReservation} handleClose={setUpdateReservation} />
            <GetSanad handleReadOldDataModal={handleReadOldDataModal} oldSanadData={oldSanadsListData} show={oldSanadsListData.length} hide={setOldSanadsListData} title={oldSanadsListTitle} />
        </div >
    );
}
