import React from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';


export default function ShowRoomModal(props) {
    return <div>
        <Modal className='modalToUpdateDepartment' show={props.showRoom} onHide={props.setShowRoomFunc}>

            <Modal.Header>
                <button className='closeModal' onClick={() => props.setShowRoomFunc()}>
                     <CloseRoundedIcon />
                     </button>
                <p>معلومات الوحدة - {props?.myRoomSelected?.room_name}</p>
            </Modal.Header>
            <Form>
                <Modal.Body>
                    {/* offer name */}


                    <div className='roomShowModal'>
                        <div className='roomModalRow'>
                            <span className='roomModalRowLeftSide'> {props?.myRoomSelected?.room_number} </span>
                            <span className='roomModalRowRightSide'> رقم الغرفة </span>
                        </div>

                        <div className='roomModalRow'>
                            <span className='roomModalRowLeftSide'> {props?.myRoomSelected?.type} </span>
                            <span className='roomModalRowRightSide'> قسم الوحدة </span>
                        </div>

                        <div className='roomModalRow'>
                            <span className='roomModalRowLeftSide'> {props?.myRoomSelected?.type} </span>
                            <span className='roomModalRowRightSide'> حالة الوحدة </span>
                        </div>

                        <div className='roomModalRow roomModalRowServices'>
                            <span className='roomModalRowLeftSide modalServicesToShow'>
                                {props?.myRoomSelected?.services && 
                                props?.myRoomSelected?.services.map((service, index) => {
                                    return <span key={index}> / {service} </span>
                                })
                                }
                            </span>
                            <span className='roomModalRowRightSide'> الخدمات </span>
                        </div>

                        <div className='roomModalRowPrice'>
                            {
                                props?.myRoomSelected?.daily_price &&
                                <span className='roomModalRowLeftSidePrice'>
                                    <span className='modalShowPriceDailySpan'>السبت:{props?.myRoomSelected?.daily_price[0]}</span>
                                    <span className='modalShowPriceDailySpan'>الاحد:{props?.myRoomSelected?.daily_price[1]}</span>
                                    <span className='modalShowPriceDailySpan'>الاثنين:{props?.myRoomSelected?.daily_price[2]}</span>
                                    <span className='modalShowPriceDailySpan'>الثلاثاء:{props?.myRoomSelected?.daily_price[3]}</span>
                                    <span className='modalShowPriceDailySpan'>الاربعاء:{props?.myRoomSelected?.daily_price[4]}</span>
                                    <span className='modalShowPriceDailySpan'>الخميس:{props?.myRoomSelected?.daily_price[5]}</span>
                                    <span className='modalShowPriceDailySpan'>الجمعه:{props?.myRoomSelected?.daily_price[6]}</span>
                                </span>

                            }
                            <span className='roomModalRowRightSide'> اسعار الوحده </span>
                        </div>
                        <div className='roomModalRow'>
                            <span className='roomModalRowLeftSide'> {props?.myRoomSelected?.monthely_price} </span>
                            <span className='roomModalRowRightSide'> السعر الشهري </span>
                        </div>

                    </div>


                </Modal.Body>

            </Form>
        </Modal>
    </div>;
}
