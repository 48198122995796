import React, { useContext } from 'react';
import { When } from 'react-if';
import { Link } from 'react-router-dom';
import { LoginContext } from '../../context/login';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import "./Profile.css"


export default function Profile() {

    const login = useContext(LoginContext);

    return (
        <div className='profileContainer'>
            <div className='navigator'>
                <p>
                    <Link className='linkContainer' to='/'>
                        الرئيسية
                    </Link>
                    <span> / </span>
                    حسابي
                </p>
            </div>

            <div className='headOfProfile'>


                <div>
                    <When condition={login.user.role == 'manager'}>
                        <Link to="/profile/addNewEmployee">
                            <a title='Add New Employee' className='profileIcons'>
                                <PersonAddAltRoundedIcon />
                            </a>
                        </Link>
                        <Link to="/profile/employeeList">
                            <a title='Employee List' className='profileIcons'>
                                <FormatListBulletedRoundedIcon />
                            </a>
                        </Link>
                    </When>
                </div>

                <h4> حسابي </h4>
            </div>

            <div className='infoContainer'>
                <div className='row'>
                    <p className='colLeft'> {login.user.id || ""} </p>
                    <p className='colRight'> # </p>
                </div>

                <div className='row'>
                    <p className='colLeft'> {login.user.name || ""} </p>
                    <p className='colRight'> الاسم </p>
                </div>

                <div className='row'>
                    <p className='colLeft'> {login.user.email || ""} </p>
                    <p className='colRight'> البريد الالكتروني </p>
                </div>

                <div className='row'>
                    <p className='colLeft'> {login.user.phone_number || ""} </p>
                    <p className='colRight'> الجوال </p>
                </div>

                <div className='row'>
                    <p className='colLeft'> {login.user.role || ""} </p>
                    <p className='colRight'> الصلاحيات </p>
                </div>
                {/* <div className='leftProfile'>
                    <p> {login.user.id || ""} </p>
                    <p> {login.user.name || ""} </p>
                    <p> {login.user.email || ""} </p>
                    <p> {login.user.phone_number || ""} </p>
                    <p> {login.user.role || ""} </p>
                </div>

                <div className='righProfile'>
                    <p> # </p>
                    <p> الاسم </p>
                    <p> البريد الالكتروني </p>
                    <p> الجوال </p>
                    <p> الصلاحيات </p>

                </div> */}
            </div>
        </div>
    )
}
