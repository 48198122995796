import React, { useEffect, useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import logo from "../../image/Aya&Reem.png";
import { useReactToPrint } from 'react-to-print';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';


export default function PrintReport(props) {

    const [reciept, setReciept] = useState();
    const [expense, setExpense] = useState();
    useEffect(() => {
        let myReciept = {
            igar: 0,
            tameen: 0,
            arboun: 0,
            services: 0
        };
        let myExpense = {
            igar: 0,
            tameen: 0,
            services: 0
        };

        // filterRecipts

        props.filterRecipts?.map(item => {
            switch (item.for) {
                case "قيمة إيجار":
                    myReciept.igar += item.amount;
                    break;
                case "تأمين":
                    myReciept.tameen += item.amount;
                    break;
                case "عربون":
                    myReciept.arboun += item.amount;
                    break;
                case "خدمات":
                    myReciept.services += item.amount;
                    break;
            }
        })
        props.filterExpenses?.map(item => {
            switch (item.for) {
                case "مرتجع إيجار":
                    myExpense.igar += item.amount;
                    break;
                case "مرتجع تأمين":
                    myExpense.tameen += item.amount;
                    break;
                case "خدمات":
                    myExpense.services += item.amount;
                    break;
            }
        })
        setReciept(myReciept);
        setExpense(myExpense);
    }, [props.show])

    const sanadatQabd = useRef();
    const handlePrint_sanadatQabd = useReactToPrint({
        content: () => sanadatQabd.current,
    });

    return (
        <Modal className='modalToShowImage ' show={props.show} onHide={props.handelClose}>
            <Modal.Header >
                <button className='closeModal' onClick={() => props.handelClose()}> <CloseRoundedIcon />  </button>
                {/* <span  > X </span> */}
                <Modal.Title> Print </Modal.Title>
            </Modal.Header>

            <Modal.Body ref={sanadatQabd}>
                <div className='printCashContainer'>
                    
                    <img className='printCashContainer_Logo' src={logo} alt="logo" />
                    <p> سكن آيه وريم </p>


                    <p> {new Date().toISOString().split("T")[0]} : اليوم</p>
                    <p className='printCashContainer_Title'> {props.dataToPrint?.type} </p>

                    <div className='printCash_InternalContainer'>
                        <div className='internal_Qabd'>
                            <span className='internal_Qabd_Row'>احصائيات سندات القبض</span>
                            <span className='internal_Qabd_Row'>{reciept?.igar} : الايجار
                                <span className='internal_Qabd_Row-Dinar'> دينار </span>
                            </span>
                            <span className='internal_Qabd_Row'>{reciept?.tameen} : التأمين
                                <span className='internal_Qabd_Row-Dinar'> دينار </span>
                            </span>
                            <span className='internal_Qabd_Row'>{reciept?.arboun} : العربون
                                <span className='internal_Qabd_Row-Dinar'> دينار </span>
                            </span>
                            <span className='internal_Qabd_Row'>{reciept?.services} : الخدمات
                                <span className='internal_Qabd_Row-Dinar'> دينار </span>
                            </span>

                            <span className='internal_Qabd_Row lastChild'>
                                <span>
                                    : اجمالي المقبوضات
                                </span>
                                <span>
                                    {props.mySanadatQabd}
                                </span>
                                <span>
                                    دينار
                                </span>
                            </span>

                        </div>
                        <div className='internal_Qabd'>
                            <span className='internal_Qabd_Row'>احصائيات سندات الصرف</span>
                            <span className='internal_Qabd_Row'>

                                {expense?.igar} : مرتجع إيجار
                                <span className='internal_Qabd_Row-Dinar'> دينار </span>
                            </span>
                            <span className='internal_Qabd_Row'>{expense?.tameen} : مرتجع تأمين
                                <span className='internal_Qabd_Row-Dinar'> دينار </span>
                            </span>
                            <span className='internal_Qabd_Row'>{expense?.services} : الخدمات
                                <span className='internal_Qabd_Row-Dinar'> دينار </span>
                            </span>

                            <span className='internal_Qabd_Row lastChild'>
                                <span>
                                    : اجمالي المصروفات
                                </span>
                                <span>
                                    {props.mySanadatSarf}
                                </span>
                                <span>
                                    دينار
                                </span>
                            </span>

                        </div>
                    </div>
                    <p className='printCashContainer_Total'>
                        <span>
                            : رصيد الصندوق
                        </span>
                        <span>
                            {props.mySanadatQabd - props.mySanadatSarf}
                        </span>
                        <span>
                            دينار
                        </span>
                    </p>
                </div>

                {
                    props.dataToPrint?.type == "تقرير حركة الصندوق" &&
                    <div className='printCashContainer_ResultTable'>
                        <p className='printCashContainer_ResultTable_HeadP'> سندات القبض </p>

                        <table className="cash_report_table_expenses">
                            <thead>
                                <tr className='trMainTable_ToPrint'>
                                    <th className='partOfHeadTable_ToPrint'> الملاحظات</th>
                                    <th className='partOfHeadTable_ToPrint'> الموظف</th>
                                    <th className='partOfHeadTable_ToPrint'> تاريخ السند </th>
                                    <th className='partOfHeadTable_ToPrint'> لأجل</th>
                                    <th className='partOfHeadTable_ToPrint'> المبلغ</th>
                                    <th className='partOfHeadTable_ToPrint'> رقم السند </th>
                                </tr>

                            </thead>
                            <tbody>

                                {props.filterRecipts.map((reciept, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="myResultData_ToPrint">{reciept.description}</td>
                                            <td className="myResultData_ToPrint">{reciept.employee_name}</td>
                                            <td className="myResultData_ToPrint">{reciept.date}</td>
                                            <td className="myResultData_ToPrint">{reciept.for + " - " + reciept.paied_for}</td>
                                            <td className="myResultData_ToPrint">{reciept.amount}</td>
                                            <td className="myResultData_ToPrint">{reciept.id}</td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>

                        <p className='printCashContainer_ResultTable_HeadP'> سندات الصرف </p>
                        <table className="cash_report_table_expenses">
                            <thead>
                                <tr className='trMainTable_ToPrint'>
                                    <th className='partOfHeadTable_ToPrint'> الملاحظات</th>
                                    <th className='partOfHeadTable_ToPrint'> الموظف</th>
                                    <th className='partOfHeadTable_ToPrint'> تاريخ السند </th>
                                    <th className='partOfHeadTable_ToPrint'> لأجل</th>
                                    <th className='partOfHeadTable_ToPrint'> المبلغ</th>
                                    <th className='partOfHeadTable_ToPrint'> رقم السند </th>
                                </tr>

                            </thead>
                            <tbody>
                                {props.filterExpenses.map((expense, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="myResultData_ToPrint">{expense.description}</td>
                                            <td className="myResultData_ToPrint">{expense.employee_name}</td>
                                            <td className="myResultData_ToPrint">{expense.date}</td>
                                            <td className="myResultData_ToPrint">{expense.for + " - " + expense.paied_for}</td>
                                            <td className="myResultData_ToPrint">{expense.amount}</td>
                                            <td className="myResultData_ToPrint">{expense.id}</td>
                                        </tr>
                                    )
                                })
                                }

                            </tbody>
                        </table>
                    </div>
                }

            </Modal.Body>

            <Modal.Footer>
                <span onClick={handlePrint_sanadatQabd} className='printCashContainer_printSpan'>
                    Print
                </span>
            </Modal.Footer>

        </Modal>
    )
}
