import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { LoginContext } from '../../context/login';
import useForm from '../../hooks/form';
import axios from 'axios';
import "./NotificationSetting.css"
import { removeListener } from 'process';
import cookies from 'react-cookies';


export default function NotificationSetting(props) {
    // To load the token from cookies
    const qs = new URLSearchParams(window.location.search);
    const cookieToken = cookies.load('token');
    const token = qs.get('token') || cookieToken || null;
    const login = useContext(LoginContext);

    const [emails, setEmails] = useState([]);
    const [allActivities, setAllActivities] = useState({});

    useEffect(async () => {
        await axios.get(`${login.API}/settings` , { headers: { 'Authorization': `Basic ${token}` }})
            .then(res => {
                setEmails(res.data);
            })
    }, []);


    const { handleChange, handleSubmit } = useForm(setSettings);

    function setSettings(data) {
        if (!data.newReserve) {
            data.newReserve = "off";
        }



        if (!data.cancleReserve) {
            data.cancleReserve = "off";
        }

        if (!data.dailyReport) {
            data.dailyReport = "off";
        }

        if (!data.email) {
            if (emails.length > 0) {
                data.email = emails;
            } else {
                data.email = [];
            }
        } else {
            data.email = emails
        }
        // if (data.email) {
        //     data.email = [data.email];
        // }

        if (!data.loggedIn) {
            data.loggedIn = "off";
            data.loggedInMessage = "مرحبا بك في فندقنا ...  نتمنى لك اقامة سعيدة";
            data.loggedInCotract = "off";
            data.loggedInDate = "off";
            data.loggedInUnit = "off";
            data.loggedInAmount = "off";
        } else {
            if (!data.loggedInMessage) {
                if (login.setting) {
                    data.loggedInMessage = login.setting.loggedInMessage;
                } else {
                    data.loggedInMessage = "مرحبا بك في فندقنا ...  نتمنى لك اقامة سعيدة";
                }
            }

            if (!data.loggedInCotract) {
                data.loggedInCotract = "off";
            }

            if (!data.loggedInDate) {
                data.loggedInDate = "off";
            }

            if (!data.loggedInUnit) {
                data.loggedInUnit = "off";
            }

            if (!data.loggedInAmount) {
                data.loggedInAmount = "off";
            }
        }

        if (!data.loggedOut) {
            data.loggedOut = "off";
            data.loggedOutMessage = "نأمل ان تكونوا قضيتم وقتا ممتعا معنا في فندقنا كما نأمل ان نراكم مرة آخرى";
            data.loggedOutCotract = "off";
            data.loggedOutDate = "off";
            data.loggedOutUnit = "off";
            data.loggedOutAmount = "off";
        } else {
            if (!data.loggedOutMessage) {
                if (login.setting) {
                    data.loggedOutMessage = login.setting.loggedOutMessage;
                } else {
                    data.loggedOutMessage = "نأمل ان تكونوا قضيتم وقتا ممتعا معنا في فندقنا كما نأمل ان نراكم مرة آخرى";
                }
            }

            if (!data.loggedOutCotract) {
                data.loggedOutCotract = "off";
            }

            if (!data.loggedOutDate) {
                data.loggedOutDate = "off";
            }

            if (!data.loggedOutUnit) {
                data.loggedOutUnit = "off";
            }

            if (!data.loggedOutAmount) {
                data.loggedOutAmount = "off";
            }

        }

        data.id = 'settings';

        login.socket.emit("SetSettings", data);
        // new activity
        axios.post(`${login.API}/newActivity`, {
            employee_id: login.user.id,
            description: `تعديل اعدادات التنبيهات`,
            type: "الاعدادات",
        },{ headers: { 'Authorization': `Basic ${token}` }})
        props.setLoadingFunc(true).then(async () => {
            await axios.get(`${login.API}/settings`, { headers: { 'Authorization': `Basic ${token}` } })
                .then(res => {
                    setEmails(res.data.email);
                    setAllActivities(res.data);
                })
                props.setLoadingFunc(false)
        })
    }

    function setSettings(e) {
        e.preventDefault();
        props.setLoadingFunc(true).then(async () => {
            let myData = {
                id: allActivities.id,
                cancleReserve: e.target.cancleReserve.checked ? "on" : "off",
                dailyReport: e.target.dailyReport.checked ? "on" : "off",
                email: emails,
                loggedIn: e.target.loggedIn.checked ? "on" : "off",
                loggedInAmount: e.target.loggedInAmount.checked ? "on" : "off",
                loggedInCotract: e.target.loggedInCotract.checked ? "on" : "off",
                loggedInDate: e.target.loggedInDate.checked ? "on" : "off",
                loggedInMessage: e.target.loggedInMessage.value,
                loggedInUnit: e.target.loggedInUnit.checked ? "on" : "off",
                loggedOut: e.target.loggedOut.checked ? "on" : "off",
                loggedOutAmount: e.target.loggedOutAmount.checked ? "on" : "off",
                loggedOutCotract: e.target.loggedOutCotract.checked ? "on" : "off",
                loggedOutDate: e.target.loggedOutDate.checked ? "on" : "off",
                loggedOutMessage: e.target.loggedOutMessage.value,
                loggedOutUnit: e.target.loggedOutUnit.checked ? "on" : "off",
                newReserve: e.target.newReserve.checked ? "on" : "off",
    
            }
    
            login.socket.emit("SetSettings", myData);
            // new activity
            axios.post(`${login.API}/newActivity`, {
                employee_id: login.user.id,
                description: `تعديل اعدادات التنبيهات`,
                type: "الاعدادات",
            }, { headers: { 'Authorization': `Basic ${token}` } })
            .then(()=>{
                props.setLoadingFunc(false);  
            })
        })
    }


    const handleAddEmail = () => {
        let email = document.getElementById("email");
        let wrongEmail_Type = document.getElementById("wrongEmail_Type");
        let includes_Email = document.getElementById("includes_Email");

        if (emails.includes(email.value)) {
            wrongEmail_Type.style.display = "none";
            includes_Email.style.display = "block";
        } else if (email.value.includes("@") && email.value.includes(".")) {
            setEmails([...emails, email.value]);
            wrongEmail_Type.style.display = "none";
            includes_Email.style.display = "none";
            email.value = "";
        } else {
            wrongEmail_Type.style.display = "block";
            includes_Email.style.display = "none";
        }

    }

    const handleRemoveEmail = (index) => {
        let newEmails = emails;

        let removeElement = document.getElementById("add_remove_buttons");
        let remove = document.getElementById(index);
        remove.parentNode.removeChild(remove);

        newEmails.splice(index, 1);
        setEmails(newEmails);
    }

    const handel_Input_Email = () => {
        let wrongEmail_Type = document.getElementById("wrongEmail_Type");
        let includes_Email = document.getElementById("includes_Email");

        wrongEmail_Type.style.display = "none";
        includes_Email.style.display = "none";
    }

    return (
        <div>
            <div className='navigator'>
                <p> <Link className='linkContainer' to='/'>الرئيسية</Link>
                    /
                    <Link className='linkContainer' to='/settings'>
                        الإعدادات
                    </Link> /
                    إعدادات الإشعارات
                </p>
            </div>


            <div>
                <h3 id='title'>التحكم في إعدادات التنبيهات</h3>
                <Form onSubmit={setSettings}>
                    <div className='notification_setting_body'>
                        <p className='notification_title'> تنبيهات الإدارة </p>
                        <div className='notification_setting_body_item'>
                            <div>
                                <p>
                                    <span>
                                        البريد الإلكتروني
                                    </span>

                                    <span>
                                    {
                                            (allActivities?.newReserve == "on") ?
                                            <input required name='newReserve' type="checkbox" checked className='notification_setting_checkbox' />:
                                            <input required name='newReserve' type="checkbox" className='notification_setting_checkbox' />
                                        }
                                        
                                    </span>

                                    {/* <label  className="switch">
                                        <input required type="checkbox"  name='newReserve' value="off"/>
                                        <span  className="slider round"></span>
                                    </label> */}
                                </p>

                                <p>
                                    إرسال تنبيه عند اضافة حجز جديد
                                </p>
                            </div>



                            <div>
                                <p>
                                    <span>
                                        البريد الإلكتروني
                                    </span>

                                    <span>
                                        {
                                            (allActivities?.cancleReserve == "on") ?
                                                <input required defaultChecked name='cancleReserve' type="checkbox" className='notification_setting_checkbox' /> :
                                                <input required name='cancleReserve' type="checkbox" className='notification_setting_checkbox' />
                                        }

                                    </span>
                                </p>

                                <p>
                                    إرسال تنبيه عند إلغاء حجز
                                </p>
                            </div>

                            <div>
                                <p>
                                    <span>
                                        البريد الإلكتروني
                                    </span>

                                    <span>
                                        {
                                            (allActivities?.dailyReport == "on") ?
                                                <input required defaultChecked name='dailyReport' type="checkbox" className='notification_setting_checkbox' /> :
                                                <input required name='dailyReport' type="checkbox" className='notification_setting_checkbox' />
                                        }
                                    </span>
                                </p>

                                <p>
                                    التقرير اليومي المختصر
                                </p>
                            </div>

                            <div id='add_remove_buttons_container'>
                                <div id='add_remove_buttons'>
                                    <div>
                                        <Button onClick={handleAddEmail}>إضافة</Button>
                                        <div className="inputEmail_Container">
                                            <input required onChange={handel_Input_Email} id="email" name='email' type="email" className='notification_setting_input' placeholder='Enter your email' />
                                            <span id="wrongEmail_Type"> يجب إدخال صيغة ايمبل صحيحة </span>
                                            <span id="includes_Email"> هذا الايميل موجودٌ مسبقاً </span>
                                        </div>
                                    </div>


                                    {emails.length > 0 &&
                                        emails.map((email, index) => {
                                            return (
                                                <div key={index} id={index} >
                                                    <Button onClick={() => handleRemoveEmail(index)} variant='danger'>إزالة</Button>
                                                    <input required type="text" className='notification_setting_input' readOnly defaultValue={email} />
                                                </div>
                                            )
                                        })

                                        // :
                                        // login.setting &&

                                        // allActivities?.email.map((email, index) => {
                                        //     return (
                                        //         <div key={index}>
                                        //             <Button variant='danger'>إزالة</Button>
                                        //             <input required type="text" className='notification_setting_input' readOnly defaultValue={email} />
                                        //         </div>
                                        //     )
                                        // })
                                    }
                                </div>

                                <p>
                                    البريد الإلكتروني لتصل له التنبيهات
                                </p>
                            </div>
                        </div>
                        <p className='notification_title'> تنبيهات الزوار </p>
                        <div className='notification_setting_body_item'>
                            <div id='notification_setting_body_item'>
                                <div id='alert_message_1'>
                                    <p>
                                        <span>
                                            البريد الإلكتروني
                                        </span>

                                        <span>
                                            {
                                                (allActivities?.loggedIn == "on") ?
                                                    <input required name='loggedIn' type="checkbox" className='notification_setting_checkbox' defaultChecked /> :
                                                    <input required name='loggedIn' type="checkbox" className='notification_setting_checkbox' />
                                            }

                                        </span>
                                    </p>

                                    <input required name='loggedInMessage' type="text" className='notification_setting_input_2' placeholder='ادخل الرساله' defaultValue={allActivities?.loggedInMessage} />
                                    <div id='options_1'>
                                        <p>
                                            <span>
                                                رقم العقد
                                            </span>

                                            <span>
                                                {
                                                    (allActivities?.loggedInCotract == "on") ?
                                                        <input required name='loggedInCotract' type="checkbox" className='notification_setting_checkbox' defaultChecked /> :
                                                        <input required name='loggedInCotract' type="checkbox" className='notification_setting_checkbox' />
                                                }

                                            </span>
                                        </p>

                                        <p>
                                            <span>
                                                التاريخ
                                            </span>

                                            <span>
                                                {
                                                    (allActivities?.loggedInDate == "on") ?
                                                        <input required name='loggedInDate' type="checkbox" defaultChecked className='notification_setting_checkbox' /> :
                                                        <input required name='loggedInDate' type="checkbox" className='notification_setting_checkbox' />
                                                }

                                            </span>
                                        </p>

                                        <p>
                                            <span>
                                                إسم الوحدة
                                            </span>

                                            <span>
                                                {
                                                    (allActivities?.loggedInUnit == "on") ?
                                                        <input required name='loggedInUnit' defaultChecked type="checkbox" className='notification_setting_checkbox' /> :
                                                        <input required name='loggedInUnit' type="checkbox" className='notification_setting_checkbox' />
                                                }

                                            </span>
                                        </p>

                                        <p>
                                            <span>
                                                المبلغ
                                            </span>

                                            <span>
                                                {
                                                    (allActivities?.loggedInAmount == "on") ?
                                                        <input required name='loggedInAmount' type="checkbox" defaultChecked className='notification_setting_checkbox' /> :
                                                        <input required name='loggedInAmount' type="checkbox" className='notification_setting_checkbox' />
                                                }

                                            </span>
                                        </p>
                                    </div>
                                </div>

                                <p id='log_title'>
                                    رسالة تلقائية عند تسجيل الدخول
                                </p>
                            </div>

                            <div id='notification_setting_body_item_1'>
                                <div id='alert_message'>
                                    <p>
                                        <span>
                                            البريد الإلكتروني
                                        </span>

                                        <span>
                                            {
                                                (allActivities?.loggedOut == "on") ?
                                                    <input required name='loggedOut' type="checkbox" defaultChecked className='notification_setting_checkbox' /> :
                                                    <input required name='loggedOut' type="checkbox" className='notification_setting_checkbox' />
                                            }

                                        </span>
                                    </p>
                                        <input required name='loggedOutMessage' type="text" className='notification_setting_input_2'  placeholder='ادخل الرساله'  defaultValue={allActivities?.loggedOutMessage} />
                                    <div id='options'>
                                        <p>
                                            <span>
                                                رقم العقد
                                            </span>

                                            <span>
                                                {(allActivities?.loggedOutCotract == "on") ?
                                                    <input required checked name='loggedOutCotract' type="checkbox" className='notification_setting_checkbox' />
                                                    :
                                                    <input required name='loggedOutCotract' type="checkbox" className='notification_setting_checkbox' />
                                                }

                                            </span>
                                        </p>

                                        <p>
                                            <span>
                                                التاريخ
                                            </span>

                                            <span>
                                                {
                                                    (allActivities?.loggedOutDate == "on") ?
                                                        <input required name='loggedOutDate' type="checkbox" defaultChecked className='notification_setting_checkbox' /> :
                                                        <input required name='loggedOutDate' type="checkbox" className='notification_setting_checkbox' />
                                                }
                                            </span>
                                        </p>

                                        <p>
                                            <span>
                                                إسم الوحدة
                                            </span>

                                            <span>
                                                {
                                                    (allActivities?.loggedOutUnit == "on") ?
                                                        <input required name='loggedOutUnit' type="checkbox" className='notification_setting_checkbox' defaultChecked /> :
                                                        <input required name='loggedOutUnit' type="checkbox" className='notification_setting_checkbox' />
                                                }

                                            </span>
                                        </p>

                                        <p>
                                            <span>
                                                المبلغ
                                            </span>

                                            <span>
                                                {
                                                    (allActivities?.loggedOutAmount == "on") ?
                                                        <input required name='loggedOutAmount' type="checkbox" className='notification_setting_checkbox' defaultChecked /> :
                                                        <input required name='loggedOutAmount' type="checkbox" className='notification_setting_checkbox' />
                                                }

                                            </span>
                                        </p>
                                    </div>
                                </div>

                                <p id='log_title_1'>
                                    رسالة تلقائية عند تسجيل المغادرة
                                </p>
                            </div>
                        </div>
                        <div id='save_button'>
                            <Button type='submit' variant='primary'>حفظ</Button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    )
}
