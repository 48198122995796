import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { LoginContext } from '../../context/login';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import axios from 'axios';
import PrintReport from './PrintReport';
import cookies from 'react-cookies';
import PrintIcon from '@mui/icons-material/Print';


export default function CashReport(props) {
  // To load the token from cookies
  const qs = new URLSearchParams(window.location.search);
  const cookieToken = cookies.load('token');
  const token = qs.get('token') || cookieToken || null;

  const login = useContext(LoginContext);

  const [dates, setDates] = useState([]);
  const [reciepts, setReciepts] = useState([]);
  const [totalRecipts, setTotalRecipts] = useState(0);
  const [totalReciptsInsurance, setTotalReciptsInsurance] = useState(0);
  const [totalReciptsTransactions, setTotalReciptsTransactions] = useState(0);
  const [filterRecipts, setFilterRecipts] = useState([]);

  const [expenses, setExpenses] = useState([]);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [totalExpensesInsurance, setTotalExpensesInsurance] = useState(0);
  const [totalExpensesTransactions, setTotalExpensesTransactions] = useState(0);
  const [filterExpenses, setFilterExpenses] = useState([]);

  const [employees, setEmployees] = useState([]);


  let mySanadatQabd = 0;
  let mySanadatSarf = 0;

  useEffect(() => {
    let totalReciptsAmount = 0;
    let totalReciptsInsuranceAmount = 0;
    let totalReciptsTransactionsAmount = 0;

    let totalExpensesAmount = 0;
    let totalExpensesInsuranceAmount = 0;
    let totalExpensesTransactionsAmount = 0;

    const fetchData = async () => {
      await axios.post(`${login.API}/getallreciptesandexpense`, {}, { headers: { 'Authorization': `Bearer ${token}` } })
        .then(res => {
          // Caluclate the total recipts
          res.data.reciptsData.map(item => {
            totalReciptsAmount += item.amount;
            if (item.for === "تأمين") {
              totalReciptsInsuranceAmount += item.amount;
            }
            if (item.for === "تحويل من الصندوق إلى الإدارة") {
              totalReciptsTransactionsAmount += item.amount;
            }
          })

          setTotalRecipts(totalReciptsAmount);
          setTotalReciptsInsurance(totalReciptsInsuranceAmount);
          setTotalReciptsTransactions(totalReciptsTransactionsAmount);

          // Caluclate the total expenses
          let myExpense = res.data.expenseData.filter(item => {
            if (item.for === "مرتجع تأمين") {
              totalExpensesInsuranceAmount += item.amount;
            }
            if (item.for === "تحويل من الصندوق إلى الإدارة") {
              totalExpensesTransactionsAmount += item.amount;
            }
            if (item.for != 'خدمات') {
              totalExpensesAmount += item.amount;
              return item
            }
          })

          setTotalExpenses(totalExpensesAmount);
          setTotalExpensesInsurance(totalExpensesInsuranceAmount);
          setTotalExpensesTransactions(totalExpensesTransactionsAmount);

          setReciepts(res.data.reciptsData);
          setFilterRecipts(res.data.reciptsData);
          setExpenses(myExpense);
          setFilterExpenses(myExpense);
        })
        .catch(err => {
          console.log(err);
        })

      await axios.post(`${login.API}/getEmployees`, {}, { headers: { 'Authorization': `Bearer ${token}` } })
        .then(res => {
          setEmployees(res.data);
        })
        .catch(err => {
          console.log(err);
        })
    }
    props.loading(true).then(async () => {
      await fetchData();
      props.loading(false);
    })
  }, [])

  useEffect(() => {
    let totalReciptsAmount = 0;
    let totalReciptsInsuranceAmount = 0;
    let totalReciptsTransactionsAmount = 0;

    let totalExpensesAmount = 0;
    let totalExpensesInsuranceAmount = 0;
    let totalExpensesTransactionsAmount = 0;
    // Caluclate the total recipts
    filterRecipts.map(item => {
      if (item.for != "قيمة خصم إيجار") {
        totalReciptsAmount += item.amount;
      }
      if (item.for === "تأمين") {
        totalReciptsInsuranceAmount += item.amount;
      }
      if (item.for === "تحويل من الصندوق إلى الإدارة") {
        totalReciptsTransactionsAmount += item.amount;
      }
    })

    setTotalRecipts(totalReciptsAmount);
    setTotalReciptsInsurance(totalReciptsInsuranceAmount);
    setTotalReciptsTransactions(totalReciptsTransactionsAmount);

    // Caluclate the total expenses
    filterExpenses.map(item => {
      totalExpensesAmount += item.amount;
      if (item.for === "مرتجع تأمين") {
        totalExpensesInsuranceAmount += item.amount;
      }
      if (item.for === "تحويل من الصندوق إلى الإدارة") {
        totalExpensesTransactionsAmount += item.amount;
      }
    })

    setTotalExpenses(totalExpensesAmount);
    setTotalExpensesInsurance(totalExpensesInsuranceAmount);
    setTotalExpensesTransactions(totalExpensesTransactionsAmount);
  }, [filterExpenses, filterRecipts])



  const filterFunc = () => {
    let filterByStartDate = document.getElementById("filterByStartDate").value;
    let filterByEndDate = document.getElementById("filterByEndDate").value;
    let filterByEmployee = document.getElementById("filterByEmployee").value;
    // Filtered Data by start Date
    if (filterByStartDate !== '') {
      let filteredRecipts = reciepts.filter(recipt => {
        let date = new Date(recipt.date) !== 'Invalid Date' ? new Date(recipt.date) : (recipt.date.split("  ")[1]).split("/")[2] + "-" + (recipt.date.split("  ")[1]).split("/")[1] + "-" + (recipt.date.split("  ")[1]).split("/")[0]
        if (dates[1]) {
          if (new Date(date).getTime() + 7200000 >= new Date(filterByStartDate).getTime() && new Date(date).getTime() + 7200000 <= new Date(dates[1]).getTime()) {
            return recipt
          }
        } else {
          if (new Date(date).getTime() + 7200000 >= new Date(filterByStartDate).getTime()) {
            return recipt
          }
        }

      })

      let filteredExpinses = expenses.filter(expense => {
        let date = new Date(expenses.date) !== 'Invalid Date' ? new Date(expenses.date) : (expense.date.split("  ")[1]).split("/")[2] + "-" + (expense.date.split("  ")[1]).split("/")[1] + "-" + (expense.date.split("  ")[1]).split("/")[0];
        if (dates[1]) {
          if (new Date(date).getTime() + 7200000 >= new Date(filterByStartDate).getTime() && new Date(date).getTime() + 7200000 <= new Date(dates[1]).getTime()) {
            return expense
          }
        } else {
          if (new Date(date).getTime() + 7200000 >= new Date(filterByStartDate).getTime()) {
            return expense
          }
        }
      })
      setFilterRecipts(filteredRecipts);
      setFilterExpenses(filteredExpinses);
    }else{
      setFilterRecipts(reciepts);
      setFilterExpenses(expenses);
    }

    // Filtered Data by End Date
    if (filterByEndDate !== '') {
      let filteredRecipts = reciepts.filter(recipt => {
        let date = new Date(recipt.date) !== 'Invalid Date' ? new Date(recipt.date) : (recipt.date.split("  ")[1]).split("/")[2] + "-" + (recipt.date.split("  ")[1]).split("/")[1] + "-" + (recipt.date.split("  ")[1]).split("/")[0];
        if (dates[0]) {
          if (new Date(date).getTime() + 7200000 <= new Date(filterByEndDate).getTime() && new Date(date).getTime() + 7200000 >= new Date(dates[0]).getTime()) {
            return recipt
          }
        } else {
          if (new Date(date).getTime() + 7200000 <= new Date(filterByEndDate).getTime()) {
            return recipt
          }
        }

      })

      let filteredExpinses = expenses.filter(expense => {
        let date = new Date(expenses.date) !== 'Invalid Date' ? new Date(expenses.date) : (expense.date.split("  ")[1]).split("/")[2] + "-" + (expense.date.split("  ")[1]).split("/")[1] + "-" + (expense.date.split("  ")[1]).split("/")[0];
        if (dates[0]) {
          if (new Date(date).getTime() + 7200000 <= new Date(filterByEndDate).getTime() && new Date(date).getTime() + 7200000 >= new Date(dates[0]).getTime()) {
            return expense
          }
        } else {
          if (new Date(date).getTime() + 7200000 <= new Date(filterByEndDate).getTime()) {
            return expense
          }
        }

      })
      setFilterRecipts(filteredRecipts);
      setFilterExpenses(filteredExpinses);
    }

    let newDate = [filterByStartDate ? filterByStartDate : null, filterByEndDate ? filterByEndDate : null];
    setDates([]);
    setDates(newDate);
    // Filtered Data by Employee

    if (filterByEmployee) {

      let filteredRecipts = reciepts.filter(recipt => {
        let date = new Date(recipt.date) !== 'Invalid Date' ? new Date(recipt.date) : (recipt.date.split("  ")[1]).split("/")[2] + "-" + (recipt.date.split("  ")[1]).split("/")[1] + "-" + (recipt.date.split("  ")[1]).split("/")[0];
        if (dates[0] && dates[1]) {
          if (new Date(date).getTime() + 7200000 >= new Date(dates[0]).getTime() && new Date(date).getTime() + 7200000 <= new Date(dates[1]).getTime() && recipt.employee_id === parseInt(filterByEmployee)) {
            return recipt
          }
        }
        else if (dates[0]) {
          if (new Date(date).getTime() + 7200000 >= new Date(dates[0]).getTime() && recipt.employee_id === parseInt(filterByEmployee)) {
            return recipt
          }
        }
        else if (dates[1]) {
          if (new Date(date).getTime() + 7200000 <= new Date(dates[1]).getTime() && recipt.employee_id === parseInt(filterByEmployee)) {
            return recipt
          }
        }
        else {
          if (recipt.employee_id === parseInt(filterByEmployee)) {
            return recipt
          }
        }
      })

      let filteredExpinses = expenses.filter(expense => {
        let date = new Date(expenses.date) !== 'Invalid Date' ? new Date(expenses.date) : (expense.date.split("  ")[1]).split("/")[2] + "-" + (expense.date.split("  ")[1]).split("/")[1] + "-" + (expense.date.split("  ")[1]).split("/")[0];
        if (dates[0] && dates[1]) {
          if (new Date(date).getTime() + 7200000 >= new Date(dates[0]).getTime() && new Date(date).getTime() + 7200000 <= new Date(dates[1]).getTime() && expense.employee_id === parseInt(filterByEmployee)) {
            return expense
          }
        }
        else if (dates[0]) {
          if (new Date(date).getTime() + 7200000 >= new Date(dates[0]).getTime() && expense.employee_id === parseInt(filterByEmployee)) {
            return expense
          }
        }
        else if (dates[1]) {
          if (new Date(date).getTime() + 7200000 <= new Date(dates[1]).getTime() && expense.employee_id === parseInt(filterByEmployee)) {
            return expense
          }
        }
        else {
          if (expense.employee_id === parseInt(filterByEmployee)) {
            return expense
          }
        }
      })


      setFilterRecipts(filteredRecipts);
      setFilterExpenses(filteredExpinses);
    }
  }

  const resetFilter = () => {
    setDates([]);
    let filterByEmployee = document.getElementById("filterByEmployee");
    filterByEmployee.selectedIndex = 0;
    setFilterRecipts([]);
    setFilterExpenses([]);
    setFilterRecipts(reciepts);
    setFilterExpenses(expenses);

  }
  const sanadatQabd = useRef();
  const sanadatSarf = useRef();
  const handlePrint_sanadatQabd = useReactToPrint({
    content: () => sanadatQabd.current,
  });
  const handlePrint_sanadatSarf = useReactToPrint({
    content: () => sanadatSarf.current,
  });

  const [print, setPrint] = useState(false);
  const [dataToPrint, setDataToPrint] = useState();
  const print_Taqrer = (type) => {
    setPrint(!print);
    if (type == "simple") {
      setDataToPrint({
        type: "تقرير الاحصائيات",
        totalRecipts,
        totalReciptsTransactions,
        totalReciptsInsurance,
        totalExpenses,
        totalExpensesTransactions,
        totalExpensesInsurance
      });
    } else if (type == "comolex") {
      setDataToPrint({
        type: "تقرير حركة الصندوق",
        totalRecipts,
        totalReciptsTransactions,
        totalReciptsInsurance,
        totalExpenses,
        totalExpensesTransactions,
        totalExpensesInsurance
      });
      // setDataToPrint();
    }
  }

  return (
    <div>

      <div className='navigator'>
        <p> <Link className='linkContainer' to='/'> الرئيسية </Link>  /
          <Link className='linkContainer' to='/financialManagments'> الإدارة المالية </Link> /
          تقرير حركة الصندوق
        </p>
      </div>

      <div className='cash_report_body'>
        <p className='cash_report_title'>  تقرير حركة الصندوق </p>
        <div className='cash_report_body_item'>
          <div className='cash_report_body_item_filter'>
            <span className='managementFilter'>
              <select id="filterByEmployee" className='filterInput' onChange={filterFunc}>
                <option value=""> تصنيف حسب الموظف </option>
                {
                  employees.map(employee => {
                    return (
                      <option value={employee.id}> {employee.name} </option>
                    )
                  })
                }
              </select>
            </span>

            <span className='managementFilter'>
              <span id="filterByEndDateDiv" className='filterInput filterByEndDateDiv'>
                {dates[1] ? `${dates[1]}  التاريخ إلى` : " التاريخ إلى"}
                <input onChange={filterFunc} id="filterByEndDate" className="filterInput filterByEndDate" title='تاريخ النهاية' type="date" />
              </span>
            </span>

            <span className='managementFilter'>
              <span id="filterByStartDateDiv" className='filterInput filterByStartDateDiv'>
                {dates[0] ? `${dates[0]}  التاريخ من` : " التاريخ من"}
                <input onChange={filterFunc} id="filterByStartDate" className="filterInput filterByStartDate" title='تاريخ البداية' type="date" />
              </span>
            </span>


          </div>
        </div>

        <div className='cash_report_body_item primary_numbers'>
          <div>
            <span>رصيد الإدارة</span>
            {totalExpensesTransactions - totalReciptsTransactions > 0 ?
              <span className='total_green_color'>{(totalExpensesTransactions - totalReciptsTransactions)?.toFixed(2)}</span>
              :
              totalExpensesTransactions - totalReciptsTransactions < 0 ?
                <span className='total_red_color'>{(totalExpensesTransactions - totalReciptsTransactions)?.toFixed(2)}</span>
                :
                <span>{(totalExpensesTransactions - totalReciptsTransactions)?.toFixed(2)}</span>
            }
          </div>

          <div>
            <span>رصيد الصندوق</span>
            {totalRecipts - totalExpenses > 0 ?
              <span className='total_green_color'>{(totalRecipts - totalExpenses)?.toFixed(2)}</span>
              :
              totalRecipts - totalExpenses < 0 ?
                <span className='total_red_color'>{(totalRecipts - totalExpenses)?.toFixed(2)}</span>
                :
                <span>{(totalRecipts - totalExpenses)?.toFixed(2)}</span>
            }
          </div>

          <div>
            <span>إجمالي المصروفات</span>
            <span>{totalExpenses?.toFixed(2)}</span>
          </div>

          <div>
            <span>إجمالي المقبوضات</span>
            <span>{totalRecipts?.toFixed(2)}</span>
          </div>
        </div>

        <div className='cash_report_body_item'>
          <div className='cash_report_body_item_print'>
            <span onClick={() => print_Taqrer('comolex')}>
              <i title=" طباعة التقرير المفصل" className="printIcon">
                <PrintIcon />
              </i>
            </span>
            <span onClick={() => print_Taqrer('simple')}>
              <i title="طباعة التقرير المختصر" className="printIcon">
                <PrintIcon />
              </i>
            </span>
          </div>

          <div className='cash_report_body_item_cards'>
            <div className='cash_report_body_item_cards_expenses'>
              <p>إحصائيات سندات الصرف</p>

              <div className='cash_report_body_item_cards_item'>
                <div>
                  <span>دينار</span>
                  <span className='cash_report_body_item_cards_item_expense'>{totalExpensesInsurance?.toFixed(2)}</span>
                  <span>التامين</span>
                </div>

                <div>
                  <span>دينار</span>
                  <span className='cash_report_body_item_cards_item_expense'>{totalExpensesTransactions?.toFixed(2)}</span>
                  <span>المبالغ المحولة للإدارة</span>
                </div>

                <div>
                  <span>دينار</span>
                  <span className='cash_report_body_item_cards_item_expense'>{totalExpenses?.toFixed(2)}</span>
                  <span>الإجمالي</span>
                </div>
              </div>

            </div>
            <div className='cash_report_body_item_cards_reciepts'>
              <p>إحصائيات سندات القبض</p>
              <div className='cash_report_body_item_cards_item'>
                <div>
                  <span>دينار</span>
                  <span className='cash_report_body_item_cards_item_reciepts'>{totalReciptsInsurance?.toFixed(2)}</span>
                  <span>التامين</span>
                </div>

                <div>
                  <span>دينار</span>
                  <span className='cash_report_body_item_cards_item_reciepts'>{totalReciptsTransactions?.toFixed(2)}</span>
                  <span>المبالغ المستلمة من الإدارة</span>
                </div>

                <div>
                  <span>دينار</span>
                  <span className='cash_report_body_item_cards_item_reciepts'>{totalRecipts?.toFixed(2)}</span>
                  <span>الإجمالي</span>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className='cash_report_body_item'>
          <div className='cash_report_body_item_tables'>
            <div className='cash_report_body_item_tables_expenses'>
              <p>
                <span>
                  <i title='طباعة السندات' onClick={handlePrint_sanadatSarf}>
                    <PrintIcon />
                  </i>
                  <span title="excel تصدير بصيغة ملف">
                    <ReactHTMLTableToExcel
                      id="test-table-xls-button"
                      className="printIcon far fa-file-excel"
                      table="sanadatSarf"
                      filename="تقرير سندات الصرف"
                      sheet="تقرير سندات الصرف"
                      buttonText=""
                    />
                  </span>
                </span>
                <span>
                  سندات الصرف
                </span>

              </p>
              <div ref={sanadatSarf} >
                <table id="sanadatSarf" className="cash_report_table_expenses">
                  <thead>
                    <tr className='trMainTable'>
                      <th className='partOfHeadTable'> الملاحظات</th>
                      <th className='partOfHeadTable'> الموظف</th>
                      <th className='partOfHeadTable'> تاريخ السند </th>
                      <th className='partOfHeadTable'> لأجل</th>
                      <th className='partOfHeadTable'> المبلغ</th>
                      <th className='partOfHeadTable'> رقم السند </th>
                    </tr>

                  </thead>
                  <tbody>
                    {filterExpenses.map((expense, index) => {
                      mySanadatSarf += expense.amount;
                      return (
                        <tr key={index}>
                          <td>{expense.description}</td>
                          <td>{expense.employee_name}</td>
                          <td>{expense.date}</td>
                          <td>{expense.for + " - " + expense.paied_for}</td>
                          <td>{expense.amount?.toFixed(2)}</td>
                          <td>{expense.id}</td>
                        </tr>
                      )
                    })
                    }

                  </tbody>
                </table>
                <span className='totalCashReport_Text'>
                  <span>
                    : المجموع
                  </span>
                  <span>
                    {mySanadatSarf?.toFixed(2)}
                  </span>
                  <span>
                    دينار
                  </span>
                </span>
              </div>
            </div>
            <div className='cash_report_body_item_tables_reciepts'>
              <p>
                <span>
                  <i title='طباعة السندات' onClick={handlePrint_sanadatQabd} >
                    <PrintIcon />
                  </i>
                  <span title="excel تصدير بصيغة ملف">
                    <ReactHTMLTableToExcel
                      id="test-table-xls-button"
                      className="printIcon far fa-file-excel"
                      table="sanadatQabd"
                      filename="تقرير سندات القبض"
                      sheet="تقرير سندات القبض"
                      buttonText=""
                    />
                  </span>
                </span>
                <span>
                  سندات القبض
                </span>

              </p>
              <div ref={sanadatQabd}>
                <table id="sanadatQabd" className="cash_report_table_expenses">
                  <thead>
                    <tr className='trMainTable'>
                      <th className='partOfHeadTable'> الملاحظات</th>
                      <th className='partOfHeadTable'> الموظف</th>
                      <th className='partOfHeadTable'> تاريخ السند </th>
                      <th className='partOfHeadTable'> لأجل</th>
                      <th className='partOfHeadTable'> المبلغ</th>
                      <th className='partOfHeadTable'> رقم السند </th>
                    </tr>

                  </thead>
                  <tbody>

                    {filterRecipts.map((reciept, index) => {
                      mySanadatQabd += reciept.amount;
                      return (
                        <tr key={index}>
                          <td>{reciept.description}</td>
                          <td>{reciept.employee_name}</td>
                          <td>{reciept.date}</td>
                          <td>{reciept.for + " - " + reciept.paied_for}</td>
                          <td>{reciept.amount?.toFixed(2)}</td>
                          <td>{reciept.id}</td>
                        </tr>
                      )
                    })
                    }
                  </tbody>
                </table>
                <span className='totalCashReport_Text'>
                  <span>
                    : المجموع
                  </span>
                  <span>
                    {mySanadatQabd?.toFixed(2)}
                  </span>
                  <span>
                    دينار
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <PrintReport mySanadatSarf={mySanadatSarf} mySanadatQabd={mySanadatQabd} filterExpenses={filterExpenses} filterRecipts={filterRecipts} dataToPrint={dataToPrint} show={print} handelClose={print_Taqrer} />

      </div>
    </div>
  )
}
