import React, { useEffect, useState } from "react";

export default function ServerMessage(props){
    const [title, setTitle] = useState('');

    useEffect(()=>{
        let status = `${props.error.status}`
        switch (status[0]) {
            case '4':
                setTitle('Page Not Found')
                break;
            default:
                setTitle('Internal Server Error')
                break;
        }
    }, [props.status])
    return (
        <div className="serverMessage">
            <h4>{props.error.status} - {title}</h4>
            <p>{props.error.message}</p>
        </div>
    )
}