import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Form } from 'react-bootstrap';
import { LoginContext } from '../../context/login';
// import { LoginContext } from '../../context/login';
import cookies from 'react-cookies';
import useForm from '../../hooks/form';
import axios from 'axios';
import swal from 'sweetalert2';
// import {moveFile} from 'move-file';
import fs from "fs";
import PinDropIcon from '@mui/icons-material/PinDrop';
import PublishIcon from '@mui/icons-material/Publish';


export default function General_Setings(props) {
    // To load the token from cookies
    const qs = new URLSearchParams(window.location.search);
    const cookieToken = cookies.load('token');
    const token = qs.get('token') || cookieToken || null;

    const [hotel_Info, setHotel_Info] = useState({
        name: "",
        description: "",
        stars: "",
        image: "",
        address: "",
        phone: "",
        email: "",
        website: "",
        works: "",
        lease: "",
    })
    const [image, setImage] = useState(null)
    const [status, setStatus] = useState('')

    const login = useContext(LoginContext)

    useEffect(async () => {
        // getMyHotel
        props.setLoadingFunc(true).then(async () => {
            await axios.post(`${login.API}/getMyHotel`, {}, { headers: { 'Authorization': `Basic ${token}` } })
                .then(res => {
                    setHotel_Info(res.data[0]);

                    props.setLoadingFunc(false);
                })
        })
    }, [])

    const myLogoUploder = (e) => {

        const img = e.target.files[0]
        setImage(img)
    }
    // editMyHotel
    function editMyHotel(event) {
        event.preventDefault();

        let formData = new FormData()
        formData.append('file', image)

        let newHotel_Info = {
            name: event.target.name.value,
            description: event.target.description.value,
            // stars: e.target.stars.value,
            image: event.target.logoInput?.files[0]?.name || hotel_Info.image,
            address: event.target.address.value,
            phone: event.target.phone.value,
            email: event.target.email.value,
            website: event.target.webSite.value,
            works: `${event.target.startWork.value} to ${event.target.endWork.value}`,
            locationOnMap: event.target.map.value,
        }
        props.setLoadingFunc(true).then(async () => {
            setHotel_Info(newHotel_Info);
            await axios.post(`${login.API}/editMyHotel`, { data: newHotel_Info, name: hotel_Info.name }, { headers: { 'Authorization': `Basic ${token}` } })
                .then(res => {
                    if (image) {

                        axios.post(`${login.API}/image`, formData, { headers: { 'Authorization': `Basic ${token}` } })
                    }
                    // new activity
                    axios.post(`${login.API}/newActivity`, {
                        employee_id: login.user.id,
                        description: `تعديل بيانات الفندق`,
                        type: "الاعدادات",
                    }, { headers: { 'Authorization': `Basic ${token}` } })
                    new swal({
                        title: "تم تعديل البيانات بنجاح  ",
                        text: "Updated Successfully",
                        icon: "success",
                        button: "okay!",
                    });
                    props.setLoadingFunc(false);
                })
        })

    }
    return (
        <div>
            <div className='navigator'>
                <p> <Link className='linkContainer' to='/'>الرئيسية</Link> / <Link className='linkContainer' to='/settings'>الاعدادات</Link> / الاعدادات العامة </p>
            </div>



            <div>
                <div className='headOfTable'>
                    <p> معلومات المنشأة </p>

                </div>

                <div className='bodyOfTable'>
                    <Table striped bordered hover>
                        <thead>
                            {/* رقم الوحدة	الاسم	قسم الوحدة	مفعل	حالة الوحدة	الصورة الرئيسية	الاجرائات */}
                            <tr className='trMainTable'>

                                <th className='addressPlace'> العنوان </th>
                                <th> معلومات الاتصال </th>
                                <th> اوقات الدوام </th>
                                <th className='addressPlace'>الشعار</th>
                                <th>اسم الفندق </th>

                            </tr>
                        </thead>
                        <tbody>
                            {/* run from database */}

                            <tr className='rowHotelInfos'>
                                <td className='addressPlace'>
                                    <p>  {hotel_Info.address}  </p>
                                    <a target="_blank" href={hotel_Info.locationOnMap} className='openMap'> <PinDropIcon className="setingsIcon"/> اعرض على الخريطة </a>
                                </td>
                                <td>
                                    <div className='tdContactInfos'>
                                        <span className='contactInfos'>رقم الهاتف : {hotel_Info.phone}</span>
                                        <span className='contactInfos'>{hotel_Info.email} : البريد الالكتروني</span>
                                        <span className='contactInfos'> {hotel_Info.phone} : واتس آب </span>
                                        <span className='contactInfos'> <a href={`${hotel_Info.website}`} target="_blank">{hotel_Info.website}</a>  : الموقع الالكتروني </span>
                                    </div>
                                </td>
                                <td> {hotel_Info.works} </td>
                                <td className='addressPlace'> {hotel_Info.description} </td>
                                <td className='hotelNamePlace'> {hotel_Info.name} </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>

            {
                (login.user.role === "manager") &&

                <>
                    <div>
                        <h4 className='newEmployeeTag'> الاعدادات العامة </h4>
                    </div>
                    <Form onSubmit={editMyHotel} >
                        <div className='infoContainer'>

                            <div className='row'>
                                <div className='colLeft logoDiv'>
                                    <span className='logoSpan'> <PublishIcon className="logoImage"/> {hotel_Info.image} </span>

                                    <input required onChange={myLogoUploder} className='logoInput' name='logoInput' type="file" />
                                </div>
                                <p className='colRight'> الشعار </p>
                            </div>
                            <div className='row'>
                                <input required className='colLeft' defaultValue={hotel_Info.name} name="name" required type='text' />
                                <p className='colRight'>اسم المنشأة </p>
                            </div>
                            <div className='row'>
                                <input required className='colLeft' defaultValue={hotel_Info.website} name="webSite" required type='text' />
                                <p className='colRight'> الموقع الالكتروني </p>
                            </div>
                            <div className='row'>
                                <input required className='colLeft' name="email" defaultValue={hotel_Info.email} required type='email' />
                                <p className='colRight'> البريد الالكتروني </p>
                            </div>
                            <div className='row'>
                                <input required className='colLeft' name="phone" defaultValue={hotel_Info.phone} required type='number' />
                                <p className='colRight'> الهاتف </p>
                            </div>
                            <div className='row'>
                                <textarea className='hotelInput colLeft' name="address" defaultValue={hotel_Info.address} required type='text' />
                                <p className='colRight'> العنوان </p>
                            </div>
                            <div className='row'>
                                <textarea className='hotelInput colLeft' name="description" defaultValue={hotel_Info.address} required type='text' />
                                <p className='colRight'> الوصف </p>
                            </div>
                            <div className='row'>
                                <textarea className='hotelInput colLeft' name="map" defaultValue={hotel_Info.locationOnMap} required type='text' />
                                <p className='colRight'> الموقع على الخريطة </p>
                            </div>
                            <div className='row'>
                                <input required className='colLeft' name="startWork" defaultValue={hotel_Info.works.split("to")[0]} required type='text' />
                                <p className='colRight'> بداية الدوام </p>
                            </div>
                            <div className='row'>
                                <input required className='colLeft' name="endWork" defaultValue={hotel_Info.works.split("to")[1]} required type='text' />
                                <p className='colRight'> نهاية الدوام </p>
                            </div>
                        </div>
                        <div className='submitNewEmployee'>
                            <button type='submit'>
                                حفظ البيانات
                            </button>
                        </div>
                    </Form>
                </>
            }


        </div>
    );
}
