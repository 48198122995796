import { useState } from 'react';

const useForm = (callback) => {

    const [values, setValues] = useState({});

    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        callback(values);

    };

    const handleChange = (event) => {
        event.persist();
        setValues(values => ({ ...values, [event.target.name]: event.target.value}));
    };



    const handleChange_2 = (event) => {
        event.persist();
        setValues(values => ({ ...values, [event.target.name]: event.target.value}));
    };

    const handleSubmit_2 = (event) => {
        if (event) event.preventDefault();
        callback(values);
    };

    const handleChangeEmail = (event) => {
        event.persist();

        setValues(values => ({ ...values, [event.target.name]: event.target.value}));
    };

    const handleSubmitEmail = (event) => {
        if (event) event.preventDefault();
        callback(values);
    };


   


    return {
        handleChange,
        handleSubmit,
        handleChange_2,
        handleSubmit_2,
        handleChangeEmail,
        handleSubmitEmail,
        values
    };
};

export default useForm;
