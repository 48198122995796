import React, { useEffect, useState, useContext } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DatePicker from "react-datepicker";
import axios from 'axios';
import { LoginContext } from "../../context/login";
import cookies from 'react-cookies';

const reservationType = ['يومي', 'اسبوعي', 'شهري'];


export default function UpdateReservationInfo(props) {
    const [reservationInfo, setReservationInfo] = useState({});

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [rent_type, setRent_type] = useState('شهري');
    const [barePrice, setBarePrice] = useState(0);

    const [duration, setDuration] = useState('1 شهر');
    const [period, setPeriod] = useState(1);

    const cookieToken = cookies.load('token');
    const qs = new URLSearchParams(window.location.search);
    const login = useContext(LoginContext);
    const token = qs.get('token') || cookieToken || null;

    useEffect(() => {
        setReservationInfo(props.reservationInfo);

        let resPrice = reservationInfo.total_price - reservationInfo.services_price
        setRent_type(reservationInfo.rent_type)

        let newStart;
        if (reservationInfo.loggedIn_At) {
            newStart = reservationInfo.loggedIn_At.split(" ")[2]
            let day = newStart.split('/')[0];
            let month = parseInt(newStart.split('/')[1]) - 1;
            let year = newStart.split('/')[2];

            let time = new Date(year, month, day, 0, 0, 0).getTime();
            setStartDate(new Date(time));
        } else {
            let time = new Date(reservationInfo.checkIn).getTime();
            setStartDate(new Date(time));
        }

        let newEnd;
        if (reservationInfo.loggedOut_At) {
            newEnd = reservationInfo.loggedOut_At.split(" ")[2]
            let day = newEnd.split('/')[0];
            let month = parseInt(newEnd.split('/')[1]) - 1;
            let year = newEnd.split('/')[2];

            let time = new Date(year, month, day, 0, 0, 0).getTime();
            setEndDate(new Date(time));
        } else {
            let time = new Date(reservationInfo.checkOut).getTime();
            setEndDate(new Date(time));
        }

    }, [props]);

    useEffect(()=>{
        switchBarePrice();
    },[rent_type])

    useEffect(() => {
        calculateDuration();
    }, [rent_type, startDate, endDate])

    const switchBarePrice = () => {
        switch (rent_type) {
            case "يومي":
                setBarePrice((reservationInfo?.Room?.daily_price[0] || 0)*(reservationInfo?.bed_numbers/reservationInfo?.Room?.bed_numbers))
                break;
            case "اسبوعي":
                setBarePrice((reservationInfo?.Room?.weekly_price || 0)*(reservationInfo?.bed_numbers/reservationInfo?.Room?.bed_numbers))
                break;
            case "شهري":
                setBarePrice((reservationInfo?.Room?.monthely_price || 0)*(reservationInfo?.bed_numbers/reservationInfo?.Room?.bed_numbers))
                break;
        }
    }

    const calculateDuration = () => {
        if (rent_type == "يومي") {
            let d = (new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 60 * 60 * 24);
            setDuration(`${d} أيام`);
            setPeriod(d);
        } else if (rent_type == "شهري") {
            let year = new Date(endDate).getFullYear() - new Date(startDate).getFullYear();
            let day = new Date(endDate).getDate() - new Date(startDate).getDate();

            let month = year * 12 + (new Date(endDate).getMonth() - new Date(startDate).getMonth());
            if (day > 0) {
                month = month + 1;
            }
            if (month === 1) {
                setDuration(` شهر واحد`);
                setPeriod(1);
            } else if (month === 2) {
                setDuration(` شهران فقط`);
                setPeriod(2);
            } else if (month === 11 || month === 12) {
                setDuration(`${month} شهر`);
            } else {
                setDuration(`${month} أشهر`);
            }
            setPeriod(month);
        } else if (rent_type == "اسبوعي") {
            let d = (new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 60 * 60 * 24);

            let newWeek = (d / 7);
            let week = parseInt(d / 7);
            if (newWeek > week) {
                week = week + 1;
            }
            if (week <= 1 && week >= 0) {
                setDuration(` اسبوع واحد`);
                setPeriod(1);
            }
            else if (week <= 2 && week > 1) {
                setDuration(` اسبوعان فقط`);
                setPeriod(2);
            }
            else {
                setDuration(`${week} اسابيع`);
                setPeriod(week);
            }
        }
    }
    
    const isNumeric = (value) => {
        return /^-?\d+$/.test(value);
    }

    const validatePrice = e => {
        e.target.className = '';
        let priceString = e.target.value;
        if(isNumeric(priceString)) return setBarePrice(parseInt(priceString));
        
        setBarePrice(0);
        e.target.className = 'errorPrice';
    }

    const submitForm = (e) => {
        e.preventDefault();

        let lastPeriod = [];
        for (let i = 0; i < period; i++) {
            lastPeriod[i] = reservationInfo.period[i] || false;
        }

        let correctDateFormat = `${new Date(startDate).getDate()}/${new Date(startDate).getMonth() + 1}/${new Date(startDate).getFullYear()}`
        let newLogAt = `${new Date(startDate).toDateString().split(' ')[0]}  ${correctDateFormat}`
        let updatedReservationData = {
            checkIn: new Date(startDate).toLocaleDateString(),
            checkOut: new Date(endDate).toLocaleDateString(),
            loggedIn_At: newLogAt,
            period: lastPeriod,
            rent_type,
            total_price: ((barePrice * period) + reservationInfo.services_price),
            price: (barePrice * period),
        }
        props.setLoadingFunc(true);
        sendUpdatedData(updatedReservationData, props.reservationInfo.id)
    }

    const sendUpdatedData = async (data, id) => {
        await axios.put(`${login.API}/updateReservationForServices`, { ...data, id }, { headers: { 'Authorization': `Basic ${token}` } })
            .then((res) => {
                props.setreservationInfo({ ...props.reservationInfo, ...data });
                props.setLoadingFunc(false);
                props.handleClose(false);
            })
    }

    return (
        <Modal show={props.updateReservation} onHide={() => props.handleClose(false)}>
            <Modal.Header className='recipts_modal_header'>
                <CloseRoundedIcon onClick={() => props.handleClose(false)} />
                <Modal.Title>تعديل معلومات الحجز</Modal.Title>
            </Modal.Header>
            <Form onSubmit={submitForm}>
                <ul className='reservation_info_list'>
                    <li> <span className='reservation_confirmed'> {reservationInfo.confirmed ? <span className='confirmed_true'> مؤكد </span> : <span className='confirmed_false'>ملغي</span>} <span>{reservationInfo.id}# </span>  </span> <span>: رقم الحجز </span> </li>
                    <li> <span>{props.logInStatus} </span> <span>: حالة الحجز </span> </li>
                    <li> <span> {props.resrvationInfo?.room_name} - {props.resrvationInfo?.room_id}</span> <span>: الوحدة</span> </li>
                    <li>
                        <span className="inputDataForNewGuest">
                            <select onChange={(e) => setRent_type(e.target.value)} id="rent_type" className='finishReservationSelected'>
                                {
                                    reservationType.map((type) => (<option key={type} selected={rent_type == type ? 'selected' : false} value={type}> {type} </option>))
                                }
                            </select>
                        </span>
                        <span>: نظام الحجز </span>
                    </li>
                    <li> <span id='nights'> {duration.split(" ")[1]} </span> <span>{duration.split(" ")[0]}</span> <span>: مدة الحجز</span> </li>
                    <li> <span id='dates'>
                        <span>
                            <DatePicker
                                selectsEnd
                                startDate={startDate}
                                minDate={startDate}
                                endDate={endDate}
                                showMonthDropdown={true}
                                showYearDropdown={true}
                                className="inputDataForNewGuest" dateFormat="dd-MM-yyyy" selected={endDate} onChange={(date) => setEndDate(date)} />
                        </span>
                        <span>إلى</span>
                        <span>
                            <DatePicker
                                selectsStart
                                startDate={startDate}
                                showMonthDropdown={true}
                                showYearDropdown={true}
                                maxDate={endDate}
                                endDate={endDate}
                                className="inputDataForNewGuest" dateFormat="dd-MM-yyyy" selected={startDate} onChange={(date) => setStartDate(date)} />
                        </span> <span>من</span> </span> <span>: تاريخ الحجز</span> </li>
                    <li>
                        <span className="inputDataForNewGuest">
                            <input required id="barePrice" value={barePrice} type="text" onChange={validatePrice} />
                        </span>
                        <span>: سعر التاجير</span>
                    </li>
                    <li>
                        <span> دينار {reservationInfo.services_price}</span>
                        <span>: سعر الخدمات</span> </li>
                    <li> <span> دينار {((parseInt(barePrice || 0) * period) + parseInt(reservationInfo.services_price || 0))?.toFixed(2)}</span> <span>:  السعر الكلي</span> </li>
                    {/* priceWithTax */}
                </ul>

                <Button className="updateReservation" type="submit"> حفظ معلومات الحجز </Button>
            </Form>
        </Modal>
    )
}