import React, { useEffect, useState, useContext } from 'react';
import { Card, Button, Modal, Form } from 'react-bootstrap';
import { LoginContext } from '../context/login';
import { Link } from 'react-router-dom';
import cookies from 'react-cookies';
import axios from 'axios';
import './HousingUnit.css';
import PrintComingLeaveCleints from './PrintComingLeaveCleints';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PrintIcon from '@mui/icons-material/Print';
import SettingsIcon from '@mui/icons-material/Settings';
import CleanHandsIcon from '@mui/icons-material/CleanHands';
import EngineeringIcon from '@mui/icons-material/Engineering';


export default function HousingUnit2(props) {
    // To get the token
    const qs = new URLSearchParams(window.location.search);
    const cookieToken = cookies.load('token');
    const token = qs.get('token') || cookieToken || null;

    const login = useContext(LoginContext);

    const [rooms, setRooms] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [filteredRooms, setFilteredRooms] = useState([]);
    const [setting, setSetting] = useState(false);
    const [index, setIndex] = useState();
    const [reservations, setReservations] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [date, setDate] = useState(null);
    const [allReservations, setAllReservations] = useState([]);
    const [incoming, setIncoming] = useState([]);
    const [outgoing, setOutgoing] = useState([]);

    let avilable = 0;
    let reservedRoom = 0;
    let loggedInRooms = 0;
    let underMaintenance = 0;
    let underClean = 0;



    useEffect(() => {
        document.getElementById('date').value = new Date(new Date().getTime() + 3000 * 60 * 60).toISOString().split('T')[0];
        let selectedDate = document.getElementById('date').value;
        setDate(selectedDate);

        async function fetchData() {
            props.setLoadingFunc(true).then(async () => {
                await axios.post(`${login.API}/housingUnits`, {}, { headers: { 'Authorization': `Bearer ${token}` } })
                    .then((res) => {
                        let myArr = res.data.sort((a, b) => a.id - b.id);
                        setRooms(res.data.sort((a, b) => a.id - b.id));
                        // setFilteredRooms(res.data.sort((a, b) => a.id - b.id));
                        if (date) {
                            onChangeFilter(date, myArr);
                        } else {
                            onChangeFilter(new Date((new Date().getTime() + 3000 * 60 * 60)).toISOString().split('T')[0], myArr);
                        }

                    })
                    .catch((err) => {
                        console.log("err", err);
                    })

                await axios.get(`${login.API}/allDepartments`, { headers: { 'Authorization': `Bearer ${token}` } })
                    .then((res) => {
                        setDepartments(res.data);
                    })
                    .catch((err) => {
                        console.log("err", err);
                    })

                await axios.post(`${login.API}/getAllReservation`, {}, { headers: { 'Authorization': `Bearer ${token}` } })
                    .then((res) => {
                        setAllReservations(res.data);
                    })
                    .catch((err) => {
                        console.log("err", err);
                    })

                props.setLoadingFunc(false);
            })

        }
        fetchData();
    }, []);


    const incomingGuests = () => {


        let myFilter = allReservations.filter(reserve => {
            if (reserve.loggedIn_At) {
                let loggedIn_At_Date = reserve.loggedIn_At?.split("  ")[1]?.split("/");
                loggedIn_At_Date = loggedIn_At_Date[2] + "-" + loggedIn_At_Date[1] + "-" + loggedIn_At_Date[0];
                if (new Date(date).toLocaleDateString() === new Date(loggedIn_At_Date).toLocaleDateString()) {
                    return reserve
                }
            }

        })
        setIncoming(myFilter);
    }

    const outGoingGuests = () => {

        let myFilter = allReservations.filter(reserve => {
            if (reserve.loggedOut_At) {
                let loggedOut_At_Date = reserve.loggedOut_At.split("  ")[1].split("/");
                loggedOut_At_Date = loggedOut_At_Date[2] + "-" + loggedOut_At_Date[1] + "-" + loggedOut_At_Date[0];
                if (new Date(date).toLocaleDateString() === new Date(loggedOut_At_Date).toLocaleDateString()) {
                    return reserve
                }
            }
        })
        setOutgoing(myFilter);
    }

    useEffect(() => {
        incomingGuests();
        outGoingGuests();
    }, [allReservations]);

    useEffect(() => {
        incomingGuests();
        outGoingGuests();
    }, [date]);

    // --------------------------------------------------------------------------------
    // This functions to filter the rooms by data
    const onChange = async (e) => {
        let selectedDate = e.target.value;
        // setFilteredRooms([]);
        setDate(e.target.value);

        let myArr = rooms;


        if (new Date(selectedDate).toISOString() == new Date().toISOString()) {
            setFilteredRooms(myArr.sort((a, b) => a.id - b.id));
        } else {
            onChangeFilter(selectedDate, myArr);

        }


        props.setLoadingFunc(false)

    }
    async function onChangeFilter(selectedDate, myArr) {
        let resultArr = [];
        for (let i = 0; i < myArr.length; i++) {
            let resultReservations = [];
            for (let j = 0; j < myArr[i].Reservations.length; j++) {
                if ((new Date(selectedDate) >= new Date(myArr[i].Reservations[j].checkIn) && new Date(selectedDate) <= new Date(myArr[i].Reservations[j].checkOut))) {
                    resultReservations.push(myArr[i].Reservations[j]);
                }
            }
            myArr[i].resultReservations = resultReservations;
            resultArr.push(myArr[i]);
        }

        let myDepartment = document.getElementById('department').value;
        if (myDepartment !== "all") {
            resultArr = resultArr.filter(room => {
                if (myDepartment === room.unit_id) {
                    return room
                }
            })
        }

        setFilteredRooms(resultArr.sort((a, b) => a.id - b.id));
    }


    const setTodaysDate = async () => {
        let today = new Date();



        let myDate = document.getElementById('date');
        myDate.value = new Date(today.getTime() + (3000 * 60 * 60)).toISOString().split('T')[0];
        await setDate(new Date(today.getTime() + (3000 * 60 * 60)).toISOString().split('T')[0]);
        props.setLoadingFunc(true)
            .then(async () => {
                let myArr = rooms;
                onChangeFilter(new Date(today.getTime() + (3000 * 60 * 60)).toISOString().split('T')[0], myArr);
                props.setLoadingFunc(false)
            })
    }

    // --------------------------------------------------------------------------------


    // filter My Rooms with respect to departments
    const setMyDepartment = (e) => {
        setFilteredRooms([]);
        let myDepartment = e.target.value;
        if (myDepartment === "all") {
            let myRooms = rooms
            if (date) {
                onChangeFilter(date, myRooms);
            } else {
                onChangeFilter(new Date(), myRooms);
            }
            // setFilteredRooms(myRooms);
        } else {
            let myRooms = rooms.filter(room => {
                if (myDepartment === room.unit_id) {
                    return room
                }
            })
            if (date) {
                onChangeFilter(date, myRooms);
            } else {
                onChangeFilter(new Date(), myRooms);
            }
            // setFilteredRooms(myRooms);
        }
    }

    // --------------------------------------------------------------------------------
    const [showCleintsModal, setShowCleintsModal] = useState(false);

    const [printData, setPrintData] = useState({});
    // setHideCleintsModal}showCleintsModal 
    const setHideCleintsModal = (myData, type) => {
        setShowCleintsModal(!showCleintsModal);
        setPrintData({ myData, type, date });
    }


    // --------------------------------------------------------------------------------
    // This function for room service (clean, repair, etc)
    const showSetting = async (idx) => {
        if (idx === index) {
            setSetting(false);
            setIndex(null);
        } else {
            setSetting(true);
            setIndex(idx);
        }
    }

    const toClean = async () => {
        let room = rooms[index];
        let data = {
            room_id: room.id,
            room_status: 'under clean'
        }
        let roomNumbers = [];
        props.setLoadingFunc(true);

        await axios.put(`${login.API}/updateRoom`, data, { headers: { 'Authorization': `Bearer ${token}` } })
            .then(res => {
                roomNumbers = res.data.sort((a, b) => Number(a.id) - Number(b.id))
                setRooms(roomNumbers);
                if (date) {
                    onChangeFilter(date, roomNumbers);
                } else {
                    onChangeFilter(new Date(), roomNumbers);
                }
                // setFilteredRooms(roomNumbers);
                // setRoomsFiltered(roomNumbers);

                // new activity
                axios.post(`${login.API}/newActivity`, {
                    employee_id: login.user.id,
                    description: `تحديث حالة الغرفة - ${room.room_name} - الى تحت التنظيف`,
                    type: "الغرف والاقسام"
                },
                    { headers: { 'Authorization': `Bearer ${token}` } })
            })
        props.setLoadingFunc(false);
        setSetting(false);
        setIndex(null);
    }

    const toReady = async () => {
        let room = rooms[index];
        let data = {
            room_id: room.id,
            room_status: 'avilable'
        }
        let roomNumbers = [];
        props.setLoadingFunc(true);
        await axios.put(`${login.API}/updateRoom`, data, { headers: { 'Authorization': `Bearer ${token}` } })
            .then(res => {
                roomNumbers = res.data.sort((a, b) => Number(a.id) - Number(b.id))
                setRooms(roomNumbers);
                // setFilteredRooms(roomNumbers);
                if (date) {
                    onChangeFilter(date, roomNumbers);
                } else {
                    onChangeFilter(new Date(), roomNumbers);
                }
                // setRoomsFiltered(roomNumbers);
                // new activity
                axios.post(`${login.API}/newActivity`, {
                    employee_id: login.user.id,
                    description: `تحديث حالة الغرفة - ${room.room_name} - الى متاحة`,
                    type: "الغرف والاقسام"
                },
                    { headers: { 'Authorization': `Bearer ${token}` } })
            })
        props.setLoadingFunc(false);
        setSetting(false);
        setIndex(null);
    }

    const toOccupied = async () => {
        let room = rooms[index];
        let data = {
            room_id: room.id,
            room_status: 'under maintenance'
        }
        let roomNumbers = [];
        props.setLoadingFunc(true);
        await axios.put(`${login.API}/updateRoom`, data, { headers: { 'Authorization': `Bearer ${token}` } })
            .then(res => {
                roomNumbers = res.data.sort((a, b) => Number(a.id) - Number(b.id))
                setRooms(roomNumbers);
                // setFilteredRooms(roomNumbers);
                if (date) {
                    onChangeFilter(date, roomNumbers);
                } else {
                    onChangeFilter(new Date(), roomNumbers);
                }
                // setRoomsFiltered(roomNumbers);
                // new activity
                axios.post(`${login.API}/newActivity`, {
                    employee_id: login.user.id,
                    description: `تحديث حالة الغرفة - ${room.room_name} - الى تحت الصيانه`,
                    type: "الغرف والاقسام"
                },
                    { headers: { 'Authorization': `Bearer ${token}` } })

            })
        props.setLoadingFunc(false);

        setSetting(false);
        setIndex(null);
    }

    // --------------------------------------------------------------------------------


    // This function for sendenig the data of reservation to Reservation Info page
    const setreservationInfoFunction = async (room_id, date, room_name, reservation_id) => {
        let data = {
            room_id: room_id,
            date: date,
            room_name: room_name,
            reservation_id: reservation_id
        }
        cookies.remove('reservationInfo');
        cookies.save('reservationInfo', JSON.stringify(data));

        props.setreservationInfo({
            room_id: room_id,
            date: date,
            room_name: room_name,
            reservation_id: reservation_id
        })
    }

    // --------------------------------------------------------------------------------


    const showReservationsModal = async (Reservations, room_id, date, room_name) => {
        let data = {
            Reservations: Reservations.sort((a, b) => a.id - b.id),
            room_id: room_id,
            date: date,
            room_name: room_name
        }
        setReservations(null);
        setReservations(data);

        setShowModal(true);
    }

    const closeReservationsModal = () => {
        setShowModal(false);
    }





    return (
        <div className='HousingUnit'>
            <div className='navigator'>
                <p> <Link className='linkContainer' to='/'>الرئيسية</Link> / تسكين الوحدات </p>
            </div>

            <Form className='housing_date_form'>
                <Form.Group className="mb-3 housing_date" >
                    <Form.Control onChange={onChange} id='date' className='housing_date_input' name="checkIn" type="date" placeholder="checkIn-Date" />
                    <Form.Label className='housing_date_lable' onClick={setTodaysDate} id="setTodaysDate" htmlFor="email">اليوم</Form.Label>
                </Form.Group>
            </Form>

            <div className='item_container'>
                <div className="item">
                    <select id='department' onChange={setMyDepartment}>
                        <option value="all" >قسم الوحدة</option>

                        {
                            departments.map((department, index) => {
                                return (
                                    <option key={index} value={department.id}>{department.name}</option>
                                )
                            })
                        }

                    </select>
                </div>
            </div>


            <div className='rooms_container'>

                {filteredRooms.map((room, idx) => {

                    // If there is no reservation at the room
                    if (room.resultReservations.length === 0) {

                        // If The Room is Clean and Free
                        if (room.room_status === 'avilable') {
                            avilable++;
                            if (setting === false) {
                                return (
                                    <Card key={idx} className='card green' style={{ width: '12rem' }}>
                                        <Card.Title className='card_title'>
                                            <SettingsIcon onClick={() => showSetting(idx)} />
                                            <span id='price'>{room.room_number}</span></Card.Title>
                                        <Card.Body>
                                            <Card.Text className='card_text'>
                                                {room.room_name}
                                            </Card.Text>
                                            <div className='card_button'>
                                                <Link to='/newreservation' className='newbook_link'>
                                                    <Button variant="primary">حجز</Button>
                                                </Link>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                )
                            } else if (setting === true) {
                                if (index == idx) {
                                    return (
                                        <Card key={idx} className='card green' style={{ width: '12rem' }}>
                                            <Card.Title className='card_title'>
                                                <SettingsIcon onClick={() => showSetting(idx)} />
                                                <span id='price'>{room.room_number}</span></Card.Title>

                                            <Card.Body className='settings_container'>
                                                <p onClick={toClean} className='under_clean'>
                                                    <CleanHandsIcon />
                                                     <span>تحويل لتحت التنظيف</span> </p>
                                                <p onClick={toOccupied} className='under_maintenance'> 
                                                <EngineeringIcon />
                                                <span>تحويل لتحت الصيانه</span></p>
                                            </Card.Body>
                                        </Card>
                                    )
                                } else {
                                    return (
                                        <Card key={idx} className='card green' style={{ width: '12rem' }}>
                                            <Card.Title className='card_title'>
                                                <SettingsIcon onClick={() => showSetting(idx)} />
                                                <span id='price'>{room.room_number}</span></Card.Title>
                                            <Card.Body>
                                                <Card.Text className='card_text'>
                                                    {room.room_name}
                                                </Card.Text>



                                                <div className='card_button'>
                                                    <Link to='/newreservation' className='newbook_link'>
                                                        <Button variant="primary">حجز</Button>
                                                    </Link>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    )
                                }
                            }
                        }  // If The Room is Under Maintenance
                        else if (room.room_status === 'under maintenance') {
                            underMaintenance++;
                            if (setting === false) {
                                return (
                                    <Card key={idx} className='card maintenance' style={{ width: '12rem' }}>
                                        <Card.Title className='card_title'>
                                            <SettingsIcon onClick={() => showSetting(idx)} />
                                            <span id='price'>{room.room_number}</span></Card.Title>
                                        <Card.Body>
                                            <Card.Text className='card_text'>
                                                {room.room_name}
                                            </Card.Text>

                                            {/* <Card.Text className='price_spans'>
                                            <span> {room.bed_numbers} </span>
                                            <span> / </span>
                                            <span> {room.free_beds} </span>
                                            <span> الأسرة المتاحة </span>
                                        </Card.Text> */}
                                            <div className='card_button'>
                                                <Button onClick={() => showSetting(idx)} variant="primary">تحت الصيانه</Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                )
                            } else if (setting === true) {
                                if (index == idx) {
                                    return (
                                        <Card key={idx} className='card maintenance' style={{ width: '12rem' }}>
                                            <Card.Title className='card_title'>
                                                <SettingsIcon onClick={() => showSetting(idx)} />
                                                <span id='price'>{room.room_number}</span>
                                            </Card.Title>

                                            <Card.Body className='settings_container'>
                                                <p onClick={toReady} className='avilable'><i className="far fa-check-circle"></i> <span>تحويل لمتاح</span> </p>
                                            </Card.Body>
                                        </Card>
                                    )
                                } else {
                                    return (
                                        <Card key={idx} className='card maintenance' style={{ width: '12rem' }}>
                                            <Card.Title className='card_title'>
                                                <SettingsIcon onClick={() => showSetting(idx)} />
                                                <span id='price'>{room.room_number}</span></Card.Title>
                                            <Card.Body>
                                                <Card.Text className='card_text'>
                                                    {room.room_name}
                                                </Card.Text>



                                                <div className='card_button'>
                                                    <Link to='/newreservation' className='newbook_link'>
                                                        <Button variant="primary">حجز</Button>
                                                    </Link>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    )
                                }
                            }
                        } // If The Room is Under Cleaning
                        else if (room.room_status === 'under clean') {
                            underClean++;
                            if (setting === false) {
                                return (
                                    <Card key={idx} className='card clean' style={{ width: '12rem' }}>
                                        <Card.Title className='card_title'>
                                            <SettingsIcon onClick={() => showSetting(idx)} />
                                            <span id='price'>{room.room_number}</span></Card.Title>
                                        <Card.Body>
                                            <Card.Text className='card_text'>
                                                {room.room_name}
                                            </Card.Text>
                                            {/* <Card.Text className='price_spans'>
                                            <span> {room.bed_numbers} </span>
                                            <span> / </span>
                                            <span> {room.free_beds} </span>
                                            <span> الأسرة المتاحة </span>
                                        </Card.Text> */}
                                            <div className='card_button'>
                                                <Button onClick={() => showSetting(idx)} variant="primary">تحت التنظيف</Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                )
                            } else if (setting === true) {
                                if (index == idx) {
                                    return (
                                        <Card key={idx} className='card clean' style={{ width: '12rem' }}>
                                            <Card.Title className='card_title'>
                                                <SettingsIcon onClick={() => showSetting(idx)} />
                                                <span id='price'>{room.room_number}</span></Card.Title>

                                            <Card.Body className='settings_container'>
                                                <p onClick={toReady} className='avilable'><i className="far fa-check-circle"></i> <span>تحويل لمتاح</span> </p>
                                            </Card.Body>
                                        </Card>
                                    )
                                } else {
                                    return (
                                        <Card key={idx} className='card clean' style={{ width: '12rem' }}>
                                            <Card.Title className='card_title'>
                                                <SettingsIcon onClick={() => showSetting(idx)} />
                                                <span id='price'>{room.room_number}</span></Card.Title>
                                            <Card.Body>
                                                <Card.Text className='card_text'>
                                                    {room.room_name}
                                                </Card.Text>



                                                <div className='card_button'>
                                                    <Link to='/newreservation' className='newbook_link'>
                                                        <Button variant="primary">حجز</Button>
                                                    </Link>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    )
                                }
                            }
                        }
                    }  // If The Room is Reserved
                    else if (room.resultReservations.length === 1) {
                        reservedRoom++;
                        if (room.resultReservations[0].status === 'لم يسجل دخول') {
                            return (
                                <Card key={idx} className='card blue' style={{ width: '12rem' }}>
                                    <Card.Title className='card_title'> <span id='price'>{room.room_number}</span></Card.Title>
                                    <Card.Body>
                                        <Card.Text className='card_text'>
                                            {room.room_name}

                                        </Card.Text>

                                        <div className='card_button'>
                                            <Link to='/reservationInfo' className='newbook_link'>
                                                <Button onClick={() => setreservationInfoFunction(room.id,
                                                    date, room.room_name, room.resultReservations[0].id)} variant="primary">إدارة الحجز</Button>
                                            </Link>
                                        </div>
                                    </Card.Body>
                                </Card>
                            )
                        } else if (room.resultReservations[0].status === 'سجل دخول') {
                            return (
                                <Card key={idx} className='card red' style={{ width: '12rem' }}>
                                    <Card.Title className='card_title'> <span id='price'>{room.room_number}</span></Card.Title>
                                    <Card.Body>
                                        <Card.Text className='card_text'>
                                            {room.room_name}
                                        </Card.Text>

                                        <div className='card_button'>
                                            <Link to='/reservationInfo' className='newbook_link'>
                                                <Button onClick={() => setreservationInfoFunction(room.id,
                                                    date, room.room_name, room.resultReservations[0].id)} variant="primary">إدارة الحجز</Button>
                                            </Link>
                                        </div>
                                    </Card.Body>
                                </Card>
                            )
                        }

                    } else if (room.resultReservations.length > 1) {
                        reservedRoom++;
                        return (
                            <Card key={idx} className='card blue' style={{ width: '12rem' }}>
                                <Card.Title className='card_title'> <span id='price'>{room.room_number}</span></Card.Title>
                                <Card.Body>
                                    <Card.Text className='card_text'>
                                        {room.room_name}

                                    </Card.Text>
                                    <div className='card_button'>
                                        <Button onClick={() => showReservationsModal(room.resultReservations, room.id, date, room.room_name)} variant="primary">إدارة الحجوزات</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    }
                })
                }
            </div>

            <Modal show={showModal} onHide={closeReservationsModal} className="reservations_modal">
                <Modal.Header className='contarct_modal_header'>
                    <CloseRoundedIcon onClick={closeReservationsModal} />
                    <Modal.Title>الحجوزات</Modal.Title>
                </Modal.Header>
                <Modal.Body className='housing_units_reservations'>
                    {reservations.Reservations &&
                        reservations.Reservations.map((reservation, idx) => {
                            if (reservation.status === "لم يسجل دخول") {
                                return (
                                    <Card key={idx} className='card blue' style={{ width: '10rem' }}>
                                        <Card.Title className='card_title'> <span id='reservation_title'>{`حجز رقم ${reservation.id}`}</span></Card.Title>
                                        <Card.Body>
                                            <Card.Text className='card_text'>
                                                { }

                                            </Card.Text>
                                            <div className='card_button'>

                                                <Link to='/reservationInfo' className='newbook_link'>
                                                    <Button onClick={() => setreservationInfoFunction(reservations.room_id, reservations.date, reservations.room_name, reservation.id)} variant="primary">إدارة الحجز</Button>
                                                </Link>

                                            </div>
                                        </Card.Body>
                                    </Card>
                                )
                            } else if (reservation.status === "سجل دخول") {
                                return (
                                    <Card key={idx} className='card red' style={{ width: '10rem' }}>
                                        <Card.Title className='card_title'> <span id='reservation_title'>{`حجز رقم ${reservation.id}`}</span></Card.Title>
                                        <Card.Body>
                                            <Card.Text className='card_text'>
                                                { }

                                            </Card.Text>
                                            <div className='card_button'>

                                                <Link to='/reservationInfo' className='newbook_link'>
                                                    <Button onClick={() => setreservationInfoFunction(reservations.room_id, reservations.date, reservations.room_name, reservation.id)} variant="primary">إدارة الحجز</Button>
                                                </Link>

                                            </div>
                                        </Card.Body>
                                    </Card>
                                )
                            }

                        })}
                </Modal.Body>


            </Modal>

            {/* Rooms Status */}
            <div className='rooms_status'>


                <div className='rooms_status left_status'>
                    {/* <span>دينار</span>  <span>100</span>  <span>:</span>  <span>رصيد الصندوق</span> */}
                    <span></span>  <span></span>  <span></span> <span> </span>

                </div>

                <div className='rooms_status right_status'>
                    <div >
                        <span>نظافة ({underClean})</span>
                        <div id='under_clean'>
                        </div>
                    </div>

                    <div >
                        <span>صيانة ({underMaintenance})</span>
                        <div id='under_maintenance'>
                        </div>
                    </div>

                    <div >
                        <span>بإنتظار الدفع {props?.waitingForPayment?.length}</span>
                        <div id='wait_for_pay'>
                        </div>
                    </div>

                    {/* <div >
                        <span>سجل دخول ({loggedInRooms})</span>
                        <div id='loggedIn'>
                        </div>
                    </div> */}

                    <div >
                        <span>محجوز ({reservedRoom})</span>
                        <div id='reserved'>
                        </div>
                    </div>

                    <div >
                        <span>متاح ({avilable})</span>
                        <div id='avilable'>
                        </div>
                    </div>
                </div>
            </div>



            {/* Incoming-Outgoing visitors */}

            <div className='incoming_outgoing'>
                <div className='incoming_outgoing_left'>
                    <div className='incoming_outgoing_left_top'>
                        <div className='incoming_outgoing_left_icon'>

                        </div>
                        <span> ({outgoing.length}) الزوار المغادرون</span>

                    </div>

                    <div className='incoming_outgoing_left_bottom'>
                        <div className='print'>
                            <i onClick={() => setHideCleintsModal(outgoing, "الزوار المغادرون")} title="طباعة جدول المغادرين">
                                <PrintIcon />
                            </i>
                        </div>

                        <div className='incoming_outgoing_left_body'>

                            {outgoing.map((reservation, index) => {

                                return (
                                    <Link id="geustCard" key={index} to="/reservationInfo">
                                        <div id="geustCard" onClick={() => setreservationInfoFunction(reservation.room_id, date, reservation.unit, reservation.id)} className='incoming_outgoing_right_body_card'>
                                            <div className='visitors_info'>
                                                <div className='visitors_info_row'>

                                                    <p>
                                                        <span> :العميل</span>
                                                        <span>{reservation.Guest.name}</span>
                                                    </p>

                                                    <p>
                                                        <span>:عدد الليالي</span>
                                                        <span>{(new Date(reservation.checkOut).getTime() - new Date(reservation.checkIn).getTime()) / (1000 * 60 * 60 * 24)}</span>
                                                    </p>

                                                    <p>
                                                        <span>:رقم الوحدة</span>
                                                        <span>{reservation.unit}</span>
                                                    </p>

                                                    <p>
                                                        <span>:رقم الحجز</span>
                                                        <span>{reservation.id}</span>
                                                    </p>

                                                </div>
                                                <div className='visitors_info_row'>
                                                    <p>
                                                        <span>:نهاية الحجز</span>
                                                        <span>{reservation.checkOut}</span>
                                                    </p>
                                                    <p>
                                                        <span>:بداية الحجز</span>
                                                        <span>{reservation.checkIn}</span>
                                                    </p>
                                                    <p>
                                                        <span>:حالة الحجز</span>
                                                        <span>{reservation.status}</span>
                                                    </p>
                                                    <p>
                                                        <span>:الجوال </span>
                                                        <span>{reservation.Guest.phone_number}</span>
                                                    </p>
                                                </div>
                                                <div className='visitors_info_row'>
                                                    {/* <p>
                                                        <span className='raseedSpan'> : الرصيد </span>
                                                        <span className='visitors_info_row_price'>
                                                            <span>دينار </span>
                                                            <span> {parseFloat(reservation.wallet)?.toFixed(2)} </span>
                                                        </span>

                                                    </p> */}

                                                    <p>
                                                        <span>:اجمالي الحجز</span>
                                                        <span className='visitors_info_row_price'>
                                                            <span>دينار </span>
                                                            <span> {parseFloat(reservation.price + reservation.services_price)?.toFixed(2)} </span>
                                                        </span>

                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    </Link>
                                )
                            }
                            )}
                        </div>
                    </div>
                </div>


                <div className='incoming_outgoing_right'>
                    <div className='incoming_outgoing_right_top'>
                        <div className='incoming_outgoing_right_icon'>

                        </div>
                        <span>({incoming.length})الزوار الوافدون</span>
                    </div>

                    <div className='incoming_outgoing_right_bottom'>
                        <div className='print'>
                            <i onClick={() => setHideCleintsModal(incoming, "الزوار الوافدون")} title="طباعة جدول الوافدين" >
                                <PrintIcon />
                            </i>
                        </div>

                        <div className='incoming_outgoing_right_body'>
                            {incoming.map((reservation, index) => {

                                return (
                                    <Link id="geustCard" key={index} to="/reservationInfo">
                                        <div onClick={() => setreservationInfoFunction(reservation.room_id, date, reservation.unit, reservation.id)} id="geustCard" className='incoming_outgoing_right_body_card'>
                                            <div className='visitors_info'>
                                                <div className='visitors_info_row'>

                                                    <p>
                                                        <span> :العميل</span>
                                                        <span>{reservation.Guest.name}</span>
                                                    </p>

                                                    <p>
                                                        <span>:عدد الليالي</span>
                                                        <span>{(new Date(reservation.checkOut).getTime() - new Date(reservation.checkIn).getTime()) / (1000 * 60 * 60 * 24)}</span>
                                                    </p>

                                                    <p>
                                                        <span>:رقم الوحدة</span>
                                                        <span>{reservation.unit}</span>
                                                    </p>

                                                    <p>
                                                        <span>:رقم الحجز</span>
                                                        <span>{reservation.id}</span>
                                                    </p>

                                                </div>
                                                <div className='visitors_info_row'>
                                                    <p>
                                                        <span>:نهاية الحجز</span>
                                                        <span>{reservation.checkOut}</span>
                                                    </p>
                                                    <p>
                                                        <span>:بداية الحجز</span>
                                                        <span>{reservation.checkIn}</span>
                                                    </p>
                                                    <p>
                                                        <span>:حالة الحجز</span>
                                                        <span>{reservation.status}</span>
                                                    </p>
                                                    <p>
                                                        <span>:الجوال </span>
                                                        <span>{reservation.Guest.phone_number}</span>
                                                    </p>
                                                </div>
                                                <div className='visitors_info_row'>
                                                    {/* <p>
                                                        <span className='raseedSpan'> : الرصيد </span>
                                                        <span className='visitors_info_row_price'>
                                                            <span>دينار </span>
                                                            <span> {parseFloat(reservation.wallet)?.toFixed(2)} </span>
                                                        </span>

                                                    </p> */}

                                                    <p>
                                                        <span>:اجمالي الحجز</span>
                                                        <span className='visitors_info_row_price'>
                                                            <span>دينار </span>
                                                            <span> {parseFloat(reservation.price + reservation.services_price)?.toFixed(2)} </span>
                                                        </span>

                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    </Link>
                                )
                            }
                            )}
                        </div>
                    </div>
                </div>


            </div>
            <PrintComingLeaveCleints printData={printData} setHideCleintsModal={setHideCleintsModal} show={showCleintsModal} />
        </div>
    )


}
