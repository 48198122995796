import React, { useEffect, useState, useContext } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './home.css';
import { LoginContext } from '../context/login';
import axios from 'axios';
import cookies from 'react-cookies';
import { Line } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import '../../node_modules/react-vis/dist/style.css';
// import { ArcSeries, RadialChart, XYPlot, VerticalBarSeries, VerticalGridLines, HorizontalGridLines } from 'react-vis';
// import { VictoryBar, VictoryChart, VictoryAxis, VictoryPie, VictoryLabel } from 'victory';
import { Chart } from 'react-google-charts';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';


const optionsService = {
    // title: 'حالة خدمة الغرف',
    pieHole: 0.4,
    is3D: false,
    legend: 'bottom',
    chartArea: { width: '100%' },
    slices: {
        0: { color: '#83df96' },
        1: { color: '#d1dde3' },
        2: { color: '#f5b26a' },
    },
    pieHole: 0.5,
    pieSliceTextStyle: {
        color: 'black',
    },

};
// data for room status
const options = {
    // title: `حالة الوحدات اليوم ${new Date().getDate()} / ${new Date().getMonth() + 1}`,
    legend: 'bottom',
    chartArea: { width: '100%' },
    slices: {
        0: { color: '#50c669' },
        1: { color: '#126ee8' },
        2: { color: '#f6574b' },
        3: { color: '#b3c0c7' },
        4: { color: '#ff9019' }
    },
    pieSliceTextStyle: {
        color: 'black',
    },
};

// Weekly Data 
const optionsWeekly = {
    series: {
        0: { color: '#50c669' },
        1: { color: '#126ee8' },
        2: { color: '#f6574b' },
        3: { color: '#b3c0c7' },
        4: { color: '#ff9019' },
    },

};

// row Bar
const dataDaily = [
    ['الوحدات', 'عدد الوحدات'],
    ['غرف مفردة ', 125],
    ['غرف مزدوجة', 75],
    ['جناح عادي', 90],
    ['جناح فاخر', 10],
    ['اقامة الشخصيات المهمه', 13],
];

const optionsDaily = {
    // title: 'إشغال انواع الوحدات ليوم',
    // chartArea: { width: '50%' },
    hAxis: {
        title: 'عدد الوحدات',
        minValue: 0,
    },
    legend: 'none',
    bar: { groupWidth: "25%", background: "#ff6600" },
    chartArea: { height: '40%' },

};

export default function Home(props) {
    // To load the token from cookies
    const qs = new URLSearchParams(window.location.search);
    const cookieToken = cookies.load('token');
    const token = qs.get('token') || cookieToken || null;

    const login = useContext(LoginContext);

    const [rooms, setRooms] = useState([]);
    const [department, setDepartments] = useState([]);
    const [reservations, setReservations] = useState([]);

    const [data, setData] = useState([
        ['Task', 'Hours per Day'],
        ['متاح', 1],
        ['محجوز', 1],
        ['سجل دخول', 1],
        ['نظافة', 1],
        ['صيانه', 1],
    ]);

    // data for room servics
    const [dataService, setdataService] = useState([
        ['Task', 'Hours per Day'],

        ['ظيف', 1],
        ['تحت التنظيف', 1],
        ['صيانه', 1], // CSS-style declaration
    ]);

    const [dataWeekly, setDataWeekly] = useState([
        ['Day', 'متاح', 'محجوز', 'سجل دخول', 'نظافة', 'صيانه'],
        [`${new Date().getDate() - 1} / ${new Date().getMonth() + 1}`, 1, 1, 1, 1, 1],
        [`${new Date().getDate() - 2} / ${new Date().getMonth() + 1}`, 1, 1, 1, 1, 1],
        [`${new Date().getDate() - 3} / ${new Date().getMonth() + 1}`, 1, 1, 1, 1, 1],
        [`${new Date().getDate() - 4} / ${new Date().getMonth() + 1}`, 1, 1, 1, 1, 1],
        [`${new Date().getDate() - 5} / ${new Date().getMonth() + 1}`, 1, 1, 1, 1, 1],
        [`${new Date().getDate() - 6} / ${new Date().getMonth() + 1}`, 1, 1, 1, 1, 1],
        [`${new Date().getDate() - 7} / ${new Date().getMonth() + 1}`, 1, 1, 1, 1, 1],

    ]);

    useEffect(() => {
        props.setLoadingFunc(true).then(() => {
            axios.get(`${login.API}/setMyNotifications`, { headers: { 'Authorization': `Basic ${token}` } })
                .then(res => {
                    let allRoomsStatus = res.data.notifications;
                    let allDepartments = res.data.units;
                    let allReservations = res.data.reservations;

                    allRoomsStatus.sort((a, b) => (new Date(a.date).getTime() - new Date(b.date).getTime()));
                    let todaysStatus = allRoomsStatus[allRoomsStatus.length - 1];

                    if (todaysStatus?.type?.length) {
                        // set cleanup rooms
                        setdataService([
                            ['Task', 'Hours per Day'],
                            ['نظيف', todaysStatus?.type[0]],
                            ['تحت التنظيف', todaysStatus?.type[3]],
                            ['صيانه', todaysStatus?.type[4]], // CSS-style declaration
                        ])
                        // set rooms status
                        setData([
                            ['Task', 'Hours per Day'],
                            ['متاح', todaysStatus?.type[0]],
                            ['محجوز', todaysStatus?.type[1]],
                            ['سجل دخول', todaysStatus?.type[2]],
                            ['نظافة', todaysStatus?.type[3]],
                            ['صيانه', todaysStatus?.type[4]],
                        ])
                        // set rooms for week
                        let weeklyStatus = allRoomsStatus.slice(allRoomsStatus.length - 7, allRoomsStatus.length);
                        weeklyStatus = weeklyStatus.map(status => ([status.date, ...status.type]));
                        weeklyStatus = [
                            ['Day', 'متاح', 'محجوز', 'سجل دخول', 'نظافة', 'صيانه'],
                            ...weeklyStatus
                        ];
                        setDataWeekly(weeklyStatus);
                    }
                    // set reservations status
                    let allRooms = [];
                    let allRoomsTaken = [];
                    let myDepartments = allDepartments?.map(dep => {
                        allRooms = [...allRooms, ...dep.Rooms || []]
                        let waitingPayments = [];
                        let takenRoom = 0;
                        allReservations.forEach(reservation => {
                            if (reservation.loggedOut_At || !reservation.confirmed) return;

                            if (!allRoomsTaken.includes(reservation.room_id) && dep?.Rooms?.find(room => (room.id === reservation.room_id))) {
                                takenRoom += 1;
                                allRoomsTaken.push(reservation.room_id);
                            }

                            let firstDay = new Date(reservation.checkIn);
                            if (reservation.loggedIn_At) {
                                let day = parseInt(reservation.loggedIn_At.split('  ')[1].split('/')[0]);
                                let month = parseInt(reservation.loggedIn_At.split('  ')[1].split('/')[1]);
                                let year = parseInt(reservation.loggedIn_At.split('  ')[1].split('/')[2]);
                                firstDay = new Date(year, month + 1, day);
                            }
                            let periodDiff = new Date().getTime() - firstDay.getTime();
                            if (reservation.rent_type === 'يومي') {
                                periodDiff = Math.abs(periodDiff / (24 * 60 * 60 * 1000));
                            } else if (reservation.rent_type === 'اسبوعي') {
                                periodDiff = Math.abs(periodDiff / (6 * 24 * 60 * 60 * 1000));
                            } else {
                                periodDiff = Math.abs(periodDiff / (29 * 24 * 60 * 60 * 1000));
                            }
                            if (reservation?.period?.indexOf(false) <= (periodDiff)) waitingPayments.push(reservation);
                        });
                        waitingPayments.sort((a, b) => a.id - b.id)
                        props.setWaitingForPayments(waitingPayments);
                        setReservations(waitingPayments);
                        return { name: dep?.name, rooms: dep?.Rooms?.length, takenRoom }
                    })
                    setDepartments(myDepartments)
                    setRooms(allRooms)
                    props.setLoadingFunc(false);
                })
        })
    }, []);

    const setreservationInfo = (room_id, date, room_name, reservation_id) => {
        let data = {
            room_id,
            date,
            room_name,
            reservation_id
        }
        cookies.remove('reservationInfo');
        cookies.save('reservationInfo', JSON.stringify(data));

        props.setreservationInfo({
            room_id,
            date,
            room_name,
            reservation_id
        })
    }

    return (
        <div>
            <div className='paymentsReportMainPage'>
                <Link to="/financialManagments" className='mainPageCardLink'>
                    <div className='mainPageCard'>
                        <span> الانتقال الى التقارير المالية </span>
                        <RequestQuoteIcon />
                    </div>
                </Link>

            </div>
            <div className='waitPay'>
                <div className='headOfWaitPay'>
                    <p>({reservations.length})حجوزات بانتظار الدفع</p>
                </div>
                <div className='bodyOfWaitPay'>
                    {reservations.map((reserve, idx) => {
                        return (
                            <Link onClick={()=>setreservationInfo(reserve.room_id, reserve.checkIn, reserve.unit, reserve.id)} key={idx} to="/reservationInfo">
                                <div className='reminder_card' >

                                    <div className='reminder_card_row'>
                                        <span className='reminder_card_row_phone'>
                                            حالة الحجز
                                            :
                                            {reserve.status}
                                        </span>
                                        <span className='reminder_card_row_name'>
                                            نوع الحجز
                                            :
                                            {reserve.rent_type}
                                        </span>
                                        <span className='reminder_card_row_room'>
                                            {reserve.room_id}
                                            : غرفة رقم
                                        </span>
                                        <span className='reminder_card_row_reseravation'>
                                            {reserve.id}
                                            : حجز رقم
                                        </span>
                                    </div>
                                </div>
                            </Link>
                        )
                    })
                    }
                </div>

            </div>
            <div className='graphFirstLine'>
                <div className='roomCleanService'>
                    <p> حالة خدمة الغرف </p>
                    <section className='graphPart'>
                        <span className='largeScreen'>
                            <Chart
                                chartType='PieChart'
                                width='100%'
                                height='400px'
                                data={dataService}
                                options={optionsService}
                            />
                        </span>
                        <span className='smallScreen'>
                            <Chart
                                chartType='PieChart'
                                width='100%'
                                height='250px'
                                data={dataService}
                                options={optionsService}
                            />
                        </span>

                    </section>
                </div>

                <div className='roomCleanService'>
                    <p> اشغال انواع الوحدات </p>
                    <section className='graphPart'>
                        <span className='largeScreen'>

                            {
                                department?.map((dep, idx) => {
                                    return (
                                        <div className='myGraphContainer' key={idx}>
                                            <section className='myGraphContainer_Section'>
                                                <span className='percentageInGraph'> {parseInt((dep.takenRoom / dep.rooms) * 100)}%</span>
                                                <span className='fillPercentageContainer'>
                                                    <span style={{ width: `${parseInt((dep.takenRoom / dep.rooms) * 100)}%` }} className='fillPercentageWater'></span>
                                                </span>
                                            </section>
                                            <section className='myGraphContainer_Section'>
                                                <span className='percentageInGraph'> ({dep.rooms}) </span>
                                                <span>{dep.name}</span>
                                            </section>

                                        </div>
                                    )
                                })
                            }
                        </span>

                        <span className='smallScreen'>

                            {
                                department?.map((dep, idx) => {
                                    return (
                                        <div className='myGraphContainer' key={idx}>
                                            <section className='myGraphContainer_Section'>
                                                <span className='percentageInGraph'> {parseInt((dep.takenRoom / dep.rooms) * 100)}%</span>
                                                <span className='fillPercentageContainer'>
                                                    <span style={{ width: `${parseInt((dep.takenRoom / dep.rooms) * 100)}%` }} className='fillPercentageWater'></span>
                                                </span>
                                            </section>
                                            <section className='myGraphContainer_Section'>
                                                <span className='percentageInGraph'> ({dep.rooms}) </span>
                                                <span>{dep.name}</span>
                                            </section>

                                        </div>
                                    )
                                })
                            }
                        </span>
                    </section>
                </div>

                <div className='roomCleanService'>
                    <p className='roomsStatusMainPage'>
                        <span> حالة الوحدات اليوم {`${new Date().toLocaleDateString()}`}  </span>
                        <span className='roomsStatusMainPage_Span'> (
                            <span> وحدة </span>
                            <span>{rooms.length}</span>
                            ) </span>
                    </p>
                    <section className='graphPart'>
                        <span className='largeScreen'>

                            <Chart
                                chartType='PieChart'
                                data={data}
                                options={options}
                                width={'100%'}
                                height={'400px'}
                            />
                        </span>
                        <span className='smallScreen'>

                            <Chart
                                chartType='PieChart'
                                data={data}
                                options={options}
                                width={'100%'}
                                height={'250px'}
                            />
                        </span>
                    </section>
                </div>
            </div>
            {/* <h1>
                Home Page
            </h1> */}

            <div className='weeklyStatusPart'>

                <span className='largeScreenBar'>
                    <Chart
                        chartType='Bar'
                        width='100%'
                        height='100%'
                        data={dataWeekly}
                        options={optionsWeekly}
                    />
                </span>
                <span className='smallScreenBar'>
                    <Chart
                        chartType='Bar'
                        width='450px'
                        height='450px'
                        data={dataWeekly}
                        options={optionsWeekly}
                    />
                </span>

            </div>


        </div>
    )
}



// <svg viewBox='0 0 400 400'>
/* <VictoryPie
colorScale={['#ff0000a6', '#00bb00ab', '#0000ffab']}
standalone={false}
width={400} height={400}
data={[
    { x: 1, y: 120 }, { x: 2, y: 150 }, { x: 3, y: 75 }
]}
innerRadius={68} labelRadius={100}
style={{ labels: { fontSize: 20, fill: 'white' } }}
/>
<VictoryLabel
textAnchor='middle'
style={{ fontSize: 20 }}
x={200} y={200}
text='Pie!'
/>
</svg> */
