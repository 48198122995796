import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import cookies from 'react-cookies';
import { LoginContext } from '../../../context/login';
import swal from 'sweetalert2';
import axios from 'axios';
import useForm from '../../../hooks/form';
import './Form.css';
import './AddRoom.css'

export default function AddDepartment(props) {
    // To load the token from cookies
    const qs = new URLSearchParams(window.location.search);
    const cookieToken = cookies.load('token');
    const token = qs.get('token') || cookieToken || null;

    const login = useContext(LoginContext);

    const { handleChange, handleSubmit } = useForm(addDepartment);

    async function addDepartment(info) {
        props.setLoadingFunc(true);
        const data = {
            name: info.name,
            type: info.type,
        }

        await axios.post(`${login.API}/addNewUnit`, data
            , { headers: { 'Authorization': `Basic ${token}` } })
            .then((res) => {
                // new activity
                axios.post(`${login.API}/newActivity`, {
                    employee_id: login.user.id,
                    description: `اضافة قسم - ${res.data.name}`,
                    type: "الغرف والاقسام",
                } , { headers: { 'Authorization': `Basic ${token}` }})
                // alert

                new swal({
                    title: "تم إضافة القسم بنجاح  ",
                    text: "Department Added Successfully",
                    icon: "success",
                    button: "okay!",
                });
            })
            .catch((err) => {
                // alert
                new swal({
                    title: "حدث خطأ ما  اثناء الاضافة",
                    text: "something went wrong",
                    icon: "warning",
                    button: "try again!",
                });

            });
        props.setLoadingFunc(false);

    }

    return (
        <div>


            <div className='navigator'>
                <p> <Link className='linkContainer' to='/'>الرئيسية</Link> / <Link className='linkContainer' to='/unit'>الوحدات</Link> / <Link className='linkContainer' to='/unit/departments'>اقسام الوحدات</Link> / اضافة قسم  </p>

            </div>

            <div className='formContainer'>
                <Form className="Form" onSubmit={handleSubmit} >

                    <Form.Label htmlFor="type" className='headerElement ' > معلومات القسم</Form.Label>

                    <Form.Group className="mb-3 departmentRowElement" >
                        <div className='FormElement'>
                            <select onChange={handleChange} id="type_id" name='type' dusk="type_id" className="w-full form-control form-select">
                                <option value="">إختر خيار</option>
                                <option value="غرفة نوم فردية">غرفة نوم فردية </option>
                                <option value="غرفة نوم زوجية">غرفة نوم زوجية </option>
                                <option value="غرفة نوم مع صالة">غرفة نوم مع صالة</option>
                            </select>
                        </div>
                        <Form.Label className='FormElement' htmlFor="email"> نوع القسم</Form.Label>

                    </Form.Group>
                    <Form.Group className="mb-3 departmentRowElement" >
                        <Form.Control required onChange={handleChange} className='FormElement' name="name" type="text" placeholder="ادخل الاسم" />
                        <Form.Label htmlFor="name" className='FormElement' > الاسم</Form.Label>
                    </Form.Group>





                    <button className='addUnitBtn newDepartmentBtn' type="submit" > إضافة القسم </button>


                </Form>
            </div>

        </div>
    )
}
