import axios from 'axios';
import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import superagent from "superagent";
import Swal from 'sweetalert2';
import { LoginContext } from '../../context/login';
import './AddNewEmployee.css';
import cookies from 'react-cookies';



export default function AddNewEmployee() {
    // To load the token from cookies
    const qs = new URLSearchParams(window.location.search);
    const cookieToken = cookies.load('token');
    const token = qs.get('token') || cookieToken || null;

    const login = useContext(LoginContext);

    const handelNewEmployee = (event) => {
        // submit to add new employee to our database
        event.preventDefault();

        let userData = {
            name: event.target.name.value,
            id: event.target.id.value,
            phone_number: event.target.phone.value,
            gender: event.target.gender.value,
            email: event.target.email.value.toLowerCase(),
            password: event.target.pass.value,
            role: event.target.role.value,
            onlineStatus: false
        }
        if (userData.password !== event.target.checkPass.value) {
            // if passwords are not the same
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Passwords are not the same !!',
            })
        } else {
            // Send data to the database
            axios.post(`${login.API}/signup`, userData, {
                headers: {
                    "Authorization": `Basic ${token}`
                }
            }).then(() => {
                    // new activity
                    axios.post(`${login.API}/newActivity`, {
                        employee_id: login.user.id,
                        description: `اظافة موظف جديد - ${userData.name}`,
                        type: "الموظفين",
                    }, { headers: { 'Authorization': `Bearer ${token}` } });

                    Swal.fire(
                        'تم إضافة الموظف بنجاح',
                        '',
                        'success'
                    )
                })
                .catch(err => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.text,
                    })
                })
        }
    }

    // Form of the new employee
    return (
        <div className='profileContainer'>
            <div className='navigator'>
                <p>

                    <Link className='linkContainer' to='/'>
                        الرئيسية
                    </Link>
                    /
                    <Link className='linkContainer' to='/profile'>
                        حسابي
                    </Link>
                    /
                    إضافة موظف جديد

                </p>
            </div>

            <div>
                <h4 className='newEmployeeTag'> إضافة موظف جديد </h4>
            </div>
            <Form onSubmit={handelNewEmployee}>
                <div className='infoContainer'>

                    <div className='row'>
                        <input required className='colLeft' name="id" placeholder='الرقم التسلسلي للموظف #' required type='number' />
                        <p className='colRight'> الرقم التسلسلي للموظف # </p>
                    </div>
                    <div className='row'>
                        <input required className='colLeft' name="name" placeholder='اسم الموظف' required type='text' />
                        <p className='colRight'> اسم الموظف </p>
                    </div>
                    <div className='row'>
                        <input required className='colLeft' name="email" placeholder='البريد الالكتروني' required type='email' />
                        <p className='colRight'> البريد الالكتروني </p>
                    </div>
                    <div className='row'>
                        <input required className='colLeft' name="phone" placeholder='رقم الهاتف' required type='number' />
                        <p className='colRight'> رقم الهاتف </p>
                    </div>
                    <div className='row'>
                        <input required className='colLeft' name="pass" placeholder='كلمة المرور' required type='password' />
                        <p className='colRight'> كلمة المرور </p>
                    </div>
                    <div className='row'>
                        <input required className='colLeft' name="checkPass" placeholder='تأكيد كلمة المرور' required type='password' />
                        <p className='colRight'> تأكيد كلمة المرور </p>
                    </div>
                    <div className='row'>
                        <select className='colLeft' name="gender" id="gender">
                            <option value="male"> ذكر </option>
                            <option value="female"> انثى </option>
                        </select>
                        <p className='colRight'> جنس الموظف </p>
                    </div>
                    <div className='row'>
                        <select className='colLeft' name="role" id="role">
                            <option value="manager"> مدير </option>
                            <option value="reciptionist"> موظف استقبال </option>
                        </select>
                        <p className='colRight'> الصلاحيات </p>
                    </div>
                </div>

                <div className='submitNewEmployee'>
                    <button type='submit'>
                        حفظ بيانات الموظف
                    </button>
                </div>
            </Form>
        </div>
    )
}
