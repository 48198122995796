import React, { useEffect, useContext, useState } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import useForm from '../../../hooks/form';
import cookies from 'react-cookies';
import axios from 'axios';
import swal from 'sweetalert2';
import { LoginContext } from '../../../context/login';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export default function AddPrice(props) {
    // To load the token from cookies
    const qs = new URLSearchParams(window.location.search);
    const cookieToken = cookies.load('token');
    const token = qs.get('token') || cookieToken || null;

    const login = useContext(LoginContext);

    const { handleChange, handleSubmit } = useForm(addNewPrice);

    const [days, setDays] = useState([
        { day: "السبت", id: 0 },
        { day: "الاحد", id: 1 },
        { day: "الاثنين", id: 2 },
        { day: "الثلاثاء", id: 3 },
        { day: "الاربعاء", id: 4 },
        { day: "الخميس", id: 5 },
        { day: "الجمعة", id: 6 },
    ]);
    function addNewPrice(info) {

        let weeklyPrice = [];
        // "saturdayPrice"mondayPrice"sundayPrice"wensdayPrice"tusedayPrice"fridayPrice"thersdayPrice
        for (let i = 0; i < days.length; i++) {
            let myDailyPrice = document.getElementById(`day_${i}`).value;
            if (myDailyPrice) {
                weeklyPrice.push(myDailyPrice);
            } else {
                weeklyPrice.push(0);
            }
        }

        info.price = weeklyPrice;
        // addNewPrice
        // link to set price in the database
        props.setLoadingFunc(true);
        if (!props.defaultPrice) {
            axios.post(`${login.API}/addNewPrice`, info, { headers: { 'Authorization': `Basic ${token}` } }).then((res) => {
                props.handleClose();
                props.setLoadingFunc(false);

                // new activity
                axios.post(`${login.API}/newActivity`, {
                    employee_id: login.user.id,
                    description: `انشاء سعر خاص - ${res.data.name}`,
                    type: "العروض والاسعار",
                }, { headers: { 'Authorization': `Basic ${token}` } })


                props.addOfferandPrice("price", res.data);
                swal.fire({
                    title: 'تم العملية',
                    text: 'تم اضافة السعر بنجاح',
                    icon: 'success',
                    confirmButtonText: 'تم'
                });
            }).catch((err) => {
                props.setLoadingFunc(false);

                swal.fire({
                    title: 'حدث خطأ',
                    text: 'حدث خطأ ما اثناء الاضافة',
                    icon: 'warning',
                    confirmButtonText: 'حاول مرة اخرى'
                });
            });

        } else {

            // price"offer
            axios.post(`${login.API}/updateOfferAndPrice`, { info, id: props.defaultPrice.id, type: "price" }, { headers: { 'Authorization': `Basic ${token}` } })
                .then((res) => {
                    props.handleClose();
                    props.setLoadingFunc(false);

                    let resultData = res.data;
                    resultData.sort((a, b) => {
                        return a.id - b.id;
                    });

                    props.handelUpdate("price", resultData);

                    // new activity
                    let name;
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].id === props.defaultPrice.id) {
                            name = res.data[i].name;
                            break;
                        }
                    }
                    axios.post(`${login.API}/newActivity`, {
                        employee_id: login.user.id,
                        description: `تحديث على السعر - ${name}`,
                        type: "العروض والاسعار",
                    }, { headers: { 'Authorization': `Basic ${token}` } })

                    swal.fire({
                        title: 'تمت العملية',
                        text: 'تم تعديل السعر بنجاح',
                        icon: 'success',
                        confirmButtonText: 'تم'
                    });
                }).catch((err) => {
                    props.setLoadingFunc(false);

                    swal.fire({
                        title: 'حدث خطأ',
                        text: 'حدث خطأ ما اثناء التعديل',
                        icon: 'warning',
                        confirmButtonText: 'حاول مرة اخرى'
                    });
                });


        }

        // props.handleClose();
    }

    useEffect(() => {
        if (props.defaultPrice) {
            for (let i = 0; i < days.length; i++) {
                let myDailyPrice = document.getElementById(`day_${i}`);

                if (props.defaultPrice.price[i] > 0) {
                    myDailyPrice.value = props.defaultPrice.price[i];
                }
            }

            // department
            let temp = props.defaultPrice.department;
            let type = document.getElementById("department");

            for (var i = 0; i < type.options.length; i++) {
                if (type.options[i].value == temp) {
                    type.options[i].defaultSelected = true;
                    break;
                }
            }
            // startDate"endDate
            let startDate = document.getElementById("startDate");
            let endDate = document.getElementById("endDate");

            startDate.defaultValue = new Date(props.defaultPrice.startDate).toISOString().slice(0, 10);;
            endDate.defaultValue = new Date(props.defaultPrice.endDate).toISOString().slice(0, 10);;


        }
    }, [props.defaultPrice])
    return (
        <div>
            <Modal show={props.show} onHide={props.handleClose}>

                <Modal.Header>
                    <button className='closeModal' onClick={() => props.handleClose()}>
                        <CloseRoundedIcon />
                    </button>
                    <p>
                        {props.defaultPrice ? "تعديل السعر الخاص" : "إضافة سعر خاص"}

                    </p>
                </Modal.Header>
                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        {/* offer name */}

                        <div className='modalRow'>
                            <div>
                                {/* offer type */}
                                <p className='headOfModalInputs'> قسم الوحدة </p>
                                <select onChange={handleChange} className='inputModalForm' name="department" id="department">
                                    <option> اختر قسم </option>
                                    {
                                        props.departments.map((item, idx) => {
                                            return (
                                                <option key={idx} value={item.name}> {item.name} </option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div>
                                <p className='headOfModalInputs'> اسم الخصم </p>
                                <input required defaultValue={props.defaultPrice?.name} onChange={handleChange} className='inputModalForm' type='text' placeholder='اسم الخصم' name="name" />
                            </div>
                        </div>


                        {/* offer days */}
                        <p className='headOfModalInputs'> اسعار الايام  </p>
                        <div className='priceModal'>

                            {
                                days.map((day, idx) => {
                                    return (
                                        <div key={idx} className='priceModalPeice'>
                                            <p className='dayLableForPrice'> {day.day} </p>
                                            <input required className='inputModalForm' type='number' placeholder='اسعار اليوم' id={`day_${day.id}`} />
                                        </div>
                                    )
                                })
                            }

                        </div>

                        <p className='headOfModalInputs'> السعر الاسبوعي  </p>
                        <div className='priceModal'>
                            <input required onChange={handleChange} className='inputModalForm' type='number' placeholder='السعر الاسبوعي' name={`weekly_price`} />
                        </div>

                        <p className='headOfModalInputs'> السعر الشهري  </p>
                        <div className='priceModal'>
                            <input required onChange={handleChange} className='inputModalForm' type='number' placeholder='السعر الشهري' name={`monthely_price`} />
                        </div>

                        <p className='headOfModalInputs'> السعر الفصلي  </p>
                        <div className='priceModal'>
                            <input required onChange={handleChange} className='inputModalForm' type='number' placeholder='السعر الفصلي' name={`seasonal_price`} />
                        </div>

                        <p className='headOfModalInputs'> السعر السنوي  </p>
                        <div className='priceModal'>
                            <input required onChange={handleChange} className='inputModalForm' type='number' placeholder='السعر السنوي' name={`yearly_price`} />
                        </div>

                        {/* offer date */}
                        <p className='headOfModalInputs'> تاريخ بداية ونهاية السعر الخاص  </p>
                        <div className='departmentDateModal'>
                            <div className='departmentPeice'>
                                <label> تاريخ النهاية </label>
                                <input required onChange={handleChange} name="endDate" id="endDate" className='inputModalForm' type="date" />
                            </div>
                            <div className='departmentPeice'>
                                <label> تاريخ البداية </label>
                                <input required onChange={handleChange} name="startDate" id="startDate" className='inputModalForm' type="date" />
                            </div>
                        </div>

                        {/* submit button */}
                        <div className='submitNewEmployee'>
                            <button type='submit'> حفظ العرض </button>
                        </div>
                    </Modal.Body>

                </Form>
            </Modal>
        </div>
    );
}
