import React, { useContext, useState } from 'react';
import { Form, Button, Modal } from "react-bootstrap";
import { LoginContext } from '../context/login';
import useForm from '../hooks/form';
import reception from "../image/reception.png"
import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import "./login.css"
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';


export default function Login(props) {

    const login = useContext(LoginContext);

    const { handleChange, handleSubmit } = useForm(handleLogin);

    async function handleLogin(user) {

        props.setLoadingFunc(true)
            .then(() => {
                login.login(user.email, user.password)
                    .then(() => {
                        props.setLoadingFunc(false);
                    });

            });

    }
    const [show, setShow] = useState(false);
    const setHideForgetFunc = () => {
        setShow(!show);
    }

    const labelSelctedLigIn = (type) => {
        if (type == "forgetPass") {
            setHideForgetFunc();
        } else {
            const input = document.getElementById(`${type}`);   
            input.focus();
            input.select();
        }
    }

    const alert = (type) => {
        if (type == "pass") {
            Swal.fire(
                'تمت العملية بنجاح',
                'تم ارسال بياناتك الى المدير المسؤول، يمكنك التواصل معه ليزودك بكل معلوماتك',
                'success'
            )
        } else if(type == "id") {
            Swal.fire({
                icon: 'error',
                title: '... خطأ',
                text: 'الرقم التسلسلي غير صحيح، يرجى التأكد من صحته والمحاولة مرة أخرى',
            })
        }else{
            Swal.fire({
                icon: 'error',
                title: '... خطأ',
                text: 'البريد الالكتروني غير صحيح، يرجى التأكد من صحته والمحاولة مرة أخرى',
            })
        }
    }
    const returnPass = (id, email) => {

        props.setLoadingFunc(true).then(async () => {
            let result = await axios.post(`${login.API}/forgetPassword`, { id, email });
            if (result.data == "passed") {
                alert("pass")
                setHideForgetFunc();
            } else {
                // id Error
                alert(`${result.data.split(" ")[0]}`)
            }
            props.setLoadingFunc(false);
        })
    }
    const returnPassForm = e => {
        e.preventDefault();
        // id_Forget
        let email = e.target.email_Forget.value;
        let id = e.target.id_Forget.value;
        returnPass(id, email);
    }

    return (
        <div className='logInContainer'>

            <section className='leftSection'>
                <h1 className='headLogIn'> Log In </h1>

                <Form className="logInForm" onSubmit={handleSubmit}>
                    <div className='logInForm_Div'>
                        <label onClick={() => labelSelctedLigIn("email")} className='loginFormElement_New' htmlFor="email">Email address</label>
                        <input required className='loginFormElement_New_Input' name="email" onChange={handleChange} id="email" type="email" placeholder="Enter email" required />

                    </div>

                    <div className='logInForm_Div'>
                        <label onClick={() => labelSelctedLigIn("pass")} className='loginFormElement_New' htmlFor="password">Password</label>
                        <input required className='loginFormElement_New_Input' name="password" onChange={handleChange} id="pass" type="password" placeholder="Password" required />
                    </div>

                    <Button className='loginFormElement' variant="primary" type="submit">
                        Log In
                    </Button>

                    <p onClick={() => labelSelctedLigIn("forgetPass")} className='loginFormElement_New forgetPass'> Forget Password   ... </p>
                </Form>
            </section>

            <section className='leftSection'>
                <img alt='reception' src={reception} />
            </section>

            <Modal show={show} onHide={setHideForgetFunc}>
                <Modal.Header >
                    <button className='closeModal' onClick={() => setHideForgetFunc()}> <CloseRoundedIcon /> </button>
                    <Modal.Title>Forget Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='loginModalEle'>
                        أدخل الرقم التسلسلي الخاص بك
                    </p>
                    <form onSubmit={returnPassForm}>
                        <div className='logInForm_Div'>
                            <input required required className='loginFormElement_New_Input loginModalEle' name="id_Forget" id="id_Forget" type="text" placeholder="الرقم التسلسلي" />
                            <label onClick={() => labelSelctedLigIn("id_Forget")} className='loginModalEle loginFormElement_New' htmlFor="id_Forget">الرقم التسلسلي</label>
                        </div>
                        <div className='logInForm_Div'>
                            <input required required className='loginFormElement_New_Input loginModalEle' name="email_Forget" id="email_Forget" type="email" placeholder="البريد الالكتروني" />
                            <label onClick={() => labelSelctedLigIn("email_Forget")} className='loginModalEle loginFormElement_New' htmlFor="email_Forget">البريد الالكتروني</label>
                        </div>
                        
                        <div className='returnPassById_Div'>
                            <button type='submi' className='returnPassById'>
                                إستعادة كلمة المرور
                            </button>
                        </div>

                    </form>

                    <p className='loginModalEle'>
                        إذا فقدت الرقم التسلسلي الخاص بك ولا تستطيع العثور عليه فانقر الزر في الأسفل
                    </p>

                    <div className='returnPassById_Div'>
                        <p onClick={() => returnPass("all")} className='returnPassById returnPassById_2'>
                            إستعادة كامل المعلومات الخاصة بك
                        </p>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
