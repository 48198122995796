import React, { useEffect, useState, useContext, useRef } from 'react';
import { LoginContext } from '../../context/login';
import { Table, Modal } from 'react-bootstrap';
import axios from 'axios';
import { useReactToPrint } from 'react-to-print';
import QRCode from 'qrcode.react';
import cookies from 'react-cookies';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PrintIcon from '@mui/icons-material/Print';

export default function Invoice(props) {
    // To load the token from cookies
    const qs = new URLSearchParams(window.location.search);
    const cookieToken = cookies.load('token');
    const token = qs.get('token') || cookieToken || null;

    const [invoice, setInvoices] = useState([]);
    const [showInvoice, setShowInvoice] = useState(false);
    useEffect(async () => {

        await axios.post(`${process.env.REACT_APP_BACK}/getInvoiceById`,
            { id: window.location.href.split("=")[1] },
            { headers: { 'Authorization': `Basic ${token}` } })
            .then(res => {
                setInvoices(res.data);
                setShowInvoice(true);
            });

        let hotelData = cookies.load('hotelData');
        if (hotelData) {
            props.hotel = hotelData;
        }
    }, []);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleClose = () => {
        setShowInvoice(false);
    }

    return (
        <div>
            {invoice &&
                <Modal show={true} onHide={handleClose} className='invoice_modal'>
                    <Modal.Header className='contarct_modal_header'>
                        <CloseRoundedIcon onClick={handleClose} />
                        <Modal.Title>
                            فاتورة رقم #{invoice.id}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Header className='contarct_modal_print'>
                        <i onClick={handlePrint}>
                            <PrintIcon />
                        </i>
                    </Modal.Header>

                    <Modal.Body className='contarct_modal_body'>
                        <div ref={componentRef} className='invoice_modal_body_container'>

                            <div className='invoice_modal_body_top'>
                                <span>رقم الحجز #{invoice.id}</span>
                                <div>
                                    <h3>Fandaqa</h3>
                                    <h5>Irbid</h5>
                                </div>
                                <span>{invoice.date} : التاريخ  </span>
                            </div>

                            <div className='contarct_modal_body_middle'>
                                <h3> نموذج الفاتورة </h3>
                            </div>

                            <div className='invoice_modal_body_info'>
                                <div>
                                    <span className='invoice_modal_body_info_span'>
                                        <span>{invoice.checkIn}</span> <span> : بداية الحجز</span>
                                    </span>

                                    <span className='invoice_modal_body_info_span'>
                                        <span>{invoice.guest_name}</span> <span> : الإسم</span>
                                    </span>

                                    <span className='invoice_modal_body_info_span'>
                                        <span>{invoice.date}</span> <span> : التاريخ</span>
                                    </span>
                                </div>

                                <div>
                                    <span className='invoice_modal_body_info_span'>
                                        <span>{invoice.checkOut}</span> <span> : نهاية الحجز</span>
                                    </span>

                                    <span className='invoice_modal_body_info_span'>
                                        <span>{invoice.guest_phone}</span> <span> : رقم الهاتف</span>
                                    </span>

                                    <span className='invoice_modal_body_info_span'>
                                        <span>{invoice.id}</span> <span> : رقم الحجز</span>
                                    </span>
                                </div>

                                <div>
                                    <span className='invoice_modal_body_info_span'>
                                        <span>{(new Date(invoice.checkOut).getTime() - new Date(invoice.checkIn).getTime()) / (1000 * 60 * 60 * 24)}</span> <span>: ليالي الحجز</span>
                                    </span>

                                    <span className='invoice_modal_body_info_span'>
                                        <span>{invoice.id}</span> <span> : رقم العقد</span>
                                    </span>

                                    <span className='invoice_modal_body_info_span'>
                                        <span>{invoice.room_id}</span> <span> : رقم الوحدة</span>
                                    </span>
                                </div>

                            </div>

                            <Table className='invoice_table'>

                                <tbody>
                                    <tr>
                                        <td>المجموع</td>
                                        <td>القيمة المضافة</td>
                                        <td>سعر الخدمات</td>
                                        <td>سعر الحجز</td>
                                        <td>عدد الليالي</td>
                                        <td>البيان</td>
                                        <td>#</td>
                                    </tr>

                                    <tr>
                                        <td>{invoice.total_price}</td>
                                        <td>0</td>
                                        <td>{invoice.services_price}</td>
                                        <td>{invoice.price}</td>
                                        <td>   {(new Date(invoice.checkOut).getTime() - new Date(invoice.checkIn).getTime()) / (1000 * 60 * 60 * 24)}   </td>
                                        <td id='title'>
                                            <span>
                                                {invoice.checkOut}
                                            </span>
                                            <span>
                                                :  إلى
                                            </span>
                                            <span>
                                                {invoice.checkIn}
                                            </span>
                                            <span>
                                                : من
                                            </span>
                                            <span>
                                                - {invoice.room_id}
                                            </span>


                                            <span>
                                                {invoice.room_name}
                                            </span>
                                            <span>
                                                -
                                            </span>

                                            <span>
                                                قيمة حجز
                                            </span>

                                        </td>
                                        <td>1</td>
                                    </tr>


                                </tbody>
                            </Table>


                            <div className='invoice_modal_body_bottom'>
                                <div>
                                    <ul>
                                        <li>
                                            <span>{invoice.price}</span>
                                            <span>سعر الحجز</span>
                                        </li>
                                        <li>
                                            <span>{invoice.services_price}</span>
                                            <span>سعر الخدمات</span>
                                        </li>
                                        <li>
                                            <span>0</span>
                                            <span>القيمة المضافة</span>
                                        </li>
                                        <li>
                                            <span>{invoice.total_price}</span>
                                            <span>اجمالي القيمة</span>
                                        </li>
                                        <li>
                                            <span>0</span>
                                            <span>المدفوع</span>
                                        </li>
                                    </ul>
                                </div>

                                <div>

                                    <QRCode value="https://hotel-app-legends.netlify.app/" />

                                </div>
                            </div>



                            <div className='contract_hotel_info'>
                                <span>Fandaqa</span>
                                <span>fandaqa@gmail.com</span>
                                <span>0777777777</span>
                            </div>


                        </div>
                    </Modal.Body>


                </Modal>}

        </div>
    );
}
