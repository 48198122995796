import React, { useEffect, useState } from "react";
import superagent from "superagent";
import base64 from 'base-64';
import jwt from 'jsonwebtoken';
import cookie from 'react-cookies';
import axios from "axios";
import { io } from "socket.io-client";
import Swal from "sweetalert2";


export const LoginContext = React.createContext();
const API = `${process.env.REACT_APP_BACK}`;

export default function LoginProvider(props) {
    const [loggedIn, setLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
    const [socket, setSocket] = useState();
    const [setting, setSetting] = useState();

    // initial render
    useEffect(() => {
        const qs = new URLSearchParams(window.location.search);
        const cookieToken = cookie.load('token');
        const socketFromCockies = cookie.load('socket');
        const token = qs.get('token') || cookieToken || null;

        validateJwToken(token);

    }, []);


    const login = async (email, password) => {
        let myMail = email.toLowerCase();

        let bufferObj = Buffer.from(`${myMail}:${password}`, "utf8");
        // Encode the Buffer as a base64 string
        let encodedUsernameAndPassword = bufferObj.toString("base64");

        try {
            const response = await superagent
                .post(`${API}/signin`)
                .set("authorization", `Basic ${encodedUsernameAndPassword}`);

            cookie.save('token', JSON.stringify(response.body.token));
            validateJwToken(response.body.token, response.body);

            let socketInfo = io.connect(API, { transports: ['websocket'] });

            setSocket(socketInfo);
            // socketInfo.on("test" , data=>{
            // })

            let currentdate = new Date();
            let datetime = (currentdate.toDateString()).split(' ')[0] + "  " + currentdate.getDate() + "/" + (currentdate.toLocaleDateString()).split('/')[0] + "/" + currentdate.getFullYear() + "  "
                + currentdate.toLocaleTimeString();

            // if(!response.body.email =="ibrahimkuderat@gmail.com") {
            //     socketInfo.emit('online', { user: response.body.name, id: response.body.id, email: response.body.email, loginTime: datetime });
            // }

            socketInfo.emit('online', { user: response.body.name, id: response.body.id, email: response.body.email.toLowerCase(), loginTime: datetime });

            // new activity
            let token = cookie.load('token');
            axios.post(`${API}/newActivity`, {
                employee_id: response.body.id,
                description: `تسجيل دخول الموظف`,
                employeeName: `${response.body.name}`,
                type: "تسجيل الدخول الموظف",
            }, { headers: { 'Authorization': `Basic ${token}` } })


            socketInfo.emit('getSetting', {});

            socketInfo.on('AllSettings', (data) => {
                setSetting(data);
            })
        } catch (error) {

            // alert('Invalid username or password');

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Invalid Email or Passwrod!',
            })
        }

    },

        validateJwToken = async (token, user) => {
            if (user) {
                // the First logged in
                cookie.save('token', user.token);
                let userData = jwt.decode(user.token);
                setLoginState(true, userData);
            }
            else {
                // the user is already logged in
                const cookieToken = cookie.load('token');
                if (cookieToken) {
                    let userData = jwt.decode(cookieToken);

                    setLoginState(true, userData);
                    let socketInfo = io.connect(API, { transports: ['websocket'] });
                    setSocket(socketInfo);

                    // socketInfo.on("test" , data=>{
                    // })

                    socketInfo.emit('getSetting', {});

                    socketInfo.on('AllSettings', (data) => {
                        setSetting(data);
                    })
                } else {
                    setLoginState(false, null);
                }
            }
        };

    const logOut = () => {
        let token = cookie.load('token');
        cookie.remove('token');
        setLoginState(false, null);
        let currentdate = new Date();
        let datetime = (currentdate.toDateString()).split(' ')[0] + "  " + currentdate.getDate() + "/" + (currentdate.toLocaleDateString()).split('/')[0] + "/" + currentdate.getFullYear() + "  "
            + currentdate.toLocaleTimeString();
        // new activity
        axios.post(`${API}/newActivity`, {
            employee_id: user.id,
            description: `تسجيل حروج الموظف`,
            employeeName: `${user.name}`,
            type: "تسجيل الخروج الموظف",
        }, { headers: { 'Authorization': `Basic ${token}` } })
        socket.emit('disconnected', { user: user.name, id: user.id, email: user.email.toLowerCase(), logoutTime: datetime });
    }



    const setLoginState = (loggedIn, user) => {

        setLoggedIn(loggedIn);
        setUser(user);

    }

    const state = {
        loggedIn,
        login,
        logOut,
        user,
        socket,
        setting,
        API,
    };

    return (
        <LoginContext.Provider value={state}>
            {props.children}
        </LoginContext.Provider>
    )
}
