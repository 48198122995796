import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Form } from 'react-bootstrap';
import { LoginContext } from '../../context/login';
// import { LoginContext } from '../../context/login';
import cookies from 'react-cookies';
import useForm from '../../hooks/form';
import axios from 'axios';
import Swal from 'sweetalert2';
// import {moveFile} from 'move-file';
import fs from "fs";

export default function Documents(props) {
    // To load the token from cookies
    const qs = new URLSearchParams(window.location.search);
    const cookieToken = cookies.load('token');
    const token = qs.get('token') || cookieToken || null;

    const login = useContext(LoginContext)
    const [defaultDocuments, setdefaultDocuments] = useState(null);

    useEffect(() => {
        props.setLoadingFunc(true)
            .then(async () => {
                await axios.get(`${login.API}/getDocuments`, { headers: { 'Authorization': `Basic ${token}` } })
                    .then(res => {
                        setdefaultDocuments(res.data[0])
                    })
                props.setLoadingFunc(false)
            })
    }, [])
    // getDocuments

    const showSweet = (info) => {
        if (info) {
            Swal.fire({
                icon: 'success',
                title: 'تمت العميلة',
                text: 'تم تعديل البيانات بنجاح',
            })
        } else {
            Swal.fire({
                icon: 'error',
                title: 'خطأ',
                text: '!! حدث خطأ أثناء عملية التعديل ',
            })
        }
    }
    const { handleChange, handleSubmit } = useForm(managerNames);

    async function managerNames(data) {
        // updateDocuments
        props.setLoadingFunc(true)
            .then(async () => {
                await axios.put(`${login.API}/updateDocuments`, { data }, { headers: { 'Authorization': `Basic ${token}` } })
                    .then(res => {
                        setdefaultDocuments(res.data[0])

                        // new activity
                        axios.post(`${login.API}/newActivity`, {
                            employee_id: login.user.id,
                            description: `تعديل بنود الوثائق`,
                            type: "الاعدادات",
                        }, { headers: { 'Authorization': `Basic ${token}` } })
                            .then(() => {
                                showSweet(true)
                                props.setLoadingFunc(false)

                            })
                    })
                    .catch(err => {
                        props.setLoadingFunc(false);
                        showSweet(false);
                    })
            })
    }

    return (
        <div>
            <div className='navigator'>
                <p> <Link className='linkContainer' to='/'> الرئيسية </Link>
                    /
                    <Link className='linkContainer' to='/settings'> الإعدادات </Link>
                    /
                    إعدادات الوثائق والمستندات
                </p>
            </div>

            <div>
                <h4 className='newEmployeeTag'> إعدادات الوثائق والمستندات </h4>
            </div>
            <Form onSubmit={handleSubmit} >
                <div className='infoContainer'>


                    <div className='row'>
                        <input required className='colLeft' onChange={handleChange} defaultValue={defaultDocuments?.contractName} name="contractName" placeholder='إسم مسؤول العقد' required type='text' />
                        <p className='colRight'> إسم المسؤول في عقد الإيجار </p>
                    </div>
                    {/* receiptName:expenseName:contractName */}
                    <div className='row'>
                        <input required className='colLeft' onChange={handleChange} defaultValue={defaultDocuments?.receiptName} name="receiptName" placeholder='إسم مسؤول سند قبض' required type='text' />
                        <p className='colRight'> إسم المسؤول في سند القبض </p>
                    </div>
                    <div className='row'>
                        <input required className='colLeft' onChange={handleChange} defaultValue={defaultDocuments?.expenseName} name="expenseName" placeholder='إسم مسؤول سند الصرف' required type='text' />
                        <p className='colRight'> إسم المسؤول في سند الصرف </p>
                    </div>
                    {/* <div className='row'>
                        <textarea className='hotelInput colLeft' placeholder='... ملاحظات العقد' name="address" required type='text' />
                        <p className='colRight'> ملاحظات عقد الإيجار </p>
                    </div>
                    <div className='row'>
                        <textarea className='hotelInput colLeft' placeholder='... ملاحظات سند القبض' name="address" required type='text' />
                        <p className='colRight'> ملاحظات سند القبض </p>
                    </div>
                    <div className='row'>
                        <textarea className='hotelInput colLeft' placeholder='... ملاحظات سند الصرف' name="address" required type='text' />
                        <p className='colRight'> ملاحظات سند الصرف </p>
                    </div>
                    <div className='row'>
                        <textarea className='hotelInput colLeft' placeholder='... ملاحظات الفاتورة' name="address" required type='text' />
                        <p className='colRight'> ملاحظات الفاتورة </p>
                    </div>
                     */}

                </div>

                <div className='submitNewEmployee'>
                    <button type='submit'>
                        حفظ البيانات
                    </button>
                </div>
            </Form>
        </div>
    )
}
