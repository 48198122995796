import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import useForm from '../../../hooks/form';
import { LoginContext } from '../../../context/login';
import swal from 'sweetalert2';
import cookies from 'react-cookies';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';



export default function AddOffer(props) {
    // To load the token from cookies
    const qs = new URLSearchParams(window.location.search);
    const cookieToken = cookies.load('token');
    const token = qs.get('token') || cookieToken || null;

    const login = useContext(LoginContext);

    const [allDays, setAllDays] = useState(false);
    const [days, setDays] = useState([
        { day: "السبت", id: 0 },
        { day: "الاحد", id: 1 },
        { day: "الاثنين", id: 2 },
        { day: "الثلاثاء", id: 3 },
        { day: "الاربعاء", id: 4 },
        { day: "الخميس", id: 5 },
        { day: "الجمعة", id: 6 },
    ]);
    const { handleChange, handleSubmit } = useForm(addOffer);

    useEffect(() => {
        if (props.defaultOffer) {
            for (let i = 0; i < props.departments.length; i++) {
                let myDepartments = document.getElementById(`departments-${i}`);
                if (props.defaultOffer.departments.includes(myDepartments.value)) {
                    myDepartments.checked = true;
                }
            }

            for (let i = 0; i < days.length; i++) {
                let myDays = document.getElementById(`days-${i}`);
                if (props.defaultOffer.days.includes(myDays.value)) {
                    myDays.checked = true;
                }
            }
            checkIfAllDays();

            // startDate"endDate
            let startDate = document.getElementById('startDate');
            let endDate = document.getElementById('endDate');
            let type = document.getElementById('type');

            startDate.defaultValue = new Date(props.defaultOffer.startDate).toISOString().slice(0, 10);
            endDate.defaultValue = new Date(props.defaultOffer.endDate).toISOString().slice(0, 10);

            let temp = props.defaultOffer.type;
            // type.options[j];
            for (var i = 0; i < type.options.length; i++) {
                if (type.options[i].value == temp) {
                    type.options[i].defaultSelected = true;
                    break;
                }
            }
        }
    }, [props.defaultOffer])

    function addOffer(info) {

        let departmentsArr = [];
        for (let i = 0; i < props.departments.length; i++) {
            let myDepartments = document.getElementById(`departments-${i}`);
            if (myDepartments.checked) {
                departmentsArr.push(myDepartments.value);
            }
        }

        let daysArr = [];
        for (let i = 0; i < days.length; i++) {
            let myDays = document.getElementById(`days-${i}`);
            if (myDays.checked) {
                daysArr.push(myDays.value);
            }
        }

        info.days = daysArr;
        info.departments = departmentsArr;

        // link with database to add the offer
        props.setLoadingFunc(true);
        if (!props.defaultOffer) {
            axios.post(`${login.API}/addNewOffer`, info ,{ headers: { 'Authorization': `Basic ${token}` }}).then((res) => {

                // new activity
                axios.post(`${login.API}/newActivity`, {
                    employee_id: login.user.id,
                    description: `انشاء عرض جديد - ${res.data.name}`,
                    type: "العروض والاسعار",
                },{ headers: { 'Authorization': `Basic ${token}` }})

                props.addOfferandPrice("offer", res.data)

                props.handleClose();

                props.setLoadingFunc(false);
                swal.fire({
                    title: 'تم بنجاح',
                    text: 'تم اضافة العرض بنجاح',
                    icon: 'success',
                    confirmButtonText: 'تم'
                });
            }).catch((err) => {

                props.setLoadingFunc(false);

                swal.fire({
                    title: 'حدث خطأ',
                    text: 'حدث خطأ ما اثناء الاضافة',
                    icon: 'warning',
                    confirmButtonText: 'حاول مرة اخرى'
                });
            })
        } else {
         
            // price"offer
            axios.post(`${login.API}/updateOfferAndPrice`, { info, id: props.defaultOffer.id, type: "offer" },{ headers: { 'Authorization': `Basic ${token}` }})
                .then((res) => {
                    props.handleClose();
                    props.setLoadingFunc(false);

                    // new activity
                    let name;
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].id === props.defaultOffer.id) {
                            name = res.data[i].name;
                            break;
                        }
                    }
                    axios.post(`${login.API}/newActivity`, {
                        employee_id: login.user.id,
                        description: `تحديث على العرض - ${name}`,
                        type: "العروض والاسعار",
                    },{ headers: { 'Authorization': `Basic ${token}` }})

                    let resultData = res.data;
                    resultData.sort((a, b) => {
                        return a.id - b.id;
                    });

                    props.handelUpdate("offer", resultData);
                    swal.fire({
                        title: 'تمت العملية',
                        text: 'تم تعديل العرض بنجاح',
                        icon: 'success',
                        confirmButtonText: 'تم'
                    });
                }).catch((err) => {
                    props.setLoadingFunc(false);
                    swal.fire({
                        title: 'حدث خطأ',
                        text: 'حدث خطأ ما اثناء التعديل',
                        icon: 'warning',
                        confirmButtonText: 'حاول مرة اخرى'
                    });
                })

        }


    }

    // select all days
    function selectAllDays() {
        let myCheck = !allDays;
        if (myCheck) {
            for (let i = 0; i < days.length; i++) {
                let myDays = document.getElementById(`days-${i}`).checked = true;
            }
        } else {
            for (let i = 0; i < days.length; i++) {
                let myDays = document.getElementById(`days-${i}`).checked = false;
            }
        }
        setAllDays(myCheck)

    }
    function selectAllDaysByLabel() {
        let days_3000 = document.getElementById('days_3000');
        days_3000.checked = !days_3000.checked;
        selectAllDays();
    }

    function checkIfAllDays() {
        let myCheck = true
        for (let i = 0; i < days.length; i++) {
            let myDays = document.getElementById(`days-${i}`);
            if (!myDays.checked) {
                myCheck = false;
            }
        }
        if (myCheck) {
            // setAllDays
            let setAllDays = document.getElementById('days_3000').checked = true;
        } else {
            let setAllDays = document.getElementById('days_3000').checked = false;
        }
        setAllDays(myCheck)
    }
    function selectNewDepartmentByLabel(id) {
        let myCheck = document.getElementById(`departments-${id}`).checked;
        if (myCheck) {
            let setAllDays = document.getElementById(`departments-${id}`).checked = false;
        } else {
            let setAllDays = document.getElementById(`departments-${id}`).checked = true;
        }
    }
    function selectNewDayByLabel(idx) {
        let myCheck = document.getElementById(`days-${idx}`).checked;
        if (myCheck) {
            let setAllDays = document.getElementById(`days-${idx}`).checked = false;
        } else {
            let setAllDays = document.getElementById(`days-${idx}`).checked = true;
        }
        checkIfAllDays();
    }


    return (
        <div>
            <Modal show={props.show} onHide={props.handleClose}>

                <Modal.Header>
                    <button className='closeModal' onClick={() => props.handleClose()}> 
                    <CloseRoundedIcon />
                    </button>
                    <p>
                        {props.defaultOffer ? "تعديل العرض" : "اضافة عرض جديد"}
                    </p>
                </Modal.Header>
                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        {/* offer name */}
                        <p className='headOfModalInputs'> اسم العرض </p>
                        <input required defaultValue={props.defaultOffer?.name} onChange={handleChange} className='inputModalForm' type='text' placeholder='اسم العرض' name="name" />

                        <div className='modalRow'>
                            <div>
                                {/* offer type */}
                                <p className='headOfModalInputs'> قيمة الخصم  </p>
                                <input required className='inputModalForm' type='text' placeholder='قيمة العرض' onChange={handleChange} defaultValue={props.defaultOffer?.value} name="value" />
                            </div>
                            <div>
                                <p className='headOfModalInputs'> نوع الخصم </p>
                                <select onChange={handleChange} className='inputModalForm' name="type" id="type">
                                    <option> اختر نوع الخصم </option>
                                    <option value="نسبة"> نسبة </option>
                                    <option value="سعر"> سعر (دينار) </option>
                                </select>
                            </div>
                        </div>

                        {/* offer departments */}
                        <p className='headOfModalInputs'> اقسام الوحدات المشموله في العرض </p>
                        <div className='departmentModal'>

                            {/* units={units} departments={departments} */}

                            {
                                props.departments.map((item, idx) => {
                                    return (
                                        <div key={idx} className='departmentPeice'>
                                            <label onClick={() => selectNewDepartmentByLabel(idx)} for={item.name}> {item.name} </label>
                                            <input required className='checkBoxOffers' type='checkbox' id={`departments-${idx}`} name={item.name} value={item.name} />
                                        </div>
                                    )
                                })

                            }

                            {/* <label> غرفة مزدوجة </label>
                                <input required type='checkbox' value="غرفة مزدوجة" /> */}

                        </div>

                        {/* offer days */}
                        <p className='headOfModalInputs'> الخصم سيطبق على هذة الايام طبقا للتواريخ المختارة  </p>
                        <div className='allDaysCheckBox'>
                            <label onClick={selectAllDaysByLabel}> تحديد كل الايام </label>
                            <input required className='checkBoxOffers' onClick={selectAllDays} type='checkbox' id="days_3000" />
                        </div>
                        <div className='departmentModal daysPart'>

                            {
                                days.map((item, idx) => {
                                    return (
                                        <div key={idx} className='departmentPeice'>
                                            <label onClick={() => selectNewDayByLabel(idx)}> {item.day} </label>
                                            <input required onChange={checkIfAllDays} className='checkBoxOffers' type='checkbox' id={`days-${idx}`} name={item.day} value={item.day} />
                                        </div>
                                    )
                                })
                            }
                        </div>

                        {/* offer date */}
                        <p className='headOfModalInputs'> تاريخ بداية ونهاية العرض  </p>
                        <div className='departmentDateModal'>
                            <div className='departmentPeice'>
                                <label> تاريخ النهاية </label>
                                <input required className='inputModalForm' type="date" id="endDate" name="endDate" onChange={handleChange} />
                            </div>
                            <div className='departmentPeice'>
                                <label> تاريخ البداية </label>
                                <input required className='inputModalForm' type="date" name="startDate" id="startDate" onChange={handleChange} />
                            </div>
                        </div>

                        {/* submit button */}
                        <div className='submitNewEmployee'>
                            <button type='submit'> حفظ العرض </button>
                        </div>
                    </Modal.Body>

                </Form>
            </Modal>
        </div>
    );
}
