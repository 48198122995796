import React, { useContext } from 'react';
import "./NavBar.css";
import { LoginContext } from '../../context/login';
import { Link } from 'react-router-dom';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';

export default function NavBar() {
    const login = useContext(LoginContext);

    const handleLogOut = () => {
        login.logOut();
    }

    // show menu
    const showMenu = () => {
        const dropMenu = document.getElementById("dropMenu");
        const divToHideMenu = document.getElementById("divToHideMenu");
        const arrowDown = document.getElementById("arrowDown");
        const arrowUp = document.getElementById("arrowUp");
        if (dropMenu.style.display !== 'block') {
            dropMenu.style.display = 'block';
            divToHideMenu.style.display = 'block';
            arrowDown.classList.add('hidden');
            arrowUp.classList.remove('hidden');
        } else {
            dropMenu.style.display = 'none';
            divToHideMenu.style.display = 'none';
            arrowUp.classList.add('hidden');
            arrowDown.classList.remove('hidden');
        }
    }

    return (
        <div className='navBar'>
            <div onClick={showMenu} id='divToHideMenu' className='divToHideMenu'>

            </div>
            <div className="dropdown">
                <button onClick={showMenu} id='menuBtn' className="dropbtn">
                    {login.user &&
                        login.user.name.split(" ")[0]}

                    <i id='arrowDown' >
                        <KeyboardArrowDownRoundedIcon />
                    </i>
                    <i id='arrowUp' className='hidden' >
                        <KeyboardArrowUpRoundedIcon />
                    </i>

                </button>
                {/* drop down menu */}
                <div onClick={showMenu} id='dropMenu' className="dropdown-content">
                    <Link to="/profile">
                        <div className='navSeting'>
                            <span>حسابي</span>
                            <ManageAccountsRoundedIcon />
                        </div>
                    </Link>
                    <hr className='lineInBetween' />

                    <Link to="/">
                        <div onClick={handleLogOut} className='navSeting signOutBtn'>
                            <span>خروج</span>
                            <LogoutRoundedIcon />
                        </div>
                    </Link>
                </div>

                <Link title='الاعدادات' to="/settings">
                    {/* setings */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="iconInBar bi bi-gear" viewBox="0 0 16 16">
                        <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                        <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                    </svg>
                </Link>

                <Link title='الدعم الفني' to="/techincal-support">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" fill="currentColor" className="iconInBar bi bi-headset" viewBox="0 0 16 16">
                        <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z" />
                    </svg>
                </Link>
                <Link className='a_Btn' to='/newreservation'>
                    <button title='New Reservation' className='newResservation'>
                        حجز جديد
                    </button>
                </Link>
            </div>
        </div>
    )
}
