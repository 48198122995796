import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import cookies from 'react-cookies';
import { LoginContext } from '../../../context/login';
import swal from 'sweetalert2';
import axios from 'axios';
import useForm from '../../../hooks/form';
import './Form.css';

export default function AddService(props) {
    // To load the token from cookies
    const qs = new URLSearchParams(window.location.search);
    const cookieToken = cookies.load('token');
    const token = qs.get('token') || cookieToken || null;

    const login = useContext(LoginContext);


    const { handleChange, handleSubmit } = useForm(addService);

    async function addService(info) {
        if (!info.name) {
            info.name = props.updateService.name;
        }
        if (!info.price) {
            info.price = props.updateService.price;
        }
        if (props.updateService) {
            info.id = props.updateService.id;
        } else {
            info.id = null;
        }

        const data = {
            id: info.id,
            name: info.name,
            price: info.price,
        }


        if (!props.updateService) {
            await axios.post(`${login.API}/addNewService`, data
                , { headers: { 'Authorization': `Basic ${token}` } })
                .then((res) => {
                    // new activity
                    axios.post(`${login.API}/newActivity`, {
                        employee_id: login.user.id,
                        description: `اضافة خدمة جديدة - ${data.name}`,
                        type: "الخدمات",
                    },{ headers: { 'Authorization': `Basic ${token}` }})
                    // alert
                    new swal({
                        title: "تم إضافة الخدمة بنجاح  ",
                        text: "ٍService Added Successfully",
                        icon: "success",
                        button: "okay!",
                    });

                })
                .catch((err) => {
                    // alert
                    new swal({
                        title: "حدث خطأ ما  اثناء الاضافة",
                        text: "something went wrong",
                        icon: "warning",
                        button: "try again!",
                    });

                });
        } else {
            await axios.put(`${login.API}/updateService`, data
                , { headers: { 'Authorization': `Basic ${token}` } })
                .then((res) => {
                    // new activity
                    axios.post(`${login.API}/newActivity`, {
                        employee_id: login.user.id,
                        description: `تعديل خدمة - ${data.name}`,
                        type: "الخدمات",
                    },{ headers: { 'Authorization': `Basic ${token}` }})
                    // alert
                    new swal({
                        title: "تم تعديل الخدمة بنجاح  ",
                        text: "Service Updated Successfully",
                        icon: "success",
                        button: "okay!",
                    });

                })
                .catch((err) => {
                    // alert
                    new swal({
                        title: "حدث خطأ ما  اثناء التعديل",
                        text: "something went wrong",
                        icon: "warning",
                        button: "try again!",
                    });

                });
        }



    }


    return (
        <div>


            <div className='navigator'>
                <p> <Link className='linkContainer' to='/'>الرئيسية</Link> / <Link className='linkContainer' to='/unit'>الوحدات</Link> / <Link className='linkContainer' to='/unit/services'>الخدمات</Link> / اضافة جديد  </p>

            </div>


            <div className='formContainer'>
                <Form className="logInForm" onSubmit={handleSubmit} >
                    <Form.Group className="mb-3" >
                        {props.updateService ?
                            <Form.Control onChange={handleChange} className='FormElement' name="name" type="text" defaultValue={props.updateService.name} lang='ar' />
                            :
                            <Form.Control onChange={handleChange} className='FormElement' name="name" type="text" placeholder="ادخل الخدمة" lang='ar' />
                        }
                        <Form.Label className='FormElement' htmlFor="email">اسم الخدمة</Form.Label>

                    </Form.Group>
                    <Form.Group className="mb-3" >
                        {props.updateService ?
                            <Form.Control onChange={handleChange} className='FormElement' name="price" type="number" defaultValue={props.updateService.price} />
                            :
                            <Form.Control onChange={handleChange} className='FormElement' name="price" type="number" placeholder="ادخل السعر" />
                        }
                        <Form.Label className='FormElement' htmlFor="password">سعر الخدمة</Form.Label>
                    </Form.Group>

                    <div >
                        {props.updateService ?
                            <button className='addUnitBtn'  > تعديل  </button>
                            :
                            <button className='addUnitBtn'  > إضافة  </button>
                        }
                    </div>


                </Form>
            </div>

        </div>
    )
}
