
import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { LoginContext } from '../../../context/login';
import useForm from '../../../hooks/form';
import cookies from 'react-cookies';
import axios from 'axios';
import swal from 'sweetalert2';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

export default function UpdateRoom(props) {
    // To load the token from cookies
    const qs = new URLSearchParams(window.location.search);
    const cookieToken = cookies.load('token');
    const token = qs.get('token') || cookieToken || null;

    const login = useContext(LoginContext);
    const [departments, setDepartments] = useState([]);

    const [defaultRoom, setDefaultRoom] = useState({});

    const [myImage, setMyImage] = useState(null);

    useEffect(() => {

        props.setLoadingFunc(true).then(async () => {
            let myRoom;

            for (let i = 0; i < props.rooms.length; i++) {
                if (props.rooms[i].id == props.roomId) {
                    myRoom = props.rooms[i];
                    setDefaultRoom(myRoom)
                    // set the room
                    break;
                }
            }
            for (let i = 0; i < props.departments.length; i++) {
                if (props.departments[i].id == myRoom.unit_id) {
                    // set the department
                    setDepartments(props.departments[i])
                    break;
                }
            }

            
        }).then(() => {
            props.setLoadingFunc(false)
        })


        // unit_id
    }, [])

    // set Image
    const handleImageChange = (e) => {
        setMyImage(e.target.files[0]);
    }


    const addRoom = async (data) => {
        let id = defaultRoom.id
        if (data.id) {
            data.room_number = data.id
        }
        data.images = myImage?.name
        const sendData = { data, id };
        // editMyRoom
        sendData.data.daily_price = [];
        sendData.data.weekly_price = [];
        sendData.data.monthely_price = [];

        
        for (let i = 1; i <= 7; i++) {
            document.getElementById(`price_${i}`).value ? sendData.data.daily_price.push(parseInt(document.getElementById(`price_${i}`).value)) : sendData.data.daily_price.push(parseInt(defaultRoom.daily_price[i - 1]))
        }

        document.getElementById("weekly_price").value ? sendData.data.weekly_price = parseInt(document.getElementById("weekly_price").value) : sendData.data.weekly_price = parseInt(defaultRoom.weekly_price);
        document.getElementById("monthely_price").value ? sendData.data.monthely_price = parseInt(document.getElementById("monthely_price").value) : sendData.data.monthely_price = parseInt(defaultRoom.monthely_price);



        props.setLoadingFunc(true).then(async () => {
            await axios.post(`${login.API}/editMyRoom`, sendData
                , { headers: { 'Authorization': `Basic ${token}` } })
                .then((res) => {
                    let resultRoom = { ...res, ...defaultRoom }
                    setDefaultRoom(resultRoom);

                    let formData = new FormData()
                    formData.append('file', myImage)
                    axios.post(`${login.API}/image`, formData , { headers: { 'Authorization': `Basic ${token}` }});

                    // new activity
                    axios.post(`${login.API}/newActivity`, {
                        employee_id: login.user.id,
                        description: `تعديل غرفة - ${defaultRoom.room_name}`,
                        type: "الغرف والاقسام",
                    },{ headers: { 'Authorization': `Basic ${token}` }})
                    // alert
                    new swal({
                        title: "تم تعديل الوحدة بنجاح  ",
                        text: "Unit Added Successfully",
                        icon: "success",
                        button: "okay!",
                    });

                })
                .catch((err) => {
                    // alert
                    new swal({
                        title: "حدث خطأ ما  اثناء التعديل",
                        text: "something went wrong",
                        icon: "warning",
                        button: "try again!",
                    });

                });
        }).then(() => {
            props.setLoadingFunc(false)
        })

    }

    const { handleChange, handleSubmit } = useForm(addRoom);



    const serviceLabelFunc = (service) => {
        // {`service-${idx}`}
        let myService = document.getElementById(`service-${service}`);
        if (myService.checked) {
            myService.checked = false;
        }
        else {
            myService.checked = true;
        }
    }





    return <div>
        <div className='navigator'>
            <p> <Link className='linkContainer' to='/'>الرئيسية</Link> / <Link className='linkContainer' to='/unit'>الوحدات</Link> / تعديل بيانات الوحدة </p>
        </div>


        <Form className='addNewRoomForm' onSubmit={handleSubmit}>

            <Form.Label className='headerElement ' > تعديل بيانات الوحدة </Form.Label>



            <Form.Group className="mb-3 departmentRowElement" >
                <Form.Control defaultValue={defaultRoom.id} onChange={handleChange} className='FormElement' name="id" placeholder='رقم الوحدة #' type='number' />
                <p className='FormElement'> رقم الوحدة # </p>
            </Form.Group>

            <Form.Group className="mb-3 departmentRowElement" >
                <select onChange={handleChange} className='FormElement w-full form-control form-select' name="unit_id" id="role">
                    <option value="" >إختر خيار</option>
                    {props.departments.map((department, idx) => {
                        if (departments == department) {
                            return (
                                <option selected="selected" key={idx} value={department.id}>{department.name}</option>
                            )
                        } else {
                            return (
                                <option key={idx} value={department.id}>{department.name}</option>
                            )
                        }

                    })}

                </select>
                <p className='FormElement'> نوع الوحدة </p>
            </Form.Group>

            <Form.Group className="mb-3 departmentRowElement" >
                <Form.Control defaultValue={defaultRoom.room_name} onChange={handleChange} className='FormElement' name="room_name" placeholder='اسم الوحدة' type='text' />
                <p className='FormElement'> اسم الوحدة </p>
            </Form.Group>

            <Form.Group className="mb-3 departmentRowElement" >
                <Form.Control defaultValue={defaultRoom.description} onChange={handleChange} className='FormElement' name="description" placeholder='وصف الوحدة ' type='text' />
                <p className='FormElement'> وصف الوحدة </p>
            </Form.Group>

            <Form.Group className="mb-3 departmentRowElement" >
                {/* {/* <input required type='file' /> */}
                <div className='FormElement logoDiv'>
                    <span className='logoSpan'>
                         <ArrowUpwardIcon className="logoImage" />
                          ({defaultRoom.images}) صورة الوحدة </span>

                    <Form.Control onChange={handleImageChange} name="images" id='roomImage' className='logoInput' type="file" />
                </div>
                <p className='FormElement'>  صور الوحدة </p>

            </Form.Group>
            {defaultRoom.id &&

                <>
                    <Form.Label className='headerElement ' >  الاسعار اليومية</Form.Label>

                    <Form.Group className="mb-3 departmentRowElement" >
                        <Form.Control required onChange={handleChange} className='FormElement' name="price_1" id="price_1" type="number" defaultValue={defaultRoom.daily_price[0]} />
                        <Form.Label className='FormElement' htmlFor="email"> سعر يوم الاحد</Form.Label>
                    </Form.Group>

                    <Form.Group className="mb-3 departmentRowElement" >
                        <Form.Control required onChange={handleChange} className='FormElement' name="price_2" id="price_2" type="number" defaultValue={defaultRoom.daily_price[1]} />
                        <Form.Label className='FormElement' htmlFor="price_2"> سعر يوم الاثنين</Form.Label>
                    </Form.Group>

                    <Form.Group className="mb-3 departmentRowElement" >
                        <Form.Control required onChange={handleChange} className='FormElement' name="price_3" id="price_3" type="number" defaultValue={defaultRoom.daily_price[2]} />
                        <Form.Label className='FormElement' htmlFor="price_3"> سعر يوم الثلاثاء</Form.Label>
                    </Form.Group>

                    <Form.Group className="mb-3 departmentRowElement" >
                        <Form.Control required onChange={handleChange} className='FormElement' name="price_4" id="price_4" type="number" defaultValue={defaultRoom.daily_price[3]} />
                        <Form.Label className='FormElement' htmlFor="price_4"> سعر يوم الاربعاء</Form.Label>
                    </Form.Group>

                    <Form.Group className="mb-3 departmentRowElement" >
                        <Form.Control required onChange={handleChange} className='FormElement' name="price_5" id="price_5" type="number" defaultValue={defaultRoom.daily_price[4]} />
                        <Form.Label className='FormElement' htmlFor="price_5"> سعر يوم الخميس</Form.Label>
                    </Form.Group>

                    <Form.Group className="mb-3 departmentRowElement" >
                        <Form.Control required onChange={handleChange} className='FormElement' name="price_6" id="price_6" type="number" defaultValue={defaultRoom.daily_price[5]} />
                        <Form.Label className='FormElement' htmlFor="price_6"> سعر يوم الحمعة</Form.Label>
                    </Form.Group>

                    <Form.Group className="mb-3 departmentRowElement" >
                        <Form.Control required onChange={handleChange} className='FormElement' name="price_7" id="price_7" type="number" defaultValue={defaultRoom.daily_price[6]} />
                        <Form.Label className='FormElement' htmlFor="price_7"> سعر يوم السبت</Form.Label>
                    </Form.Group>

                    <Form.Label className='headerElement ' htmlFor="email">  السعر الاسبوعي </Form.Label>
                    <Form.Group className="mb-3 departmentRowElement" >
                        <Form.Control required onChange={handleChange} className='FormElement' name="weekly_price" id="weekly_price" type="number" defaultValue={defaultRoom.weekly_price} />
                        <Form.Label className='FormElement' htmlFor="price_month"> السعر الاسبوعي</Form.Label>
                    </Form.Group>

                    <Form.Label className='headerElement ' htmlFor="email">  السعر الشهري </Form.Label>
                    <Form.Group className="mb-3 departmentRowElement" >
                        <Form.Control required onChange={handleChange} className='FormElement' name="price_month" id="monthely_price" type="number" defaultValue={defaultRoom.monthely_price} />
                        <Form.Label className='FormElement' htmlFor="price_month"> السعر الشهري</Form.Label>
                    </Form.Group>

                    {/* <Form.Label className='headerElement ' htmlFor="email">  السعر الفصلي </Form.Label>
                <Form.Group className="mb-3 departmentRowElement" >
                    <Form.Control required onChange={handleChange} className='FormElement' name="seasonal_price" type="number" placeholder="ادخل السعر" />
                    <Form.Label className='FormElement' htmlFor="price_month"> السعر الفصلي</Form.Label>
                </Form.Group>

                <Form.Label className='headerElement ' htmlFor="email">  السعر السنوي </Form.Label>
                <Form.Group className="mb-3 departmentRowElement" >
                    <Form.Control required onChange={handleChange} className='FormElement' name="yearly_price" type="number" placeholder="ادخل السعر" />
                    <Form.Label className='FormElement' htmlFor="price_month"> السعر السنوي</Form.Label>
                </Form.Group> */}
                </>
            }


            <button className='addUnitBtn newDepartmentBtn submitNewRoom' type='submit'>
                حفظ بيانات الوحدة
            </button>

            {/* <div className='submitNewEmployee'>
                <button type='submit'>
                    حفظ بيانات الوحدة
                </button>
            </div> */}
        </Form>

    </div>;
}
