import React from 'react';
import "./support.css";
import MailOutlineIcon from '@mui/icons-material/MailOutline';


export default function Support() {
    return (<div>
        <p className='support_P'> الدعم الفني </p>

        <div className='supportContainer'>
            <p className='messageToSupport'> يوفر النظام خدمات الدعم الفني بعدة طرق وذلك لضمان الرد على استفسارات وحل مشاكل مستخدمي النظام </p>
            <div>
                <p><span> للتواصل معنا عبر البريد الإلكتروني </span> <span> <a className='linkToSupport' href='mailto:alaasmadi1010@gmail.com' target={"_blank"}> alaasmadi1010@gmail.com </a> </span></p>
                <MailOutlineIcon/>
            </div>

            <div>
                <p> <p><span> تواصل معنا من خلال الواتس آب </span> <span> <a className='linkToSupport' href='https://wa.me/+962787189817' target={"_blank"}> +962-787189817 </a> </span></p> </p>
            </div>
        </div>
    </div>);
}
