import React, { useEffect } from "react";
import { Modal, Button } from 'react-bootstrap';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export default function GetSanad(props) {

    return (
        <Modal show={props.show} onHide={() => props?.hide([])} style={{ direction: 'rtl' }}>
            <Modal.Header className='contarct_modal_header'>
                <Modal.Title>
                    إضافة سند {props.title}
                </Modal.Title>
                <CloseRoundedIcon onClick={() => props?.hide([])} />
            </Modal.Header>

            <Modal.Body className='contarct_modal_body'>
                <h5 style={{ padding: '20px' }}>يمكنك اضافة معلومات السند الجديد من سند {props.title == 'صرف' ? 'قبض' : 'صرف'} سابق لتسريع العمل</h5>

                <ul style={{ height: "50vh", overflow: "auto", boxShadow: "0px 0 10px 0px #6b6cb05c" }}>
                    {
                        props.oldSanadData?.map(sanad => {
                            return (
                                <li onClick={() => props?.handleReadOldDataModal(sanad)} style={{ padding: '15px', margin: '5px', border: '1px solid #b9b2b2', borderRadius: '7px', listStyle: 'none' }} className="moneySpan list-of-old-data" >
                                    <div className='time_span' style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}> <span> <span> رقم السند</span><span> {sanad.id} </span> </span>  <span> <span>تاريخ السند </span> {new Date(sanad.date) != 'Invalid Date' ? new Date(sanad.date).toJSON().split("T")[0] : sanad.date}</span> </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', }}>  <span className='payment_colors' style={{ width: '-webkit-fill-available' }}> <span className='rent_type_span'>{sanad.for == "خدمات" ? (sanad.isPaied ? ` خدمة مدفوعة: ${sanad.description}` : ` خدمة غير مدفوعة: ${sanad.description}`) : sanad.for}</span> </span> <span className='amount_span'> <span> القيمة </span> <span>{`  ` + ` ${sanad.amount} ` + `  `}</span> <span>دينار</span> </span> </div>
                                </li>
                            )
                        })
                    }
                </ul>

                <Button onClick={() => props?.handleReadOldDataModal({})} style={{ margin: 'auto', display: 'block' }}>انشاء سند {props.title} فارغ</Button>
            </Modal.Body>
        </Modal >
    )
}