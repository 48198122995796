import React, { useContext, useEffect, useState } from 'react';
import { When } from 'react-if';
import Home from './components/Home';
import Login from './components/Login';
import Unit from './components/Unit/Unit';
import { LoginContext } from './context/login';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import UnitDepartment from './components/Unit/UnitDepartment';
import Services from './components/Unit/Services';
import AddService from './components/Unit/Forms/AddService';
import Offers from './components/Unit/offers/Offers';
import AddDepartment from './components/Unit/Forms/AddDepartment';
import SideBar from './components/sideBar/SideBar';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css'
import NavBar from './components/navBar/NavBar';
import Profile from './components/navBar/Profile';
import AddNewEmployee from './components/navBar/AddNewEmployee';
import NewReservation from './components/Reservation/NewReservation';
import EmployeeList from './components/navBar/EmployeeList';
import EditEmplyee from './components/navBar/EditEmployee';
import AddRoom from './components/Unit/Forms/AddRoom';
import Support from './components/support/Support';
import Setings from './components/setings/Setings';
import General_Setings from './components/setings/General_Setings';
import Loading from './components/loading/Loading';
import UpdateRoom from './components/Unit/Forms/UpdateRoom';
import ReservationManagement from './components/Reservation/ReservationManagement';
import ReservationInfo from './components/Reservation/ReservationInfo';
import ReservationTable from './components/Reservation/ReservationTable';
import Invoice from './components/Reservation/Invoice';
import NotificationSetting from './components/setings/NotificationSetting';
import FinancialManagement from './components/Financial-Managment/FinancialManagement';
import Recipts from './components/Financial-Managment/Recipts';
import Expenses from './components/Financial-Managment/Expenses';
import CashReport from './components/Financial-Managment/CashReport';
import Activities from './components/setings/Activities';
import HousingUnit2 from './components/HousingUnit2';
import Documents from './components/setings/Documents';
import Guest from './components/Reservation/Guest';
import cookies from 'react-cookies';
import GuestsList from './components/setings/GuestsList';
import ReorderRoundedIcon from '@mui/icons-material/ReorderRounded';
import "react-datepicker/dist/react-datepicker.css";
import ServerMessage from './components/mainMessage';


function App(props) {
  // To load the token from cookies
  const qs = new URLSearchParams(window.location.search);
  const cookieToken = cookies.load('token');
  const token = qs.get('token') || cookieToken || null;

  const login = useContext(LoginContext);
  const [employeeId, setEmployeeId] = useState(0);
  const [roomId, setRoomId] = useState(0);
  const [rooms, setRooms] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [resrvationInfo, setreservationInfo] = useState({});
  const [updateService, setUpdateService] = useState({});
  const [hotel, setHotel] = useState();
  const [waitingForPayment, setWaitingForPayments] = useState([]);
  // const [serverHealth, setServerHealth] = useState(null);

  useEffect(() => {
    async function fetchData() {
      let hotelData = cookies.load('hotel');
      if (!hotelData) {
        await axios.post(`${login.API}/getMyHotel`,{},{ headers: { 'Authorization': `Basic ${token}` }})
          .then(res => {
            cookies.save('hotel', JSON.stringify(res.data[0]));
            // setServerHealth(null);
            setHotel(res.data[0]);
            console.log('line --= 75 =-- ', res.data[0]);
          })
          // .catch(err=>{
          //   let errorMessage = {
          //     message:err.response.data.message || serverHealth?.message,
          //     status: err.response.status || serverHealth?.status,
          //   }
          //   setServerHealth(errorMessage);
          // })
      }
    }
    fetchData();
  }, [])

  // add id for the employee to update his info
  const editRoom = (id, roomsArr, departmentsArr) => {
    setRoomId(id);
    setRooms(roomsArr);
    setDepartments(departmentsArr);
  }

  const editEmployee = (id) => {
    setEmployeeId(id);

  }

  const handelNewBar = () => {
    const bar = document.getElementById('rightSideOfMain');
    const btn = document.getElementById("barBtnSmallScreen");

    if (bar.style.display === "none") {
      bar.style.display = "block";
      bar.style.right = "0px";
      btn.style.right = "75px";
      // btn.style.top = "60px";
    } else {
      bar.style.display = "none";
      bar.style.right = "-75px";
      btn.style.right = "3px";
      // btn.style.top = "60px";
    }
  }

  // loading part
  const [loading, setLoading] = useState(false);
  const setLoadingFunc = async (con) => {
     setLoading(con)
  }

  
  return (
    <div className="App">
      {
        loading &&
        <Loading />
      }
      {/* {
        serverHealth && 
        <ServerMessage error={serverHealth} />
      } */}

      <Router>

        <Route exact path="/invoice">
          <Invoice hotel={hotel} />
        </Route>

        <When condition={!login.loggedIn}>
          <Login setLoadingFunc={setLoadingFunc} />
        </When>




        <When condition={login.loggedIn}>



          <div id='rightSideOfMain' className='rightSideOfMain'>
            <SideBar setLoadingFunc={setLoadingFunc} />
          </div>
          <div className='rightSideOfMain'>
            <SideBar setLoadingFunc={setLoadingFunc} />
          </div>

          <div className='leftSideOfMain'>

            <NavBar />

            <div className='componentContainer'>
              <Switch>

                <Route exact path="/">
                  <Home waitingForPayment={waitingForPayment} setWaitingForPayments={setWaitingForPayments} setLoadingFunc={setLoadingFunc} setreservationInfo={setreservationInfo} />
                </Route>

                <Route exact path="/housing-units">
                  <HousingUnit2 waitingForPayment={waitingForPayment} setLoadingFunc={setLoadingFunc} setreservationInfo={setreservationInfo} />
                </Route>
                <Route exact path="/profile">
                  <Profile setLoadingFunc={setLoadingFunc} />
                </Route>

                <Route exact path="/reservationInfo">
                  <ReservationInfo hotel={hotel} setLoadingFunc={setLoadingFunc} resrvationInfo={resrvationInfo} />
                </Route>

                <Route exact path="/guest">
                  <Guest setLoadingFunc={setLoadingFunc} />
                </Route>

                <Route exact path="/profile/addNewEmployee">
                  <When condition={login.user?.role !== "manager"}>
                    <Profile setLoadingFunc={setLoadingFunc} />
                  </When>
                  <When condition={login.user?.role === "manager"}>
                    <AddNewEmployee setLoadingFunc={setLoadingFunc} />
                  </When>
                </Route>

                <Route exact path='/financialManagments'>
                  <FinancialManagement hotel={hotel} />
                </Route>

                <Route exact path='/financialManagments/Reciepts'>
                  <Recipts hotel={hotel} loading={setLoadingFunc} />
                </Route>

                <Route exact path='/financialManagments/Expenses'>
                  <Expenses hotel={hotel} loading={setLoadingFunc} />
                </Route>

                <Route exact path='/financialManagments/cashReport'>
                  <CashReport hotel={hotel} loading={setLoadingFunc}/>
                </Route>

                <Route exact path="/settings/notifications-Setings">
                  <NotificationSetting setLoadingFunc={setLoadingFunc} />
                </Route>

                <Route exact path="/settings/guests-list">
                  <GuestsList/>
                </Route>

                <Route exact path="/unit"  >
                  <Unit roomId={roomId} editRoom={editRoom} setLoadingFunc={setLoadingFunc} />
                </Route>

                <Route exact path="/unit/edit-Unit" >
                  <UpdateRoom rooms={rooms} departments={departments} roomId={roomId} setLoadingFunc={setLoadingFunc} />
                </Route>

                <Route exact path="/newreservation"  >
                  <NewReservation setreservationInfo={setreservationInfo} setLoadingFunc={setLoadingFunc} />
                </Route>

                <Route exact path="/unit/departments"  >
                  <UnitDepartment setLoadingFunc={setLoadingFunc} />
                </Route>

                <Route exact path="/unit/services"  >
                  <Services setUpdateService={setUpdateService} setLoadingFunc={setLoadingFunc} />
                </Route>

                <Route exact path="/unit/addRoom"  >
                  <AddRoom setLoadingFunc={setLoadingFunc} />
                </Route>

                <Route exact path="/unit/offers">
                  <Offers setLoadingFunc={setLoadingFunc} />
                </Route>

                <Route exact path="/profile/employeeList">
                  <EmployeeList setLoadingFunc={setLoadingFunc} editEmployee={editEmployee} />
                </Route>
                {/* setings */}
                <Route exact path="/settings/employeeList">
                  <EmployeeList setLoadingFunc={setLoadingFunc} editEmployee={editEmployee} />
                </Route>

                <Route exact path="/profile/employeeList/editEmployee">
                  <EditEmplyee setLoadingFunc={setLoadingFunc} employeeId={employeeId} />
                </Route>

                <Route exact path="/unit/departments/add"  >
                  <AddDepartment setLoadingFunc={setLoadingFunc} />
                </Route>

                <Route exact path="/unit/services/add"  >
                  <AddService updateService={updateService} setLoadingFunc={setLoadingFunc} />
                </Route>



                <Route exact path="/settings">
                  <Setings setLoadingFunc={setLoadingFunc} />
                </Route>

                <Route exact path="/settings/activities-Setings">
                  <Activities setLoadingFunc={setLoadingFunc} />
                </Route>

                <Route exact path="/settings/general-Setings">
                  <General_Setings setLoadingFunc={setLoadingFunc} />
                </Route>

                <Route exact path="/settings/terms-Setings">
                  <Documents setLoadingFunc={setLoadingFunc} />
                </Route>

                <Route exact path="/techincal-support">
                  <Support setLoadingFunc={setLoadingFunc} />
                </Route>

                {/* ReservationManagement */}
                <Route exact path="/ReservationManagement">
                  <ReservationManagement setreservationInfo={setreservationInfo} setLoadingFunc={setLoadingFunc} />
                </Route>

                <Route exact path="/Reservation-Table">
                  <ReservationTable setreservationInfo={setreservationInfo} setLoadingFunc={setLoadingFunc} />
                </Route>


              </Switch>
            </div>

          </div>


        </When>

        <div id='barBtnSmallScreen' onClick={handelNewBar} className='barBtnSmallScreen'>
          <i className="newSmallBar">
            <ReorderRoundedIcon />
          </i>
        </div>

      </Router>
    </div>
  );
}


export default App;