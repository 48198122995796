import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { LoginContext } from '../../../context/login';
import useForm from '../../../hooks/form';
import cookies from 'react-cookies';
import axios from 'axios';
import swal from 'sweetalert2';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';


export default function AddRoom(props) {
    // To load the token from cookies
    const qs = new URLSearchParams(window.location.search);
    const cookieToken = cookies.load('token');
    const token = qs.get('token') || cookieToken || null;

    const login = useContext(LoginContext);
    const [departments, setDepartments] = useState([]);



    const { handleChange, handleSubmit } = useForm(addRoom);

    useEffect(() => {
        
        axios.post(`${login.API}/getUnits`,{} ,{ headers: { 'Authorization': `Basic ${token}` }})
            .then(async res => {
                setDepartments(res.data);
            });
    }, []);


    useEffect(() => {
        axios.post(`${login.API}/getUnits`,{},{ headers: { 'Authorization': `Basic ${token}` }})
            .then(async res => {
                setDepartments(res.data);
            });
    }, []);


    const [myImage, setMyImage] = useState(null);
    // set Image
    const handleImageChange = (e) => {
        setMyImage(e.target.files[0]);
    }

    async function addRoom(info) {

        props.setLoadingFunc(true);

        var checkedValue = [];
        var inputElements = document.getElementsByClassName('serviceCheckbox');
        for (var i = 0; inputElements[i]; i++) {
            if (inputElements[i].checked) {
                checkedValue.push(inputElements[i]._wrapperState.initialValue)
            }
        }

        const data = {
            id: info.id,
            unit_id: info.unit_id,
            room_name: info.room_name,
            room_number: info.id.toString(),
            // room_type:
            description: info.description,
            images: myImage?.name,
            services: checkedValue,
            room_status: 'avilable',
            avililability: true,
            bed_numbers: parseInt(info.bed_numbers),
            daily_price: [info.price_1, info.price_2, info.price_3, info.price_4, info.price_5, info.price_6, info.price_7],
            monthely_price: info.price_month,
            weekly_price: info.weekly_price,
            seasonal_price: info.seasonal_price,
            yearly_price: info.yearly_price,
        }

        const token = cookies.load('token');


        await axios.post(`${login.API}/addNewRoom`, data
            , { headers: { 'Authorization': `Basic ${token}` } })
            .then((res) => {
                let formData = new FormData()
                formData.append('file', myImage)
                axios.post(`${login.API}/image`, formData, { headers: { 'Authorization': `Basic ${token}` }});
                // new activity
                axios.post(`${login.API}/newActivity`, {
                    employee_id: login.user.id,
                    description: `اضافة غرفة - ${res.data.room_name}`,
                    type: "الغرف والاقسام",
                },{ headers: { 'Authorization': `Basic ${token}` }})
                props.setLoadingFunc(false);

                // alert
                new swal({
                    title: "تم إضافة الوحدة بنجاح  ",
                    text: "Unit Added Successfully",
                    icon: "success",
                    button: "okay!",
                });

            })
            .catch((err) => {
                props.setLoadingFunc(false);

                // alert
                new swal({
                    title: "حدث خطأ ما  اثناء الاضافة",
                    text: "something went wrong",
                    icon: "warning",
                    button: "try again!",
                });

            });

    }

    const serviceLabelFunc = (service) => {
        // {`service-${idx}`}
        let myService = document.getElementById(`service-${service}`);
        if (myService.checked) {
            myService.checked = false;
        }
        else {
            myService.checked = true;
        }
    }

    return (



        <div className='profileContainer'>
            <div className='navigator'>
                <p> <Link className='linkContainer' to='/'>
                    الرئيسية
                </Link>
                    / <Link className='linkContainer' to='/unit'>
                        الوحدات
                    </Link>
                    / اضافة وحدة
                </p>
            </div>

            <Form className='addNewRoomForm' onSubmit={handleSubmit}>


                <Form.Label className='headerElement ' > إضافة وحدة جديدة </Form.Label>

                <Form.Group className="mb-3 departmentRowElement" >
                    <Form.Control onChange={handleChange} className='FormElement' name="id" placeholder='رقم الوحدة #' required type='number' />
                    <p className='FormElement'> رقم الوحدة # </p>
                </Form.Group>

                <Form.Group className="mb-3 departmentRowElement" >
                    <select onChange={handleChange} className='FormElement w-full form-control form-select' name="unit_id" id="role">
                        <option value="" selected="selected">إختر خيار</option>
                        {departments.map((department, idx) => {
                            return (
                                <option key={idx} value={department.id}>{department.name}</option>
                            )
                        })}

                    </select>
                    <p className='FormElement'> نوع الوحدة </p>
                </Form.Group>

                <Form.Group className="mb-3 departmentRowElement" >
                    <Form.Control onChange={handleChange} className='FormElement' name="room_name" placeholder='اسم الوحدة' required type='text' />
                    <p className='FormElement'> اسم الوحدة </p>
                </Form.Group>


                <Form.Group className="mb-3 departmentRowElement" >
                    <Form.Control onChange={handleChange} className='FormElement' name="bed_numbers" placeholder='عدد الأسرة' required type='number' />
                    <p className='FormElement'>  عدد الأسرة </p>
                </Form.Group>



                <Form.Group className="mb-3 departmentRowElement" >

                    <Form.Control onChange={handleChange} className='FormElement' name="description" placeholder='وصف الوحدة ' required type='text' />
                    <p className='FormElement'> وصف الوحدة </p>
                </Form.Group>

                {/* <div className='row'>
                        <input required />
                        <p className='colRight'> صور الوحدة </p>
                    </div> */}

                <Form.Group className="mb-3 departmentRowElement" >

                    <div className='FormElement logoDiv'>
                        <span className='logoSpan'> 
                         <ArrowUpwardIcon className="logoImage " />
                        صورة الوحدة </span>

                        <Form.Control className='logoInput' onChange={handleImageChange} name="images" placeholder='صور الوحدة ' id='roomImage' required type='file' />
                    </div>
                    <p className='FormElement'> صورة الوحدة </p>
                </Form.Group>



                <Form.Label className='headerElement ' >  الاسعار اليومية</Form.Label>

                <Form.Group className="mb-3 departmentRowElement" >
                    <Form.Control required onChange={handleChange} className='FormElement' name="price_1" type="number" placeholder="ادخل السعر" />
                    <Form.Label className='FormElement' htmlFor="email"> سعر يوم الاحد</Form.Label>
                </Form.Group>

                <Form.Group className="mb-3 departmentRowElement" >
                    <Form.Control required onChange={handleChange} className='FormElement' name="price_2" type="number" placeholder="ادخل السعر" />
                    <Form.Label className='FormElement' htmlFor="price_2"> سعر يوم الاثنين</Form.Label>
                </Form.Group>

                <Form.Group className="mb-3 departmentRowElement" >
                    <Form.Control required onChange={handleChange} className='FormElement' name="price_3" type="number" placeholder="ادخل السعر" />
                    <Form.Label className='FormElement' htmlFor="price_3"> سعر يوم الثلاثاء</Form.Label>
                </Form.Group>

                <Form.Group className="mb-3 departmentRowElement" >
                    <Form.Control required onChange={handleChange} className='FormElement' name="price_4" type="number" placeholder="ادخل السعر" />
                    <Form.Label className='FormElement' htmlFor="price_4"> سعر يوم الاربعاء</Form.Label>
                </Form.Group>

                <Form.Group className="mb-3 departmentRowElement" >
                    <Form.Control required onChange={handleChange} className='FormElement' name="price_5" type="number" placeholder="ادخل السعر" />
                    <Form.Label className='FormElement' htmlFor="price_5"> سعر يوم الخميس</Form.Label>
                </Form.Group>

                <Form.Group className="mb-3 departmentRowElement" >
                    <Form.Control required onChange={handleChange} className='FormElement' name="price_6" type="number" placeholder="ادخل السعر" />
                    <Form.Label className='FormElement' htmlFor="price_6"> سعر يوم الحمعة</Form.Label>
                </Form.Group>

                <Form.Group className="mb-3 departmentRowElement" >
                    <Form.Control required onChange={handleChange} className='FormElement' name="price_7" type="number" placeholder="ادخل السعر" />
                    <Form.Label className='FormElement' htmlFor="price_7"> سعر يوم السبت</Form.Label>
                </Form.Group>

                <Form.Label className='headerElement ' htmlFor="email">  السعر الاسبوعي </Form.Label>
                <Form.Group className="mb-3 departmentRowElement" >
                    <Form.Control required onChange={handleChange} className='FormElement' name="weekly_price" type="number" placeholder="ادخل السعر" />
                    <Form.Label className='FormElement' htmlFor="price_month"> السعر الاسبوعي</Form.Label>
                </Form.Group>

                <Form.Label className='headerElement ' htmlFor="email">  السعر الشهري </Form.Label>
                <Form.Group className="mb-3 departmentRowElement" >
                    <Form.Control required onChange={handleChange} className='FormElement' name="price_month" type="number" placeholder="ادخل السعر" />
                    <Form.Label className='FormElement' htmlFor="price_month"> السعر الشهري</Form.Label>
                </Form.Group>


                {/* <div className='submitNewEmployee'> */}
                <button className='addUnitBtn newDepartmentBtn submitNewRoom' type='submit'>
                    حفظ بيانات الوحدة
                </button>
                {/* </div> */}
            </Form>
        </div >
    )
}
