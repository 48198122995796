import React from 'react';
import { Modal } from 'react-bootstrap';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export default function ImageModal(props) {
    return (
        <Modal className='modalToShowImage ' show={props.show} onHide={props.setHideImageFunc}>
            <Modal.Header >
                <button className='closeModal' onClick={() => props.setHideImageFunc()}>
                    <CloseRoundedIcon />
                </button>
                {/* <span  > X </span> */}
                <Modal.Title> {props.image?.name} - {props.image?.id} </Modal.Title>
            </Modal.Header>

            <img className='imageShowInBigModal' alt="myImage" src={props.image?.src} />


        </Modal>
    );
}
