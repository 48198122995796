import React, { useEffect, useState, useContext } from 'react';
import cookies from 'react-cookies';
import { Link } from 'react-router-dom';
import { LoginContext } from '../../context/login';
import useForm from '../../hooks/form';
import { Form, Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import swal from 'sweetalert2';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DatePicker from "react-datepicker";



export default function Guest(props) {
    // To load the token from cookies
    const qs = new URLSearchParams(window.location.search);
    const cookieToken = cookies.load('token');
    const token = qs.get('token') || cookieToken || null;

    const login = useContext(LoginContext);
    const [guest, setGuest] = useState();
    const [birthDate, setBirthDate] = useState(new Date());

    const [showImage, setShowImage] = useState(false);
    const [Image, setImage] = useState({});

    const handleChangeImage = (e) => {
        setImage(e.target.files[0]);

    }

    useEffect(() => {
        let guesData = cookies.load('guest');
        if (guesData) {
            setGuest(guesData);
        }
    }, [])

    useEffect(()=>{
        setBirthDate(guest?.birth_date? new Date(guest?.birth_date):new Date())
    }, [guest])

    const onSubmit = (guestData) => {
        guestData.id = guest.id;
        guestData.birth_date = birthDate;

        props.setLoadingFunc(true)
            .then(async () => {
                let personalImage = new FormData();
                personalImage.append('file', Image);

                if (Image) {
                    guestData.personal_ID_Image = Image.name;
                    axios.post(`${login.API}/image`, personalImage, { headers: { 'Authorization': `Basic ${token}` } })
                }

                await axios.put(`${login.API}/updateGuest`, guestData, { headers: { 'Authorization': `Basic ${token}` } })
                    .then(res => {
                        setGuest(res.data);



                        cookies.save('guest', JSON.stringify(res.data))
                        new swal({
                            title: "تم تعديل المعلومات بنجاح  ",
                            icon: "success",
                            button: "okay!",
                        });
                    })
                    .catch(err => {
                        console.log(err);
                    })
                props.setLoadingFunc(false)

            })

    }
    const { handleChange, handleSubmit } = useForm(onSubmit);

    const setHideImageFunc = () => {
        setShowImage(!showImage);
    }

    return (
        <div>
            <div className='navigator'>
                <p>
                    <Link className='linkContainer' to='/'>
                        الرئيسية
                    </Link>
                    /
                    <Link className='linkContainer' to='/reservationInfo'>
                        معلومات الحجز
                    </Link>
                    /
                    تعديل معلومات الضيف

                </p>
            </div>


            <Form onSubmit={handleSubmit}>
                <div className='infoContainer'>


                    <div className='row'>
                        <input required className='colLeft' onChange={handleChange} name="name" defaultValue={guest?.name} placeholder='اسم الضيف' type='text' />
                        <p className='colRight'> اسم الضيف </p>
                    </div>
                    <div className='row'>
                        <input required className='colLeft' onChange={handleChange} name="email" defaultValue={guest?.email} placeholder='البريد الالكتروني' type='email' />
                        <p className='colRight'> البريد الالكتروني </p>
                    </div>
                    <div className='row'>
                        <input required className='colLeft' onChange={handleChange} name="phone_number" defaultValue={guest?.phone_number} placeholder='رقم الهاتف' type='text' />
                        <p className='colRight'> رقم الهاتف </p>
                    </div>

                    <div className='row'>
                        <input required className='colLeft' onChange={handleChange} name="phone_number_2" defaultValue={guest?.phone_number_2} placeholder='رقم ولي الأمر' type='text' />
                        <p className='colRight'> رقم ولي الأمر </p>
                    </div>

                    <div className='row'>
                        <DatePicker
                            selectsStart
                            className="inputDataForNewGuest"
                            dateFormat="dd-MM-yyyy"
                            selected={birthDate}
                            onChange={(date) => setBirthDate(date)}
                            name="checkIn"
                            type="date"
                            placeholder="checkIn-Date"
                            required />
                        { }
                        <p className='colRight'> تاريخ الميلاد </p>
                    </div>

                    <div className='row'>
                        <input required className='colLeft' onChange={handleChange} name="id_number" defaultValue={guest?.id_number} placeholder=" رقم الهوية / الجواز" type='text' />
                        <p className='colRight'> رقم الهوية / الجواز </p>
                    </div>

                    <div className='row'>
                        <span className='colLeft personalImageContainer'>
                            <span className='personalImageContainer_Internal'>
                                <span onClick={setHideImageFunc} className='showPersonalImage'>
                                    عرض صورة الهوية
                                </span>
                            </span>
                            <span className='personalImageContainer_Internal'>
                                <span > {guest?.personal_ID_Image} </span>
                                <input required onChange={handleChangeImage} defaultValue={guest?.personal_ID_Image} id='personal_ID_Image' placeholder="صورة الهوية" name="personal_ID_Image" type='file' />
                            </span>
                        </span>
                        {/* <input required  onChange={handleChange} name="personal_ID_Image" defaultValue={guest?.personal_ID_Image} placeholder="صوؤة الهوية" required type='file' /> */}
                        <p className='colRight'> صورة هوية التعريف </p>
                    </div>

                    <div className='row'>
                        <input required className='colLeft'id="role" onChange={handleChange} name="nationallity" defaultValue={guest?.nationallity} placeholder="الجنسية" type='text' />
                        <p className='colRight'> الجنسية </p>
                    </div>

                    <div className='row'>
                        <select value={guest?.guest_type} className='colLeft' onChange={handleChange} name="guest_type" >
                            <option value=""> اختر نوع </option>
                            <option value="مواطن"> مواطن </option>
                            <option value="زائر"> زائر </option>
                            <option value="مقيم"> مقيم </option>
                        </select>
                        <p className='colRight'> نوع العميل </p>
                    </div>

                    <div className='row'>
                        <select value={guest?.id_type} className='colLeft' onChange={handleChange} name="id_type">
                            <option > اختر نوع </option>
                            <option value="بطاقة احوال"> بطاقة احوال </option>
                            <option value="دفتر عائلة"> دفتر عائلة </option>
                            <option value="جواز سفر"> جواز سفر </option>
                        </select>
                        <p className='colRight'> نوع الهوية </p>
                    </div>

                    <div className='row'>
                        <select value={guest?.guest_rate} className='colLeft' onChange={handleChange} name="guest_rate" id="role">
                            <option value=""> اختر تصنيف العميل </option>
                            <option value="مميز">  مميز </option>
                            <option value="خاص">  خاص </option>
                            <option value="غير مرغوب فيه">  غير مرغوب فيه </option>
                        </select>
                        <p className='colRight'>تصنيف العميل </p>
                    </div>


                </div>

                <div className='submitNewEmployee'>
                    <button type='submit'>
                        حفظ بيانات الضيف
                    </button>
                </div>
            </Form>

            <Modal className='modalToShowImage ' show={showImage} onHide={setHideImageFunc}>
                <Modal.Header >
                    <button className='closeModal' onClick={() => setHideImageFunc()}> <CloseRoundedIcon /> </button>
                    {/* <span  > X </span> */}
                    <Modal.Title> {guest?.name} - البطاقة التعريفية </Modal.Title>
                </Modal.Header>

                <img className='imageShowInBigModal' alt="myImage" src={`${login.API}/myImage/${guest?.personal_ID_Image}`} />


            </Modal>
        </div>
    )
}
