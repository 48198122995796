import React, { useRef } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import { useReactToPrint } from 'react-to-print';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';


export default function PrintComingLeaveCleints(props) {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (

        <Modal className='modalToShowCleints ' show={props.show} onHide={props.setHideCleintsModal}>
            <Modal.Header >
                <button className='closeModal' onClick={() => props.setHideCleintsModal()}> <CloseRoundedIcon /> </button>
                {/* <span  > X </span> */}
                <Modal.Title> طباعة {props.printData.type} </Modal.Title>
            </Modal.Header>

            {/* <p>
                هنا يجب ان تكون الزوار الذين يجب ان يحضروا في الوحدة الحالية
                {
                }
            </p> */}

            <div ref={componentRef} className='printCleintsTable'>
                <div className='headOfCleints'>
                    <span className='title_headOfCleints'>
                        سكن آيه وريم
                    </span>
                    <span className='total_HeadOfCleints_Container'>
                        {
                            props.printData.type == "الزوار المغادرون" ?

                                <span className='totalCleints_headOfCleints'>
                                    المغادرون ليوم {props.printData.date}
                                </span> :
                                <span className='totalCleints_headOfCleints'>
                                    الوافدون ليوم {props.printData.date}
                                </span>
                            // الزوار المغادرون
                        }
                        <span className='totalReservation_headOfCleints'>
                            اجمالي الحجوزات {props.printData?.myData?.length}
                        </span>

                    </span>
                </div>

                <div className='cleintsTable'>
                    <div className='cleintsTable_Row'>
                        <span className='cleintsTable_Cell'>رقم الحجز</span>
                        <span className='cleintsTable_Cell'>رقم الوحدة</span>
                        <span className='cleintsTable_Cell'>العميل</span>
                        <span className='cleintsTable_Cell'>الهاتف</span>
                        <span className='cleintsTable_Cell'>بداية الحجز</span>
                        <span className='cleintsTable_Cell'>نهاية الحجز</span>
                        <span className='cleintsTable_Cell'>عدد الليالي</span>
                        <span className='cleintsTable_Cell'>اجمالي الحجز</span>
                        <span className='cleintsTable_Cell'>حالة الحجز</span>

                    </div>

                    {
                        props.printData?.myData?.map((item, index) => {
                            return (
                                <div className='cleintsTable_Row cleintsTable_Row_Rendered' key={index}>
                                    <span className='cleintsTable_Cell'>{item.id} # </span>
                                    <span className='cleintsTable_Cell'>{item.unit}</span>
                                    <span className='cleintsTable_Cell'>{item.Guest.name}</span>
                                    <span className='cleintsTable_Cell'>{item.Guest.phone_number}</span>
                                    <span className='cleintsTable_Cell'>{item.checkIn}</span>
                                    <span className='cleintsTable_Cell'>{item.checkOut}</span>
                                    <span className='cleintsTable_Cell'>{(new Date(item.checkOut).getTime() - new Date(item.checkIn).getTime()) / (1000 * 60 * 60 * 24) + 1}</span>
                                    <span className='cleintsTable_Cell'>{parseFloat(item.price + item.services_price)?.toFixed(2)} </span>
                                    <span className='cleintsTable_Cell'>{item.status}</span>
                                </div>
                            )
                        }
                        )
                    }
                </div>
            </div>


            <span onClick={handlePrint} className='print_Btn_Span'> طباعة </span>
        </Modal>
    )
}
