import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { LoginContext } from '../../context/login';
import { Button, Form, Modal } from 'react-bootstrap';
import useForm from '../../hooks/form';
import axios from 'axios';
import cookies from 'react-cookies';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PrintIcon from '@mui/icons-material/Print';
import swal from 'sweetalert2';

import "./financialManagment.css";


export default function Expenses(props) {
    // To load the token from cookies
    const qs = new URLSearchParams(window.location.search);
    const cookieToken = cookies.load('token');
    const token = qs.get('token') || cookieToken || null;
    const login = useContext(LoginContext);

    const [expenses, setExpenses] = useState([]);
    const [filteredExpenses, setFilteredExpenses] = useState([]);
    const [filter, setFilter] = useState();
    const [showModal, setShowModal] = useState(false);
    const [spent_For, setSpent_For] = useState();
    const [guest, setGuest] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [dates, setDates] = useState([]);
    const [todayDate, setTodayDate] = useState();
    const [months, setMonths] = useState(["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]);
    const [depits, setDepits] = useState(0);
    const [maintenance, setMaintenance] = useState(0);
    const [insurance, setInsurance] = useState(0);
    const [rentAmount, setRentAmount] = useState(0);
    const [generalExpenses, setGeneralExpenses] = useState(0);
    const [transactions, setTransactions] = useState(0);
    const [sort, setSort] = useState("down");
    const [showExpence, setShowExpence] = useState(false);
    const [selectedExpence, setSelectedExpence] = useState({});
    const [managers, setManagers] = useState([]);
    const [hotel, setHotel] = useState();
    const [moneyBillsToUpdate, setMoneyBillsToUpdate] = useState(null);


    useEffect(async () => {
        props.loading(true).then(async()=>{
            let today = new Date();
            setTodayDate(today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate());
    
            let hotelData = cookies.load('hotel');
            setHotel(hotelData);
    
            await axios.post(`${login.API}/getallexpenses`, {}, { headers: { 'Authorization': `Bearer ${token}` } })
                .then(async res => {
                    setExpenses(res.data);
                    setFilteredExpenses(res.data);
                });
            await axios.post(`${login.API}/getEmployees`, {}, { headers: { 'Authorization': `Bearer ${token}` } })
                .then(async res => {
    
                    setEmployees(res.data);
                })
    
            await axios.post(`${login.API}/getAllRooms`, {}, { headers: { 'Authorization': `Bearer ${token}` } })
                .then(async res => {
                    setRooms((res.data.rooms).sort((a, b) => a.id - b.id));
                })
    
    
            await axios.get(`${login.API}/getDocuments`, { headers: { 'Authorization': `Bearer ${token}` } })
                .then(res => {
                    setManagers(res.data[0]);
                })
                props.loading(false);
        })
    }, []);

    const handleShowModal = () => {
        setShowModal(true);
    }

    const handleClose = () => {
        setShowModal(false);
        setShowExpence(false);
    }

    const handleChange_2 = () => {
        let spentfor = document.getElementById('expenseType').value;
        setSpent_For(spentfor);

    }

    const { handleChange, handleSubmit } = useForm(addExpense);

    async function addExpense(data) {
        if (!data.for) {
            data.for = spent_For;
        }

        data.employee_id = login.user.id;
        data.employee_name = login.user.name;

        if (data.reciver) {
            data.reciver_name = data.reciver.split(" - ")[1];
            data.reciver_id = parseInt(data.reciver.split(" - ")[0]);
        }


        data.type = "صرف";

        data.reservation_id = 0;
        if (!data.reciver_id) {
            data.reciver_id = 0;
        }

        props.loading(true).then(async () => {
            if (moneyBillsToUpdate) {
                await axios.post(`${login.API}/updateExpense`, { id: moneyBillsToUpdate.id, params: data }, { headers: { 'Authorization': `Bearer ${token}` } })
                    .then(async res => {
                        let newRecords = expenses.map(expense => {
                            if (expense.id !== moneyBillsToUpdate.id) return expense;
                            return { ...expense, ...data }
                        })
                        setExpenses(newRecords);
                    })
            } else {
                await axios.post(`${login.API}/newExpense`, data, { headers: { 'Authorization': `Bearer ${token}` } })
                    .then(async res => {
                        let newRecords = [...expenses, res.data.record];
                        setExpenses(newRecords);
                    })
            }

            setShowModal(false);
            props.loading(false);
            setMoneyBillsToUpdate(null);
        })
    }

    useEffect(() => {
        calculateMoney();
    }, [filteredExpenses])
    useEffect(() => {
        if(!showModal) setMoneyBillsToUpdate(null);
    }, [showModal])

    const calculateMoney = () => {

        let depitsCount = 0;
        let maintenanceCount = 0;
        let insuranceCount = 0;
        let rentAmountCount = 0;
        let generalExpensesCount = 0;
        let transactionsCount = 0;

        filteredExpenses.map(expense => {
            if (expense.for === "رواتب") {
                // setDepits(depits+parseInt(expense.amount));
                depitsCount += parseInt(expense.amount);
            } else if (expense.for === "مرتجع إيجار") {
                // setRentAmount(rentAmount+parseInt(expense.amount));
                rentAmountCount += parseInt(expense.amount);
            } else if (expense.for === "صيانة") {
                // setMaintenance(maintenance+parseInt(expense.amount));
                maintenanceCount += parseInt(expense.amount);
            } else if (expense.for === "مرتجع تأمين") {
                // setInsurance(insurance+parseInt(expense.amount));
                insuranceCount += parseInt(expense.amount);
            } else if (expense.for === "مصروفات عامة") {
                // setGeneralExpenses(generalExpenses+parseInt(expense.amount));
                generalExpensesCount += parseInt(expense.amount);
            } else if (expense.for === "تحويل من الصندوق إلى الإدارة") {
                // setTransactions(transactions+parseInt(expense.amount));
                transactionsCount += parseInt(expense.amount);
            }
        })

        setDepits(depitsCount);
        setRentAmount(rentAmountCount);
        setMaintenance(maintenanceCount);
        setInsurance(insuranceCount);
        setGeneralExpenses(generalExpensesCount);
        setTransactions(transactionsCount);

    }

    const filterFunc = () => {
        let filteredArray = expenses;

        let filterByExpeseNumber = document.getElementById('filterByExpeseNumber').value;
        let filterByExpeseType = document.getElementById('filterByExpeseType').value;
        setFilter(filterByExpeseType)
        let filterByEndDate = document.getElementById('filterByEndDate').value;
        let filterByStartDate = document.getElementById('filterByStartDate').value;
        let filterByReservation = document.getElementById('filterReservationByNumber').value;

        // Filter By Expense Number
        if (filterByExpeseNumber !== "") {
            filteredArray = filteredArray.filter(expense => {
                if (expense.id === parseInt(filterByExpeseNumber)) {
                    return expense;
                };
            });
        }
        // Filter By Reservation ID
        if (filterByReservation !== "") {
            filteredArray = filteredArray.filter(expense => {
                if (expense.reservation_id === parseInt(filterByReservation)) {
                    return expense;
                };
            });
        }
        // Filter By Expense Type
        if (filterByExpeseType !== "null") {
            filteredArray = filteredArray.filter(expense => {
                if (expense.for === filterByExpeseType) {

                    return expense;
                };
            });
        }
        // Filter By Start Date
        if (filterByStartDate !== "") {
            let date = dates;
            setDates([]);
            setDates([filterByStartDate,date[1]||new Date().toISOString().split('T')[0]]);
            let newDate;
            filteredArray = filteredArray.filter(expense => {
                newDate = new Date(expense.date)!=='Invalid Date'?new Date(expenses.date):(expense.date.split("  ")[1]).split("/")[2] + "-" + (expense.date.split("  ")[1]).split("/")[1] + "-" + (expense.date.split("  ")[1]).split("/")[0];
                if ((new Date(newDate).getTime() + 7200000 >= (new Date(filterByStartDate)).getTime())) {
                    return expense;
                };
            });
        }else{
            let date = dates;
            setDates([]);
            setDates(['',date[1]||new Date().toISOString().split('T')[0]]);
        }
        // Filter By End Date
        if (filterByEndDate !== "") {
            let date = dates;
            setDates([]);
            setDates([date[0],filterByEndDate]);
            let newDate;
            filteredArray = filteredArray.filter(expense => {
                newDate = expense.date.split("  ")[1];
                newDate = new Date(newDate)!=='Invalid Date'?new Date(newDate):newDate.split("/")[2] + "-" + newDate.split("/")[1] + "-" + newDate.split("/")[0];
                if ((new Date(newDate).getTime() + 7200000 <= (new Date(filterByEndDate)).getTime())) {
                    return expense;
                };
            });
        }else{
            let date = dates;
            setDates([]);
            setDates([date[0],'']);
        }

        setFilteredExpenses([])
        setFilteredExpenses(filteredArray);
    }

    const sortData = (sortBy) => {

        let sortedArray = filteredExpenses;
        if (sort === "up") {
            setSort("down");
            if (sortBy === "expense") {
                sortedArray = sortedArray.sort((a, b) => b.id - a.id);
            } else if (sortBy === "date") {
                sortedArray = sortedArray.sort((a, b) => {
                    return (
                        (new Date(a.date).getTime() - new Date(b.date).getTime())
                    )
                });
            } else if (sortBy === "amount") {
                sortedArray = sortedArray.sort((a, b) => b.amount - a.amount);
            } else if (sortBy === "reserve") {
                sortedArray = sortedArray.sort((a, b) => b.reservation_id - a.reservation_id);
            }
        } else if (sort === "down") {
            setSort("up");
            if (sortBy === "expense") {
                sortedArray = sortedArray.sort((a, b) => a.id - b.id);
            } else if (sortBy === "date") {
                sortedArray = sortedArray.sort((a, b) => {
                    return (
                        (new Date(b.date).getTime() - new Date(a.date).getTime())
                    )
                });
            } else if (sortBy === "amount") {
                sortedArray = sortedArray.sort((a, b) => a.amount - b.amount);
            } else if (sortBy === "reserve") {
                sortedArray = sortedArray.sort((a, b) => a.reservation_id - b.reservation_id);
            }
        }

        setFilteredExpenses([])
        setFilteredExpenses(sortedArray);
    }


    const handlesetShowExpence = (expense) => {
        setShowExpence(true);
        setSelectedExpence(expense);
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        filterFunc();
    }, [expenses])

    useEffect(() => {
        if (moneyBillsToUpdate && (moneyBillsToUpdate.for !== "مرتجع إيجار")) {
            setShowExpence(false);
            handleShowModal();
        } else if (moneyBillsToUpdate) {
            setShowExpence(false);
            swal.fire({
                title: "تعديل غير مسموح",
                text: "لا يمكنك تعديل السندات المالية الخاصة بالحجوزات من هنا، يرجى تعديلها من داخل الحجز تجنبا لأي خطأ",
                icon: 'error',
                confirmButtonColor: '#d33',
                cancelButtonText: 'حسناً'
            })
            setMoneyBillsToUpdate(null);
        }
    }, [moneyBillsToUpdate])

    const updateMoneyTransfer = item => {
        setMoneyBillsToUpdate(item);
        setSpent_For(item.for);
    }

    return (
        <div>
            <div className='navigator'>
                <p> <Link className='linkContainer' to='/'> الرئيسية </Link>  /
                    <Link className='linkContainer' to='/financialManagments'> الإدارة المالية </Link> /
                    إدارة المصروفات
                </p>
            </div>

            <div className='filterInputsContainer'>


                <div className='managementRow_1'>
                    <span className='managementFilter'>
                        <select onChange={filterFunc} id="filterByExpeseType" className='filterInput'>

                            <option value="null">صرف لأجل</option>
                            <option value="مرتجع إيجار">مرتجع إيجار </option>
                            <option value="مرتجع تأمين">مرتجع تأمين</option>
                            <option value="رواتب">رواتب</option>
                            <option value="صيانة">صيانة</option>
                            <option value="مصروفات عامة">مصروفات عامة</option>
                            <option value="تحويل من الصندوق إلى الإدارة">تحويل من الصندوق إلى الإدارة </option>
                        </select>
                    </span>

                    <span className='managementFilter'>
                        <span id="filterByEndDateDiv" className='filterInput filterByEndDateDiv'>
                            {dates[1] ? `${dates[1]} تاريخ النهاية` : "تاريخ النهاية"}
                            <input required onChange={filterFunc} id="filterByEndDate" className="filterInput filterByEndDate" title='تاريخ النهاية' type="date" />
                        </span>

                    </span>
                    <span className='managementFilter'>
                        <span id="filterByStartDateDiv" className='filterInput filterByStartDateDiv'>
                            {dates[0] ? `${dates[0]} تاريخ البداية` : "تاريخ البداية"}
                            <input required onChange={filterFunc} id="filterByStartDate" className="filterInput filterByStartDate" title='تاريخ البداية' type="date" />
                        </span>

                    </span>

                    <span className='managementFilter'>
                        <input required onChange={filterFunc} id="filterReservationByNumber" placeholder='رقم الحجز ' className='filterInput' type="text" />

                    </span>

                    <span className='managementFilter'>
                        <input required onChange={filterFunc} id="filterByExpeseNumber" placeholder='رقم السند ' className='filterInput' type="text" />

                    </span>
                </div>


                <div className='managementRow_1'>
                    <span className='managementFilter managementCol'>
                        <span>الصيانة</span>
                        <span>{maintenance?.toFixed(2)}</span>
                    </span>
                    <span className='managementFilter managementCol'>
                        <span>اجمالي التأمين</span>
                        <span>{insurance?.toFixed(2)}</span>
                    </span>
                    <span className='managementFilter managementCol'>
                        <span>مرتجعات الإيجار</span>
                        <span>{rentAmount?.toFixed(2)}</span>
                    </span>
                    <span className='managementFilter managementCol'>
                        <span>النقدي</span>
                        <span>{(rentAmount + insurance + maintenance + depits + generalExpenses + transactions)?.toFixed(2)}</span>
                    </span>

                </div>

                <div className='managementRow_1'>

                    <span className='managementFilter managementCol'>
                        <span>المبلغ الكلي </span>
                        <span>{(rentAmount + insurance + maintenance + depits + generalExpenses + transactions)?.toFixed(2)}</span>
                    </span>

                    <span className='managementFilter managementCol'>
                        <span>تحويل من الصندوق إلى الإدارة </span>
                        <span>{transactions?.toFixed(2)}</span>
                    </span>

                    <span className='managementFilter managementCol'>
                        <span>المصروفات العامة</span>
                        <span>{generalExpenses?.toFixed(2)}</span>
                    </span>


                    <span className='managementFilter managementCol'>
                        <span>الرواتب</span>
                        <span>{depits?.toFixed(2)}</span>
                    </span>
                </div>

                <div className='managementRow_1'>



                </div>
            </div>

            <div className='printFeild'>
                <i title="طباعة التقرير" onClick={handlePrint} className="printIcon">
                    <PrintIcon />
                </i>
                {/*  */}

                {/*  */}

                <span title="excel تصدير بصيغة ملف">
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="printIcon far fa-file-excel"
                        table="myReport"
                        filename="تقرير الحجوزات"
                        sheet="تقرير الحجوزات"
                        buttonText=""
                    />
                </span>
                {/* </i> */}
            </div>

            <div className='filterInputsContainer'>
                <div className='managementRow_1_button '>
                    <Button onClick={handleShowModal}>
                        إضافة سند صرف
                    </Button>
                </div>

                <table ref={componentRef} id="myReport">
                    <thead>
                        <tr className='trMainTable'>
                            <th className='roomSetingHeader'>الاجراءات </th>
                            <th className='partOfHeadTable' > الملاحظات</th>
                            <th className='partOfHeadTable' > الموظف</th>
                            <th className='partOfHeadTable expense_filter_option' onClick={() => sortData("date")}> تاريخ السند </th>
                            <th className='partOfHeadTable' > لأجل</th>
                            <th className='partOfHeadTable' > صرف ل</th>
                            <th className='partOfHeadTable expense_filter_option' onClick={() => sortData("amount")}> المبلغ</th>
                            <th className='partOfHeadTable'  >رقم الوحدة</th>
                            <th className='partOfHeadTable expense_filter_option' onClick={() => sortData("reserve")} > رقم الحجز </th>
                            <th className='partOfHeadTable expense_filter_option' onClick={() => sortData("expense")}> رقم السند </th>
                        </tr>

                    </thead>
                    <tbody>
                        {/* run from database */}
                        {filteredExpenses.map((expense, index) => {
                            return (
                                expense.for !== "خدمات" &&
                                <tr onClick={() => { handlesetShowExpence(expense) }} className='veiwReservationTable expense_row' key={index}>
                                    <td>
                                        <div data-v-0a75d390="" className="action">

                                            <a className="takeActionUnit" title="عرض">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20"
                                                    height="20" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                                </svg>
                                            </a>

                                            {
                                                login.user?.role === "manager" &&
                                                <a onClick={() => updateMoneyTransfer(expense)} className="takeActionUnit" title="تعديل">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                    </svg>
                                                </a>
                                            }
                                        </div>
                                    </td>
                                    <td>{expense.description}</td>
                                    <td>{expense.employee_name}</td>
                                    <td>{expense.date}</td>
                                    <td>{expense.for + " - " + expense.paied_for}</td>
                                    <td>{expense.reciver_name}</td>
                                    <td>{expense.amount?.toFixed(2)}</td>
                                    <td>{expense.paied_for}</td>
                                    <td>{expense.reservation_id}</td>
                                    <td>{expense.id}</td>
                                </tr>
                            )
                        })}

                        <tr id="reciepts_amount">
                            {filter === "مرتجع إيجار" ?
                                <td colSpan={6}>{rentAmount?.toFixed(2)}</td>
                                :
                                filter === "رواتب" ?
                                    <td colSpan={6}>{depits?.toFixed(2)}</td>
                                    :
                                    filter === "مرتجع تأمين" ?
                                        <td colSpan={6}>{insurance?.toFixed(2)}</td>
                                        :
                                        filter === "مصروفات عامة" ?
                                            <td colSpan={6}>{generalExpenses?.toFixed(2)}</td>
                                            :
                                            filter === "تحويل من الصندوق إلى الإدارة" ?
                                                <td colSpan={6}>{transactions?.toFixed(2)}</td>
                                                :
                                                filter === "صيانة" ?
                                                    <td colSpan={6}>{maintenance?.toFixed(2)}</td>
                                                    :
                                                    <td colSpan={6}>{(rentAmount + insurance + transactions + generalExpenses + depits + maintenance)?.toFixed(2)}</td>
                            }

                            <th colSpan={6}>المبلغ الكلي</th>

                        </tr>




                    </tbody>
                </table>
            </div>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header className='recipts_modal_header'>
                    <CloseRoundedIcon onClick={handleClose} />
                    <Modal.Title>مصروفات</Modal.Title>
                </Modal.Header>

                <Form onSubmit={handleSubmit}>
                    <Modal.Body className='recipts_modal_body'>
                        <div className='recipts_modal_body_top'>
                            <div>
                                <span>التاريخ</span>
                                <input required name='date' onChange={handleChange} type="date" />
                            </div>
                            <div>
                                <span> النوع</span>
                                <select required name="for" className='recipts_type' id="expenseType" onChange={handleChange_2}>
                                    <option value="">  إختر النوع </option>
                                    <option selected={moneyBillsToUpdate?.for === "رواتب" ? 'selected' : ''} value="رواتب">رواتب</option>
                                    <option selected={moneyBillsToUpdate?.for === "صيانة" ? 'selected' : ''} value="صيانة">صيانة</option>
                                    <option selected={moneyBillsToUpdate?.for === "مصروفات عامة" ? 'selected' : ''} value="مصروفات عامة">مصروفات عامة</option>
                                    <option selected={moneyBillsToUpdate?.for === "تحويل من الصندوق إلى الإدارة" ? 'selected' : ''} value="تحويل من الصندوق إلى الإدارة">تحويل من الصندوق إلى الإدارة </option>
                                </select>
                            </div>
                        </div>

                        <div className='recipts_modal_body_middle'>
                            {(spent_For === "رواتب" || moneyBillsToUpdate?.for === "رواتب") ?
                                <div>
                                    <span>استلمت بواسطة</span>
                                    <select required name="reciver" className='recipts_type' id="expenseType" onChange={handleChange}>
                                        <option value="">  إختر الموظف </option>
                                        {employees.map(employee => {
                                            return (
                                                <option selected={moneyBillsToUpdate?.reciver === `${employee.id} - ${employee.name}` ? 'selected' : ''} value={`${employee.id} - ${employee.name}`}>{`${employee.id} - ${employee.name}`}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                : (spent_For === "صيانة" || moneyBillsToUpdate?.for === "صيانة") ?
                                    <div>
                                        <span>استلمت بواسطة</span>
                                        <input required defaultValue={moneyBillsToUpdate?.reciver_name} type='text' name='reciver_name' onChange={handleChange} placeholder="أدخل إسم المستلم" />
                                    </div>
                                    :
                                    (spent_For === "تحويل من الصندوق إلى الإدارة" || moneyBillsToUpdate?.for === "تحويل من الصندوق إلى الإدارة") ?
                                        <div>
                                            <span>استلمت بواسطة</span>
                                            <select required name="reciver_name" className='recipts_type' id="expenseType" onChange={handleChange}>
                                                <option value="">  إختر المدير </option>
                                                {employees.map((employee, index) => {
                                                    if (employee.role === "manager") {
                                                        return (
                                                            <option selected={moneyBillsToUpdate?.reciver_name === employee.name ? 'selected' : ''} key={index} value={employee.name}>{employee.name}</option>
                                                        )
                                                    }

                                                })}
                                            </select>
                                        </div>
                                        :
                                        <div>
                                            <span>استلمت بواسطة</span>
                                            <input required  defaultValue={moneyBillsToUpdate?.reciver_name} type='text' name="reciver_name" onChange={handleChange} placeholder="أدخل إسم المستلم" />
                                        </div>
                            }

                            <div>
                                <span> المبلغ</span>
                                <input required  defaultValue={moneyBillsToUpdate?.amount?.toFixed(2)} name='amount' type='text' onChange={handleChange} placeholder="أدخل المبلغ" />
                            </div>
                        </div>

                        <div className='recipts_modal_body_bottom'>
                            <div>
                                <span> قام بتسليمها</span>
                                <input required readOnly defaultValue={login.user.name} type='text' />
                            </div>
                            {(spent_For === "رواتب" || moneyBillsToUpdate?.for === "رواتب") ?
                                <div>
                                    <span> لاجل </span>
                                    <select required name="paied_for" className='recipts_type' onChange={handleChange}>
                                        <option value=""> إختر الشهر  </option>
                                        {months.map(month => {
                                            return (
                                                <option selected={moneyBillsToUpdate?.paied_for === `${month} -  راتب شهر ` ? 'selected' : ''} value={`${month} -  راتب شهر `}>{`${month} -  راتب شهر `}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                : (spent_For === "صيانة" || moneyBillsToUpdate?.for === "صيانة") ?
                                    <div>
                                        <span> لاجل </span>
                                        <select required name="paied_for" className='recipts_type' onChange={handleChange}>
                                            <option value="">  إختر الغرفة </option>
                                            {rooms.map(room => {
                                                return (
                                                    <option selected={moneyBillsToUpdate?.paied_for === `${room.id} -  صيانة غرفه رقم ` ? 'selected' : ''} value={`${room.id} -  صيانة غرفه رقم `}>{`${room.id} -  صيانة غرفه رقم `}</option>
                                                )
                                            })}
                                            <option selected={moneyBillsToUpdate?.paied_for === "أخرى" ? 'selected' : ''} value="أخرى">  أخرى </option>

                                        </select>
                                    </div>
                                    :
                                    <div>
                                        <span> لاجل </span>
                                        <input required defaultValue={moneyBillsToUpdate?.paied_for} name='paied_for' type='text' placeholder='أدخل السبب' onChange={handleChange} />
                                    </div>
                            }
                        </div>

                        <div className='recipts_modal_body_bottom'>
                            <div>
                                <span>ملاحظات </span>
                                <input required defaultValue={moneyBillsToUpdate?.description} onChange={handleChange} name='description' type='text' placeholder='... أدخل الملاحظات ' />
                            </div>
                            <div>
                                <span> طريقة الدفع </span>
                                <select required onChange={handleChange} name="payment_method" className='recipts_type'>
                                    <option value="">  إختر طريقة الدفع </option>
                                    <option selected={moneyBillsToUpdate?.payment_method === "دفع نقدي" ? 'selected' : ''} value="دفع نقدي">دفع نقدي </option>
                                    <option selected={moneyBillsToUpdate?.payment_method === "دفع بنكي" ? 'selected' : ''} value="دفع بنكي"> دفع بنكي</option>
                                </select>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='recipts_modal_footer'>
                        <Button type='submit' variant="primary" >
                            {moneyBillsToUpdate ? 'تعديل السند' : 'اضافة سند'}
                        </Button>
                    </Modal.Footer>

                </Form>
            </Modal>

            <Modal show={showExpence} onHide={handleClose} className='invoice_modal'>
                <Modal.Header className='contarct_modal_header'>
                    <CloseRoundedIcon onClick={handleClose} />

                    <Modal.Title>
                        سند رقم #{selectedExpence.id}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Header className='contarct_modal_print'>
                    <i onClick={handlePrint} >
                        <PrintIcon />
                    </i>
                </Modal.Header>

                <Modal.Body className='contarct_modal_body'>



                    <div ref={componentRef} className='reciptsAndExpense_modal_body_container'>

                        <div className='invoice_modal_body_top'>
                            <span>رقم السند #{selectedExpence.id}</span>
                            <div>
                                <h3>{hotel?.name}</h3>
                                {/* <h5>{hotel?.location}</h5> */}
                            </div>
                            <span>التاريخ : {selectedExpence.date}</span>
                        </div>

                        <div className='contarct_modal_body_middle'>
                            <h3>  {` سند ${selectedExpence.type} `} </h3>
                        </div>

                        <div className='reciptsAndExpense_modal_body_info'>
                            <ul>
                                <li>
                                    <span>Paid To Master : </span>
                                    <span>{selectedExpence.reciver_name}</span>
                                    <span>: تم تسليمها إلى السيد / السيدة </span>
                                </li>
                                <li>
                                    <span>Amount : </span>
                                    <span>{selectedExpence.amount?.toFixed(2)}</span>
                                    <span> : مبلغ وقدرة </span>
                                </li>
                                <li>
                                    <span>Payment Method : </span>
                                    <span>{selectedExpence.payment_method}</span>
                                    <span> : طريقة الدفع </span>
                                </li>
                                <li>
                                    <span>Being : </span>
                                    <span>{`${selectedExpence.for} / ${selectedExpence.paied_for}`}</span>

                                    <span> : وذلك مقابل </span>
                                </li>
                                <li>
                                    <span>Notes : </span>
                                    <span>{selectedExpence.description}</span>
                                    <span> : ملاحظات </span>
                                </li>
                            </ul>

                        </div>



                        <div className='signature'>
                            <div>
                                <span>المدير</span>
                                <span>{managers?.expenseName}</span>
                            </div>

                            <div>
                                <span>الموظف</span>
                                <span>{selectedExpence.employee_name}</span>
                            </div>

                            <div>
                                <span>المستلم</span>
                                <span>{selectedExpence.reciver_name}</span>
                            </div>

                        </div>



                        <div className='contract_hotel_info'>
                            <span>{hotel?.name}</span>
                            <span>{hotel?.email}</span>
                            <span>{hotel?.phone}</span>
                        </div>


                    </div>
                </Modal.Body>
            </Modal>


        </div>
    )
}
