import React, { useEffect, useState, useContext } from 'react';
import "./SideBar.css";
// import "https://kit.fontawesome.com/a076d05399.js";
import { GiBunkBeds, GiExitDoor, GiBank, GiStarsStack } from 'react-icons/gi';
import { FaConciergeBell, FaHeadset } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import barLogo from "../../image/Aya&Reem.png";
import { LoginContext } from '../../context/login';
import axios from 'axios';
import cookies from 'react-cookies';



export default function SideBar(props) {
    // To load the token from cookies
    const qs = new URLSearchParams(window.location.search);
    const cookieToken = cookies.load('token');
    const token = qs.get('token') || cookieToken || null;

    const login = useContext(LoginContext);

    const [hotel_Info, setHotel_Info] = useState(null);
    useEffect(()=>{
        props.setLoadingFunc(true).then(async () => {
            await axios.post(`${login.API}/getMyHotel` ,{} , { headers: { 'Authorization': `Basic ${token}` }})
                .then(res => {
                    setHotel_Info(res.data[0]);
                    props.setLoadingFunc(false);
                })
        })
    },[])

    const toUp = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    return (

        <div className="sidebar">

            <Link onClick={toUp} to="/">
                <div className='logo'>
                    {/* <img alt="logo" src={`${login.API}/myImage/${hotel_Info?.image}`} /> */}
                    <img alt="logo" src={barLogo} />
                </div>
            </Link>


            <ul>

                <Link to="/" onClick={toUp}>
                    <li className="listItemA">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="29.99" viewBox="0 0 38.049 38.041"><g transform="translate(0 0)">
                            <path d="M49.078,34.419a.935.935,0,0,0-1.225.01L45.3,36.712v-.3a.634.634,0,1,0-1.268,0v1.435l-1.057.946a.634.634,0,0,0,.846.945l4.649-4.162,4.649,4.162a.634.634,0,1,0,.846-.945Z" transform="translate(-39.591 -31.658)" fill="#9fafbb"></path><path d="M68.017,119.495a.634.634,0,0,0-.634.634v4.438h-6.34v-4.438a.634.634,0,1,0-1.268,0v4.841a.877.877,0,0,0,.888.865h7.1a.879.879,0,0,0,.888-.867v-4.839A.634.634,0,0,0,68.017,119.495Z" transform="translate(-55.333 -110.619)" fill="#9fafbb">
                            </path>
                            <path d="M64.213,221.874a4.438,4.438,0,1,0,4.438,4.438A4.438,4.438,0,0,0,64.213,221.874Zm-3.17,4.438a3.176,3.176,0,0,1,2.536-3.107v2.747l-2.346,1.407A3.126,3.126,0,0,1,61.043,226.312Zm.844,2.135,2.228-1.337,1.844,1.844A3.14,3.14,0,0,1,61.887,228.447Zm4.968-.39-2.008-2.007v-2.844a3.164,3.164,0,0,1,2.008,4.851Z" transform="translate(-55.333 -205.39)" fill="#9fafbb">
                            </path>
                            <path d="M255.422,43.056v-2.17a1,1,0,0,0-1-1h-1.8a1,1,0,0,0-1,1v2.17H250.35V39.081a1.1,1.1,0,0,0-1.1-1.1h-1.61a1.1,1.1,0,0,0-1.1,1.1v3.975h-1.268V36.082a1.268,1.268,0,0,0-1.268-1.268h-1.268a1.268,1.268,0,0,0-1.268,1.268v6.974h-.634a.634.634,0,0,1-.634-.634V34.814a.634.634,0,1,0-1.268,0v7.608a1.9,1.9,0,0,0,1.9,1.9h14.582a.634.634,0,0,0,0-1.268Zm-11.412,0h-1.268V36.082h1.268Zm5.072,0h-1.268v-3.8h1.268Zm5.072,0h-1.268v-1.9h1.268v1.9Z" transform="translate(-221.182 -31.644)" fill="#9fafbb">
                            </path>
                            <path d="M255.422,213.688H240.84a.634.634,0,0,1-.634-.634v-1.006l2.088-2.088a.647.647,0,0,1,.9,0l.743.743a1.9,1.9,0,0,0,2.689,0l2.645-2.645a.647.647,0,0,1,.9,0l.743.744a1.906,1.906,0,0,0,2.689,0l2.274-2.274a.634.634,0,0,0-.9-.9l-2.274,2.273a.634.634,0,0,1-.9,0l-.743-.743a1.948,1.948,0,0,0-2.689,0l-2.645,2.645a.634.634,0,0,1-.9,0l-.742-.743a1.948,1.948,0,0,0-2.69,0l-1.191,1.191v-4.81a.634.634,0,1,0-1.268,0v7.608a1.9,1.9,0,0,0,1.9,1.9h14.582a.634.634,0,0,0,0-1.268Z" transform="translate(-221.182 -189.596)" fill="#9fafbb">
                            </path>
                            <path d="M38.045,29.505V3.569A3.36,3.36,0,0,0,34.875.053H3.174A3.36,3.36,0,0,0,0,3.569V29.505a3.36,3.36,0,0,0,3.17,3.516H13.318V34.29H10.782a2.536,2.536,0,0,0-2.536,2.536v.634a.634.634,0,0,0,.634.634H29.169a.634.634,0,0,0,.634-.634v-.634a2.536,2.536,0,0,0-2.536-2.536H24.731V33.022H34.875A3.36,3.36,0,0,0,38.045,29.505ZM27.266,35.558a1.268,1.268,0,0,1,1.268,1.268H9.514a1.268,1.268,0,0,1,1.268-1.268ZM14.586,34.29V33.022h8.876V34.29Zm20.288-2.536H3.174a2.1,2.1,0,0,1-1.9-2.248V27.95h35.5v1.556A2.1,2.1,0,0,1,34.875,31.754Zm1.9-28.185V26.681H1.272V3.569a2.1,2.1,0,0,1,1.9-2.248h31.7A2.1,2.1,0,0,1,36.777,3.569Z" transform="translate(0 -0.053)" fill="#9fafbb">
                            </path></g>
                        </svg>
                        <p> الرئيسية </p>
                    </li>
                </Link>


                <Link onClick={toUp} to="/housing-units">
                    <li className="listItemA">

                        <GiBunkBeds />
                        <p> تسكين الوحدات </p>

                    </li>
                </Link>

                <Link onClick={toUp} to="/Reservation-Table">
                    <li className="listItemA">
                        <FaConciergeBell />
                        <p> جدول الحجوزات </p>
                    </li>
                </Link>

                <Link onClick={toUp} to="/ReservationManagement">
                    <li className="listItemA">
                        <GiExitDoor />
                        <p> ادارة الحجوزات </p>
                    </li>
                </Link>

                <Link onClick={toUp} to="/financialManagments">
                    <li className="listItemA">
                        <GiBank />
                        <p> الادارة المالية </p>
                    </li>
                </Link>

                <Link onClick={toUp} to='/unit'>
                    <li className="listItemA" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-building" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z" />
                            <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z" />
                        </svg>
                        <p> الوحدات </p>
                    </li>
                </Link>

                <Link onClick={toUp} to="/settings">
                    <li className="listItemA">
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-gear" viewBox="0 0 16 16">
                            <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                            <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                        </svg>
                        <p> الاعدادات </p>
                    </li>
                </Link>

                <Link to="/techincal-support" onClick={toUp}>
                    <li className="listItemA">
                        <FaHeadset />
                        <p> الدعم الفني </p>
                    </li>
                </Link>

            </ul>

        </div>
    )
}
