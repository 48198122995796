import React, { useState, useEffect, useContext } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AddOffer from './AddOffer';
import AddPrice from './AddPrice';
import { LoginContext } from '../../../context/login';
import cookies from 'react-cookies';
import "./offer.css"
import axios from 'axios';
import Swal from 'sweetalert2';



export default function Unit(props) {

    const login = useContext(LoginContext);
    // handelling models
    const [showOffer, setShowOffer] = useState(false);
    const [showPrice, setShowPrice] = useState(false);

    const [departments, setDepartments] = useState([]);
    const [units, setUnits] = useState([]);

    const [offer, setOffer] = useState([]);
    const [price, setPrice] = useState([]);

    const [defaultOffer, setDefaultOffer] = useState(null);
    const [defaultPrice, setDefaultPrice] = useState(null);

    const [days, setDays] = useState([
        "السبت",
        "الاحد",
        "الاثنين",
        "الثلاثاء",
        "الاربعاء",
        "الخميس",
        "الجمعة",
    ]);


    // handelling the add offer

    useEffect(async () => {
        props.setLoadingFunc(true).then(() => {
            axios.post(`${login.API}/getAllRooms`,{},
                { headers: { 'Authorization': `Basic ${cookies.load('token')}` } })
                .then(async res => {
                    let myRooms = res.data.rooms;
                    let myDepartment = res.data.department;
                    setDepartments(res.data.department)

                    myRooms.sort((a, b) => {
                        if (a.id > b.id) return 1;
                        if (a.id < b.id) return -1;
                        return 0;
                    })

                    setUnits(myRooms);

                    axios.get(`${login.API}/getAllOffers`,{ headers: { 'Authorization': `Basic ${cookies.load('token')}` } }).then(async res => {


                        let myOffers = res.data.offers;
                        let myPrices = res.data.price;

                        await setPrice(myPrices);
                        await setOffer(myOffers);

                        props.setLoadingFunc(false);


                    })


                })

        })
    }, []);
    // handelling the add price

    const handelOffer = () => {
        setDefaultOffer(null);
        setShowOffer(!showOffer)
    }
    const handelPrice = () => {
        setDefaultPrice(null);
        setShowPrice(!showPrice)
    }
    const handelWithEdit = (index, type) => {
        if (type == "offer") {
            setDefaultOffer(null)
            const myData = offer[index];
            setDefaultOffer(myData);
            setShowOffer(!showOffer);
        } else if (type == "price") {
            setDefaultPrice(null);
            const myData = price[index];
            setDefaultPrice(myData);
            setShowPrice(!showOffer);
        }
    }
    // ***********
    const handelUpdate = (type, data) => {
        if (type === "offer") {
            let myData = offer;
            setOffer([])
            myData = data;
            setOffer(myData);
        }
        else {
            let myData = price;
            setPrice([])
            myData = data;
            setPrice(myData);
        }
    }
    // set new Offers and Prices
    const addOfferandPrice = (type, data) => {
        if (type === "offer") {
            let myData = offer;
            setOffer([])
            myData.push(data);
            setOffer(myData);
        }
        else {
            let myData = price;
            setPrice([])
            myData.push(data);
            setPrice(myData);
        }
    }

    // Delete Data 
    const deleteData = (type, id) => {
        // deleteOfferAndPrice

        Swal.fire({
            title: 'هل انت متأكد من حذف البيانات؟',
            text: "لن تستطيع عكس هذا الحذف!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'تأكيد الحذف',
        }).then((result) => {
            if (result.isConfirmed) {

                props.setLoadingFunc(true);
                axios.post(`${login.API}/deleteOfferAndPrice`, { id, type },
                { headers: { 'Authorization': `Basic ${cookies.load('token')}` } })
                    .then(res => {
                        props.setLoadingFunc(false);
                        switch (type) {
                            case "offer":
                                setOffer([])
                                setOffer(res.data);
                                break;

                            default:
                                setPrice([])
                                setPrice(res.data);
                                break;
                        }
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                    }).catch(err => {
                        props.setLoadingFunc(false);
                        Swal.fire(
                            'Error!',
                            'Something went wrong.',
                            'error'

                        )
                    })
            }
        })


    }
    return (
        <div className='mainUnitContainer'>

            <div className='navigator'>
                <p> <Link className='linkContainer' to='/'> الرئيسية </Link>  /  <Link className='linkContainer' to='/unit'>  الوحدات </Link> / العروض والاسعار الخاصة </p>

            </div>

            {/* Table */}

            <div>


                <div className='headOfTable'>
                    <button onClick={handelOffer} className='addOfferBtn'  > إضافة عرض جديد </button>
                    <label> العروض </label>
                </div>

                <div className='bodyOfTable'>
                    <Table striped bordered hover>
                        <thead>
                            {/* رقم الوحدة	الاسم	قسم الوحدة	مفعل	حالة الوحدة	الصورة الرئيسية	الاجرائات */}
                            <tr className='trMainTable'>
                                <th>الاجراءات </th>
                                <th>بداية / نهاية</th>
                                <th>تاريخ الانشاء</th>
                                <th>اقسام الوحدات</th>
                                <th>نوع الخصم / قيمتة</th>
                                <th>الاسم</th>
                                {/* الاسم	نوع الخصم / قيمتة	اقسام الوحدات	تاريخ الانشاء	بداية / نهاية	الاجرائات */}
                            </tr>
                        </thead>
                        <tbody>
                            {/* run from database */}
                            {/* offer.map */}
                            {
                                offer &&

                                offer.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <div data-v-0a75d390="" className="action">
                             
                                                    <a onClick={() => handelWithEdit(index, "offer")} className="takeActionUnit" title="تعديل">

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                            <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                        </svg>

                                                    </a>
                                                    {/* deleteData */}
                                                    <a onClick={() => deleteData("offer", item.id)} className="takeActionUnit" title="حذف">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                            <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                        </svg>
                                                    </a>
                                                </div>
                                            </td>

                                            <td>{new Date(item.endDate).toLocaleDateString()} - {new Date(item.startDate).toLocaleDateString()}</td>
                                            <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                                            <td>
                                                {/* {item.departments[0]} */}
                                                {
                                                    item?.departments?.map((item, idx) => {
                                                        return (
                                                            <span key={index}> / {item}</span>
                                                        )
                                                    })
                                                }
                                            </td>
                                            <td>{item.type} / {item.value}</td>
                                            <td>{item.name}</td>
                                        </tr>
                                    )
                                })
                            }



                        </tbody>
                    </Table>
                </div>
            </div>



            <div className='offersTable'>



                <div className='headOfTable'>
                    <button onClick={handelPrice} className='addOfferBtn'  > إضافة سعر خاص </button>
                    <lable> الاسعار الخاصة </lable>
                </div>

                <div className='bodyOfTable'>
                    <Table striped bordered hover>
                        <thead>
                            {/* الاسم	قسم الوحدة	تاريخ الانشاء	تاريخ بداية ونهاية السعر الخاص	الاجرائات */}
                            <tr className='trMainTable'>
                                <th>الاجراءات </th>
                                <th>السعر الخاص</th>
                                <th>تاريخ بداية ونهاية</th>
                                <th>تاريخ الانشاء</th>
                                <th>قسم الوحدة</th>
                                <th>الاسم</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* run from database */}
                            {
                                price &&
                                price.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <div data-v-0a75d390="" className="action">
                                                    
                                                    <a onClick={() => handelWithEdit(index, "price")} className="takeActionUnit" title="تعديل">

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                            <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                        </svg>

                                                    </a>

                                                    <a onClick={() => deleteData("price", item.id)} className="takeActionUnit" title="حذف" >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                            <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                        </svg>

                                                    </a>

                                                </div>
                                            </td>
                                            <td>
                                                <div className='spacialPriceContainer_TD'>
                                                    {
                                                        item?.price?.map((pr, id) => {
                                                            return (
                                                                <span className='spacialPriceContainer' key={id}>
                                                                    <span>دينار</span>
                                                                    <span>{pr > 0 ? pr : '--'}</span>
                                                                    <span> {days[id]} </span>
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </td>
                                            <td>{new Date(item.endDate).toLocaleDateString()} - {new Date(item.startDate).toLocaleDateString()}</td>
                                            <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                                            <td>{item.department}</td>
                                            <td>{item.name}</td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </Table>
                </div>
            </div>


            {/* models */}
            <AddOffer handelUpdate={handelUpdate} defaultOffer={defaultOffer} addOfferandPrice={addOfferandPrice} setLoadingFunc={props.setLoadingFunc} units={units} departments={departments} show={showOffer} handleClose={setShowOffer} />
            <AddPrice handelUpdate={handelUpdate} defaultPrice={defaultPrice} addOfferandPrice={addOfferandPrice} setLoadingFunc={props.setLoadingFunc} units={units} departments={departments} show={showPrice} handleClose={setShowPrice} />

        </div>
    )
}
