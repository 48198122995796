import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import cookies from 'react-cookies';
import { LoginContext } from '../../context/login';
import { Table } from 'react-bootstrap';

export default function GuestsList() {
    // To load the token from cookies
    const qs = new URLSearchParams(window.location.search);
    const cookieToken = cookies.load('token');
    const token = qs.get('token') || cookieToken || null;
    const login = useContext(LoginContext);

    const [guests, setGuests] = useState([]);

    useEffect(() => {
        axios.post(`${login.API}/getGuest`, {}, { headers: { 'Authorization': `Basic ${token}` } })
            .then(res => {
                setGuests(res.data.sort((a, b) => (a.name > b.name) ? 1 : -1));
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    const sendGuest = (guest) => {
        cookies.remove('guest');
        cookies.save('guest', JSON.stringify(guest));
    }

    return (
        <div>
            <div className='navigator'>
                <p> <Link className='linkContainer' to='/'>الرئيسية</Link>
                    /
                    <Link className='linkContainer' to='/settings'>
                        الإعدادات
                    </Link> /
                    قائمة العملاء
                </p>
            </div>


            <div className='employessTables'>
                <div className='headOfTable'>
                    <p> قائمة العملاء </p>

                </div>

                <div className='bodyOfTable'>
                    <Table striped bordered hover>
                        <thead>
                            {/* رقم الوحدة	الاسم	قسم الوحدة	مفعل	حالة الوحدة	الصورة الرئيسية	الاجرائات */}
                            <tr className='trMainTable'>
                                <th>عرض معلومات العميل</th>
                                <th>الجنس</th>
                                <th>البريد الالكتروني</th>
                                <th>رقم ولي الأمر</th>
                                <th>رقم الهاتف</th>
                                <th>اسم العميل</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                guests.map((guest, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td id="showMyGeust" onClick={() => sendGuest(guest)}>
                                                <Link to="/guest">
                                                    عرض المعلومات
                                                </Link>
                                            </td>
                                            <td> {guest.gender} </td>
                                            <td> {guest.email} </td>
                                            <td> {guest.phone_number_2} </td>
                                            <td> {guest.phone_number} </td>
                                            <td> {guest.name} </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    )
}
