import axios from 'axios';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LoginContext } from '../../context/login';
import cookies from 'react-cookies';
// import { LoginContext } from '../../context/login';
import Swal from 'sweetalert2';
import { useReactToPrint } from 'react-to-print';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import MyTable from './MyTable';
import PrintIcon from '@mui/icons-material/Print';
import CheckIcon from '@mui/icons-material/Check';
import "./management.css";

export default function ReservationManagement(props) {
    // To load the token from cookies
    const qs = new URLSearchParams(window.location.search);
    const cookieToken = cookies.load('token');
    const token = qs.get('token') || cookieToken || null;

    const login = useContext(LoginContext)

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    // Get data from database
    // Conditional Loop

    // set div on click to the input date
    // filterByEndDateDiv"filterByStartDateDiv


    const [reservation, setReservation] = useState([]);

    const [reservationFiltered, setReservationFiltered] = useState([]);
    const [typeOfSort, setTypeOfSort] = useState('increasing');

    const [geust, setGeust] = useState([]);
    const [rooms, setRooms] = useState([]);

    // getExpense'getReceipts
    const [expense, setExpense] = useState([]);
    const [receipts, setReceipts] = useState([]);

    const [masaref, setMasrof] = useState(0);
    const [maqbodat, setMaqbod] = useState(0);
    const [totalDaen, setTotalDaen] = useState(0);
    const [totalMaden, setTotalMaden] = useState(0);
    const [withDeletedReservation, setWithDeletedReservation] = useState(false);

    const [totalRent, setTotalRent] = useState(0);
    const [totalServices, setTotalServices] = useState(0);

    const [totalOfAll, setTotalOfAll] = useState(0);
    const [totalTaxes, setTotalTaxes] = useState(0);
    const [totalIncoming, setTotalIncoming] = useState(0);

    const [hotel_Info, setHotel_Info] = useState({});

    const [dates, setDates] = useState([])
    useEffect(() => {
        let myGeust;
        let myRoom;

        let myEx;
        let myRec;

        props.setLoadingFunc(true).then(async () => {

            await axios.post(`${login.API}/getGuest`, {}, { headers: { Authorization: `Bearer ${token}` } })
                .then(async res => {
                    myGeust = res.data;
                    // to sort with respect to the name
                    myGeust.sort(function (a, b) {
                        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                    });
                    await setGeust(myGeust);
                    // geusts
                }
                )

            await axios.post(`${login.API}/getAllRooms`, {}, { headers: { Authorization: `Bearer ${token}` } })
                .then((res) => {
                    myRoom = res.data.rooms;
                    // to sort the array
                    myRoom.sort(function (a, b) {
                        return a.id - b.id;
                    });

                    setRooms(myRoom)
                    // rooms
                })
            // getExpense'getReceipts
            await axios.get(`${login.API}/getReceipts`, { headers: { 'Authorization': `Basic ${token}` } })
                .then(res => {
                    // setReceipts]setExpense
                    myRec = res.data;
                    setReceipts(res.data);
                });
            await axios.get(`${login.API}/getExpense`, { headers: { 'Authorization': `Basic ${token}` } })
                .then(res => {
                    // setReceipts]setExpense
                    myEx = res.data;
                    setExpense(res.data);
                });

            await axios.post(`${login.API}/getAllReservation`, {}, { headers: { Authorization: `Bearer ${token}` } })
                .then((res) => {

                    let myReservation = res.data;
                    // to sort with respect to the name
                    myReservation.sort(function (a, b) {
                        var reserveOne = a.checkIn; // ignore upper and lowercase
                        var reserveTwo = b.checkIn; // ignore upper and lowercase
                        if (reserveOne > reserveTwo) {
                            return -1;
                        }
                        if (reserveOne < reserveTwo) {
                            return 1;
                        }
                    });

                    // set rooms in the reservation 
                    for (let i = 0; i < myReservation.length; i++) {
                        // status: "لم يسجل دخول"   status: "سجل دخول"  status: "سجل مغادرة"
                        if (myReservation[i].confirmed == false) {
                            myReservation[i].percenteOfGet = "ملغي";
                            myReservation[i].status = "حجز ملغي";
                        }
                        else if (myReservation[i].status == "لم يسجل دخول") {
                            myReservation[i].percenteOfGet = "حجز مستقبلي";
                        } else if (myReservation[i].status == "سجل دخول") {
                            myReservation[i].percenteOfGet = "مؤكد";
                        } else if (myReservation[i].status == "سجل مغادرة") {
                            myReservation[i].percenteOfGet = "حجز منقضي";
                        }

                        for (let j = 0; j < myRoom.length; j++) {
                            // guest_id
                            if (myRoom[j].id == myReservation[i].room_id) {
                                myReservation[i].room_name = myRoom[j].room_name;
                                break;
                            }
                        }

                        myReservation[i].priceWithTax = myReservation[i].price;
                    }
                    getFilteredAmountOfMoney(myReservation, myGeust, myRec, myEx)
                    setReservationFiltered(myReservation);
                    setReservation(myReservation);
                    // reservations
                })

            props.setLoadingFunc(false)
        })

    }, [])

    useEffect(() => {
        getFilteredAmountOfMoney(reservationFiltered, geust, receipts, expense);
    }, [withDeletedReservation])
    // sort function
    async function sortReservation(respectTo) {

        let newArr = reservationFiltered;

        // buttons ==>
        let id = document.getElementById("id");
        let geust = document.getElementById("geust");
        let unit = document.getElementById("unit");
        let start = document.getElementById("start");
        let end = document.getElementById("end");
        let money = document.getElementById("money");
        // tableSortButton.selectedHeadButton
        await setReservationFiltered([]);
        if (typeOfSort == 'increasing') {
            setTypeOfSort('decreasing');
            // 
            if (respectTo == 'id') {
                // setAttributes
                id.style.backgroundColor = "#2e394d";
                geust.style.backgroundColor = "#4a5568";
                unit.style.backgroundColor = "#4a5568";
                start.style.backgroundColor = "#4a5568";
                end.style.backgroundColor = "#4a5568";
                money.style.backgroundColor = "#4a5568";

                // set Sort Data
                newArr.sort(function (a, b) {
                    return a.id - b.id;
                });
            } else if (respectTo == 'geust') {
                // setAttributes
                id.style.backgroundColor = "#4a5568";
                geust.style.backgroundColor = "#2e394d";
                unit.style.backgroundColor = "#4a5568";
                start.style.backgroundColor = "#4a5568";
                end.style.backgroundColor = "#4a5568";
                money.style.backgroundColor = "#4a5568";

                //  // set Sort Data

                newArr.sort(function (a, b) {
                    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                });
            } else if (respectTo == 'unit') {
                // setAttributes
                id.style.backgroundColor = "#4a5568";
                geust.style.backgroundColor = "#4a5568";
                unit.style.backgroundColor = "#2e394d";
                start.style.backgroundColor = "#4a5568";
                end.style.backgroundColor = "#4a5568";
                money.style.backgroundColor = "#4a5568";

                //  // set Sort Data
                newArr.sort(function (a, b) {
                    return a.room_id - b.room_id;
                });
            } else if (respectTo == 'start') {
                // setAttributes
                id.style.backgroundColor = "#4a5568";
                geust.style.backgroundColor = "#4a5568";
                unit.style.backgroundColor = "#4a5568";
                start.style.backgroundColor = "#2e394d";
                end.style.backgroundColor = "#4a5568";
                money.style.backgroundColor = "#4a5568";

                //  // set Sort Data
                newArr.sort(function (a, b) {
                    var reserveOne = a.checkIn; // ignore upper and lowercase
                    var reserveTwo = b.checkIn; // ignore upper and lowercase
                    if (reserveOne > reserveTwo) {
                        return -1;
                    }
                    if (reserveOne < reserveTwo) {
                        return 1;
                    }
                });
            } else if (respectTo == 'end') {
                // setAttributes
                id.style.backgroundColor = "#4a5568";
                geust.style.backgroundColor = "#4a5568";
                unit.style.backgroundColor = "#4a5568";
                start.style.backgroundColor = "#4a5568";
                end.style.backgroundColor = "#2e394d";
                money.style.backgroundColor = "#4a5568";

                //  // set Sort Data
                newArr.sort(function (a, b) {
                    var reserveOne = a.checkOut; // ignore upper and lowercase
                    var reserveTwo = b.checkOut; // ignore upper and lowercase
                    if (reserveOne > reserveTwo) {
                        return -1;
                    }
                    if (reserveOne < reserveTwo) {
                        return 1;
                    }
                });
            } else if (respectTo == 'money') {
                // setAttributes
                id.style.backgroundColor = "#4a5568";
                geust.style.backgroundColor = "#4a5568";
                unit.style.backgroundColor = "#4a5568";
                start.style.backgroundColor = "#4a5568";
                end.style.backgroundColor = "#4a5568";
                money.style.backgroundColor = "#2e394d";

                //  // set Sort Data
                newArr.sort(function (a, b) {
                    return a.total_price - b.total_price;
                });
            }
            // 
        } else {
            setTypeOfSort('increasing')
            if (respectTo == 'id') {
                // setAttributes
                id.style.backgroundColor = "#2e394d";
                geust.style.backgroundColor = "#4a5568";
                unit.style.backgroundColor = "#4a5568";
                start.style.backgroundColor = "#4a5568";
                end.style.backgroundColor = "#4a5568";
                money.style.backgroundColor = "#4a5568";

                // set Sort Data
                newArr.sort(function (a, b) {
                    return b.id - a.id;
                });
            } else if (respectTo == 'geust') {
                // setAttributes
                id.style.backgroundColor = "#4a5568";
                geust.style.backgroundColor = "#2e394d";
                unit.style.backgroundColor = "#4a5568";
                start.style.backgroundColor = "#4a5568";
                end.style.backgroundColor = "#4a5568";
                money.style.backgroundColor = "#4a5568";

                //  // set Sort Data

                newArr.sort(function (a, b) {
                    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                    if (nameA > nameB) {
                        return -1;
                    }
                    if (nameA < nameB) {
                        return 1;
                    }
                });
            } else if (respectTo == 'unit') {
                // setAttributes
                id.style.backgroundColor = "#4a5568";
                geust.style.backgroundColor = "#4a5568";
                unit.style.backgroundColor = "#2e394d";
                start.style.backgroundColor = "#4a5568";
                end.style.backgroundColor = "#4a5568";
                money.style.backgroundColor = "#4a5568";

                //  // set Sort Data
                newArr.sort(function (a, b) {
                    return b.room_id - a.room_id;
                });
            } else if (respectTo == 'start') {
                // setAttributes
                id.style.backgroundColor = "#4a5568";
                geust.style.backgroundColor = "#4a5568";
                unit.style.backgroundColor = "#4a5568";
                start.style.backgroundColor = "#2e394d";
                end.style.backgroundColor = "#4a5568";
                money.style.backgroundColor = "#4a5568";

                //  // set Sort Data
                newArr.sort(function (a, b) {
                    var reserveOne = a.checkIn; // ignore upper and lowercase
                    var reserveTwo = b.checkIn; // ignore upper and lowercase
                    if (reserveOne < reserveTwo) {
                        return -1;
                    }
                    if (reserveOne > reserveTwo) {
                        return 1;
                    }
                });
            } else if (respectTo == 'end') {
                // setAttributes
                id.style.backgroundColor = "#4a5568";
                geust.style.backgroundColor = "#4a5568";
                unit.style.backgroundColor = "#4a5568";
                start.style.backgroundColor = "#4a5568";
                end.style.backgroundColor = "#2e394d";
                money.style.backgroundColor = "#4a5568";

                //  // set Sort Data
                newArr.sort(function (a, b) {
                    var reserveOne = a.checkOut; // ignore upper and lowercase
                    var reserveTwo = b.checkOut; // ignore upper and lowercase
                    if (reserveOne < reserveTwo) {
                        return -1;
                    }
                    if (reserveOne > reserveTwo) {
                        return 1;
                    }
                });
            } else if (respectTo == 'money') {
                // setAttributes
                id.style.backgroundColor = "#4a5568";
                geust.style.backgroundColor = "#4a5568";
                unit.style.backgroundColor = "#4a5568";
                start.style.backgroundColor = "#4a5568";
                end.style.backgroundColor = "#4a5568";
                money.style.backgroundColor = "#2e394d";

                //  // set Sort Data
                newArr.sort(function (a, b) {
                    return b.total_price - a.total_price;
                });
            }
        }

        await setReservationFiltered(newArr)
    }

    // filter Data ==> 
    const filterFunc = async () => {
        let newArr = reservation;
        // let newArrResult = [];
        // Filter Inputs
        let filterByGeustName = document.getElementById("filterByGeustName").value
        let filterByStatus = document.getElementById("filterByStatus").value
        let filterByNumber = document.getElementById("filterByNumber").value
        let filterByUnit = document.getElementById("filterByUnit").value
        let filterByStartDate = document.getElementById("filterByStartDate").value
        let filterByEndDate = document.getElementById("filterByEndDate").value
        let filterByGeustClassify = document.getElementById("filterByGeustClassify").value
        let filterByRentType = document.getElementById("filterByRentType").value
        let filterByMoneyType = document.getElementById("filterByMoneyType").value

        // By geust Name
        if (filterByGeustName !== null) {
            newArr = newArr.filter(reserve => {
                if (reserve.name.toUpperCase().includes(filterByGeustName.toUpperCase())) {
                    return reserve
                }
            })
        }
        // By geust Status
        if (filterByStatus !== "null") {
            newArr = newArr.filter(reserve => {
                if (reserve.percenteOfGet == filterByStatus) {
                    return reserve
                }
            })
        }

        // By Reservation Number
        if (filterByNumber !== null) {
            newArr = newArr.filter(reserve => {
                if (reserve.id.toString().includes(filterByNumber)) {
                    return reserve
                }
            })
        }

        // By Unit number
        if (filterByUnit !== "null") {
            newArr = newArr.filter(reserve => {
                if (reserve.room_id == filterByUnit) {
                    return reserve
                }
            })
        }
        // By geust Start Date
        if (filterByStartDate) {
            let newDates = dates
            newDates[0] = filterByStartDate
            setDates(newDates)

            newArr = newArr.filter(reserve => {
                if (new Date(reserve.checkIn).getTime() >= new Date(filterByStartDate).getTime()) {

                    return reserve
                }
            })
        }else{
            let newDates = dates
            newDates[0] = ''
            setDates([])
            setDates(newDates)
        }
        // By geust End Date
        if (filterByEndDate) {
            let newDates = dates
            newDates[1] = filterByEndDate
            setDates(newDates)

            newArr = newArr.filter(reserve => {
                if (new Date(reserve.checkOut).getTime() <= new Date(filterByEndDate).getTime()) {

                    return reserve
                }
            })
        }else{
            let newDates = dates
            newDates[1] = ''
            setDates([])
            setDates(newDates)
        }
        // By geust Geust Classify
        if (filterByGeustClassify !== "null") {
            newArr = newArr.filter(reserve => {
                if (reserve.guest_rate == filterByGeustClassify) {
                    return reserve
                }
            })
        }
        // By geust Rent Type
        if (filterByRentType !== "null") {
            newArr = newArr.filter(reserve => {
                if (reserve.rent_type == filterByRentType) {
                    return reserve
                }
            })
        }
        // By geust Money Type
        if (filterByMoneyType !== "null") {
            newArr = newArr.filter(reserve => {
                if (reserve.madeoneah == filterByMoneyType) {
                    // .madeoneah = "daen"maden
                    return reserve
                }
            })
        }

        getFilteredAmountOfMoney(newArr, geust, receipts, expense);
        // getFilteredAmountOfMoney
        setReservationFiltered([]);
        setReservationFiltered(newArr);
    }

    // getFilteredAmountOfMoney
    function getFilteredAmountOfMoney(myReservation, myGeust, myRec, myEx) {

        let masrof = 0;
        let maqbod = 0;

        let totalOfDaen = 0;
        let totalOfMaden = 0;

        let myRent = 0;
        let myTotalServices = 0;

        let myTotal = 0;
        let myTaxes = 0;
        let myIncoming = 0;

        for (let i = 0; i < myReservation.length; i++) {
            for (let j = 0; j < myGeust.length; j++) {
                // guest_id
                if (myGeust[j].id == myReservation[i].guest_id) {
                    myReservation[i] = { ...myGeust[j], ...myReservation[i] }
                    // .geust_name = myGeust[j].name;
                    break;
                }
            }
        }

        for (let i = 0; i < myReservation.length; i++) {
            // set Receipts

            let reservationMadfo3 = 0;

            let reservationAmmount = myReservation[i].price + myReservation[i].services_price;
            // myReservation[i].Receipts = [];
            for (let j = 0; j < myRec.length; j++) {
                if ((myRec[j].reservation_id == myReservation[i].id) && (myReservation[i].confirmed == true || withDeletedReservation)) {
                    reservationAmmount -= myRec[j].amount
                    maqbod += myRec[j].amount;
                    reservationMadfo3 += myRec[j].amount;
                }
            }

            for (let j = 0; j < myEx.length; j++) {
                if ((myEx[j].reservation_id == myReservation[i].id) && (myReservation[i].confirmed == true || withDeletedReservation) && (myEx[j].for !== "خدمات")) {
                    reservationAmmount += myEx[j].amount;
                    masrof += myEx[j].amount;
                }
            }

            if (reservationAmmount < 0) {

                myReservation[i].daen = reservationAmmount;
                totalOfDaen += myReservation[i].daen;

                myReservation[i].madeoneah = "daen";

            } else if ((reservationAmmount > 0) && (myReservation[i].confirmed == true || withDeletedReservation)) {
                myReservation[i].maden = reservationAmmount;
                totalOfMaden += myReservation[i].maden;

                myReservation[i].madeoneah = "maden";

            }
            if (withDeletedReservation) {
                myReservation[i].reservationMadfo3 = reservationMadfo3;

                myRent += myReservation[i].price;
                myTotalServices += myReservation[i].services_price;

                myTaxes += 0;
                myTotal += (myReservation[i].priceWithTax + myReservation[i].services_price);
            } else {
                if (myReservation[i].confirmed == true) {
                    myReservation[i].reservationMadfo3 = reservationMadfo3;

                    myRent += myReservation[i].price;
                    myTotalServices += myReservation[i].services_price;

                    myTaxes += 0;
                    myTotal += (myReservation[i].priceWithTax + myReservation[i].services_price);

                } else {
                    myReservation[i].reservationMadfo3 = reservationMadfo3;
                    myReservation[i].maden = 0;
                }
            }
        }

        myIncoming = (myTotal - myTaxes)?.toFixed(2);
        myTotal = myTotal?.toFixed(2);
        myTaxes = myTaxes?.toFixed(2);

        setTotalIncoming(myIncoming);
        setTotalOfAll(myTotal);

        setTotalMaden(totalOfMaden?.toFixed(2));
        setTotalDaen(totalOfDaen?.toFixed(2));

        setMasrof(masrof?.toFixed(2));
        setMaqbod(maqbod?.toFixed(2));

        setTotalRent(myRent?.toFixed(2));
        setTotalServices(myTotalServices?.toFixed(2));

        setTotalTaxes(myTaxes);

    }

    // contain all reservation with deleted
    const yesShowDeleted = () => {
        let absolutShowDeletedReservation = document.getElementById("absolutShowDeletedReservation");
        let yesShowDeletedElement = document.getElementById("yesShowDeleted");

        if (yesShowDeletedElement.style.display !== "inline-block") {
            yesShowDeletedElement.style.display = "inline-block";
            absolutShowDeletedReservation.style.backgroundColor = "#0000ff54";
            setWithDeletedReservation(true);
        } else {
            yesShowDeletedElement.style.display = "none";
            absolutShowDeletedReservation.style.backgroundColor = "#fafafa";
            setWithDeletedReservation(false);
        }


    }
    return (
        <div className='mainUnitContainer'>

            <div className='navigator'>
                <p> <Link className='linkContainer' to='/'> الرئيسية </Link>  /   إدارة الحجوزات </p>

            </div>


            {/* Table */}

            <div>
                <div className='headOfTable'>
                    <p>إدارة الحجوزات</p>

                </div>
                <div className='filterInputsContainer'>

                    <div className='managementRow'>
                        <span className='managementFilter'>
                            <input required onChange={() => filterFunc()} id="filterByGeustName" placeholder='اسم العميل' className='filterInput' type="text" />
                        </span>
                        <span className='managementFilter'>
                            <select onChange={filterFunc} id="filterByStatus" className='filterInput'>

                                <option value="null">حالة الحجز</option>
                                <option value="مؤكد"> حجز مؤكد </option>
                                <option value="حجز منقضي"> حجز منقضي </option>
                                <option value="حجز مستقبلي"> حجز مستقبلي </option>
                                <option value="ملغي"> حجز ملغي </option>

                            </select>
                        </span>
                        <span className='managementFilter'>
                            <input required onChange={filterFunc} id="filterByNumber" placeholder='رقم الحجز' className='filterInput' type="text" />

                        </span>
                        <span className='managementFilter'>
                            <span id="filterByEndDateDiv" className='filterInput filterByEndDateDiv'>
                                {dates[1] ? `${dates[1]} تاريخ النهاية` : "تاريخ النهاية"}

                                <input required onChange={filterFunc} id="filterByEndDate" className="filterInput filterByEndDate" title='تاريخ النهاية' type="date" />
                            </span>

                        </span>
                        <span className='managementFilter'>
                            <span id="filterByStartDateDiv" className='filterInput filterByStartDateDiv'>
                                {dates[0] ? `${dates[0]} تاريخ البداية` : "تاريخ البداية"}

                                <input required onChange={filterFunc} id="filterByStartDate" className="filterInput filterByStartDate" title='تاريخ البداية' type="date" />
                            </span>

                        </span>


                    </div>
                    <div className='managementRow'>
                        <span onClick={yesShowDeleted} className='managementFilter'>
                            <span id="absolutShowDeletedReservation" className='filterInput absolutShowDeletedReservation'>
                                <span>تضمين الحجوزات الملغاة</span>
                                <i id="yesShowDeleted" className="yesShowDeleted">
                                <CheckIcon/>
                                </i>
                            </span>

                        </span>

                        <span className='managementFilter '>
                            <select onChange={filterFunc} id="filterByGeustClassify" className='filterInput '>
                                <option value="null">تصنيف العملاء</option>
                                <option value="مميز">مميز</option>
                                <option value="خاص">خاص</option>
                                <option value="غير مرغوب فيه">غير مرغوب فيه</option>
                            </select>
                        </span>
                        <span className='managementFilter'>

                            <select onChange={filterFunc} id="filterByRentType" className='filterInput'>
                                <option value="null">نوع الايجار</option>
                                <option value="يومي">يومي</option>
                                <option value="شهري">شهري</option>
                                <option value="اسبوعي">اسبوعي</option>
                            </select>
                        </span>

                        <span className='managementFilter'>
                            <select onChange={filterFunc} id="filterByMoneyType" className='filterInput'>
                                <option value="null">نوع المديونية</option>
                                <option value="daen">دائن</option>
                                <option value="maden">مدين</option>

                            </select>
                        </span>

                        {/* <span className='managementFilter'>
                            <input required placeholder='اسم الوحدة' className='filterInput' type="text" />
                        </span> */}

                        <span className='managementFilter'>
                            <select onChange={filterFunc} id="filterByUnit" className='filterInput'>
                                <option value="null">رقم الوحدة</option>
                                {
                                    rooms.map((room, idx) => {
                                        return (
                                            <option value={room.id} key={idx}>{room.room_name} - {room.id}</option>
                                        )
                                    })
                                }
                            </select>
                        </span>
                    </div>

                    <div className='managementRow'>
                        <span className='managementFilter managementCol'>
                            <span>اجمالي الضرائب</span>
                            <span>{totalTaxes}</span>
                        </span>
                        <span className='managementFilter managementCol'>
                            <span>اجمالي الخدمات</span>
                            <span>{totalServices}</span>
                        </span>
                        <span className='managementFilter managementCol'>
                            <span>اجمالي الايجار</span>
                            <span>{totalRent}</span>
                        </span>
                        <span className='managementFilter managementCol'>
                            <span>اجمالي الايرادات</span>
                            <span>{totalIncoming}</span>
                        </span>
                        <span className='managementFilter managementCol'>
                            <span>اجمالي التكلفة</span>
                            <span>{totalOfAll}</span>
                        </span>

                    </div>

                    <div className='managementRow'>
                        <span className='managementFilter managementCol'>
                            <span>اجمالي الدائن</span>
                            <span>{totalDaen}</span>
                        </span>
                        <span className='managementFilter managementCol'>
                            <span>اجمالي المدين</span>
                            <span>{totalMaden}</span>
                        </span>
                        <span className='managementFilter managementCol'>
                            <span>الرصيد</span>
                            <span>{(maqbodat - masaref)?.toFixed(2)}</span>
                        </span>
                        <span className='managementFilter managementCol'>
                            <span>اجمالي المقبوضات</span>
                            <span>{maqbodat}</span>
                        </span>
                        <span className='managementFilter managementCol'>
                            <span>اجمالي المصروفات </span>
                            <span>{masaref}</span>
                        </span>

                    </div>

                </div>
                <div className='printFeild'>
                    <i title="طباعة التقرير" onClick={handlePrint} className="printIcon">
<PrintIcon/>
                    </i>
                    {/*  */}

                    {/*  */}

                    <span title="excel تصدير بصيغة ملف">
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="printIcon far fa-file-excel"
                            table="myReport"
                            filename="تقرير الحجوزات"
                            sheet="تقرير الحجوزات"
                            buttonText=""
                        />
                    </span>
                    {/* </i> */}
                </div>


                {/* <ComponentToPrint ref={componentRef} />
      <button onClick={handlePrint}>Print this out!</button> */}
                <MyTable totalOfAll={totalOfAll} maqbodat={maqbodat} masaref={masaref} totalDaen={totalDaen} totalMaden={totalMaden} setreservationInfo={props.setreservationInfo} reservation={reservationFiltered} ref={componentRef} sortReservation={sortReservation} />
                {/* < /> */}
            </div>


        </div>
    );
}
