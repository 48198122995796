import React, { useState, useEffect, useContext } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import cookies from 'react-cookies';
import axios from 'axios';
import { LoginContext } from '../../context/login';
import swal from 'sweetalert2';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';

import "./employeeList.css";



export default function EmployeeList(props) {
    // To load the token from cookies
    const qs = new URLSearchParams(window.location.search);
    const cookieToken = cookies.load('token');
    const token = qs.get('token') || cookieToken || null;

    const login = useContext(LoginContext);
    // check the permission
    // if()
    const [employee, setEmployee] = useState([]);


    const alert = (user) => {
        swal.fire({
            title: "هل أنت متأكد من حذف الموظف؟",
            text: "! لن تستطيع عكس هذا الإجراء  ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: " ! نعم احذف الموظف  ",
            cancelButtonText: " ! إلغاء ",
            cancelButtonColor: '#d33',
        })
            .then((result) => {
                if (result.value) {
                    deleteUser(user);
                    swal.fire(
                        '! تم الحذف بنجاح',
                        '! تم حذف الموظف بنجاح',
                        'success'
                    )
                }
                else {
                    swal.fire(
                        '! تم الإلغاء',
                        '! تم الإلغاء',
                        'error'
                    )
                }
            });
    };
    const deleteUser = async (user) => {

        await axios.post(`${login.API}/deleteemployee`, { id: user.id }
            , { headers: { 'Authorization': `Basic ${token}` }})
            .then((res) => {
                setEmployee(res.data);

                // new activity
                axios.post(`${login.API}/newActivity`, {
                    employee_id: login.user.id,
                    description: `حذف الموظف - ${user.name}`,
                    type: "الموظفين",
                },
                { headers: { 'Authorization': `Basic ${token}`}})
            });
    }
    // 
    useEffect(() => {
        // get all employees from database once we refreshed the pages
        props.setLoadingFunc(true).then(async()=>{
            await axios.post(`${login.API}/getEmployees`,{},
                {
                    headers: {
                        "Authorization": `Basic ${token}`
                    }
                }).then(res => {
                    setEmployee(res.data);
                }).catch(err => {
                    console.log("error");
                })
                props.setLoadingFunc(false)
        })
    }, [])

    return (
        <div className='mainUnitContainer'>

            <div className='navigator'>
                <p> <Link className='linkContainer' to='/'> الرئيسية </Link>  / <Link className='linkContainer' to="/profile"> حسابي </Link> / قائمة الموظفين </p>

            </div>

            <Link to="/profile/addNewEmployee">
                <a title='Add New Employee' className='profileIcons'>
                    <PersonAddAltRoundedIcon />
                </a>
            </Link>


            {/* Table */}

            <div className='employessTables'>
                <div className='headOfTable'>
                    <p> قائمة الموظيف </p>

                </div>

                <div className='bodyOfTable'>
                    <Table striped bordered hover>
                        <thead>
                            {/* رقم الوحدة	الاسم	قسم الوحدة	مفعل	حالة الوحدة	الصورة الرئيسية	الاجرائات */}
                            <tr className='trMainTable'>
                                <th>الاجراءات </th>
                                <th>الصلاحيات</th>
                                <th>الجنس</th>
                                <th>رقم الهاتف</th>
                                <th>البريد الالكتروني</th>
                                <th>اسم الموظف</th>
                                <th>الرقم التسلسلي للموظف</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                employee.map(user => {
                                    return (
                                        <tr>
                                            <td>
                                                {
                                                    (user.id !== login.user.id) ?
                                                        (<div data-v-0a75d390="" className="action">
                                                            <Link to="/profile/employeeList/editEmployee">
                                                                <a data-v-0a75d390="" onClick={() => props.editEmployee(user.id)} className="takeActionUnit" title="تعديل">

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                                    </svg>

                                                                </a>
                                                            </Link>

                                                            <a data-v-0a75d390="" onClick={() => alert(user)} className="takeActionUnit" title="حذف">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                    <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                                </svg>
                                                            </a>
                                                        </div>) :
                                                        (<>
                                                            <Link to="/profile/employeeList/editEmployee">
                                                                <a data-v-0a75d390="" onClick={() => props.editEmployee(user.id)} className="takeActionUnit" title="تعديل">

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                                    </svg>

                                                                </a>
                                                            </Link>
                                                            <span>  ..حسابي  </span>
                                                        </>)
                                                }

                                            </td>
                                            <td> {user.role} </td>
                                            <td> {user.gender} </td>
                                            <td> {user.phone_number} </td>
                                            <td> {user.email} </td>
                                            {user.onlineStatus === true ?
                                                <td >
                                                    <span className='online_user'>{user.name}</span>
                                                </td>
                                                :
                                                <td> {user.name} </td>
                                            }

                                            {user.onlineStatus === true ?
                                                <td >
                                                    <span className='online_user'>{user.id}</span>
                                                </td> :
                                                <td> {user.id} </td>
                                            }


                                        </tr>
                                    )

                                })
                            }
                            {/* run from database */}


                        </tbody>
                    </Table>
                </div>
            </div>


        </div>
    )
}
