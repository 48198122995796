import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import cookies from 'react-cookies';
import { LoginContext } from '../../context/login';
import axios from 'axios';
import { Table } from 'react-bootstrap';


export default function Activities(props) {
    // To load the token from cookies
    const qs = new URLSearchParams(window.location.search);
    const cookieToken = cookies.load('token');
    const token = qs.get('token') || cookieToken || null;
    // getActivities
    const login = useContext(LoginContext);
    const [activities, setActivities] = useState([]);
    const [activitiesFilter, setActivitiesFilter] = useState([]);
    const [employees, setEmployees] = useState([]);

    const [dates, setDates] = useState([]);

    const [activitiesToRender, setActivitiesToRender] = useState([
        "اضافة حجز", "اضافة سند مالي", "سجل دخول", "تعديل بيانات العقد",
        , "إنشاء فاتورة", "تسجيل مغادرة", "الغاء حجز الغرفة", "الموظفين", "الغرف والاقسام"
        , "العروض والاسعار", "الخدمات", "تسجيل الدخول الموظف", "تسجيل الخروج الموظف", "الاعدادات"
    ]);

    useEffect(() => {

        props.setLoadingFunc(true);
        // get activities
        let myActivities = [];
        let myEmployees = [];


        axios.get(`${login.API}/getActivities` ,{ headers: { 'Authorization': `Basic ${token}` }})
            .then(res => {
                // activities
                myActivities = res.data;
                axios.post(`${login.API}/getEmployees`, {} , 
                    {
                        headers: {
                            "Authorization": `Basic ${token}`
                        }
                    }).then(async resEm => {
                        // employees
                        myEmployees = resEm.data;
                        props.setLoadingFunc(false);
                        for (let i = 0; i < myEmployees.length; i++) {
                            for (let j = 0; j < myActivities.length; j++) {
                                if (myActivities[j].employee_id === myEmployees[i].id) {
                                    myActivities[j].employeeName = myEmployees[i].name;
                                }
                            }
                        }
                        myActivities.sort((a, b) => {
                            return b.id - a.id;
                        });
                        await setActivities(myActivities);
                        await setActivitiesFilter(myActivities);
                        await setEmployees(myEmployees);


                    }).catch(err => {
                        props.setLoadingFunc(false);
                    })

            });



    }, [])

    useEffect(() => {
        let myCurrentDate = new Date();
        let filterByStartDate = document.getElementById("filterByStartDate");
        filterByStartDate.value = myCurrentDate.toISOString().split('T')[0];

        filterFunc(myCurrentDate, "useEffect");
    }, [activities])

    async function filterFunc(testDate, fromWhere) {
        let myActivities = activities;
        await setActivitiesFilter([]);

        let startDate = document.getElementById("filterByStartDate").value;
        let endDate = document.getElementById("filterByEndDate").value;
        let employee = document.getElementById("employee").value;
        let activityType = document.getElementById("activityType").value;

        let Dates = [startDate, endDate];
        setDates(Dates);

        if (employee !== "null") {
            myActivities = myActivities.filter(item => item.employeeName === employee);
        }

        if (fromWhere === "useEffect") {
            myActivities = myActivities.filter(item => {
                let date = testDate;
                let day = item.date.split("  ")[1].split("/")[0];
                let month = item.date.split("  ")[1].split("/")[1];
                let year = item.date.split("  ")[1].split("/")[2];

                // day => date.getDate() /// month => date.getMonth() /// year => date.getFullYear()
                if (parseInt(day) >= date[1] && parseInt(month) >= date[0] && parseInt(year) >= date[2]) {
                    return item;
                }
            });
        }

        if (startDate) {
            myActivities = myActivities.filter(item => {
                let date = new Date(startDate);
                let day = item.date.split("  ")[1].split("/")[0];
                let month = item.date.split("  ")[1].split("/")[1];
                let year = item.date.split("  ")[1].split("/")[2];

                // day => date.getDate() /// month => date.getMonth() /// year => date.getFullYear()
                if (parseInt(day) >= date.getDate() && parseInt(month) >= (date.getMonth() + 1) && parseInt(year) >= date.getFullYear()) {
                    return item;
                }
            });
        }

        if (endDate) {
            myActivities = myActivities.filter(item => {
                let date = new Date(endDate);
                let day = item.date.split("  ")[1].split("/")[0];
                let month = item.date.split("  ")[1].split("/")[1];
                let year = item.date.split("  ")[1].split("/")[2];

                // day => date.getDate() /// month => date.getMonth() /// year => date.getFullYear()
                if (parseInt(day) <= date.getDate() && parseInt(month) <= (date.getMonth() + 1) && parseInt(year) <= date.getFullYear()) {
                    return item;
                }
            });
        }

        if (activityType !== "null") {
            myActivities = myActivities.filter(item => item.type === activityType);
        }

        myActivities.sort((a, b) => {
            return b.id - a.id;
        });

        setActivitiesFilter(myActivities);

    }
    return (
        <div>
            <div className='navigator'>
                <p> <Link className='linkContainer' to='/'> الرئيسية </Link>
                    /
                    <Link className='linkContainer' to='/settings'> الإعدادات </Link>
                    /
                    سجل الأنشطة
                </p>
            </div>


            <div>
                <div className='headOfTable'>
                    <p> الأنشطة </p>

                </div>

                <div className='bodyOfTable'>
                    <div className='managementRow'>
                        <span className='managementFilter'>
                            <select onChange={filterFunc} id="activityType" className='filterInput'>

                                <option value="null">نوع النشاط</option>
                                {
                                    activitiesToRender.map((item, index) => {
                                        return <option key={index} value={item}>{item}</option>
                                    })
                                }


                            </select>
                        </span>

                        <span className='managementFilter'>
                            <select onChange={filterFunc} id="employee" className='filterInput'>

                                <option value="null">الموظف</option>
                                {
                                    employees.map((employee, index) => {
                                        return <option key={index} value={employee.name}>{employee.name}</option>
                                    })
                                }


                            </select>
                        </span>

                        <span className='managementFilter'>
                            <span id="filterByEndDateDiv" className='filterInput filterByEndDateDiv'>
                                {dates[1] ? `${dates[1]} تاريخ النهاية` : "تاريخ النهاية"}
                                <input required onChange={filterFunc} id="filterByEndDate" className="filterInput filterByEndDate" title='تاريخ النهاية' type="date" />
                            </span>

                        </span>
                        <span className='managementFilter'>
                            <span id="filterByStartDateDiv" className='filterInput filterByStartDateDiv'>
                                {dates[0] ? `${dates[0]} تاريخ البداية` : "تاريخ البداية"}
                                <input required onChange={filterFunc} id="filterByStartDate" className="filterInput filterByStartDate" title='تاريخ البداية' type="date" />
                            </span>

                        </span>


                    </div>


                    <Table className="myReport" striped bordered hover>
                        <thead>
                            {/* رقم الوحدة	الاسم	قسم الوحدة	مفعل	حالة الوحدة	الصورة الرئيسية	الاجرائات */}
                            <tr className='trMainTable'>
                                <th>التاريخ</th>
                                <th>المستخدم</th>
                                <th>النوع</th>
                                <th>الوصف</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* run from database */}
                            {
                                activitiesFilter.map((activity, index) => {
                                    return (
                                        <tr key={index}>

                                            <td>{activity.date}</td>
                                            <td>{activity.employeeName}</td>
                                            <td>{activity.type}</td>
                                            <td>{activity.description}</td>
                                        </tr>
                                    )
                                })
                            }


                        </tbody>
                    </Table>
                </div>
            </div>


        </div>
    )
}
