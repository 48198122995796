import React from 'react';
import { Link } from 'react-router-dom';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import HistoryIcon from '@mui/icons-material/History';
import GroupsIcon from '@mui/icons-material/Groups';
import "./setings.css"

export default function Setings() {

    const setings = [
        {
            set_name: "الإعدادات العامة",
            set_path: "general-Setings",
            set_font: <SettingsSuggestIcon className="setingsIcon"/>,
        },
        {
            set_name: "المستخدمين والصلاحيات",
            set_path: "employeeList",
            set_font: <ManageAccountsIcon className="setingsIcon"/>,
        },
        {
            set_name: "إعدادات التنبيهات",
            set_path: "notifications-Setings",
            set_font: <NotificationsActiveIcon className="setingsIcon"/>,
        },
        {
            set_name: "سجل الاحداث",
            set_path: "activities-Setings",
            set_font: <HistoryIcon className="setingsIcon"/>,
        },
        {
            set_name: "قائمة العملاء ",
            set_path: "guests-list",
            set_font: <GroupsIcon className="setingsIcon"/>,
        },
    
    ];

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return <div className='mainUnitContainer'>

        {/* <div>
            <h4 className='newEmployeeTag'> الاعدادات </h4>
        </div> */}

        <div className='navigator'>
            <p> <Link className='linkContainer' to='/'>الرئيسية</Link>
                /
                الإعدادات
            </p>
        </div>

        <div className='cardSettingsContainer'>
            {
                setings.map((setType, idx) => {
                    return (
                        <Link key={idx} to={`/settings/${setType.set_path}`} className='linkContainer'>
                            <div onClick={scrollToTop} className='setingsPageCard'>
                                <p>
                                    {setType.set_name}
                                </p>
                                <span>
                                    {setType.set_font}
                                </span>

                            </div>
                        </Link>
                    )
                })
            }


        </div>
    </div>;
}
