import axios from 'axios';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LoginContext } from '../../context/login';
import cookies from 'react-cookies';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import "./reservationTable.css";

export default function ReservationTable(props) {
    // To load the token from cookies
    const qs = new URLSearchParams(window.location.search);
    const cookieToken = cookies.load('token');
    const token = qs.get('token') || cookieToken || null;

    const login = useContext(LoginContext)

    const [rooms, setRooms] = useState([]);
    const [reservation, setReservation] = useState([]);

    const [renderDays, setRenderDays] = useState([]);
    const [daysStateArr, setDaysStateArr] = useState(['الاحد', 'الاثنين', 'الثلاثاء', 'الاربعاء', 'الخميس', 'الجمعه', 'السبت'])

    const [startDate, setStartDate] = useState(new Date().toString())
    const [endDate, setEndDate] = useState(new Date(startDate).getTime() + 13 * 1000 * 60 * 60 * 24)
    // let endDate = new Date(startDate).getTime() + 13 * 1000 * 60 * 60 * 24;

    const [daysArr, setDaysArr] = useState([]);

    const setMapArr = async (date) => {
        setRenderDays([])
        let myArr = []
        let innerObj = {}
        date = new Date(date)
        // let currentDay = date;
        for (let i = 0; i < 14; i++) {
            innerObj.date = date;

            date = new Date(date)
            date = date.getTime() - 1 * 1000 * 60 * 60 * 24;

            innerObj.rooms = rooms;
            myArr.push(innerObj);

            innerObj = {}
        }

        setRenderDays(myArr);
    }

    // increase Date ==>
    const increasePeriod = async () => {
        let myNewDate = new Date(endDate).getTime() + (13 * 1000 * 60 * 60 * 24);
        await setStartDate(endDate)
        // startDate = endDate;

        await setEndDate(myNewDate)
        // setMapArr(endDate)

        // endDate = new Date(startDate).getTime() + 13 * 1000 * 60 * 60 * 24;

    }
    const decreasePeriod = async () => {
        await setEndDate(startDate)
        // endDate = startDate;
        let myNewDate = new Date(startDate).getTime() - 13 * 1000 * 60 * 60 * 24;
        // startDate = new Date(endDate).getTime() - 13 * 1000 * 60 * 60 * 24;
        await setStartDate(myNewDate)
        // setMapArr(endDate)
    }
    const setTodayPeriod = async () => {
        let myDate = new Date().toString();
        await setEndDate(new Date(myDate).getTime() + 13 * 1000 * 60 * 60 * 24)
        await setStartDate(new Date().toString())

    }


    useEffect(() => {
        let myGeust;
        let myRoom;
        props.setLoadingFunc(true).then(async () => {

            await axios.post(`${login.API}/getAllRooms`, {}, { headers: { Authorization: `Bearer ${token}` } })
                .then((res) => {
                    myRoom = res.data.rooms;
                    myRoom.sort(function (a, b) {
                        return a.id - b.id;
                    });
                    let myDepartment = res.data.department
                    // to sort the array


                    for (let j = 0; j < myRoom.length; j++) {
                        myRoom[j].checkDates = [];
                        for (let i = 0; i < myDepartment.length; i++) {
                            // unit_id
                            if (myRoom[j].unit_id == myDepartment[i].id) {
                                myRoom[j] = { ...myDepartment[i], ...myRoom[j] }
                                break
                            }
                        }
                    }


                    // rooms
                })
            await axios.post(`${login.API}/getAllReservation`, {}, { headers: { Authorization: `Bearer ${token}` } })
                .then((res) => {

                    let myReservation = res.data;
                    // to sort with respect to the name
                    myReservation.sort(function (a, b) {
                        var reserveOne = a.checkIn; // ignore upper and lowercase
                        var reserveTwo = b.checkIn; // ignore upper and lowercase
                        if (reserveOne > reserveTwo) {
                            return -1;
                        }
                        if (reserveOne < reserveTwo) {
                            return 1;
                        }
                    });



                    for (let i = 0; i < myRoom.length; i++) {
                        let reservedFor = '';
                        for (let j = 0; j < myReservation.length; j++) {
                            if (myRoom[i].id == myReservation[j].room_id) {

                                let loggedIn_At = myReservation[j]?.loggedIn_At?.split("  ")[1];
                                let loggedOut_At = myReservation[j]?.loggedOut_At?.split("  ")[1];

                                loggedIn_At = loggedIn_At?.split("/")[2] + "-" + loggedIn_At?.split("/")[1] + "-" + loggedIn_At?.split("/")[0];
                                loggedOut_At = loggedOut_At?.split("/")[2] + "-" + loggedOut_At?.split("/")[1] + "-" + loggedOut_At?.split("/")[0];

                                if (reservedFor == '') {
                                    reservedFor = myReservation[j].status;
                                } else if (reservedFor == 'سجل مغادرة') {
                                    reservedFor = myReservation[j].status;
                                } else if (reservedFor == 'حجز ملغي') {
                                    reservedFor = myReservation[j].status;
                                }

                                myRoom[i].checkDates.push({ in: myReservation[j].checkIn, out: myReservation[j].checkOut, status: myReservation[j].status, reservation_id: myReservation[j].id, loggedIn_At: loggedIn_At, loggedOut_At: loggedOut_At, confermation: myReservation[j].confirmed })


                            }
                        }
                    }

                    setReservation(myReservation);
                    setRooms(myRoom)
                    // reservations
                })




            props.setLoadingFunc(false)
        })

    }, [])

    useEffect(async () => {
        await setMapArr(endDate);
    }, [rooms])
    useEffect(async () => {
        await setMapArr(endDate);
    }, [startDate])
    useEffect(async () => {
        await setMapArr(endDate);
    }, [endDate])

    // Set reservation Date function
    const setreservationInfoFunction = async (room_id, date, room_name, reservation_id) => {
        let data = {
            room_id: room_id,
            date: date.date,
            room_name: room_name,
            reservation_id: reservation_id
        }
        // cookies.remove('reservationInfo');

        cookies.remove('reservationInfo');
        cookies.save('reservationInfo', JSON.stringify(data));


        props.setreservationInfo({
            room_id: room_id,
            date: new Date(date.date).toLocaleDateString(),
            room_name: room_name,
            reservation_id: reservation_id
        })
    }

    return (
        <div>
            <div className='navigator'>
                <p> <Link className='linkContainer' to='/'> الرئيسية </Link>  /   جدول الحجوزات </p>

            </div>

            <div>
                <h4 className='newEmployeeTag'> جدول الحجوزات </h4>
            </div>

            <div className='daysTableManagement'>
                <div className='increaseDecreaseDiv'>
                    <span title="ابدأ من تاريخ اليوم" onClick={() => setTodayPeriod()} className='changePeriodSpan'>اليوم</span>

                    <i title="التقدم في التاريخ" onClick={() => increasePeriod()} className="increaseDecreaseSpan">
                        <ChevronLeftIcon />
                    </i>
                    <i title="العودة في التاريخ" onClick={() => decreasePeriod()} className="increaseDecreaseSpan">
                        <ChevronRightIcon />
                    </i>
                </div>
                <div className='calenderDiv'>
                    <span className='calenderDatePart'>
                        {new Date(endDate).toISOString().split('T')[0]} - {new Date(startDate).toISOString().split('T')[0]}
                    </span>
                    <i className="calenderDatePart">
                        <EventNoteIcon />
                    </i>
                </div>
            </div>

            <div className='reservationTablePart'>

                <div className='renderDataInReservationTable'>
                    {/* map dates and days ==>  */}

                    {
                        renderDays.map((day, idx) => {
                            return (
                                <div key={idx} className='renderedCol'>
                                    {/* map reservation ==>  */}
                                    <div className='myCel'>
                                        <span className='celSpan'> {daysStateArr[parseInt(new Date(day.date).getDay())]} </span>
                                        <span className='celSpan'> {new Date(day.date).toISOString().split('T')[0]} </span>
                                    </div>

                                    {
                                        day.rooms.map((room, ids) => {
                                            return (
                                                // daily_price
                                                <div key={ids} className='myCelRendered'>
                                                    <span className='celSpanPrice'> دينار </span>
                                                    <span className='celSpanPrice'>  {room.daily_price[parseInt(new Date(day.date).getDay())]} </span>
                                                    {/* <span>  {new Date(day.date).getDay()} </span> */}

                                                    {
                                                        room.checkDates.map((date, num) => {
                                                            if ((new Date(day.date) >= new Date(date.in)) && (new Date(day.date).getTime() / (1 * 1000 * 60 * 60 * 24) <= new Date(date.out).getTime() / (1 * 1000 * 60 * 60 * 24) + 1) && (date.status == "لم يسجل دخول") && (date.confermation)) {
                                                                return (
                                                                    <div key={num}>
                                                                        <Link onClick={() => setreservationInfoFunction(room.id, day, room.room_name, date.reservation_id)} to="/reservationInfo" title="لم يسجل دخول ، انقر لعرض الحجز" className='hidenLinkInTable'></Link>
                                                                        <Link key={num} to="/reservationInfo" title="لم يسجل دخول ، انقر لعرض الحجز" onClick={() => setreservationInfoFunction(room.id, day, room.room_name, date.reservation_id)} className='hereSpan blueReservation'></Link>
                                                                    </div>
                                                                )
                                                            } else if (date.loggedIn_At && (new Date(day.date) >= new Date(date.loggedIn_At)) && (new Date(day.date).getTime() / (1000 * 60 * 60 * 24) <= new Date(date.out).getTime() / (1000 * 60 * 60 * 24) + 1) && (date.status == "سجل دخول") && (date.confermation)) {
                                                                return (
                                                                    <div key={num}>
                                                                        <Link onClick={() => setreservationInfoFunction(room.id, day, room.room_name, date.reservation_id)} to="/reservationInfo" title="تم تسجيل الدخول ، انقر لعرض الحجز" className='hidenLinkInTable'></Link>
                                                                        <Link key={num} to="/reservationInfo" title="تم تسجيل الدخول ، انقر لعرض الحجز" onClick={() => setreservationInfoFunction(room.id, day, room.room_name, date.reservation_id)} className='hereSpan greenReservation'></Link>
                                                                    </div>
                                                                )
                                                            } else if (date.loggedIn_At && date.loggedOut_At && (new Date(day.date).toLocaleDateString() >= new Date(date.loggedIn_At).toLocaleDateString()) && (new Date(day.date).toLocaleDateString() <= new Date(date.loggedOut_At).toLocaleDateString()) && (date.status == "سجل مغادرة") && (date.confermation)) {
                                                                return (
                                                                    <div key={num}>
                                                                        <Link onClick={() => setreservationInfoFunction(room.id, day, room.room_name, date.reservation_id)} to="/reservationInfo" title="تم تسجيل المغادرة ، انقر لعرض الحجز" className='hidenLinkInTable'></Link>
                                                                        <Link key={num} to="/reservationInfo" title="تم تسجيل المغادرة ، انقر لعرض الحجز" onClick={() => setreservationInfoFunction(room.id, day, room.room_name, date.reservation_id)} className='hereSpan yellowReservation'></Link>
                                                                    </div>
                                                                )
                                                            } else if ((date.confermation == false) && ((new Date(day.date) >= new Date(date.in)) && (new Date(day.date).getTime() / (1 * 1000 * 60 * 60 * 24) <= new Date(date.out).getTime() / (1 * 1000 * 60 * 60 * 24) + 1))) {
                                                                return (
                                                                    <div key={num}>
                                                                        <Link onClick={() => setreservationInfoFunction(room.id, day, room.room_name, date.reservation_id)} to="/reservationInfo" title="تم الغاء الحجز ، انقر لعرض الحجز" className='hidenLinkInTable'></Link>
                                                                        <Link key={num} to="/reservationInfo" title="تم الغاء الحجز ، انقر لعرض الحجز" onClick={() => setreservationInfoFunction(room.id, day, room.room_name, date.reservation_id)} className='hereSpan redReservation'></Link>
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                    {/* سجل دخول */}

                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            )
                        })
                    }

                </div>

                <div className='myCol'>
                    <div className='myCel'>
                        <span> الوحدات </span>
                    </div>
                    {/* map units ==>  */}
                    {
                        rooms.map((room, idx) => {
                            return (
                                <Link key={idx} to="/newreservation" title="انقر لاضافة حجز جديد" className='myCel myRoomCel'>
                                    <span className='celSpan'> {room.room_name} - {room.id} </span>
                                </Link>
                            )
                        })
                    }
                </div>


            </div>

            <div className='legend'>
                <p> حالات الحجز </p>

                <div className='legendContainer'>
                    <div className='statusOfReserve'>
                        <span title="تم تسجيل دخول" className='colorReservation greenReservation'></span>
                        <span className='desc'> تم تسجيل دخول </span>
                    </div>
                    <div className='statusOfReserve'>
                        <span title="لم يتم تسجيل دخول" className='colorReservation blueReservation'></span>
                        <span className='desc'> لم يتم تسجيل دخول </span>
                    </div>
                    <div className='statusOfReserve'>
                        <span title="تم تجسيل مغادرة" className='colorReservation yellowReservation'></span>
                        <span className='desc'> تم تجسيل مغادرة </span>
                    </div>
                    <div className='statusOfReserve'>
                        <span title="تم الغاء الحجز" className='colorReservation redReservation'></span>
                        <span className='desc'> تم الغاء الحجز </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
