import React, { useEffect, useContext, useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LoginContext } from '../../context/login';
import ReservationInfo from './ReservationInfo';
import useForm from '../../hooks/form';
import axios from 'axios';
import cookie from 'react-cookies';
import './newreserve.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import swal from 'sweetalert2';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import DatePicker from 'react-bootstrap-date-picker';
// import ReactWhatsapp from 'react-whatsapp';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function NewReservation(props) {
    // To load the token from cookies
    const qs = new URLSearchParams(window.location.search);
    const cookieToken = cookie.load('token');
    const token = qs.get('token') || cookieToken || null;

    const login = useContext(LoginContext);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [birthDate, setBirthDate] = useState(new Date());

    const [Rooms, setRooms] = useState([]);
    const [selectedRoom, setSelectedRoom] = useState('');
    const [dates, setDates] = useState([]);

    const [usersSearch, setUsersSearch] = useState([]);
    const [printUsersSearch, setPrintUsersSearch] = useState([]);
    const [defaultUserSelected, setDefaultUserSelected] = useState({});
    const [showReservation, setShowReservation] = useState(false);

    const [pricePeriod, setPricePeriod] = useState({});
    const [roomWithPrice, setRoomWithPrice] = useState({});
    const [roomWithOfferAndPrices, setRoomWithOfferAndPrices] = useState({});

    const [typeOfRent, setTypeOfRent] = useState("");
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalPriceWithTax, setTotalPriceWithTax] = useState(0);

    const [totalServicePrice, setTotalServicePrice] = useState(0);

    const [typeOfSearchData, setTypeOfSearchData] = useState('');

    const [offer, setOffer] = useState([]);
    const [price, setPrice] = useState([]);

    const [bedNumbers, setBedNumbers] = useState(0);
    const [avilableBeds, setAvilableBeds] = useState(0);

    const [Image, setImage] = useState({});

    const [discount, setDiscount] = useState({
        type: "",
        index: 0
    })

    // reservationData roomsData
    const [reservationData, setReservationData] = useState(null)
    const [roomsData, setRoomsData] = useState(null)

    const [days, setDays] = useState([
        { day: "السبت", id: 0 },
        { day: "الاحد", id: 1 },
        { day: "الاثنين", id: 2 },
        { day: "الثلاثاء", id: 3 },
        { day: "الاربعاء", id: 4 },
        { day: "الخميس", id: 5 },
        { day: "الجمعة", id: 6 },
    ]);

    const [singleAndDouble, setSingleAndDouble] = useState("");

    // scroll on select geust
    const scrollOnSelectGeust = () => {
        window.scrollTo({
            top: 500,
            behavior: 'smooth'
        });
    }

    useEffect(() => {
        props.setLoadingFunc(true).then(() => {
            axios.post(`${login.API}/getGuest`, {}, { headers: { Authorization: `Bearer ${token}` } })
                .then(res => {
                    setUsersSearch(res.data);

                    // get offer And Price
                    axios.get(`${login.API}/getAllOffers`, { headers: { Authorization: `Bearer ${token}` } })
                        .then(offersRes => {

                            let myOffers = offersRes.data.offers;
                            let myPrices = offersRes.data.price;

                            setPrice(myPrices);
                            setOffer(myOffers);

                        })
                    axios.post(`${login.API}/getAllRooms`, {},
                        { headers: { Authorization: `Bearer ${token}` } }).then((roomsDataArr) => {
                            setRoomsData(roomsDataArr.data);
                        })

                    axios.post(`${login.API}/getAllReservation`, {},
                        { headers: { Authorization: `Bearer ${token}` } }).then((reservationDataArr) => {
                            setReservationData(reservationDataArr.data);
                        })

                    props.setLoadingFunc(false)
                })
        })

    }, [])
    useEffect(()=>{
        if(reservationData && reservationData.length){
            let allowedUsers = usersSearch.filter(user=>{
                let userReservation = reservationData.find(reservation=>((reservation.status=='سجل دخول' || reservation.status=='لم يسجل دخول')&&(user.id==reservation.Guest.id)));
                if (userReservation) return false;
                else return true;
            });
            setUsersSearch(allowedUsers);
        }
    }, [reservationData])
    // getGuest
    useEffect(() => {
        if (dates.length > 0 && reservationData !== null) {
            let rooms = [];
            reservationData.map(reservation => {
                if (reservation.confirmed && (((new Date(dates[0]) >= new Date(reservation.checkIn) && new Date(dates[0]) <= new Date(reservation.checkOut)) || (new Date(dates[1]) >= new Date(reservation.checkIn) && new Date(dates[1]) <= new Date(reservation.checkOut))) && reservation.status != 'سجل مغادرة')) {
                    rooms.push(reservation);
                }
            });
            for (let j = 0; j < roomsData.rooms.length; j++) {  // id
                let availableBeds = roomsData.rooms[j].bed_numbers;
                for (let i = 0; i < rooms.length; i++) {  // room_id
                    if (rooms[i].room_id == roomsData.rooms[j].id) {
                        availableBeds -= rooms[i].bed_numbers;
                    }
                }
                roomsData.rooms[j].availableBeds = availableBeds;
            }


            let myDepartments = roomsData.department
            let roomsArr = [];
            roomsArr = roomsData.rooms.filter(room => {
                if ((room.availableBeds > 0) && (room.room_status == "avilable")) {
                    return room;
                }
            })

            roomsArr.sort((a, b) => Number(a.id) - Number(b.id));

            for (let i = 0; i < roomsArr.length; i++) {
                for (let j = 0; j < myDepartments.length; j++) {
                    if (myDepartments[j].id == roomsArr[i].unit_id) {
                        roomsArr[i] = { ...myDepartments[j], ...roomsArr[i] }
                    }
                }
            }

            setRooms(roomsArr);

        }
    }, [dates]);

    useEffect(() => {
        setPriceFunc();
    }, [dates])

    useEffect(() => {
        setPriceFunc();
    }, [typeOfRent])

    useEffect(() => {
        setPriceFunc();
    }, [selectedRoom])

    useEffect(() => {
        setPriceFunc();
    }, [bedNumbers])

    useEffect(() => {
        let newStart = new Date(startDate).getTime();
        let newEnd = new Date(endDate).getTime();

        setDates([newStart, newEnd]);
    }, [startDate, endDate])

    const setPriceFunc = () => {
        if (roomWithOfferAndPrices.monthely_price && dates[1] && dates[0]) {
            // setTotalPriceWithTax
            // monthly"daily
            let price = 0.00;

            if (typeOfRent == "يومي") {

                if (roomWithPrice.offer && discount.type == "offer") {
                    setDiscount({
                        type: "",
                        index: 0
                    })
                }
                if (roomWithPrice.price && discount.type == "price") {
                    setDiscount({
                        type: "",
                        index: 0
                    })
                }

                // let numberOfDayes = (new Date(dates[1]).getTime() - new Date(dates[0]).getTime()) / (1000 * 60 * 60 * 24);

                let startDate = dates[0];
                let endDate = dates[1];

                let startPeriod = (new Date(startDate).getTime() / (1000 * 60 * 60 * 24)) - 1;
                let lastPeriod = new Date(endDate).getTime() / (1000 * 60 * 60 * 24);
                let index = new Date(startDate).getDay();

                let period = {
                    period: parseInt((lastPeriod - startPeriod) - 1),
                    periodText: "ليلة"
                }
                setPricePeriod(period);

                while ((startPeriod < (lastPeriod - 1)) && (discount.type !== "price")) {
                    price += roomWithOfferAndPrices.daily_price[index];
                    if (index == 6) {
                        index = 0;
                    } else {
                        index += 1;
                    }
                    startPeriod += 1;
                }
                // set price
                while ((startPeriod < (lastPeriod - 1)) && (discount.type == "price")) {
                    if (roomWithOfferAndPrices.spacialPrice[discount.index].price[index] > 0) {
                        price += roomWithOfferAndPrices.spacialPrice[discount.index].price[index];
                    } else {
                        price += roomWithOfferAndPrices.daily_price[index];
                    }
                    if (index == 6) {
                        index = 0;
                    } else {
                        index += 1;
                    }
                    startPeriod += 1;
                }

            } else if (typeOfRent == "شهري") {
                // let price = 0;

                let startDate = dates[0];
                let endDate = dates[1];

                let startDay = new Date(startDate).getDate();
                let lastDay = new Date(endDate).getDate();

                let startMonth = new Date(startDate).getMonth();
                let lastMonth = new Date(endDate).getMonth();

                let startYear = new Date(startDate).getFullYear();
                let lastYear = new Date(endDate).getFullYear();

                let differenceDays = lastDay - startDay;
                if (differenceDays > 0) {
                    differenceDays = 1;
                } else {
                    differenceDays = 0;
                }
                let fullPeriod = ((lastYear - startYear) * 12) + (lastMonth - startMonth) + differenceDays;

                let period = {
                    period: fullPeriod?.toFixed(0),
                    periodText: "شهر"
                }

                setPricePeriod(period);

                let index = parseInt(period.period);
                // monthely_price
                if (discount.type == "price" && roomWithOfferAndPrices.spacialPrice[discount.index].monthely_price) {
                    price = index * roomWithOfferAndPrices.spacialPrice[discount.index].monthely_price;
                } else {
                    price = index * roomWithOfferAndPrices.monthely_price;
                }
            } else if (typeOfRent == "فصلي") {
                // let price = 0;

                let startDate = dates[0];
                let endDate = dates[1];

                let startPeriod = (new Date(startDate).getTime() / (1000 * 60 * 60 * 24)) - 1;
                let lastPeriod = new Date(endDate).getTime() / (1000 * 60 * 60 * 24);
                let period = {
                    period: (((lastPeriod - startPeriod) - 1) / 120)?.toFixed(0),
                    periodText: "فصل"
                }
                let lastAprovedPerdiod = parseInt((((lastPeriod - startPeriod) - 1) / 120)?.toFixed(0));
                if (lastAprovedPerdiod !== period.period) {
                    period.period = lastAprovedPerdiod + 1;
                }
                setPricePeriod(period);

                let index = period.period;
                // monthely_price
                if (discount.type == "price" && roomWithOfferAndPrices.spacialPrice[discount.index].seasonal_price) {
                    price = index * roomWithOfferAndPrices.spacialPrice[discount.index].seasonal_price;
                } else {
                    price = index * roomWithOfferAndPrices.seasonal_price;
                }
            } else if (typeOfRent == "اسبوعي") {
                // let price = 0;
                let startDate = dates[0];
                let endDate = dates[1];
                let startPeriod = (new Date(startDate).getTime() / (1000 * 60 * 60 * 24));
                let lastPeriod = new Date(endDate).getTime() / (1000 * 60 * 60 * 24);

                let period = {
                    period: ((lastPeriod - startPeriod) / 7)?.toFixed(2),
                    periodText: "اسبوع"
                }
                if (period.period.split(".")[1] != "00") {
                    period.period = parseInt(period.period.split(".")[0]) + 1;
                }

                setPricePeriod(period);

                let index = period.period;
                // monthely_price
                if (discount.type == "price" && roomWithOfferAndPrices.spacialPrice[discount.index].weekly_price) {
                    price = index * roomWithOfferAndPrices.spacialPrice[discount.index].weekly_price
                } else {
                    price = index * roomWithOfferAndPrices.weekly_price;
                }
            } else if (typeOfRent == "سنوي") {
                // let price = 0;

                let startDate = dates[0];
                let endDate = dates[1];

                let startPeriod = (new Date(startDate).getTime() / (1000 * 60 * 60 * 24)) - 1;
                let lastPeriod = new Date(endDate).getTime() / (1000 * 60 * 60 * 24);

                let period = {
                    period: (((lastPeriod - startPeriod) - 1) / 365)?.toFixed(0),
                    periodText: "سنة"
                }
                let lastAprovedPerdiod = parseInt((((lastPeriod - startPeriod) - 1) / 365)?.toFixed(0));
                if (lastAprovedPerdiod !== period.period) {
                    period.period = lastAprovedPerdiod + 1;
                }
                setPricePeriod(period);

                let index = period.period;
                // monthely_price

                if (discount.type == "price" && roomWithOfferAndPrices.spacialPrice[discount.index].yearly_price) {
                    price = index * roomWithOfferAndPrices.spacialPrice[discount.index].yearly_price;
                } else {
                    price = index * roomWithOfferAndPrices.yearly_price;
                }
            }

            // set tax
            let numberOfBeds = bedNumbers;
            if (bedNumbers === 0 || bedNumbers > roomWithPrice.bed_numbers) {
                setBedNumbers(roomWithPrice.bed_numbers)
                numberOfBeds = roomWithPrice.bed_numbers
            }
            let myBedPrice = parseFloat(numberOfBeds / roomWithPrice.bed_numbers);

            price = parseFloat(price * myBedPrice);

            if (discount.type == "offer") {
                if (roomWithPrice.spacialOffer[discount.index].type == "سعر") {
                    price -= roomWithPrice.spacialOffer[discount.index].value
                } else {
                    price = price - price * (roomWithPrice.spacialOffer[discount.index].value / 100)
                }
            }

            price = parseFloat((price)?.toFixed(0));

            setTotalPriceWithTax(price);
            setTotalPrice(price);
        }
    }

    const setBedNumbersFunction = (e) => {
        let myBeds = e.target.value
        setAvilableBeds(myBeds);
        setBedNumbers(myBeds);
    }
    // This Filter for the avilable services from selected room
    const selected = (e) => {

        // let myRoom;
        setRoomWithPrice(null);
        setRoomWithOfferAndPrices(null);
        setSelectedRoom(null);

        Rooms.filter(async (room) => {

            if (room.room_number === e.target.value) {

                setSelectedRoom(room.room_number);
                // myRoom = room

                room.hasOffers = false;
                room.spacialOffer = [];
                for (let i = 0; i < offer.length; i++) {
                    if (offer[i].departments.includes(room.name) && offer[i].status == "active") {
                        // room.offers.push(offer[i])
                        // type: "نسبة"
                        room.spacialOffer.push(offer[i]);
                        room.hasOffers = true;

                    }
                }

                room.hasPrices = false;
                room.spacialPrice = [];
                for (let i = 0; i < price.length; i++) {
                    if (price[i].department == room.name && price[i].status == "active") {

                        room.hasPrices = true;
                        room.spacialPrice.push(price[i]);

                    }
                }

                setRoomWithPrice(room);
                setRoomWithOfferAndPrices(room);
            }
        });


    };

    const rent_type = (e) => {
        setTypeOfRent(e.target.value)
    }

    const setDiscountFunction = (index, type) => {
        setDiscount({
            type: type,
            index: index
        })
    }

    useEffect(() => {
        setPriceFunc();
        // alert("لم يتم تفعيل العروض والاسعار الخاصه بعد")
    }, [discount])


    const { handleChange, handleSubmit } = useForm(handleNewReservation);

    // This is to handle the new reservation
    async function handleNewReservation(newData) {

        let data = { ...newData, ...defaultUserSelected }
        data.employee_id = login.user.id;
        data.birth_date = new Date(birthDate).getTime();

        var checkedValue = [];
        var inputElements = document.getElementsByClassName('service_check');
        for (var i = 0; inputElements[i]; i++) {
            if (inputElements[i].checked) {
                checkedValue.push(inputElements[i]._wrapperState.initialValue)
            }
        }

        data.services = checkedValue;
        data.unit = selectedRoom;

        if (avilableBeds != 0) {
            data.bed_numbers = parseInt(avilableBeds);
        } else {
            data.bed_numbers = parseInt(roomWithPrice.availableBeds);
        }


        data.checkIn = new Date(dates[0]).toLocaleDateString();
        data.checkOut = new Date(dates[1]).toLocaleDateString();
        data.rent_type = typeOfRent;
        data.confirmed = true;

        data.taxPercentege = 0;
        data.totalWithTaxContract = (totalPriceWithTax + totalServicePrice)?.toFixed(2)

        let todayDate = new Date();
        let checkInDate = new Date(data.checkIn);
        let checkOutDate = new Date(data.checkOut);

        data.price = (totalPrice)?.toFixed(2);
        data.totalServicePrice = (totalServicePrice)?.toFixed(2);

        data.personal_ID_Image = Image.name;

        if (!data.type) {
            data.type = singleAndDouble;
        }

        if (!data.bed_numbers ||
            !data.birth_date ||
            !data.email ||
            !data.guest_rate ||
            !data.guest_type ||
            !data.gender ||
            !data.id_type ||
            !data.id_number ||
            !data.name ||
            !data.nationallity ||
            !data.rent_type ||
            !data.type ||
            !data.unit ||
            !data.visit_reason) {
            return new swal({
                title: "حدث خطأ ما  اثناء الاضافة",
                text: "يرجى تعبئة كامل المعلومات قبل الحجز",
                icon: "warning",
                button: "try again!",
            });
        }

        if (checkOutDate > checkInDate) {

            props.setLoadingFunc(true).then(async () => {
                await axios.post(`${login.API}/newReservation`, data, { headers: { Authorization: `Bearer ${token}` } })
                    .then((res) => {
                        // add New Activity
                        // /newActivity'employee_id : date : description : type
                        let personalImage = new FormData();
                        personalImage.append('file', Image);

                        if (Image) {

                            axios.post(`${login.API}/image`, personalImage, { headers: { 'Authorization': `Basic ${token}` } })
                        }

                        axios.post(`${login.API}/newActivity`, {
                            employee_id: login.user.id,
                            description: ` اضافة حجز جديد للغرفة ${data.unit}`,
                            type: "اضافة حجز"
                        },
                            { headers: { Authorization: `Bearer ${token}` } })

                        let currentdate = new Date();
                        let datetime = (currentdate.toDateString()).split(' ')[0] + "  " + currentdate.getDate() + "/" + (currentdate.toLocaleDateString()).split('/')[0] + "/" + currentdate.getFullYear() + "  "
                            + currentdate.toLocaleTimeString();
                        let room_name = Rooms.filter(room => {
                            if (room.room_number == res.data.room_id) {
                                return room.room_name;
                            }

                        });
                        let reservationData = {
                            room_id: res.data.room_id,
                            reservation_id: res.data.id,
                            date: datetime,
                            room_name: room_name[0].room_name,
                            checkIn: res.data.checkIn,
                            checkOut: res.data.checkOut,
                        }



                        cookie.remove('reservationInfo');
                        cookie.save('reservationInfo', JSON.stringify(reservationData));

                        props.setreservationInfo(reservationData);
                        setShowReservation(true);

                        reservationData.employee_id = login.user.id;
                        reservationData.employee_name = login.user.name;
                        reservationData.guest_id = res.data.guest_id;
                        reservationData.total_price = totalPriceWithTax + totalServicePrice;
                        if (login.setting) {
                            if (login.setting.newReserve === "on") {
                                login.socket.emit("newReservation", reservationData);
                            }
                        }



                    })
                    .catch((err) => {
                        // alert
                        new swal({
                            title: "حدث خطأ ما  اثناء الاضافة",
                            text: "something went wrong",
                            icon: "warning",
                            button: "try again!",
                        });
                    });

                props.setLoadingFunc(false)
            })


        } else if (checkInDate >= checkOutDate) {
            // alert
            new swal({
                title: "تاريخ الدخول بنفس او اكبر من تاريخ المغادرة",
                text: "يرجى التأكد من صحة التاريخ",
                icon: "warning",
                button: "try again!",
            });
        }
    }



    // Search Function ==> 

    const getUsersSearch = (serchType) => {
        const typeOfSearch = serchType;
        setTypeOfSearchData(typeOfSearch);
        const userInputElement = document.getElementById("userInput");
        const userInput = userInputElement.value;


        let bookeOption = document.getElementById("bookOption");
        let emailOption = document.getElementById("emailOption");
        let phoneOption = document.getElementById("phoneOption");
        let nameOption = document.getElementById("nameOption");

        switch (typeOfSearch) {
            // bookOption"emailOption"phoneOption"nameOption
            case "name":
                userInputElement.placeholder = "أدخل معلومات البحث عن طريق الاسم";
                nameOption.style.background = "rgb(56 73 135)";
                nameOption.style.color = "white";

                bookeOption.style.background = "#5b9cf3cf";
                bookeOption.style.color = "black";
                emailOption.style.background = "#5b9cf3cf";
                emailOption.style.color = "black";
                phoneOption.style.background = "#5b9cf3cf";
                phoneOption.style.color = "black";
                // #5b9cf3cf
                break;
            case "email":
                userInputElement.placeholder = "أدخل معلومات البحث عن طريق الايميل";
                emailOption.style.background = "rgb(56 73 135)";
                emailOption.style.color = "white";

                bookeOption.style.background = "#5b9cf3cf";
                bookeOption.style.color = "black";
                nameOption.style.background = "#5b9cf3cf";
                nameOption.style.color = "black";
                phoneOption.style.background = "#5b9cf3cf";
                phoneOption.style.color = "black";

                break;
            case "phone":
                userInputElement.placeholder = "أدخل معلومات البحث عن طريق الهاتف";
                phoneOption.style.background = "rgb(56 73 135)";
                phoneOption.style.color = "white";

                bookeOption.style.background = "#5b9cf3cf";
                bookeOption.style.color = "black";
                emailOption.style.background = "#5b9cf3cf";
                emailOption.style.color = "black";
                nameOption.style.background = "#5b9cf3cf";
                nameOption.style.color = "black";

                break;
            case "book":

                userInputElement.placeholder = "أدخل معلومات البحث عن طريق رقم التعريف ";
                bookeOption.style.background = "rgb(56 73 135)";
                bookeOption.style.color = "white";

                emailOption.style.background = "#5b9cf3cf";
                emailOption.style.color = "black";
                phoneOption.style.background = "#5b9cf3cf";
                phoneOption.style.color = "black";
                nameOption.style.background = "#5b9cf3cf";
                nameOption.style.color = "black";

                break;

            default:
                break;
        }

        // id_number  phone_number  email
        // email">book">phone">name
        let userResult = usersSearch.filter((user) => {
            if (typeOfSearch == "name") {
                if (user.name.toLocaleLowerCase().includes(userInput.toLocaleLowerCase())) {
                    return (user);
                }
            } else if (typeOfSearch == "email") {
                if (user.email.toLocaleLowerCase().includes(userInput.toLocaleLowerCase())) {
                    return (user);
                }
            } else if (typeOfSearch == "book") {
                if (user.id_number.includes(userInput)) {
                    return (user);
                }
            } else if (typeOfSearch == "phone") {
                if (user.phone_number.includes(userInput)) {
                    return (user);
                }
            }
        })
        setPrintUsersSearch(userResult);
    }
    // setDevault Data to be rendered
    const renderNewDefaultUser = async (data) => {
        let birth_date = new Date(parseInt(data.birth_date));
        setDefaultUserSelected({ ...data, birth_date });
        scrollOnSelectGeust();
    }

    // handel Labels =>
    const selectLabel = (label) => {
        let mySelect = document.getElementById(`service-${label}`);
        mySelect.setAttribute.checked = true;

    }

    const checkLabelWithRadio = (id) => {
        let myRadio = document.getElementById(`${id}`);
        myRadio.checked = true;
        // setTypeOfRent
        let newRent = myRadio.value;
        if (id !== "single" && id !== "double") {
            setTypeOfRent(newRent);
        } else {
            setSingleAndDouble(newRent);
        }
    }

    const handleChangeImage = (e) => {
        setImage(e.target.files[0]);

    }

    return (
        <div >
            <div className='navigator'>
                <p>

                    <Link className='linkContainer' to='/'>
                        الرئيسية
                    </Link>
                    /
                    حجز جديد


                </p>
            </div>

            <div className='getUsersSearchPart'>
                <div className='searchBarRadio'>
                    <p className='tpyOfSearch'> البحث عن العملاء </p>
                    <div className='geustSerchRow'>

                        <input required onChange={() => getUsersSearch(typeOfSearchData)} id='userInput' placeholder='أدخل معلومات البحث' className='selectGeust' type="text" />
                        <div className='selectGeust' >
                            <span id="nameOption" onClick={() => getUsersSearch('name')} className='selectGeustOptions'> الاسم </span>
                            <span id="phoneOption" onClick={() => getUsersSearch('phone')} className='selectGeustOptions'> رقم الهاتف </span>
                            <span id="emailOption" onClick={() => getUsersSearch('email')} className='selectGeustOptions'> الايميل </span>
                            <span id="bookOption" onClick={() => getUsersSearch('book')} className='selectGeustOptions'> رقم بطاقة التعريف </span>
                        </div>

                    </div>

                </div>
                <div className='geustSearchResult'>
                    {
                        printUsersSearch.map((userToSelect, idx) => {
                            return (
                                <div key={idx} onClick={() => renderNewDefaultUser(userToSelect)} className='userToSelect'>
                                    <span className='userColumn'>  <p className='userListedData'> الايميل</p>    <p className='userListedData'>{userToSelect.email}</p> </span>
                                    <span className='userColumn'>  <p className='userListedData'>رقم الهاتف</p> <p className='userListedData'>{userToSelect.phone_number}</p> </span>
                                    <span className='userColumn'>  <p className='userListedData'> الاسم </p>     <p className='userListedData'>{userToSelect.name}</p> </span>
                                    <span className='userColumn'>  <p className='userListedData'>رقم اثبات الشخصية</p> <p className='userListedData'>{userToSelect.id_number}</p> </span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <Form className='newReserve' onSubmit={handleSubmit}>
                <div className='guestInfo'>
                    <div className='guestInfoContainer'>
                        <div className='guest_info left'>
                            <div className='inputDataForNewGuest'>
                                <label> رقم الهاتف </label>
                                <div className='inputPhone'>
                                    <input required defaultValue={defaultUserSelected.phone_number} onChange={handleChange} name="phone" placeholder=' رقم الهاتف' type='tel' />
                                </div>
                            </div>
                            <div className='inputDataForNewGuest'>
                                <label> رقم ولي الأمر / المعرف </label>
                                <div className='inputPhone'>
                                    <input required defaultValue={defaultUserSelected.phone_number_2} onChange={handleChange} name="phone_2" placeholder=' رقم ولي الأمر / المعرف' type='tel' />
                                </div>
                            </div>
                            <div className='inputDataForNewGuest'>
                                <label> الجنسية </label>
                                <input required value={defaultUserSelected.nationallity} onChange={handleChange} name="nationallity" placeholder='الجنسية' type='text' />
                            </div>

                            <div className='inputDataForNewGuest'>
                                <label> رقم اثبات الشخصية </label>
                                <input required defaultValue={defaultUserSelected.id_number} onChange={handleChange} name="id_number" placeholder=' رقم الهوية' type='text' />
                            </div >

                            <div className='inputDataForNewGuest'>
                                <label> تاريخ الميلاد </label>
                                <DatePicker
                                    showMonthDropdown={true}
                                    showYearDropdown={true}
                                    className="inputDataForNewGuest" dateFormat="dd-MM-yyyy" selected={defaultUserSelected.birth_date ? new Date(defaultUserSelected.birth_date) : birthDate} onChange={(date) => setBirthDate(date)} name="checkOut" type="date" placeholder="checkOut-Date" required />
                            </div >

                            <div className='inputDataForNewGuest'>
                                <label> تصنيف العميل </label>
                                <select value={defaultUserSelected.guest_rate} onChange={handleChange} name="guest_rate" id="role" required>
                                    <option value=""> اختر تصنيف العميل </option>
                                    <option value="مميز">  مميز </option>
                                    <option value="خاص">  خاص </option>
                                    <option value="غير مرغوب فيه">  غير مرغوب فيه </option>
                                </select>
                            </div>

                        </div >

                        <div className='guest_info right'>
                            <div className='inputDataForNewGuest'>
                                <label> الإسم </label>
                                <input required defaultValue={defaultUserSelected.name} onChange={handleChange} name="name" placeholder='الاسم' type='text' />
                            </div >

                            <div className='inputDataForNewGuest'>
                                <label> البريد الالكتروني </label>
                                <input required onChange={handleChange} defaultValue={defaultUserSelected.email} name="email" placeholder=' البريد الالكتروني ' type='email' />
                            </div >

                            <div className='inputDataForNewGuest'>
                                <label>نوع العميل</label>
                                <select value={defaultUserSelected.guest_type} onChange={handleChange} name="guest_type" required>
                                    <option value=""> اختر نوع </option>
                                    <option value="مواطن"> مواطن </option>
                                    <option value="زائر"> زائر </option>
                                    <option value="مقيم"> مقيم </option>
                                </select>
                            </div>

                            <div className='inputDataForNewGuest'>
                                <label>  نوع اثبات الشخصية </label>
                                <select value={defaultUserSelected.id_type} onChange={handleChange} name="id_type" required>
                                    <option > اختر نوع </option>
                                    <option value="بطاقة احوال"> بطاقة احوال </option>
                                    <option value="دفتر عائلة"> دفتر عائلة </option>
                                    <option value="جواز سفر"> جواز سفر </option>
                                </select>
                            </div>

                            <div className='inputDataForNewGuest'>
                                <label> الجنس </label>
                                <select value={defaultUserSelected.gender} onChange={handleChange} name="gender" required id="gender">
                                    <option > اختر الجنس </option>

                                    <option value="male"> ذكر </option>
                                    <option value="female"> انثى </option>
                                </select>
                            </div>

                            {
                                !defaultUserSelected.phone_number &&
                                <div className='inputDataForNewGuest'>
                                    <label> تحميل صورة اثبات الشخصية </label>
                                    <input required onChange={handleChangeImage} name="personal_ID_Image" placeholder='personal Identification' type='file' />
                                </div>
                            }


                            <div className='inputDataForNewGuest'>
                                <label> سبب الزيارة </label>
                                <select onChange={handleChange} name="visit_reason" required>
                                    <option value=""> اختر سبب </option>
                                    <option value="سياحة">  سياحة </option>
                                    <option value="زيارة الاهل والاصدقاء">  زيارة الاهل والاصدقاء </option>
                                    <option value="زيارة دينية">  زيارة دينية </option>
                                    <option value="زيارة علاجية">  زيارة علاجية </option>
                                    <option value="زيارة عمل">  زيارة عمل </option>
                                    <option value="زيارة رياضية">  زيارة رياضية </option>
                                    <option value="زيارة ترفيهية">  زيارة ترفيهية </option>
                                    <option value="دراسة">  دراسة  </option>
                                </select>
                            </div>

                        </div >
                    </div >
                </div >

                <div className='bookInfo'>

                    <div className='bookInfoContainer one' >
                        <Form.Group className="mb-3 sub" id="reservation_type" >
                            <Form.Group className="mb-3" >
                                <Form.Label className='lableToClickWithRadio' onClick={() => checkLabelWithRadio('double')} htmlFor="email">جماعي</Form.Label>
                                <input required onChange={handleChange} type="radio" className='radio_lable' id="double" name="type" value="جماعي" />
                            </Form.Group>

                            <Form.Group className="mb-3" >
                                <Form.Label className='lableToClickWithRadio' onClick={() => checkLabelWithRadio('single')} htmlFor="email">فردي</Form.Label>
                                <input required onChange={handleChange} type="radio" className='radio_lable' id="single" name="type" value="فردي" />
                            </Form.Group>



                            <Form.Label className='booktype_lable'>نوع الحجز</Form.Label>

                        </Form.Group>
                    </div>

                    <div className='bookInfoContainer two'>
                        <p className='loginFormElement lable'>نوع الايجار</p>

                        <Form.Group className="mb-3 sub rent"  >


                            <div className='rent_type'>
                                <Form.Group className="mb-3" >
                                    <Form.Label className='lableToClickWithRadio' onClick={() => checkLabelWithRadio('monthlyReservation')} htmlFor="email">شهري</Form.Label>
                                    <input required onChange={rent_type} type="radio" className='radio_lable' id="monthlyReservation" name="rent_type" value="شهري" />
                                </Form.Group>

                                <Form.Group className="mb-3" >
                                    <Form.Label className='lableToClickWithRadio' onClick={() => checkLabelWithRadio('weeklyReservation')} htmlFor="email">اسبوعي</Form.Label>
                                    <input required onChange={rent_type} type="radio" className='radio_lable' id="weeklyReservation" name="rent_type" value="اسبوعي" />
                                </Form.Group>

                                <Form.Group className="mb-3" >
                                    <Form.Label className='lableToClickWithRadio' onClick={() => checkLabelWithRadio('dailyReservation')} htmlFor="email">يومي</Form.Label>
                                    <input required onChange={rent_type} type="radio" className='radio_lable' id="dailyReservation" name="rent_type" value="يومي" />
                                </Form.Group>
                            </div>
                        </Form.Group>

                        <Form.Group className="mb-3 sub date" >
                            <DatePicker
                                selectsStart
                                startDate={startDate}
                                maxDate={endDate}
                                endDate={endDate}
                                showMonthDropdown={true}
                                showYearDropdown={true}
                                className="inputDataForNewGuest" dateFormat="dd-MM-yyyy" selected={startDate} onChange={(date) => setStartDate(date)} name="checkIn" type="date" placeholder="checkIn-Date" required />
                            <Form.Label className='loginFormElement' htmlFor="email">تاريخ البداية</Form.Label>
                        </Form.Group>

                        <Form.Group className="mb-3 sub date" >
                            <DatePicker
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                showMonthDropdown={true}
                                showYearDropdown={true}
                                className="inputDataForNewGuest" dateFormat="dd-MM-yyyy" selected={endDate} onChange={(date) => setEndDate(date)} name="checkOut" type="date" placeholder="checkOut-Date" required />
                            <Form.Label className='loginFormElement' htmlFor="email">تاريخ النهاية</Form.Label>
                        </Form.Group>

                        <Form.Group className="mb-3 sub unit_type" >
                            <Form.Group className="mb-3 inputDataForNewGuest" >
                                <select onChange={selected} name="unit" required >
                                    <option value=""> اختر وحدة </option>
                                    {Rooms &&
                                        Rooms.map((room, idx) => {
                                            return (
                                                <option key={idx} className='rooms_option' value={room.room_number}  >
                                                    {`${room.room_name} - ${room.id}#`}
                                                </option>
                                            )
                                        })
                                    }

                                </select>
                            </Form.Group>
                            <Form.Label className='loginFormElement' htmlFor="email">الوحدة</Form.Label>
                        </Form.Group>

                        {/* availableBeds */}
                        {
                            roomWithPrice.availableBeds &&
                            <div className='numberOfAvailableBedNote'>
                                <p className='bedsElements'><span>عدد الأسرة الاجمالي في الوحدة </span> <span> {roomWithPrice.bed_numbers}</span></p>
                                <p className='bedsElements'><span>عدد الأسرة المتاحة في الوحدة</span> <span> {roomWithPrice.availableBeds}</span> </p>

                            </div>
                        }

                        {(roomWithPrice.bed_numbers > 1) ?
                            <Form.Group className="mb-3 sub date" >
                                <Form.Control id="bed_numbers" onChange={setBedNumbersFunction} name="bed_numbers" type="number" defaultValue={roomWithPrice.availableBeds} max={roomWithPrice.availableBeds} required />
                                <Form.Label className='loginFormElement' htmlFor="email">عدد الأسرة</Form.Label>
                            </Form.Group>
                            :
                            null
                        }
                    </div>
                </div>
                <div className='allOffersPricesTableContainer'>
                    {
                        roomWithPrice.hasOffers == true &&
                        <div className='hasOfferContainer'>
                            <p> هذه الغرفة تستفيد من عروض سارية </p>
                            <div className='allOffersPricesContainer'>
                                {/* spacialPrice spacialOffer */}
                                <div className='offerToBeSelected'>
                                    <span> خيار التفعيل </span>
                                    <span> قيمة العرض </span>
                                    <span> نوع العرض </span>
                                    <span> اسم العرض </span>
                                </div>
                                {
                                    roomWithPrice.spacialOffer.map((off, idx) => {
                                        return (
                                            <div className='offerToBeSelected' key={idx}>
                                                <span onClick={() => setDiscountFunction(idx, "offer")} className='addThisOfferToReservation'> تفعيل هذا العرض </span>
                                                <span> {off.value} </span>
                                                <span> {off.type} </span>
                                                <span> {off.name} </span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }

                    {
                        roomWithPrice.hasPrices == true &&
                        <div className='hasPricesContainer'>
                            <p> هذه الغرفة تستفيد من اسعار خاصة سارية </p>
                            <div className='allOffersPricesContainer'>
                                <div className='priceToBeSelected' >
                                    <span> خيار التفعيل </span>
                                    <span></span>
                                    <span> اسم العرض </span>
                                </div>
                                {
                                    roomWithPrice.spacialPrice.map((off, idx) => {
                                        return (
                                            <div className='priceToBeSelected' key={idx}>
                                                <span onClick={() => setDiscountFunction(idx, "price")} className='addThisOfferToReservation'> تفعيل هذا العرض </span>
                                                <span></span>
                                                <span> {off.name} </span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }

                </div>
                {(discount.type == "offer" || discount.type == "price") ?
                    <span onClick={() => setDiscountFunction(0, "")} className='cancelOffers'>
                        الغاء تطبيق العروض والاسعار الخاصه
                    </span>
                    :
                    null
                }
                {
                    (totalPrice >= 0 && totalPriceWithTax >= 0) &&
                    <div className='calculatePrice'>
                        <div className='priceReservation'>

                            {
                                totalPrice == 0 &&
                                <span className='totalPriceReservationSpan problemWithPrice'>سعر التأجير الفرعي : {totalPrice} دينار </span>
                            }
                            {
                                totalPrice > 0 &&
                                <span className='totalPriceReservationSpan'>سعر التأجير الفرعي : {totalPrice} دينار </span>
                            }
                            <span className='totalPriceReservationSpan'>سعر الخدمات : {totalServicePrice} دينار </span>


                            <span className='totalPriceReservationSpan'>السعر الاجمالي : {(totalPriceWithTax + totalServicePrice)?.toFixed(2)} دينار </span>

                        </div>

                        <div className='priceReservation'>
                            <span>تأجير الوحدة {selectedRoom}</span>

                            <span>
                                {"   " + new Date(dates[1]).toDateString() + "   "}
                                {"  الى  "}
                                {"    " + new Date(dates[0]).toDateString() + "   "}
                                {"  من  "}
                            </span>

                            <span className='nightSpan'> <span>{pricePeriod.periodText}</span> <span>{pricePeriod.period}</span> </span>
                        </div>


                    </div>
                }


                <div className='submitNewBook'>
                    <button type='submit'>
                        حفظ بيانات الحجز
                    </button>
                </div>
            </Form >



            <Modal show={showReservation} className='addedSuccessfully'>
                <Modal.Header>
                    <CheckCircleIcon />
                    <Modal.Title>
                        تم إنشاء الحجز بنجاح
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Reservation Added Successfully
                </Modal.Body>
                <Modal.Footer>
                    <Link to='/reservationInfo'>
                        <Button variant="success" >Ok !</Button>
                    </Link>
                </Modal.Footer>
            </Modal>
        </div >
    )


}


