import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { LoginContext } from '../../context/login';
import { Button, Form, Modal } from 'react-bootstrap';
import useForm from '../../hooks/form';
import axios from 'axios';
import cookies from 'react-cookies';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PrintIcon from '@mui/icons-material/Print';
import swal from 'sweetalert2';

import "./financialManagment.css";

export default function Recipts(props) {
    // To load the token from cookies
    const qs = new URLSearchParams(window.location.search);
    const cookieToken = cookies.load('token');
    const token = qs.get('token') || cookieToken || null;

    const login = useContext(LoginContext);


    const [reciepts, setReciepts] = useState([]);
    const [filteredReciepts, setFilteredReciepts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [filter, setFilter] = useState();
    const [employees, setEmployees] = useState([]);
    const [recivedForFilter, setRecivedForFilter] = useState();
    const [dates, setDates] = useState([]);
    const [todayDate, setTodayDate] = useState();
    const [holdedAmount, setHoldedAmount] = useState(0);
    const [insurance, setInsurance] = useState(0);
    const [rentAmount, setRentAmount] = useState(0);
    const [generalReciepts, setGeneralReciepts] = useState(0);
    const [transactions, setTransactions] = useState(0);
    const [services, setServices] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [sort, setSort] = useState("down");
    const [showReciepts, setShowReciepts] = useState(false);
    const [selectedReciepts, setSelectedReciepts] = useState({});
    const [hotel, setHotel] = useState({});
    const [moneyBillsToUpdate, setMoneyBillsToUpdate] = useState(null);

    const [managers, setManagers] = useState([]);

    useEffect(async () => {
        props.loading(true).then(async () => {
            let today = new Date();
            setTodayDate(today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate());

            let hotelData = cookies.load('hotel');
            setHotel(hotelData);

            await axios.post(`${login.API}/getallreciptes`, {}, { headers: { 'Authorization': `Bearer ${token}` } })
                .then(async res => {
                    setReciepts(res.data);
                    setFilteredReciepts(res.data);
                });
            await axios.post(`${login.API}/getEmployees`, {}, { headers: { 'Authorization': `Bearer ${token}` } })
                .then(async res => {

                    setEmployees(res.data);
                })
            await axios.get(`${login.API}/getDocuments`, { headers: { 'Authorization': `Bearer ${token}` } })
                .then(res => {
                    setManagers(res.data[0]);
                })
            props.loading(false);
        })
    }, []);

    const handleShowModal = () => {
        setShowModal(true);
    }

    const handleClose = () => {
        setShowModal(false);
        setShowReciepts(false);
        setMoneyBillsToUpdate(null);
    }

    const handleChange_2 = () => {
        let recivedFor = document.getElementById("expenseType").value;
        setRecivedForFilter(recivedFor);
    }

    const { handleChange, handleSubmit } = useForm(addReciepts);

    async function addReciepts(data) {

        data.employee_id = login.user.id;
        data.employee_name = login.user.name;
        data.for = recivedForFilter;

        data.type = "قبض";
        data.reservation_id = 0;

        props.loading(true).then(async () => {
            if (moneyBillsToUpdate) {
                await axios.post(`${login.API}/updateRecipte`, { id: moneyBillsToUpdate.id, params: data }, { headers: { 'Authorization': `Bearer ${token}` } })
                    .then(async res => {
                        let newRecords = reciepts.map(receipt => {
                            if (receipt.id !== moneyBillsToUpdate.id) return receipt;
                            return { ...receipt, ...data }
                        })
                        setReciepts(newRecords);
                        setShowModal(false);
                        props.loading(false);
                        setMoneyBillsToUpdate(null);
                    })
            } else {
                await axios.post(`${login.API}/newRecipte`, data, { headers: { 'Authorization': `Bearer ${token}` } })
                    .then(async res => {
                        let newRecords = [...reciepts, res.data.reciepts];
                        setReciepts(newRecords);
                    })

                setShowModal(false);
                props.loading(false);
            }
        })
    }

    const calculateMoney = () => {

        let insuranceCount = 0;
        let rentAmountCount = 0;
        let generalRecieptsCount = 0;
        let holdAmount = 0;
        let transactionsCount = 0;
        let servicesCount = 0;
        let discountCount = 0;

        filteredReciepts.map(reciepts => {
            if (reciepts.for === "قيمة إيجار") {
                rentAmountCount += parseInt(reciepts.amount);
            } else if (reciepts.for === "عربون") {
                holdAmount += parseInt(reciepts.amount);
            } else if (reciepts.for === "تأمين") {
                insuranceCount += parseInt(reciepts.amount);
            } else if (reciepts.for === "مقبوضات عامة") {
                generalRecieptsCount += parseInt(reciepts.amount);
            } else if (reciepts.for === "تحويل من الصندوق إلى الإدارة") {
                transactionsCount += parseInt(reciepts.amount);
            } else if (reciepts.for === "خدمات") {
                servicesCount += parseInt(reciepts.amount);
            } else if (reciepts.for === "قيمة خصم إيجار") {
                discountCount += parseInt(reciepts.amount);
            }
        })


        setRentAmount(rentAmountCount);
        setInsurance(insuranceCount);
        setGeneralReciepts(generalRecieptsCount);
        setTransactions(transactionsCount);
        setHoldedAmount(holdAmount);
        setServices(servicesCount);
        setDiscount(discountCount);
    }

    const filterFunc = () => {
        let filteredArray = reciepts;

        let filterByRecieptsNumber = document.getElementById('filterByRecieptsNumber').value;
        let filterByRecieptsType = document.getElementById('filterByRecieptsType').value;
        setFilter(filterByRecieptsType);
        let filterByEndDate = document.getElementById('filterByEndDate').value;
        let filterByStartDate = document.getElementById('filterByStartDate').value;
        let filterByReservation = document.getElementById('filterReservationByNumber').value;

        // Filter By Expense Number
        if (filterByRecieptsNumber !== "") {
            filteredArray = filteredArray.filter(reciept => {
                if (reciept.id === parseInt(filterByRecieptsNumber)) {
                    return reciept;
                };
            });
        }
        // Filter By Reservation ID
        if (filterByReservation !== "") {
            filteredArray = filteredArray.filter(reciept => {
                if (reciept.reservation_id === parseInt(filterByReservation)) {
                    return reciept;
                };
            });
        }
        // Filter By Expense Type
        if (filterByRecieptsType !== "null") {
            filteredArray = filteredArray.filter(reciept => {
                if (reciept.for === filterByRecieptsType) {
                    return reciept;
                };
            });
        }
        // Filter By Start Date
        if (filterByStartDate !== "") {
            let date = [filterByStartDate,dates[1]||new Date().toISOString().split('T')[0]];
            setDates(date);
            let newDate;
            filteredArray = filteredArray.filter(reciept => {
                newDate = new Date(reciept.date)!='Invalid Date'?new Date(reciept.date):(reciept.date.split("  ")[1]).split("/")[2] + "-" + (reciept.date.split("  ")[1]).split("/")[1] + "-" + (reciept.date.split("  ")[1]).split("/")[0];
                if ((new Date(newDate).getTime() + 7200000 >= (new Date(filterByStartDate)).getTime())) {
                    return reciept;
                };
            });
        }else{
            let date = ['',dates[1]||new Date().toISOString().split('T')[0]];
            setDates(date);
        }
        // Filter By End Date
        if (filterByEndDate !== "") {
            let date = [dates[0],filterByEndDate];
            setDates(date);
            let newDate;
            filteredArray = filteredArray.filter(reciept => {
                newDate = new Date(reciept.date)!='Invalid Date'?new Date(reciept.date):(reciept.date.split("  ")[1]).split("/")[2] + "-" + (reciept.date.split("  ")[1]).split("/")[1] + "-" + (reciept.date.split("  ")[1]).split("/")[0];
                if ((new Date(newDate).getTime() + 7200000 <= (new Date(filterByEndDate)).getTime())) {
                    return reciept;
                };
            });
        }else{
            let date = [dates[0],''];
            setDates(date);
        }

        setFilteredReciepts([])
        setFilteredReciepts(filteredArray);
    }

    const sortData = (sortBy) => {

        let sortedArray = filteredReciepts;
        if (sort === "up") {
            setSort("down");
            if (sortBy === "expense") {
                sortedArray = sortedArray.sort((a, b) => b.id - a.id);
            } else if (sortBy === "date") {
                sortedArray = sortedArray.sort((a, b) => {
                    return (
                        (new Date(a.date).getTime() - new Date(b.date).getTime())
                    )
                });
            } else if (sortBy === "amount") {
                sortedArray = sortedArray.sort((a, b) => b.amount - a.amount);
            } else if (sortBy === "reserve") {
                sortedArray = sortedArray.sort((a, b) => b.reservation_id - a.reservation_id);
            }
        } else if (sort === "down") {
            setSort("up");
            if (sortBy === "expense") {
                sortedArray = sortedArray.sort((a, b) => a.id - b.id);
            } else if (sortBy === "date") {
                sortedArray = sortedArray.sort((a, b) => {
                    return (
                        (new Date(b.date).getTime() - new Date(a.date).getTime())
                    )
                });
            } else if (sortBy === "amount") {
                sortedArray = sortedArray.sort((a, b) => a.amount - b.amount);
            } else if (sortBy === "reserve") {
                sortedArray = sortedArray.sort((a, b) => a.reservation_id - b.reservation_id);
            }
        }




        setFilteredReciepts([])
        setFilteredReciepts(sortedArray);
    }

    const handlesetShowReciepts = (expense) => {
        setShowReciepts(true);
        setSelectedReciepts(expense);
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        filterFunc();
    }, [reciepts])

    useEffect(() => {
        calculateMoney();
    }, [filteredReciepts])

    useEffect(() => {
        if (moneyBillsToUpdate && (moneyBillsToUpdate.for == "مقبوضات عامة" || moneyBillsToUpdate.for == "تحويل من الصندوق إلى الإدارة")) {
            setShowReciepts(false);
            handleShowModal();
        } else if (moneyBillsToUpdate) {
            setShowReciepts(false);
            swal.fire({
                title: "تعديل غير مسموح",
                text: "لا يمكنك تعديل السندات المالية الخاصة بالحجوزات من هنا، يرجى تعديلها من داخل الحجز تجنبا لأي خطأ",
                icon: 'error',
                confirmButtonColor: '#d33',
                cancelButtonText: 'حسناً'
            })
            setMoneyBillsToUpdate(null);
        }
    }, [moneyBillsToUpdate])

    const updateMoneyTransfer = item => {
        setMoneyBillsToUpdate(item);
        setRecivedForFilter(item.for);
    }

    return (
        <div>
            <div className='navigator'>
                <p> <Link className='linkContainer' to='/'> الرئيسية </Link>  /
                    <Link className='linkContainer' to='/financialManagments'> الإدارة المالية </Link> /
                    إدارة المقبوضات
                </p>
            </div>

            <div className='filterInputsContainer'>


                <div className='managementRow_1'>
                    <span className='managementFilter'>
                        <select onChange={filterFunc} id="filterByRecieptsType" className='filterInput'>

                            <option value="null">قبض لأجل</option>
                            <option value="قيمة إيجار">قيمة إيجار </option>
                            <option value="تأمين">قيمة تأمين</option>
                            <option value="عربون"> عربون</option>
                            <option value="خدمات">خدمات</option>
                            <option value="قيمة خصم إيجار">قيمة خصم إيجار</option>
                            <option value="مقبوضات عامة">مقبوضات عامة</option>
                            <option value="تحويل من الصندوق إلى الإدارة">تحويل من الصندوق إلى الإدارة </option>
                        </select>
                    </span>

                    <span className='managementFilter'>
                        <span id="filterByEndDateDiv" className='filterInput filterByEndDateDiv'>
                            {dates[1] ? `${dates[1]} تاريخ النهاية` : "تاريخ النهاية"}
                            <input required onChange={filterFunc} id="filterByEndDate" className="filterInput filterByEndDate" title='تاريخ النهاية' type="date" />
                        </span>

                    </span>
                    <span className='managementFilter'>
                        <span id="filterByStartDateDiv" className='filterInput filterByStartDateDiv'>
                            {dates[0] ? `${dates[0]} تاريخ البداية` : "تاريخ البداية"}
                            <input required onChange={filterFunc} id="filterByStartDate" className="filterInput filterByStartDate" title='تاريخ البداية' type="date" />
                        </span>

                    </span>

                    <span className='managementFilter'>
                        <input required onChange={filterFunc} id="filterReservationByNumber" placeholder='رقم الحجز ' className='filterInput' type="text" />

                    </span>

                    <span className='managementFilter'>
                        <input required onChange={filterFunc} id="filterByRecieptsNumber" placeholder='رقم السند ' className='filterInput' type="text" />

                    </span>
                </div>


                <div className='managementRow_1'>

                    <span className='managementFilter managementCol'>
                        <span>الخدمات</span>
                        <span>{services?.toFixed(2)}</span>
                    </span>

                    <span className='managementFilter managementCol'>
                        <span>إجمالي العربون</span>
                        <span>{holdedAmount?.toFixed(2)}</span>
                    </span>
                    <span className='managementFilter managementCol'>
                        <span>اجمالي التأمين</span>
                        <span>{insurance?.toFixed(2)}</span>
                    </span>
                    <span className='managementFilter managementCol'>
                        <span>إجمالي الإيجار</span>
                        <span>{rentAmount?.toFixed(2)}</span>
                    </span>
                    <span className='managementFilter managementCol'>
                        <span>النقدي</span>
                        <span>{(rentAmount + insurance + holdedAmount + transactions + generalReciepts + services)?.toFixed(2)}</span>
                    </span>

                </div>

                <div className='managementRow_1'>

                    <span className='managementFilter managementCol'>
                        <span>المبلغ الكلي </span>
                        <span>{(rentAmount + insurance + transactions + generalReciepts + holdedAmount + services)?.toFixed(2)}</span>
                    </span>

                    <span className='managementFilter managementCol'>
                        <span>تحويل من الصندوق إلى الإدارة </span>
                        <span>{transactions?.toFixed(2)}</span>
                    </span>

                    <span className='managementFilter managementCol'>
                        <span>خصومات الإيجار</span>
                        <span>{discount?.toFixed(2)}</span>
                    </span>
                    <span className='managementFilter managementCol'>
                        <span>المقبوضات العامة</span>
                        <span>{generalReciepts?.toFixed(2)}</span>
                    </span>



                </div>

                <div className='managementRow_1'>



                </div>
            </div>

            <div className='printFeild'>
                <i title="طباعة التقرير" onClick={handlePrint} className="printIcon ">
                    <PrintIcon />
                </i>
                {/*  */}

                {/*  */}

                <span title="excel تصدير بصيغة ملف">
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="printIcon far fa-file-excel"
                        table="myReport"
                        filename="تقرير الحجوزات"
                        sheet="تقرير الحجوزات"
                        buttonText=""
                    />
                </span>
                {/* </i> */}
            </div>

            <div className='filterInputsContainer'>
                <div className='managementRow_1_button '>
                    <Button onClick={handleShowModal}>
                        إضافة سند قبض
                    </Button>
                </div>
                <table ref={componentRef} id="myReport">
                    <thead>
                        <tr className='trMainTable'>
                            <th className='roomSetingHeader'>الاجراءات </th>
                            <th className='roomSetingHeader'>الملاحظات </th>
                            <th className='partOfHeadTable' > الموظف</th>
                            <th className='partOfHeadTable expense_filter_option' onClick={() => sortData("date")}> تاريخ السند </th>
                            <th className='partOfHeadTable' > لأجل</th>
                            <th className='partOfHeadTable' >  أستلم من</th>
                            <th className='partOfHeadTable expense_filter_option' onClick={() => sortData("amount")}> المبلغ</th>
                            <th className='partOfHeadTable'  >رقم الوحدة</th>
                            <th className='partOfHeadTable expense_filter_option' onClick={() => sortData("reserve")} > رقم الحجز </th>
                            <th className='partOfHeadTable expense_filter_option' onClick={() => sortData("expense")}> رقم السند </th>
                        </tr>

                    </thead>
                    <tbody>
                        {/* run from database */}
                        {filteredReciepts.map((reciept, index) => {
                            return (
                                <tr onClick={() => { handlesetShowReciepts(reciept) }} className='veiwReservationTable expense_row' key={index}>
                                    <td>
                                        <div data-v-0a75d390="" className="action">

                                            <a className="takeActionUnit" title="عرض">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20"
                                                    height="20" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                                </svg>
                                            </a>

                                            {
                                                login.user?.role === "manager" &&
                                                <a onClick={() => updateMoneyTransfer(reciept)} className="takeActionUnit" title="تعديل">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                    </svg>
                                                </a>
                                            }

                                        </div>
                                    </td>
                                    <td>{reciept.description}</td>
                                    <td>{reciept.employee_name}</td>
                                    <td>{reciept.date}</td>
                                    <td>{reciept.for + " - " + reciept.paied_for}</td>
                                    <td>{reciept.recievedFrom}</td>
                                    <td>{reciept.amount?.toFixed(2)}</td>
                                    <td>{reciept.paied_for}</td>
                                    <td>{reciept.reservation_id}</td>
                                    <td>{reciept.id}</td>
                                </tr>
                            )
                        })

                        }


                        <tr id="reciepts_amount">
                            {filter === "قيمة إيجار" ?
                                <td colSpan={6}>{rentAmount?.toFixed(2)}</td>
                                :
                                filter === "عربون" ?
                                    <td colSpan={6}>{holdedAmount?.toFixed(2)}</td>
                                    :
                                    filter === "تأمين" ?
                                        <td colSpan={6}>{insurance?.toFixed(2)}</td>
                                        :
                                        filter === "مقبوضات عامة" ?
                                            <td colSpan={6}>{generalReciepts?.toFixed(2)}</td>
                                            :
                                            filter === "تحويل من الصندوق إلى الإدارة" ?
                                                <td colSpan={6}>{transactions?.toFixed(2)}</td>
                                                :
                                                filter === "خدمات" ?
                                                    <td colSpan={6}>{services?.toFixed(2)}</td>
                                                    :
                                                    filter === "قيمة خصم إيجار" ?
                                                        <td colSpan={6}>{discount?.toFixed(2)}</td>
                                                        :
                                                        <td colSpan={6}>{(rentAmount + insurance + transactions + generalReciepts + holdedAmount + services)?.toFixed(2)}</td>
                            }

                            <th colSpan={6}>المبلغ الكلي</th>

                        </tr>




                    </tbody>
                </table>
            </div>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header className='recipts_modal_header'>
                    <CloseRoundedIcon onClick={handleClose} />
                    <Modal.Title>مقبوضات</Modal.Title>
                </Modal.Header>

                <Form onSubmit={handleSubmit}>
                    <Modal.Body className='recipts_modal_body'>
                        <div className='recipts_modal_body_top'>
                            <div>
                                <span>التاريخ</span>
                                <input required name='date' type="date" onChange={handleChange} />
                            </div>
                            <div>
                                <span> النوع</span>
                                <select required name="for" className='recipts_type' id="expenseType" onChange={handleChange_2}>
                                    <option value="">  إختر النوع </option>
                                    <option selected={moneyBillsToUpdate?.for === "مقبوضات عامة" ? 'selected' : ''} value="مقبوضات عامة">مقبوضات عامة</option>
                                    <option selected={moneyBillsToUpdate?.for === "تحويل من الصندوق إلى الإدارة" ? 'selected' : ''} value="تحويل من الصندوق إلى الإدارة">تحويل من الإدارة إلى الصندوق </option>
                                </select>
                            </div>
                        </div>

                        <div className='recipts_modal_body_middle'>

                            <div>
                                <span>أستلمت من</span>
                                {
                                    (moneyBillsToUpdate?.for === "تحويل من الصندوق إلى الإدارة" || recivedForFilter === "تحويل من الصندوق إلى الإدارة") ?
                                        <select required name="recievedFrom" className='recipts_type' id="expenseType" onChange={handleChange}>
                                            <option value="">  إختر المدير </option>
                                            {employees.map((employee, index) => {
                                                if (employee.role === "manager") {
                                                    return (
                                                        <option selected={moneyBillsToUpdate?.recievedFrom == employee.name ? 'selected' : ''} key={index} value={employee.name}>{employee.name}</option>
                                                    )
                                                }
                                            })
                                            }
                                        </select>
                                        :
                                        <input required defaultValue={moneyBillsToUpdate?.recievedFrom} name='recievedFrom' type='text' onChange={handleChange} placeholder="أدخل الإسم" />
                                }
                            </div>


                            <div>
                                <span> المبلغ</span>
                                <input required defaultValue={moneyBillsToUpdate?.amount?.toFixed(2)} name='amount' type='text' onChange={handleChange} placeholder="أدخل المبلغ" />
                            </div>
                        </div>

                        <div className='recipts_modal_body_bottom'>
                            <div>
                                <span> أستلمت بواسطة</span>
                                <input required readOnly defaultValue={login.user.name} type='text' />
                            </div>

                            <div>
                                <span> لاجل </span>
                                <input required defaultValue={moneyBillsToUpdate?.paied_for} name="paied_for" className='recipts_type' id="expenseType" onChange={handleChange} />
                            </div>

                        </div>

                        <div className='recipts_modal_body_bottom'>
                            <div>
                                <span>ملاحظات </span>
                                <input required defaultValue={moneyBillsToUpdate?.description} onChange={handleChange} name='description' type='text' placeholder='... أدخل الملاحظات ' />
                            </div>
                            <div>
                                <span> طريقة الدفع </span>
                                <select required onChange={handleChange} name="payment_method" className='recipts_type'>
                                    <option >  إختر طريقة الدفع </option>
                                    <option selected={moneyBillsToUpdate?.payment_method === "دفع نقدي" ? 'selected' : ''} value="دفع نقدي">دفع نقدي </option>
                                    <option selected={moneyBillsToUpdate?.payment_method === "دفع بنكي" ? 'selected' : ''} value="دفع بنكي"> دفع بنكي</option>

                                </select>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='recipts_modal_footer'>
                        <Button type='submit' variant="primary" >
                            {moneyBillsToUpdate ? 'تعديل السند' : 'اضافة سند'}
                        </Button>
                    </Modal.Footer>

                </Form>
            </Modal>

            <Modal show={showReciepts} onHide={handleClose} className='invoice_modal'>
                <Modal.Header className='contarct_modal_header'>
                    <CloseRoundedIcon onClick={handleClose} />
                    <Modal.Title>
                        سند رقم #{selectedReciepts.id}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Header className='contarct_modal_print'>
                    <i onClick={handlePrint}>
                        <PrintIcon />
                    </i>
                </Modal.Header>

                <Modal.Body className='contarct_modal_body'>
                    <div ref={componentRef} className='reciptsAndExpense_modal_body_container'>

                        <div className='invoice_modal_body_top'>
                            <span>رقم السند #{selectedReciepts.id}</span>
                            <div>
                                <h3>{hotel?.name}</h3>
                                {/* <h5>{hotel?.location}</h5> */}
                            </div>
                            <span>التاريخ : {selectedReciepts.date}</span>
                        </div>

                        <div className='contarct_modal_body_middle'>
                            <h3>  {` سند ${selectedReciepts.type} `} </h3>
                        </div>

                        <div className='reciptsAndExpense_modal_body_info'>
                            <ul>
                                <li>
                                    <span>Recived From Mr./Miss : </span>
                                    <span>{selectedReciepts.recievedFrom}</span>
                                    <span>: استلمت من السيد / السيدة </span>
                                </li>
                                <li>
                                    <span>Amount : </span>
                                    <span>{selectedReciepts.amount?.toFixed(2)}</span>
                                    <span> : مبلغ وقدرة </span>
                                </li>
                                <li>
                                    <span>Payment Method : </span>
                                    <span>{selectedReciepts.payment_method}</span>
                                    <span> : طريقة الدفع </span>
                                </li>
                                <li>
                                    <span>Being : </span>
                                    <span>{`${selectedReciepts.for} / ${selectedReciepts.paied_for}`}</span>

                                    <span> : وذلك مقابل </span>
                                </li>
                                {selectedReciepts.period != null ?
                                    <li>
                                        <span>Period : </span>
                                        <span>{selectedReciepts.period}</span>
                                        <span> : الفترة  </span>
                                    </li>
                                    : selectedReciepts.service != null ?
                                        <li>
                                            <span>Service : </span>
                                            <span>{selectedReciepts.service}</span>
                                            <span> : الخدمة  </span>
                                        </li>
                                        :
                                        null
                                }
                                <li>
                                    <span>Notes : </span>
                                    <span>{selectedReciepts.description}</span>
                                    <span> : ملاحظات </span>
                                </li>
                            </ul>

                        </div>



                        <div className='signature'>
                            <div>
                                <span>المدير</span>
                                <span>{managers?.receiptName}</span>
                            </div>

                            <div>
                                <span>الموظف</span>
                                <span>{selectedReciepts.employee_name}</span>
                            </div>
                        </div>
                        <div className='contract_hotel_info'>
                            <span>{hotel?.name}</span>
                            <span>{hotel?.email}</span>
                            <span>{hotel?.phone}</span>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
