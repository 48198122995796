import React, { useEffect, useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { LoginContext } from '../../context/login';
import axios from 'axios';
import cookies from 'react-cookies';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DatePicker from "react-datepicker";


export default function FinishCurrentReservationModal(props) {
    // To load the token from cookies
    const qs = new URLSearchParams(window.location.search);
    const cookieToken = cookies.load('token');
    const token = qs.get('token') || cookieToken || null;

    const login = useContext(LoginContext);

    const [rent, setRent] = useState();
    const [pricePeriod, setPricePeriod] = useState();
    const [totalPrice, setTotalPrice] = useState();
    const [rePaiedValue, setRePaiedValue] = useState();
    const [whatWePaied, setWhatWePaied] = useState();
    const [lastDate, setLastDate] = useState(new Date());
    const [typeOfRent, setTypeOfRent] = useState();


    useEffect(() => {
        if (props.reservationInfo?.rent_type) {
            setTypeOfRent(props.reservationInfo?.rent_type);
        }
    }, [props.show])

    useEffect(() => {
        calculatePrice();
    }, [lastDate, typeOfRent])

    const setLastDateFunc = date => {
        setLastDate(new Date(date));
    }

    const setPriceFunc = async (e) => {
        setTypeOfRent(e.target.value);
    }

    function calculatePrice() {
        // `${new Date(props.reservationInfo?.checkIn).getDate()}/${new Date(props.reservationInfo?.checkIn).getMonth() + 1}/${new Date(props.reservationInfo?.checkIn).getFullYear()}`

        let startDate = new Date();
        startDate.setFullYear(props.reservationInfo?.loggedIn_At?.split(' ')[2].split('/')[2]);
        startDate.setMonth(props.reservationInfo?.loggedIn_At?.split(' ')[2].split('/')[1] - 1);
        startDate.setDate(props.reservationInfo?.loggedIn_At?.split(' ')[2].split('/')[0]);
        // let endDate = new Date();
        let price = 0.00;

        if (typeOfRent == "يومي") {
            let startPeriod = (new Date(startDate).getTime() / (1000 * 60 * 60 * 24)) - 1;
            let lastPeriod = new Date(lastDate).getTime() / (1000 * 60 * 60 * 24);
            let index = new Date(startDate).getDay();

            let period = {
                period: ((lastPeriod - startPeriod) - 1),
                periodText: "ليلة"
            }
            setPricePeriod(period);

            while (startPeriod < (lastPeriod - 1)) {
                price += props.reservationInfo?.Room?.daily_price[index];
                if (index == 6) {
                    index = 0;
                } else {
                    index += 1;
                }
                startPeriod += 1;
            }
        } else if (typeOfRent == "شهري") {

            let startDay = new Date(startDate).getDate();
            let lastDay = new Date(lastDate).getDate();

            let startMonth = new Date(startDate).getMonth();
            let lastMonth = new Date(lastDate).getMonth();

            let startYear = new Date(startDate).getFullYear();
            let lastYear = new Date(lastDate).getFullYear();

            let differenceDays = lastDay - startDay;
            if (differenceDays > 0) {
                differenceDays = 1;
            } else {
                differenceDays = 0;
            }
            let fullPeriod = ((lastYear - startYear) * 12) + (lastMonth - startMonth) + differenceDays;
            if (fullPeriod == 0) {
                fullPeriod = 1;
            }
            let period = {
                period: fullPeriod?.toFixed(2),
                periodText: "شهر"
            }

            setPricePeriod(period);

            let index = parseInt(period.period);
            // monthely_price

            price = index * props.reservationInfo?.Room?.monthely_price;

        } else if (typeOfRent == "اسبوعي") {
            // let price = 0;

            let startPeriod = (new Date(startDate).getTime() / (1000 * 60 * 60 * 24)) - 1;
            let lastPeriod = new Date(lastDate).getTime() / (1000 * 60 * 60 * 24);

            let period = {
                period: (((lastPeriod - startPeriod) - 1) / 7)?.toFixed(2),
                periodText: "اسبوع"
            }
            let lastAprovedPerdiod = parseInt((((lastPeriod - startPeriod) - 1) / 7)?.toFixed(2));
            if (lastAprovedPerdiod !== period.period) {
                period.period = lastAprovedPerdiod + 1;
            }
            setPricePeriod(period);

            let index = period.period;

            price = index * props.reservationInfo?.Room?.weekly_price;

        }

        let mysetWhatWePaied = 0;
        price = parseFloat((price * props.reservationInfo?.bed_numbers / props.reservationInfo?.Room?.bed_numbers)?.toFixed(2));
        props.recieptsAndExpence.map(item => {
            if (item.type == 'صرف' && item.status !== 'خدمات') {
                price += item.amount;
                mysetWhatWePaied += item.amount;
            }
        })

        setWhatWePaied(mysetWhatWePaied);
        setTotalPrice(price);
        let rePaiedValueTT = price - props.amountPaid;
        setRePaiedValue(rePaiedValueTT);
    }

    const finishReservation = async () => {
        props.setLoadingFunc(true);
        let finishCode = false;

        let myReservation, myRecipte, myExpense, myInvoice;

        let currentdate = new Date(lastDate);
        let datetime = (currentdate.toDateString()).split(' ')[0] + "  " + currentdate.getDate() + "/" + (currentdate.toLocaleDateString()).split('/')[0] + "/" + currentdate.getFullYear() + "  "
            + currentdate.toLocaleTimeString();

        let nights = (new Date(lastDate).getTime() - new Date(props.reservationInfo?.checkIn).getTime()) / (1000 * 60 * 60 * 24);

        let invoiceData = {
            id: props.reservationInfo?.id,
            guest_id: props.reservationInfo?.Guest.id,
            guest_name: props.reservationInfo?.Guest.name,
            guest_phone: props.reservationInfo?.Guest.phone_number,
            reservation_id: props.reservationInfo?.id,
            room_id: props.reservationInfo?.room_id,
            room_name: props.reservationInfo?.room_name,
            date: datetime,
            checkOut: new Date(lastDate).toISOString().split('T')[0],
            checkIn: props.reservationInfo?.loggedIn_At,
            reservation_nights: nights,
            total_price: totalPrice - whatWePaied + props.reservationInfo?.services_price,
            price: totalPrice - props.reservationInfo?.services_price - whatWePaied,
            services_price: props.reservationInfo?.services_price,
            employee_id: login.user.id,
        }
        await axios.post(`${login.API}/newInvoice`, invoiceData, { headers: { 'Authorization': `Basic ${token}` } })
            .then(res => {
                // new activity
                axios.post(`${login.API}/newActivity`, {
                    employee_id: login.user.id,
                    description: `إنشاء فاتورة رقم ${res.data.id} للغرفة ${props.reservationInfo?.room_id} - ${props.reservationInfo?.Room.room_name}`,
                    type: "إنشاء فاتورة"
                },
                    { headers: { 'Authorization': `Basic ${token}` } })
                myInvoice = res.data;

                if (login.setting) {
                    if (login.setting.loggedOut === "on") {
                        let sendedData = {};
                        sendedData.loggedOutMessage = login.setting.loggedOutMessage;
                        if (login.setting.loggedOutCotract === "on") {
                            sendedData.loggedOutCotract = props.reservationInfo?.Contract.id
                        }
                        if (login.setting.loggedOutDate === "on") {
                            let currentdate = new Date(lastDate);
                            let datetime = (currentdate.toDateString()).split(' ')[0] + "  " + currentdate.getDate() + "/" + (currentdate.toLocaleDateString()).split('/')[0] + "/" + currentdate.getFullYear() + "  "
                                + currentdate.toLocaleTimeString();

                            sendedData.loggedOutDate = datetime;
                        }
                        if (login.setting.loggedOutUnit === "on") {
                            sendedData.loggedOutUnit = props.reservationInfo?.Room.room_name
                            sendedData.loggedOutUnitId = props.reservationInfo?.Room.room_id
                        }
                        if (login.setting.loggedOutAmount === "on") {
                            sendedData.loggedOutAmount = parseFloat((props.reservationInfo?.priceWithTax + props.reservationInfo?.services_price)?.toFixed(2))
                        }

                        sendedData.name = props.reservationInfo?.Guest.name;
                        sendedData.email = props.reservationInfo?.Guest.email;
                        sendedData.invoice = `${window.location.hostname}/invoice?id=${res.data.id}`;

                        login.socket.emit("guestLoggedOut", sendedData)
                    }
                }


            });

        await axios.put(`${login.API}/updateReservation`,
            {
                id: props.reservationInfo?.id,
                room_id: props.reservationInfo?.room_id,
                bed_numbers: props.reservationInfo?.bed_numbers,
                status: 'سجل مغادرة',
                loggedOut_At: datetime,
                price: totalPrice - whatWePaied,
                total_price: totalPrice - whatWePaied + props.reservationInfo?.services_price,
            },
            { headers: { 'Authorization': `Basic ${token}` } });
        // new activity
        axios.post(`${login.API}/newActivity`, {
            employee_id: login.user.id,
            description: `تسجيل مغادرة الغرفة ${props.reservationInfo?.room_id} - ${props.reservationInfo?.Room.room_name}`,
            type: "تسجيل مغادرة"
        },
            { headers: { 'Authorization': `Basic ${token}` } })

        let data = {
            id: props.reservationInfo?.id,
            loggedOut_At: datetime,
            paidValue: totalPrice - whatWePaied + props.reservationInfo?.services_price,
            total_price: totalPrice - whatWePaied + props.reservationInfo?.services_price,
            checkOut: new Date(lastDate).toISOString(),
        }
        if (data.id) {

            await axios.put(`${login.API}/updateContract`, data, { headers: { 'Authorization': `Basic ${token}` } })
                .then(async (res) => {
                    // setContract(res.data);
                    if (rePaiedValue > 0) {

                        let params = {}
                        params.type = "قبض"
                        params.employee_id = login.user.id;
                        params.employee_name = login.user.name;
                        params.guest_id = props.reservationInfo?.Guest.id;
                        params.recievedFrom = props.reservationInfo?.Guest.name;
                        params.reservation_id = props.reservationInfo?.id;
                        params.amount = parseFloat(rePaiedValue)?.toFixed(2);
                        params.for = "قيمة إيجار";
                        params.payment_method = "نقدي";
                        params.description = "تم تسجيل مغادرة المستخدم قبل الموعد المحدد، وتم تصفية الحساب بشكل كامل";

                        params.paied_for = props.reservationInfo?.room_id + ' - ' + props.reservationInfo?.Room.room_name

                        let currentdate = new Date(lastDate);
                        let datetime = (currentdate.toDateString()).split(' ')[0] + "  " + currentdate.getDate() + "/" + (currentdate.toLocaleDateString()).split('/')[0] + "/" + currentdate.getFullYear() + "  "
                            + currentdate.toLocaleTimeString();
                        params.date = datetime;

                        await axios.post(`${login.API}/newRecipte`, params, { headers: { 'Authorization': `Basic ${token}` } })
                            .then(res => {
                                myRecipte = res.data;
                                // new activity
                                axios.post(`${login.API}/newActivity`, {
                                    employee_id: login.user.id,
                                    description: `اضافة مقبوضات للغرفة ${props.reservationInfo?.room_id} - ${props.reservationInfo?.Room.room_name}`,
                                    type: "اضافة سند مالي"
                                }, { headers: { 'Authorization': `Basic ${token}` } })
                            });

                    } else if (rePaiedValue < 0) {

                        let params = {};
                        params.type = "صرف"
                        params.employee_id = login.user.id;
                        params.employee_name = login.user.name;
                        params.reciver_id = props.reservationInfo?.Guest.id;
                        params.reciver_name = props.reservationInfo?.Guest.name;
                        params.reservation_id = props.reservationInfo?.id;
                        params.paied_for = `${props.reservationInfo?.room_id} - ${props.reservationInfo?.Room.room_name}`;
                        params.payment_method = "نقدي";
                        params.for = 'مرتجع إيجار';
                        params.description = "تم تسجيل مغادرة المستخدم قبل الموعد المحدد، وتم تصفية الحساب بشكل كامل";
                        params.amount = parseFloat(rePaiedValue * -1)?.toFixed(2);

                        let currentdate = new Date(lastDate);
                        let datetime = (currentdate.toDateString()).split(' ')[0] + "  " + currentdate.getDate() + "/" + (currentdate.toLocaleDateString()).split('/')[0] + "/" + currentdate.getFullYear() + "  "
                            + currentdate.toLocaleTimeString();
                        params.date = datetime;



                        await axios.post(`${login.API}/newExpense`, params, { headers: { 'Authorization': `Basic ${token}` } })
                            .then(res => {

                                myExpense = res.data;
                                // new activity
                                axios.post(`${login.API}/newActivity`, {
                                    employee_id: login.user.id,
                                    description: `اضافة مصروفات للغرفة ${props.reservationInfo?.room_id} - ${props.reservationInfo?.Room.room_name}`,
                                    type: "اضافة سند مالي"
                                }, { headers: { 'Authorization': `Basic ${token}` } })
                            });

                    }
                    // new activity
                    axios.post(`${login.API}/newActivity`, {
                        employee_id: login.user.id,
                        description: `تعديل بيانات العقد ${props.reservationInfo?.Contract?.id}`,
                        type: "تعديل بيانات العقد"
                    }, { headers: { 'Authorization': `Basic ${token}` } })
                });
            // setreservationInfo

        }
        let sendData = {
            reservation_id: props.reservationInfo?.id
        }
        await axios.post(`${login.API}/getReservationId`, sendData, { headers: { 'Authorization': `Basic ${token}` } })
            .then(async res => {
                myReservation = res.data;

                finishCode = true;
            })

        if (finishCode) {
            props.handleClose();
            props.setLoadingFunc(false);
            props.setLogInStatus('سجل مغادرة');

            props.setLoggedOutTime(datetime);

            if (myRecipte) props.setReciepts(myReservation.reciepts);
            if (myExpense) props.setExpenses(myReservation.expense);
            if (myReservation) props.setreservationInfo(myReservation.record);
            if (myInvoice) props.setInvoice(myInvoice);

            props.logedMolakhasAlert();
        }
    }

    return (
        <div>
            <Modal show={props.show} onHide={props.handleClose}>

                <Modal.Header>
                    <button className='closeModal' onClick={() => props.handleClose()}> <CloseRoundedIcon /> </button>
                    <p className='headOffinishReservationRow_Modal'>
                        تصفية حساب العميل وانهاء الحجز
                    </p>
                </Modal.Header>
                {
                    props.reservationInfo &&

                    <Modal.Body>
                        <div className='finishReservationRow_Modal_Container'>
                            <span className='finishReservationRow_Modal'>
                                <span>
                                    {/* {new Date(props.reservationInfo?.checkIn).toLocaleDateString().split("T")[0]} */}
                                    {
                                        props.reservationInfo?.loggedIn_At ?
                                            props.reservationInfo?.loggedIn_At?.split(' ')[2]
                                            : (props.reservationInfo?.checkIn ?
                                                `${new Date(props.reservationInfo?.checkIn).getDate()}/${new Date(props.reservationInfo?.checkIn).getMonth() + 1}/${new Date(props.reservationInfo?.checkIn).getFullYear()}`
                                                : 'Day/Month/Year'
                                            )
                                    }
                                </span>
                                <span> تاريخ البداية </span>
                            </span>
                            <span className='finishReservationRow_Modal'>
                                <span>
                                    <DatePicker
                                        selectsStart
                                        className="inputDataForNewGuest"
                                        dateFormat="dd-MM-yyyy"
                                        selected={lastDate}
                                        onChange={(date) => setLastDateFunc(date)}
                                        name="checkIn"
                                        type="date"
                                        placeholder="checkIn-Date"
                                        required />
                                </span>
                                <span> تاريخ النهاية </span>
                            </span>

                            <span className='finishReservationRow_Modal'>
                                <span> {props.reservationInfo?.Guest?.name} </span>
                                <span> العميل </span>
                            </span>
                            <span className='finishReservationRow_Modal'>
                                <span> {props.reservationInfo?.Room?.room_name} </span>
                                <span> الوحدة </span>
                            </span>

                            <span className='finishReservationRow_Modal'>
                                <span> {props.reservationInfo?.id} # </span>
                                <span> رقم الحجز </span>
                            </span>
                            <span className='finishReservationRow_Modal'>
                                <span>

                                    {
                                        props.reservationInfo?.rent_type == 'يومي' ?
                                            <select id="rent_type" onChange={setPriceFunc} className='finishReservationSelected'>
                                                <option selected value='يومي'> يومي </option>
                                                <option value='اسبوعي'> أسبوعي </option>
                                                <option value='شهري'> شهري </option>
                                            </select> :
                                            props.reservationInfo?.rent_type == 'شهري' ?
                                                <select id="rent_type" onChange={setPriceFunc} className='finishReservationSelected'>
                                                    <option value='يومي'> يومي </option>
                                                    <option value='اسبوعي'> أسبوعي </option>
                                                    <option selected value='شهري'> شهري </option>
                                                </select> :
                                                <select id="rent_type" onChange={setPriceFunc} className='finishReservationSelected'>
                                                    <option value='يومي'> يومي </option>
                                                    <option selected value='اسبوعي'> أسبوعي </option>
                                                    <option value='شهري'> شهري </option>
                                                </select>
                                    }

                                </span>

                                <span> نظام الحجز </span>
                            </span>

                            <span className='finishReservationRow_Modal'>
                                <span> {`${props.reservationInfo?.bed_numbers} / ${props.reservationInfo?.Room?.bed_numbers}`} </span>
                                <span> الأسره المحجوزه </span>
                            </span>

                            <span className='finishReservationRow_Modal'>
                                <span> {props.reservationInfo?.services_price} دينار </span>
                                <span> سعر الخدمات </span>
                            </span>
                            <span className='finishReservationRow_Modal'>
                                <span> {totalPrice} دينار </span>
                                <span> المبلغ الاجمالي </span>
                            </span>
                            <span className='finishReservationRow_Modal'>
                                <span> {props.amountPaid} دينار </span>
                                <span> المبلغ المدفوع </span>
                            </span>

                            {
                                rePaiedValue > 0 ?
                                    <span className='finishReservationRow_Modal'>
                                        <span> {rePaiedValue} دينار </span>
                                        <span> المبلغ المتبقي على العميل</span>
                                    </span> :
                                    <span className='finishReservationRow_Modal'>
                                        <span> {rePaiedValue * -1} دينار </span>
                                        <span> المبلغ المتبقي للعميل </span>
                                    </span>

                            }


                            <span onClick={finishReservation} className='submitFinishReservation'> تصفية وتسجيل مغادرة </span>

                        </div>
                    </Modal.Body>
                }

            </Modal>
        </div>
    )
}
