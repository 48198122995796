import React, { useState, useEffect, useContext } from 'react';
import { Table, Modal, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import cookies from 'react-cookies';
import axios from 'axios';
import swal from 'sweetalert2';
import { LoginContext } from '../../context/login';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';



export default function UnitDepartment(props) {
    // To load the token from cookies
    const qs = new URLSearchParams(window.location.search);
    const cookieToken = cookies.load('token');
    const token = qs.get('token') || cookieToken || null;

    const login = useContext(LoginContext);

    const [departmentsTypes, setDepartmentsTypes] = useState([
        "غرفة نوم فردية",
        "غرفة نوم زوجية",
        "غرفة نوم مع صالة",
    ]);

    const [showModal, setShowModal] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [departmentToUpdate, setDepartmentToUpdate] = useState({});
    useEffect(() => {
        props.setLoadingFunc(true).then(async () => {
            axios.post(`${login.API}/getUnits`, {}, { headers: { 'Authorization': `Basic ${token}` } })
                .then(async res => {
                    setDepartments(res.data.sort((a, b) => a.id - b.id));
                }).then(() => {
                    props.setLoadingFunc(false)
                })
        })


    }, []);

    useEffect(() => {
    }, [departments]);


    const alert = (id) => {
        swal.fire({
            title: "هل أنت متأكد من حذف هذه الوحدة؟",
            text: "! لن تستطيع عكس هذا الإجراء  ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: " ! نعم احذف الوحدة  ",
            cancelButtonText: " ! إلغاء ",
            cancelButtonColor: '#d33',
        })
            .then((result) => {
                if (result.value) {
                    deleteDepartmnent(id);
                    swal.fire(
                        '! تم الحذف بنجاح',
                        '! تم حذف الوحدة بنجاح',
                        'success'
                    )
                }
                else {
                    swal.fire(
                        '! تم الإلغاء',
                        '! تم الإلغاء',
                        'error'
                    )
                }
            });
    };
    const deleteDepartmnent = async (id) => {

        props.setLoadingFunc(true).then(async () => {
            await axios.post(`${login.API}/deleteUnits`, { id: id }
                , { headers: { 'Authorization': `Basic ${token}` } })
                .then((res) => {
                    setDepartments(res.data);
                    props.setLoadingFunc(false);
                });
        })

    }

    const hideModalFunc = (myDepartment) => {
        if (myDepartment) {
            setDepartmentToUpdate(myDepartment);

            let type_id = document.getElementById('type_id');

        }
        setShowModal(!showModal);
    }


    const updateMyDepartment = async (e) => {
        e.preventDefault();

        let myDepartment = {
            id: departmentToUpdate.id,
            info: {
                name: document.getElementById('name').value,
                type: document.getElementById('type_id').value,
            }
        }

        props.setLoadingFunc(true).then(async () => {
            await axios.post(`${login.API}/updateMyDepartment`, myDepartment, { headers: { 'Authorization': `Basic ${token}` } })
                .then((res) => {
                    setDepartments(res.data.sort((a, b) => a.id - b.id));
                    // new activity
                    axios.post(`${login.API}/newActivity`, {
                        employee_id: login.user.id,
                        description: `تعديل معلومات القسم - ${myDepartment.info.name}`,
                        type: "الغرف والاقسام",
                    }, { headers: { 'Authorization': `Basic ${token}` } })
                    // alert

                    new swal({
                        title: "تم تعديل القسم بنجاح  ",
                        text: "Department Added Successfully",
                        icon: "success",
                        button: "okay!",
                    });
                })
                .catch((err) => {
                    // alert
                    new swal({
                        title: "حدث خطأ ما  اثناء التعديل",
                        text: "something went wrong",
                        icon: "warning",
                        button: "try again!",
                    });

                });
            hideModalFunc();
            props.setLoadingFunc(false);

        })


    }

    return (
        <div>
            <div className='navigator'>
                <p> <Link className='linkContainer' to='/'>الرئيسية</Link> / <Link className='linkContainer' to='/unit'>الوحدات</Link> / أقسام الوحدات </p>
            </div>


            <div>
                <Link className='linkContainer' to='/unit/departments/add'>

                    <button className='addUnitBtn'> إضافة قسم </button>
                </Link>
            </div>


            <div>
                <div className='headOfTable'>
                    <p>الاقسام</p>

                </div>

                <div className='bodyOfTable'>
                    <Table striped bordered hover>
                        <thead>
                            {/* رقم الوحدة	الاسم	قسم الوحدة	مفعل	حالة الوحدة	الصورة الرئيسية	الاجرائات */}
                            <tr className='trMainTable'>



                                <th className='setingsOfDepartment'> الاجرائات</th>
                                {/* <th>الصورة</th> */}

                                <th className='setingsOfDepartment'>اسم القسم  </th>

                            </tr>
                        </thead>
                        <tbody>
                            {/* run from database */}



                            {departments.map((department, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <div data-v-0a75d390="" className="action">
                                                {/* <a data-v-0a75d390="" className="takeActionUnit" title="عرض">

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25"
                                                        height="25" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                                    </svg>

                                                </a> */}
                                                <a onClick={() => hideModalFunc(department)} data-v-0a75d390="" className="takeActionUnit" title="تعديل">

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                    </svg>

                                                </a>

                                                <a onClick={() => alert(department.id)} data-v-0a75d390="" className="takeActionUnit" title="حذف">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                    </svg>
                                                </a>

                                            </div>
                                        </td>
                                        <td>{department.name}</td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </Table>
                </div>
                <Modal className='modalToShowImage modalToUpdateDepartment' show={showModal} onHide={hideModalFunc}>
                    <Modal.Header >
                        <button className='closeModal' onClick={() => hideModalFunc()}>
                            <CloseRoundedIcon />
                        </button>
                        {/* <span  > X </span> */}
                        <Modal.Title> تعديل القسم - {departmentToUpdate.name} </Modal.Title>




                    </Modal.Header>

                    <Modal.Body>
                        <Form className="Form" onSubmit={updateMyDepartment} >

                            <Form.Group className="mb-3 departmentRowElement" >
                                <div className='FormElement'>
                                    <select id="type_id" name='type' dusk="type_id" className="w-full form-control form-select">
                                        <option value="">إختر خيار</option>
                                        {
                                            departmentsTypes.map((departmentType, idx) => {
                                                if (departmentType == departmentToUpdate.type) {
                                                    return <option key={idx} value={departmentType} selected>{departmentType}</option>
                                                } else {
                                                    return <option key={idx} value={departmentType}>{departmentType}</option>
                                                }
                                            })
                                        }
                                    </select>
                                </div>
                                <Form.Label className='FormElement' htmlFor="email"> نوع القسم</Form.Label>

                                {/* <img className='imageShowInBigModal' alt="myImage" src={props.image?.src} /> */}
                            </Form.Group>

                            <Form.Group className="mb-3 departmentRowElement" >
                                <Form.Control required className='FormElement' name="name" id="name" type="text" defaultValue={departmentToUpdate.name} placeholder="ادخل الاسم" />
                                <Form.Label htmlFor="name" className='FormElement' > اسم القسم </Form.Label>
                            </Form.Group>

                            <button className='addUnitBtn newDepartmentBtn' type="submit" > تعديل القسم </button>


                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}
